import { AddressApi, IAddress, IAddressPrediction, IGetCasesListParams } from '@libs/api'
import { useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce/lib'
import { IFilterStorage, IURLFilters } from './types'
import qs from 'qs'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import { message } from 'antd'
import { useLocalization } from '@libs/localization'
import { useAuth } from 'modules/auth/hooks'
import { FILTER_STORAGE_KEY, isValidUrlParam } from './helpers'

type FilterKeyType = 'cases' | 'tasks'

export function useAddressSearch(query: string, loadingDebounceTime = 500, branchCountryCode?: string) {
	const { user } = useAuth()
	const { data = [], isLoading } = useQuery(
		[QUERIES.addressAutocomplete, query],
		() =>
			AddressApi.getAddressPredictions({
				query,
				countryCode: (branchCountryCode || user!.branches?.[0]?.countryCode)?.toLowerCase(),
			}),
		{
			enabled: query.length >= 3 && !!user,
		}
	)

	const options = useMemo<{ value: string; data?: IAddressPrediction }[]>(
		() =>
			data.map(prediction => ({
				value: prediction.text,
				data: prediction,
			})),
		[data]
	)

	const [isStillLoading] = useDebounce(isLoading, loadingDebounceTime)

	return { options, isLoading, isStillLoading }
}

export function useAddressSelector(defaultAddress?: IAddress, otherBranchCountryCode?: string) {
	const { user } = useAuth()
	const [address, setAddress] = useState(defaultAddress)
	const [prediction, setPrediction] = useState<IAddressPrediction>()
	const [query, setQuery] = useState(typeof defaultAddress === 'string' ? defaultAddress : defaultAddress?.full ?? '')
	const autocomplete = useAddressSearch(query, undefined, otherBranchCountryCode)

	const countryCode = useMemo(
		() => (otherBranchCountryCode || user?.branches?.[0]?.countryCode)?.toLowerCase(),
		[user, otherBranchCountryCode]
	)

	useQuery({
		enabled: !!user && !!prediction && query.length >= 3 && !!countryCode,
		queryKey: [QUERIES.addressFromPrediction, prediction?.id, countryCode],
		queryFn: async () =>
			countryCode
				? await AddressApi.getAddressFromPrediction({
						prediction: prediction!,
						countryCode,
				  })
				: undefined,
		onSuccess: setAddress,
	})

	return {
		address,
		prediction,
		setPrediction,
		query,
		setQuery,
		autocomplete,
	}
}

export function useParsedFilters<T>(filterKey?: FilterKeyType) {
	const persistedFilters = useGetPersistedFilters<IGetCasesListParams>(filterKey) || ({} as IGetCasesListParams)
	if (filterKey && Object.keys(persistedFilters).length > 0) return persistedFilters as T
	return qs.parse(window.location.search.substr(1)) as unknown as T
}

export function useGetPersistedFilters<T>(filterKey?: 'tasks' | 'cases') {
	return useMemo(() => {
		try {
			const storageValue = JSON.parse(localStorage.getItem(FILTER_STORAGE_KEY) as string) as IFilterStorage<T>
			return filterKey ? storageValue[filterKey] : storageValue
		} catch (err) {
			// noop
		}
	}, [])
}

export function useResetPersistedFilter(filterKey?: 'tasks' | 'cases') {
	return useCallback(() => {
		try {
			let storageValue = JSON.parse(localStorage.getItem(FILTER_STORAGE_KEY) as string)
			if (filterKey) {
				storageValue[filterKey] = {}
			} else {
				storageValue = {
					tasks: {},
					cases: {},
				}
			}
			localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(storageValue))
		} catch (err) {
			// noop
		}
	}, [])
}

export function useURLFilters(filters: IURLFilters, filterKey: 'tasks' | 'cases') {
	const router = useRouter()
	const persistedFilters = useGetPersistedFilters<IURLFilters>()
	useEffect(() => {
		const simpleFilters = Object.keys(filters).reduce((acc, next) => {
			if (isValidUrlParam(filters[next])) {
				acc[next] = filters[next]
			}

			return acc
		}, {} as IURLFilters)

		let newFilters = { ...persistedFilters } as IFilterStorage<IURLFilters>
		if (!newFilters) {
			newFilters = {
				cases: {},
				tasks: {},
			}
		}

		try {
			if (!(filters.branchIds?.length === 0 && filters.category?.length === 0 && filters.status?.length === 0)) {
				newFilters[filterKey] = {
					...filters,
					status:
						filterKey === 'tasks'
							? (filters.status as string[]).filter(status => status !== 'COMPLETE')
							: filters.status,
				}
				localStorage.setItem(FILTER_STORAGE_KEY, JSON.stringify(newFilters))
			}
		} catch (ex) {
			// noop
		}

		const newQueryString = qs.stringify(simpleFilters, {
			arrayFormat: 'brackets',
			addQueryPrefix: true,
			encode: false,
			serializeDate: date => dayjs(date).format('YYYY-MM-DD'),
		})

		if (newQueryString !== window.location.search) {
			const newUrl = `${router.pathname}${newQueryString}`
			const isNormalizationUpdate =
				Object.keys(filters).filter(key => filters[key] !== undefined).length !==
				Object.keys(qs.parse(window.location.search.substring(1))).length

			// If only part of the filters are present in the query string
			// then we consider this as a normalization of the query string
			// case in which we replace the history state instead of pushing a new one
			if (isNormalizationUpdate) {
				router.replace(newUrl, newUrl, {})
			} else {
				router.push(newUrl, newUrl, {})
			}
		}
	}, [filters])
}

const BUILD_ID = process.env.BUILD_ID ?? 'development'
export function useBuildId() {
	const { t } = useLocalization()
	const onBuildIdClick = useCallback(() => {
		if (navigator?.clipboard) {
			navigator?.clipboard.writeText(BUILD_ID)
			message.success(t('common.messages.buildIdCopiedToClipboard'))
		}
	}, [t])

	return {
		buildId: BUILD_ID,
		onBuildIdClick,
	}
}
