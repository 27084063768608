import React, { FC, useMemo } from 'react'
import { Grid, Container } from '@libs/components'
import { useLocalization } from '@libs/localization'
import dayjs, { Dayjs } from 'dayjs'
import Styled from './Styled'
import { IPhotographerBookingData } from '@libs/api'

// Why is this component not in packages/components?

export interface ITimeSelector {
	value?: Dayjs
	onChange: (newValue: ITimeSelector['value']) => void
	availableDates: IPhotographerBookingData['start'][]
}

const TimeSelector: FC<ITimeSelector> = ({ value, onChange, availableDates }) => {
	const { f } = useLocalization()

	const timeOptions = useMemo(() => {
		const availableStartTime: Dayjs[] = []
		let currentTime = dayjs('08:00', 'HH:00')
		const dayWorktimeLimit = dayjs('17:00', 'HH:00')

		while (currentTime.diff(dayWorktimeLimit, 'h') <= 0) {
			availableStartTime.push(dayjs(currentTime))
			currentTime = currentTime.add(1, 'hour')
		}

		return availableStartTime
	}, [])

	return (
		<Container>
			<Grid.Row gutter={['sm', 'xxs']}>
				{timeOptions.map((timeDate, index) => (
					<Grid.Col xs={8} key={index}>
						<Styled.TimeButton
							disabled={!availableDates.find(date => dayjs(date).get('hours') === timeDate.get('hours'))}
							size="small"
							onClick={() => onChange(timeDate)}
							type={value?.isSame(timeDate, 'hours') ? 'primary' : 'default'}
						>
							{f(timeDate.toDate(), { time: 'short' })}
						</Styled.TimeButton>
					</Grid.Col>
				))}
			</Grid.Row>
		</Container>
	)
}

export default TimeSelector
