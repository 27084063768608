import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react'
import { Container, Grid } from '@libs/components'
import { COLLAGE_OUTPUT_HEIGHT, COLLAGE_OUTPUT_WIDTH, COLLAGE_TEMPLATES } from '../CollageEditor/consts'

import Styled from './Styled'
import { Typography } from 'antd'
import { useScreenMatch } from '@libs/theme'
import { Radio } from 'formik-antd'
import { useLocalization } from '@libs/localization'
import { useFormikContext } from 'formik'
import { ICollageFormValues } from '.'

const TemplateSelectionStep = () => {
	const { t } = useLocalization()
	const match = useScreenMatch()
	const { values, setFieldValue } = useFormikContext<ICollageFormValues>()
	const firstTemplateRef = useRef() as RefObject<HTMLDivElement>
	const [itemHeight, setItemHeight] = useState(0)

	const recalculateSizes = useCallback(() => {
		if (!firstTemplateRef.current?.clientWidth) return
		const ratio = firstTemplateRef.current?.clientWidth / COLLAGE_OUTPUT_WIDTH

		setItemHeight(ratio * COLLAGE_OUTPUT_HEIGHT)
	}, [])

	useEffect(() => {
		recalculateSizes()
	}, [recalculateSizes])

	useEffect(() => {
		// reinit template images with empty values
		setFieldValue(
			'images',
			COLLAGE_TEMPLATES[values.template].items.map(() => undefined)
		)
		setFieldValue(
			'outputImages',
			COLLAGE_TEMPLATES[values.template].items.map(() => undefined)
		)
	}, [values.template, setFieldValue])

	const isCardSmall = match.map({ xxs: false, sm: true, lg: false })

	return (
		<Container>
			<Radio.Group name="template" value={values.template}>
				<Grid.Row style={{ flex: 1 }} gutter="sm">
					{COLLAGE_TEMPLATES.map(({ grid, items, layout }, index) => (
						<Grid.Col
							{...(index === 0 && {
								ref: firstTemplateRef,
							})}
							key={index}
							xs={24}
							sm={12}
							md={8}
							style={{ height: itemHeight }}
						>
							<Styled.TemplateItem
								selected={index === values.template}
								$smallSize={isCardSmall}
								onClick={() => {
									if (index !== values.template) setFieldValue('template', index)
								}}
							>
								<Container display="flex" flexDirection="column" justifyContent="center" alignItems="center" space="sm">
									<Styled.GridTemplateItem $gridArea={grid} $layout={layout}>
										{items.map(({ area }, itemIndex) => (
											<div style={{ gridArea: area }} key={itemIndex} />
										))}
									</Styled.GridTemplateItem>
									<Styled.TemplateItemFooter $smallSize={isCardSmall}>
										<Radio name="template" value={index}>
											{match.gte('xxl') ? (
												<Typography.Title level={5}>
													{t('common.terms.template')} #{index + 1}
												</Typography.Title>
											) : (
												<Typography.Text strong>
													{t('common.terms.template')} #{index + 1}
												</Typography.Text>
											)}
										</Radio>
									</Styled.TemplateItemFooter>
								</Container>
							</Styled.TemplateItem>
						</Grid.Col>
					))}
				</Grid.Row>
			</Radio.Group>
		</Container>
	)
}

export default TemplateSelectionStep
