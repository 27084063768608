export const fixBookingTimeToInterval = (minutesForTask: number) => {
	const loweredMinutes = Math.floor(minutesForTask / 10) * 10
	let minimumMinutes = 0

	if (loweredMinutes < 60) {
		minimumMinutes = loweredMinutes - 15 <= 0 ? 0 : loweredMinutes - 15
	} else if (loweredMinutes < 240) {
		minimumMinutes = loweredMinutes - 30
	} else {
		minimumMinutes = loweredMinutes - 60
	}

	return `${minimumMinutes}-${loweredMinutes} min`
}
