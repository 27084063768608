import React, { FC, useCallback, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Button, Divider, message } from 'antd'
import { useLocalization } from '@libs/localization'
import { Grid, useHideModal, UserInteraction } from '@libs/components'
import { IDatahubCase, ISimplifiedVisualMaterial, MaterialApi } from '@libs/api'
import { MaterialItem } from 'modules/material/MaterialItem'
import Styled from './Styled'
import QUERIES from 'modules/common/queries'
import { Close24 } from '@carbon/icons-react'
import { useScreenMatch } from '@libs/theme'

export interface CaseCombineImagesModalProps {
	materials: ISimplifiedVisualMaterial[]
	caseId: IDatahubCase['id']
}

const CaseCombineImagesModal: FC<CaseCombineImagesModalProps> = ({ materials, caseId }) => {
	const [selectedMaterials, setSelectedMaterials] = useState<ISimplifiedVisualMaterial[]>([])
	const hideModal = useHideModal()
	const { t } = useLocalization()
	const queryClient = useQueryClient()
	const match = useScreenMatch()

	const { mutateAsync: submitCombineRequest, isLoading } = useMutation(MaterialApi.combineImages, {
		onSuccess: response => {
			if (response?.success) {
				message.success(t('modals.combineImages.submittedSuccessfully'))
				queryClient.invalidateQueries([QUERIES.visualMaterialsSimplified, caseId])
				hideModal()
			} else {
				message.error(t('modals.combineImages.submitError'))
			}
		},
	})

	const filteredMaterials = useMemo(() => {
		const verticalMaterials = materials.filter(({ sourceOrientation }) => sourceOrientation === 'Portrait')

		return verticalMaterials.map<ISimplifiedVisualMaterial>(material => ({
			...material,
			isSelected: !!selectedMaterials.find(({ id }) => material.id === id),
		}))
	}, [materials, selectedMaterials])

	const areThereImagesToCombine = filteredMaterials.length > 1

	const selectMaterialItem = useCallback(
		materialId => {
			const isMaterialAlreadySelected = selectedMaterials.find(({ id }) => id === materialId)

			// if 2 materials already selected and user selects other one - do nothing
			if (selectedMaterials.length === 2 && !isMaterialAlreadySelected) return

			const material = filteredMaterials.find(({ id }) => id === materialId)

			setSelectedMaterials((state): ISimplifiedVisualMaterial[] => {
				if (isMaterialAlreadySelected) {
					return state.filter(({ id }) => id !== materialId)
				} else {
					return state ? [...state, material!] : [material!]
				}
			})
		},
		[selectedMaterials, filteredMaterials]
	)

	const handleSubmit = () => {
		submitCombineRequest({
			caseId,
			leftImageAssetId: selectedMaterials[0].id.toString(),
			rightImageAssetId: selectedMaterials[1].id.toString(),
		})
	}

	const NoImagesToCombineMessage: FC = () => <span>{t('modals.combineImages.noImages')}</span>

	const FooterButton: FC = () =>
		areThereImagesToCombine ? (
			<Button loading={isLoading} type={'primary'} disabled={selectedMaterials.length < 2} onClick={handleSubmit}>
				{t('modals.combineImages.combine')}
			</Button>
		) : (
			<Button type={'primary'} onClick={hideModal}>
				{t('common.actions.close')}
			</Button>
		)

	return (
		<Styled.Modal
			visible
			width={1300}
			closeIcon={<Close24 />}
			title={t('tasks.categories.COMBINE_IMAGES')}
			onCancel={hideModal}
			footer={<FooterButton />}
			centered
		>
			{areThereImagesToCombine ? (
				<UserInteraction enabled={!isLoading}>
					<Styled.Description>{t('modals.combineImages.description')}</Styled.Description>
					<Grid.Row gutter="sm">
						{filteredMaterials.map(material => {
							const isSelected = !!selectedMaterials.find(({ id }) => material.id === id)
							const isActive = selectedMaterials.length === 2 ? isSelected : true

							return (
								<Grid.Col
									xs={24}
									sm={12}
									md={6}
									xl={4}
									key={material.id}
									onClick={() => selectMaterialItem(material.id)}
								>
									<MaterialItem
										{...material}
										isActive={isActive}
										selectable={isActive}
										showDisabledInteraction={selectedMaterials.length === 2 ? !isSelected : false}
										hideInfo
										forceVerticalLayout
										showHighQualityImage
										tags={material.label ? [material.label.translateKey] : []}
									/>
								</Grid.Col>
							)
						})}
					</Grid.Row>
					{selectedMaterials.length > 0 && (
						<>
							<Divider />
							<Styled.Description>{t('modals.combineImages.selectedImages')}</Styled.Description>
							<Grid.Row gutter="sm">
								{selectedMaterials.map((material, index) => (
									<Grid.Col xs={12} xl={6} offset={index === 0 && match.gte('xl') ? 6 : 0} key={material.id}>
										<MaterialItem
											dragDisabled
											disabledInactiveOverlay
											hideInfo
											forceVerticalLayout
											showHighQualityImage
											{...material}
											tags={material.label ? [material.label.translateKey] : []}
										/>
									</Grid.Col>
								))}
							</Grid.Row>
						</>
					)}
				</UserInteraction>
			) : (
				<NoImagesToCombineMessage />
			)}
		</Styled.Modal>
	)
}

export default CaseCombineImagesModal
