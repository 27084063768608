import styled from 'styled-components'
import { padding, borderRadius, color } from '@libs/theme/mixins'

const CardWrapper = styled.div`
	.ant-card-body {
		padding: ${padding('sm')};
	}

	.ant-divider-vertical {
		height: 50px;
	}
`

const WarningNotice = styled.div`
	border: 1px solid ${color(['primary', '200'])};
	border-radius: ${borderRadius('md')};
	text-align: center;
	padding: ${padding('xxs')};
`

export default {
	CardWrapper,
	WarningNotice,
}
