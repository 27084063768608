import { MaterialApi, ISimplifiedVisualMaterial, IDatahubCase } from '@libs/api'
import { useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import { SortEndHandler } from 'react-sortable-hoc'
import { useEffect, useMemo, useCallback, useState } from 'react'
import isEqual from 'lodash/isEqual'
import arrayMove from 'array-move'
import { IMaterialList } from './MaterialList'
import { ICopyMaterialItem } from './CopyMaterialModal'
import { useBranchSearch } from 'modules/auth/ActiveDirectoryPageContent/hooks'
import { useCaseFilterOptions, useCasesTable } from 'modules/cases/hooks'
import queries from 'modules/common/queries'

const DEFAULT_MATERIAL_RESPONSE = {
	batchList: [],
	materials: [],
	links: [],
}

export const useVisualMaterials = (caseId?: IDatahubCase['id'], enabled = true) => {
	const {
		data = DEFAULT_MATERIAL_RESPONSE,
		isLoading,
		isFetched,
	} = useQuery(
		[QUERIES.visualMaterialsSimplified, caseId],
		async () => (caseId ? await MaterialApi.getSimplifiedVisualMaterials(caseId) : undefined),
		{
			enabled: enabled && !!caseId,
		}
	)
	const [innerVisualMaterials, setInnerVisualMaterials] = useState(data.materials || [])
	const [filteredMaterialIds, setFilteredMaterialIds] = useState<string[]>([])

	const isSortOrderHasChanged = useMemo(
		() => !isEqual(data.materials, innerVisualMaterials),
		[data.materials, innerVisualMaterials]
	)

	useEffect(() => {
		if (data.materials) {
			setInnerVisualMaterials(data.materials)
		}
	}, [data])

	const onSortEnd: SortEndHandler = ({ newIndex, oldIndex }) => {
		document.body.style.cursor = 'default'
		setInnerVisualMaterials(arrayMove(innerVisualMaterials, oldIndex, newIndex))
	}

	const onSortCancel = () => setInnerVisualMaterials(data.materials || [])

	const selectMaterialItem: IMaterialList['onItemClick'] = useCallback(
		materialId => {
			setInnerVisualMaterials(
				innerVisualMaterials.map(material => ({
					...material,
					isSelected: material.id === materialId ? !material.isSelected : material.isSelected,
				}))
			)
		},
		[innerVisualMaterials]
	)

	const toggleSelectAllMaterials = useCallback(
		(selected: boolean) => {
			setInnerVisualMaterials(
				innerVisualMaterials.map(material => ({
					...material,
					isSelected: selected ? (filteredMaterialIds.includes(material.id) ? true : material.isSelected) : false,
				}))
			)
		},
		[innerVisualMaterials, filteredMaterialIds]
	)

	const setActivationStatusForSelected = (materialIsActive: ISimplifiedVisualMaterial['isActive']) => {
		setInnerVisualMaterials(
			innerVisualMaterials.map(material => ({
				...material,
				isActive: material.isSelected ? materialIsActive : material.isActive,
			}))
		)
	}

	const onDescriptionEdit = (
		description: ISimplifiedVisualMaterial['description'],
		materialId: ISimplifiedVisualMaterial['id']
	) => {
		setInnerVisualMaterials(prev =>
			prev.map(material => ({
				...material,
				description: material.id === materialId ? description : material.description,
			}))
		)
	}

	return {
		visualMaterials: innerVisualMaterials,
		isLoading,
		isFetched,
		isSortOrderHasChanged,
		onSortEnd,
		onSortCancel,
		onDescriptionEdit,
		selectMaterialItem,
		toggleSelectAllMaterials,
		setActivationStatusForSelected,
		setFilteredMaterialIds,
		filteredMaterialIds,
		batchList: data.batchList,
		externalLinks: data.links,
	}
}

export const useCopyMaterialItem = ({ currentItem }: { currentItem: ICopyMaterialItem }) => {
	const [departmentSearchKey, setDepartmentSearchKey] = useState('')
	const [caseSearchKey, setCaseSearchKey] = useState('')
	const { data: departments } = useBranchSearch(departmentSearchKey)
	const { categoryValues, statusValues } = useCaseFilterOptions()

	const { dataSource: cases } = useCasesTable(
		{
			pageSize: 99,
			departmentId: currentItem.departmentId ? [currentItem.departmentId] : undefined,
			query: caseSearchKey,
			category: categoryValues,
			status: statusValues,
		},
		undefined,
		!!currentItem.departmentId && !!caseSearchKey && caseSearchKey.length >= 3
	)

	return {
		departments: departments || [],
		cases: cases || [],
		caseSearchKey,
		setDepartmentSearchKey,
		setCaseSearchKey,
	}
}

export const useCollagePricing = (caseId?: IDatahubCase['id']) =>
	useQuery(
		[queries.collagePricing, caseId],
		async () => (caseId ? await MaterialApi.getCollagePrice(caseId) : undefined),
		{
			enabled: !!caseId,
		}
	)
