import React, { FC } from 'react'
import { IAddress } from '@libs/api'
import { AutoComplete, Typography } from 'antd'
import { Edit24 } from '@carbon/icons-react'
import { useEditableAddress } from './hooks'
import Styled from './Styled'

export interface EditableAddressProps {
	address?: IAddress
	updateAddress: (address: IAddress) => Promise<boolean> | void
	otherBranchCountryCode?: string
}

const EditableAddress: FC<EditableAddressProps> = ({ address, updateAddress, otherBranchCountryCode }) => {
	const {
		t,
		ref,
		title,
		editActive,
		addressInputRef,
		addressOptions,
		addressPlaceholder,
		providerSupportsSubpremise,
		setSearchKey,
		setEditActive,
		setTitle,
		showModal,
	} = useEditableAddress({ address: address?.full || '', otherBranchCountryCode })

	return (
		<Styled.Editable>
			<div ref={ref}>
				{!title && !editActive && <Styled.EmptyAddress level={4}>{addressPlaceholder}</Styled.EmptyAddress>}
				{editActive ? (
					<>
						<AutoComplete
							ref={addressInputRef}
							options={addressOptions}
							defaultValue={title}
							onSearch={setSearchKey}
							placeholder={addressPlaceholder}
							onBlur={() => setTimeout(() => setEditActive(false), 300)}
							onSelect={async (value, option) => {
								setTitle(value)
								setEditActive(false)
								value && (await updateAddress(option.data?.__internal_address))
							}}
						/>
						<Styled.EditButton
							type="link"
							onClick={e => {
								e.stopPropagation()
								showModal('address.edit', {
									address,
									onSubmit: async (customAddress: IAddress) => {
										await updateAddress(customAddress)
										setEditActive(false)
									},
								})
							}}
						>
							{t('common.actions.editManually')}
						</Styled.EditButton>
					</>
				) : (
					<>
						<Typography.Title level={5}>{title}</Typography.Title>
						<Edit24
							onClick={() => {
								if (providerSupportsSubpremise) {
									setEditActive(true)
								} else {
									showModal('address.edit', { address, onSubmit: updateAddress })
								}
							}}
						/>
					</>
				)}
			</div>
		</Styled.Editable>
	)
}

export default EditableAddress
