import styled from 'styled-components'
import { Container as _Container } from '@libs/components'

const Container = styled(_Container)`
	.ant-steps-item:not(.ant-steps-item-finish) {
		cursor: default;
	}
`

export default {
	Container,
}
