import styled from 'styled-components'
import { Modal as _Modal } from 'antd'
import { easing } from '@libs/theme/mixins'

const Modal = styled(_Modal)<{ $transitionTimeout: number }>`
	max-width: 90vw;

	.ant-steps {
		width: 100%;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}

	// Transition animations
	.transition-enter {
		opacity: 0;
	}

	.transition-enter-active {
		opacity: 1;
		transition: all ${({ $transitionTimeout }) => `${$transitionTimeout}ms`} ${easing('in')};
	}

	.transition-exit {
		opacity: 0;
	}

	.transition-exit-active {
		opacity: 1;
		transition: all ${({ $transitionTimeout }) => `${$transitionTimeout}ms`} ${easing('in')};
	}
`

export default { Modal }
