import styled from 'styled-components'
import { borderRadius, color, height, padding, screen } from '@libs/theme/build/mixins'
import { Button } from 'antd'

const Task = styled.div`
	display: flex;
	align-items: center;
	flex: 1;
	height: ${height('md')};
	padding: ${padding(0, 'sm')};
	border: 1px solid ${color(['primary', '200'])};
	border-radius: ${borderRadius('sm')};

	${screen.lte.sm`
		height: ${height('sm')};
		flex: 0 0 calc(100% - ${height('sm')});
	`}
`

const TaskButton = styled(Button)`
	width: 200px;
	flex: 0 0 auto;
	${screen.lte.sm`
		width: auto;
	`}
`

export default {
	Task,
	TaskButton,
}
