import { color, rgbaColor } from '@libs/theme/mixins'
import styled from 'styled-components'
import { Container } from '@libs/components'
import { Typography } from 'antd'

const Wrapper = styled.div`
	.ant-divider-vertical {
		height: 100%;
	}
`

const SpinnerOverlay = styled(Container)`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 296px;
	z-index: 1051;
	background: ${rgbaColor('white', 0.75)};
`

const ArrivalTime = styled(Typography.Text)`
	color: ${color('primary')};
`

export default {
	Wrapper,
	ArrivalTime,
	SpinnerOverlay,
}
