import { padding, scrollbar } from '@libs/theme/mixins'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{ $scrollableAtHeight?: string }>`
	& .ant-row > div {
		overflow: visible !important;
	}

	${({ $scrollableAtHeight }) =>
		$scrollableAtHeight &&
		css`
			max-height: ${$scrollableAtHeight};
			overflow-x: hidden;
			overflow-y: auto;
			padding-right: ${padding('xxs')};
			${scrollbar()}
		`}
`

export default {
	Wrapper,
}
