import React, { FC, useEffect, useMemo, useState } from 'react'
import { FixedCropper, CropperRef, ImageRestriction } from 'react-advanced-cropper'
import { ICollageCell } from './types'
import { useDebouncedCallback } from 'use-debounce/lib'

import Styled from './Styled'
import { Spin, Typography } from 'antd'
import { useLocalization } from '@libs/localization'
import { Container } from '@libs/components'
import { useDrop } from 'react-dnd'
import { ISimplifiedVisualMaterial } from '@libs/api'

export interface CollageCellProps extends ICollageCell {
	imageUrl?: string
	containerSizes?: {
		width: number
		height: number
	}
	onChangeSource: (dataUrl: string) => void
	onChangeOutput: (dataUrl: string) => void
}

const CollageCell: FC<CollageCellProps> = React.memo(
	({
		area,
		containerSizes = {
			width: 0,
			height: 0,
		},
		columnSpan,
		rowSpan,
		imageUrl,
		onChangeSource,
		onChangeOutput,
	}) => {
		const { t } = useLocalization()
		const [isImageLoaded, setIsImageLoaded] = useState(false)
		const sizes = useMemo<{
			width: number
			height: number
		}>(() => {
			return {
				width: columnSpan === 2 ? containerSizes.width : containerSizes.width / 2,
				height: rowSpan === 2 ? containerSizes.height : containerSizes.height / 2,
			}
		}, [rowSpan, columnSpan, containerSizes.width, containerSizes.height])
		const [, drop] = useDrop(
			() => ({
				accept: 'asset',
				drop: (item: ISimplifiedVisualMaterial) => {
					// try to disable cache when loading the same image for Safari CORS issue
					onChangeSource(`${item.largestSource}#${Date.now()}` || `${item.source}#${Date.now()}`)
					setIsImageLoaded(false)
				},
			}),
			[]
		)

		const onCropperChange = useDebouncedCallback((cropper: CropperRef) => {
			const canvas = cropper.getCanvas()
			if (canvas) {
				onChangeOutput(canvas.toDataURL())
			}
		}, 500)

		useEffect(() => {
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'))
			}, 300)
		}, [containerSizes.width])

		if (!containerSizes.width) return null

		return (
			<Styled.CollageItemWrapper ref={drop} gridArea={area} min={sizes} max={sizes}>
				<FixedCropper
					src={imageUrl}
					defaultSize={sizes}
					onChange={onCropperChange}
					stencilProps={{
						handlers: false,
						movable: false,
						resizable: false,
					}}
					onReady={cropper =>
						setTimeout(() => {
							cropper?.zoomImage(0.0001)
							setIsImageLoaded(true)
						}, 1)
					}
					style={{ cursor: 'move' }}
					stencilSize={sizes}
					imageRestriction={ImageRestriction.stencil}
				/>
				{imageUrl ? (
					!isImageLoaded && (
						<Styled.LoaderWrapper>
							<Spin />
						</Styled.LoaderWrapper>
					)
				) : (
					<Styled.DropArea>
						<Container display="flex" justifyContent="center" space="sm" spaceDirection="vertical">
							<Typography.Text type="secondary">{t('common.actions.dropImage')}</Typography.Text>
						</Container>
					</Styled.DropArea>
				)}
			</Styled.CollageItemWrapper>
		)
	},
	(prev, next) => {
		if (
			prev.imageUrl !== next.imageUrl ||
			prev.containerSizes?.width !== next.containerSizes?.width ||
			prev.columnSpan !== prev.columnSpan ||
			prev.rowSpan !== next.rowSpan ||
			prev.area !== next.area
		)
			return false

		return true
	}
)

export default CollageCell
