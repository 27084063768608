import { Close24 } from '@carbon/icons-react'
import { useHideModal } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { ANT_MODAL_VERTICAL_FOOTER_CLASS_NAME } from '@libs/theme'
import { Button, Typography, Modal } from 'antd'
import React, { FC } from 'react'

export interface OrderAlreadyOpenedModalProps {
	onConfirm: () => void
}

const OrderAlreadyOpenedModal: FC<OrderAlreadyOpenedModalProps> = ({ onConfirm }) => {
	const hideModal = useHideModal()

	const { t } = useLocalization()

	return (
		<Modal
			title={t('modals.openOrderWarning.title')}
			visible
			closeIcon={<Close24 />}
			className={ANT_MODAL_VERTICAL_FOOTER_CLASS_NAME}
			onCancel={hideModal}
			footer={[
				<Button
					key="yes"
					type="primary"
					onClick={async () => {
						await onConfirm()
						hideModal()
					}}
				>
					{t('common.terms.yes')}
				</Button>,
				<Button key="no" onClick={hideModal}>
					{t('common.terms.no')}
				</Button>,
			]}
		>
			<Typography.Paragraph>{t('modals.openOrderWarning.message')}</Typography.Paragraph>
		</Modal>
	)
}

export default OrderAlreadyOpenedModal
