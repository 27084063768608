import React, { FC, Fragment, ReactNode, RefObject, useCallback, useEffect, useRef, useState } from 'react'
import Styled from './Styled'
import { getContainerScale, getPopoverPlacement } from './utils'
import Shapes from './Shapes'
import { ARROW_SIZE, POINT_SIZE, LASSO_BORDER_WIDTH, LABEL_SIZE } from './constants'
import { FeedbackDrawingShape, FeedbackDrawingType } from '@libs/api'
import {
	dividePoints,
	fitPointToSize,
	getPointInElement,
	isSameOrChildElement,
	multiplyPoints,
	normalizeAreaCoords,
	PointType,
	AreaType,
	getPositionOfArea,
	getSquareDimensionsOfArea,
	getCenterOfArea,
} from '@libs/util'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

export interface IFeedbackCanvasProps {
	mode: FeedbackDrawingType['shape']
	items: FeedbackDrawingType[]
	selectedItemIndex: number
	selectedImageIndex?: number
	popoverContent: (item: FeedbackDrawingType, index: number, blurSupport?: boolean) => ReactNode
	onSelectionChange: (itemIndex: number) => void
	onAddItem: (item: FeedbackDrawingType) => void
	onRemoveItem: (item: FeedbackDrawingType, index: number) => void
	selectedImageSource?: string
	containerRef?: RefObject<HTMLDivElement>
}

const DRAWING_TRANSITION_DURATION = 100

const FeedbackCanvas: FC<IFeedbackCanvasProps> = ({
	mode,
	items,
	selectedItemIndex,
	selectedImageIndex,
	selectedImageSource,
	popoverContent,
	onSelectionChange,
	onAddItem,
	onRemoveItem,
	containerRef,
}) => {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const wrapperSize: PointType = [wrapperRef.current?.clientWidth ?? 0, wrapperRef.current?.clientHeight ?? 0]
	const [isDrawing, setIsDrawing] = useState(false)
	const [arrowStartPoint, setArrowStartPoint] = useState<PointType>([0, 0])
	const [arrowEndPoint, setArrowEndPoint] = useState<PointType>([0, 0])
	const [lassoShape, setLassoShape] = useState<AreaType>([])
	const wasDrawingRef = useRef(isDrawing)
	const isPoint = mode === FeedbackDrawingShape.Point
	const isArrow = mode === FeedbackDrawingShape.Arrow
	const isLasso = mode === FeedbackDrawingShape.Lasso
	const [scale, setScale] = useState<[number, number] | undefined>()
	const [imageSizes, setImageSizes] = useState<[number, number]>([0, 0])

	const getLabelPointForLasso = useCallback(
		(area: AreaType): PointType => {
			const [canvasWidth, canvasHeight] = imageSizes
			const [areaWidth, areaHeight] = getSquareDimensionsOfArea(area, LASSO_BORDER_WIDTH)
			const [areaPosX, areaPosY] = getPositionOfArea(area)

			if (areaWidth > LABEL_SIZE && areaHeight > LABEL_SIZE) {
				// inside of area
				const centerOfArea = getCenterOfArea(area, [LABEL_SIZE, LABEL_SIZE])
				return [areaPosX + centerOfArea[0], areaPosY + centerOfArea[1]]
			} else if (canvasWidth - areaPosX - areaWidth > LABEL_SIZE && canvasHeight - areaPosY - areaHeight > LABEL_SIZE) {
				// bottom right
				return [areaPosX + areaWidth, areaPosY + areaHeight]
			} else if (canvasWidth - areaPosX - areaWidth > LABEL_SIZE && canvasHeight - areaPosY - areaHeight < LABEL_SIZE) {
				// top right
				return [areaPosX + areaWidth, areaPosY - LABEL_SIZE]
			} else if (canvasWidth - areaPosX - areaWidth < LABEL_SIZE && canvasHeight - areaPosY - areaHeight < LABEL_SIZE) {
				// top left
				return [areaPosX - LABEL_SIZE, areaPosY - LABEL_SIZE]
			} else {
				// bottom left
				return [areaPosX - LABEL_SIZE, areaPosY + areaHeight]
			}
		},
		[imageSizes]
	)

	const updateScale = useCallback(() => {
		setScale(containerRef?.current ? getContainerScale(containerRef.current, imageSizes) : undefined)
	}, [setScale, imageSizes, containerRef])

	// Reset scale on window resize
	useEffect(() => {
		// Set initial scale
		updateScale()

		// Set scale on resize
		window.addEventListener('resize', updateScale)
		return () => {
			window.removeEventListener('resize', updateScale)
		}
	}, [updateScale])

	useEffect(() => {
		if (selectedImageSource) {
			const img = new Image()
			img.onload = function () {
				setImageSizes([img.width, img.height])
			}
			img.src = selectedImageSource
		}
	}, [selectedImageSource])

	// after exporting canvas client height becomes messed up on next mounting,
	// added to dependency array to avoid scale issues

	// Arrow: handle mouse up anywhere on the screen
	useEffect(() => {
		const onMouseUp = () => {
			setIsDrawing(false)
		}
		window.addEventListener('mouseup', onMouseUp, true)
		window.addEventListener('touchend', onMouseUp, true)
		return () => {
			window.removeEventListener('mouseup', onMouseUp)
			window.removeEventListener('touchend', onMouseUp)
		}
	}, [])

	// Arrow: when stopped drawing let parent component know a new item was added
	useEffect(() => {
		if (!isDrawing && wasDrawingRef.current && (isArrow || isLasso)) {
			if (isArrow) {
				onAddItem({
					shape: FeedbackDrawingShape.Arrow,
					p0: arrowStartPoint,
					p1: arrowEndPoint,
				})
			}

			if (isLasso) {
				if (lassoShape.length < 15) {
					// to exclude accidental clicks
					return
				}

				onAddItem({
					shape: FeedbackDrawingShape.Lasso,
					points: lassoShape,
					labelPoint: getLabelPointForLasso(lassoShape),
				})
			}
		}

		wasDrawingRef.current = isDrawing
	}, [isDrawing, isArrow, isLasso, lassoShape, mode, onAddItem, arrowStartPoint, arrowEndPoint, getLabelPointForLasso])

	const handleStartDrawing = (target: HTMLElement, x: number, y: number) => {
		if (wrapperRef.current && isSameOrChildElement(wrapperRef.current, target) && (isArrow || isLasso) && scale) {
			setIsDrawing(true)
			const point = dividePoints(getPointInElement(wrapperRef.current, [x, y]), scale)

			if (isArrow) {
				setArrowStartPoint(point)
				setArrowEndPoint(point)
			}

			if (isLasso) {
				setLassoShape([point])
			}
		}
	}

	const handleDrawing = (target: HTMLElement, x: number, y: number) => {
		if (
			wrapperRef.current &&
			isSameOrChildElement(wrapperRef.current, target) &&
			(isArrow || isLasso) &&
			isDrawing &&
			scale
		) {
			const point = dividePoints(getPointInElement(wrapperRef.current, [x, y]), scale)

			if (isArrow) {
				setArrowEndPoint(point)
			}

			if (isLasso) {
				setLassoShape(prevPoints => {
					if (
						prevPoints.length &&
						prevPoints[prevPoints.length - 1][0] === x &&
						prevPoints[prevPoints.length - 1][1] === y
					) {
						return prevPoints
					}

					return [...prevPoints, point]
				})
			}
		}
	}

	const showDrawings = (item: FeedbackDrawingType, index: number) => {
		switch (item.shape) {
			case FeedbackDrawingShape.Point:
				return (
					<TransitionGroup key={index}>
						<CSSTransition classNames={'transition'} timeout={DRAWING_TRANSITION_DURATION}>
							<Shapes.Point
								index={index}
								p0={fitPointToSize(multiplyPoints(item.p0, scale!), wrapperSize, POINT_SIZE * 0.5)}
								selected={selectedItemIndex === index}
								selectedImageIndex={selectedImageIndex}
								popoverContent={popoverContent(item, index)}
								popoverPlacement={
									wrapperRef.current
										? getPopoverPlacement(wrapperRef.current, multiplyPoints(item.p0, scale!))
										: undefined
								}
								onSelectedChange={selected => onSelectionChange(selected ? index : -1)}
								onRemove={() => onRemoveItem(item, index)}
							/>
						</CSSTransition>
					</TransitionGroup>
				)
			case FeedbackDrawingShape.Arrow:
				return (
					<TransitionGroup key={index}>
						<CSSTransition classNames={'transition'} timeout={DRAWING_TRANSITION_DURATION}>
							<Shapes.Arrow
								index={index}
								key={index}
								p0={fitPointToSize(multiplyPoints(item.p0, scale!), wrapperSize, ARROW_SIZE * 0.5)}
								p1={multiplyPoints(item.p1, scale!)}
								selected={selectedItemIndex === index}
								selectedImageIndex={selectedImageIndex}
								popoverContent={popoverContent(item, index)}
								popoverPlacement={
									wrapperRef.current
										? getPopoverPlacement(wrapperRef.current, multiplyPoints(item.p0, scale!))
										: undefined
								}
								onSelectedChange={selected => onSelectionChange(selected ? index : -1)}
								onRemove={() => onRemoveItem(item, index)}
							/>
						</CSSTransition>
					</TransitionGroup>
				)
			case FeedbackDrawingShape.Lasso:
				return (
					<Shapes.Lasso
						key={index}
						points={normalizeAreaCoords(
							item.points.map(point => multiplyPoints(point, scale!)),
							LASSO_BORDER_WIDTH
						)}
						index={index}
						position={multiplyPoints(getPositionOfArea(item.points), scale!)}
						isDrawing={false}
						selectedImageIndex={selectedImageIndex}
						selected={selectedItemIndex === index}
						popoverContent={popoverContent(item, index, selectedItemIndex === index)}
						popoverPlacement={
							wrapperRef.current
								? getPopoverPlacement(wrapperRef.current, multiplyPoints(item.points[0], scale!))
								: undefined
						}
						onSelectedChange={selected => onSelectionChange(selected ? index : -1)}
						onRemove={() => onRemoveItem(item, index)}
					/>
				)
		}
	}

	return (
		<Styled.Wrapper
			ref={wrapperRef}
			$transitionTimeout={DRAWING_TRANSITION_DURATION}
			onMouseDown={e => handleStartDrawing(e.target as HTMLElement, e.clientX, e.clientY)}
			onTouchStart={e => {
				e.preventDefault()
				e.stopPropagation()
				handleStartDrawing(e.target as HTMLElement, e.touches[0].clientX, e.touches[0].clientY)
			}}
			onMouseMove={e => handleDrawing(e.target as HTMLElement, e.clientX, e.clientY)}
			onTouchMove={e => {
				e.preventDefault()
				e.stopPropagation()
				handleDrawing(
					e.target as HTMLElement,
					e.changedTouches[e.changedTouches.length - 1].clientX,
					e.changedTouches[e.changedTouches.length - 1].clientY
				)
			}}
			onClick={e => {
				if (
					wrapperRef.current &&
					isSameOrChildElement(wrapperRef.current, e.target as HTMLElement) &&
					isPoint &&
					scale
				) {
					const point = dividePoints(getPointInElement(wrapperRef.current, [e.clientX, e.clientY]), scale)
					onAddItem({
						shape: FeedbackDrawingShape.Point,
						p0: point,
						p1: point,
					})
				}
			}}
		>
			{scale && (
				<Fragment>
					{items.map(showDrawings)}
					{isDrawing && isArrow && (
						<Shapes.Arrow
							selectedImageIndex={selectedImageIndex}
							p0={fitPointToSize(multiplyPoints(arrowStartPoint, scale), wrapperSize, POINT_SIZE * 0.5)}
							p1={multiplyPoints(arrowEndPoint, scale)}
						/>
					)}
					{isDrawing && isLasso && (
						<Shapes.Lasso
							points={lassoShape.map(point => multiplyPoints(point, scale!))}
							size={wrapperSize}
							selectedImageIndex={selectedImageIndex}
							isDrawing={true}
						/>
					)}
				</Fragment>
			)}
		</Styled.Wrapper>
	)
}

export default FeedbackCanvas
