import styled, { css } from 'styled-components'
import { color, screen, margin, padding } from '@libs/theme/mixins'
import { LinkButtonWithIcon as _LinkButtonWithIcon, Container } from '@libs/components'
import { Divider as _Divider } from 'antd'

const ListItem = styled(Container)`
	padding-bottom: ${padding('xxs')};

	a:not(:hover) {
		color: inherit;
	}

	.ant-btn-link {
		padding: 0;
		height: auto;

		&:not(:hover) {
			color: ${color(['gray', '900'])};
		}

		${screen.lte.xs`
			width: auto !important;
		`}
	}

	svg {
		display: flex;
		cursor: pointer;

		${screen.lte.md`
			display: flex;
		`}
	}
`

const LinkButtonWithIcon = styled(_LinkButtonWithIcon)`
	padding-bottom: 0;
	${screen.lte.xs`
		justify-content: flex-start !important;
		width: auto !important;
	`}

	span {
		text-align: left !important;
		justify-content: flex-start !important;
	}
`

const DocumentActions = styled(Container)`
	> :not(:last-child) {
		margin-right: ${margin('xs')};
	}

	> :last-child {
		color: ${color('error')};
	}
`

const Section = styled.div<{ wideView?: boolean }>`
	${({ wideView }) =>
		wideView &&
		css`
			flex-grow: 1;
		`}
`

const Divider = styled(_Divider)`
	${({ type }) =>
		type === 'vertical' &&
		css`
			height: auto;
			margin-right: ${margin('md')};
		`}
`

export default {
	ListItem,
	LinkButtonWithIcon,
	DocumentActions,
	Section,
	Divider,
}
