import React, { FC, Fragment, useMemo, useState } from 'react'
import Link from 'next/link'
import { Container } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { AuthApi, NewsApi, TasksApi, UserAccess } from '@libs/api'
import { useRouter } from 'next/router'
import Styled from './Styled'
import { Dropdown, Menu, Typography, Avatar, Drawer, Badge } from 'antd'
import { useNavItems, useProfileMenuItems } from './hooks'
import { ChevronLeft24, EarthFilled24, Group24, Notification24 } from '@carbon/icons-react'
import { useAuth } from 'modules/auth/hooks'
import appRoutes from 'app.routes'
import { useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useBuildId } from '../hooks'
import { useScreenMatch } from '@libs/theme'
import { useShowModal } from '../GlobalModals'
import dayjs from 'dayjs'
import { useWhitelabelSettings } from 'modules/branches/hooks'

const Header: FC = () => {
	const router = useRouter()
	const { locale, locales, updateLocale } = useLocalization()
	const { user } = useAuth()
	const navItems = useNavItems()
	const { data: news = [] } = useQuery([QUERIES.news, locale], () => NewsApi.getTopThreeNews(locale))
	const { data: totalTasks = 0 } = useQuery(
		[QUERIES.totalTasksForUser, user?.id],
		async () => (user?.id ? await TasksApi.getTotalTasksForUser(user.id) : undefined),
		{
			enabled: !!user?.id,
		}
	)
	const profileItems = useProfileMenuItems()
	const userIsAdminAtAnyBranch = user?.branches.some(branch => branch.access === UserAccess.Admin)
	const { buildId, onBuildIdClick } = useBuildId()
	const { t } = useLocalization()
	const match = useScreenMatch()
	const [isDrawerActive, setIsDrawerActive] = useState(false)
	const [isLanguageDrawerActive, setIsLanguageDrawerActive] = useState(false)
	const [isNotificationsDrawerActive, setIsNotificationsDrawerActive] = useState(false)
	const showModal = useShowModal()
	const { data: whitelabel } = useWhitelabelSettings(AuthApi.isAuthenticated())

	const badgeOffset =
		useMemo(() => {
			if (totalTasks < 10) return 'sm'
			if (totalTasks < 100) return 'md'
			if (totalTasks >= 100) return 'lg'
		}, [totalTasks]) || 'sm'

	const calculatedOffset = {
		sm: 17,
		md: 23,
		lg: 29,
	}[badgeOffset]

	return (
		<Styled.Wrapper>
			<Container display={'flex'} flexDirection={'row'} alignItems={'center'}>
				<Link href={appRoutes.dashboard()} passHref>
					<a>
						<Styled.Logo src={whitelabel?.logoUrl || '/logo.png'} />
					</a>
				</Link>
				{match.gte('lg') && (
					<Styled.NavMenu as={'nav'}>
						{navItems.map(({ icon, label, href, onClick, target }, index) => {
							const navItem = (
								<Styled.NavMenuItem
									key={index}
									$active={href ? router.pathname === href : false}
									target={target}
									onClick={onClick}
								>
									{typeof icon === 'string' ? <img src={icon} alt="Shine logo" /> : match.gte('xl') && icon}
									{label && <span>{label}</span>}
								</Styled.NavMenuItem>
							)

							const tasksNavItem = (
								<Styled.NavMenuItem
									key={index}
									$active={href ? router.pathname === href : false}
									target={target}
									onClick={onClick}
									$hasBadge
								>
									{match.gte('xl') && icon}
									<Badge count={totalTasks} overflowCount={999} offset={[calculatedOffset, 0]}>
										{label}
									</Badge>
								</Styled.NavMenuItem>
							)

							if (!href) {
								return navItem
							}

							if (href === appRoutes.tasks.list()) {
								return (
									<Link key={index} href={href} passHref>
										{tasksNavItem}
									</Link>
								)
							}

							return (
								<Link key={index} href={href} passHref>
									{navItem}
								</Link>
							)
						})}
					</Styled.NavMenu>
				)}
			</Container>
			{match.gte('lg') && (
				<Container display={'flex'} flexDirection={'row'}>
					<Dropdown
						placement={'bottomRight'}
						overlay={
							<Menu>
								{locales.map(({ code, name }) => (
									<Menu.Item key={code} onClick={() => updateLocale(code)}>
										<Styled.LanguageItem>
											<Styled.LanguageIcon
												$small
												src={`/locales/${!['da', 'sv', 'no'].includes(code) ? 'en' : code}.svg`}
												alt={name}
											/>
											<span>{name}</span>
										</Styled.LanguageItem>
									</Menu.Item>
								))}
							</Menu>
						}
					>
						<Styled.Widget>
							<Styled.LanguageIcon
								src={`/locales/${!['da', 'sv', 'no'].includes(locale) ? 'en' : locale}.svg`}
								alt={'Current language'}
							/>
						</Styled.Widget>
					</Dropdown>
					<Dropdown
						placement={'bottomRight'}
						overlay={
							<Styled.NotificationsMenu>
								{news.map(({ id, date, title, subTitle }) => {
									return (
										<Menu.Item key={id} onClick={() => showModal('news', { id })}>
											<Styled.NotificationsContainer display="flex" flexDirection="column">
												<Typography.Text>{dayjs(date).format('DD-MM-YYYY')}</Typography.Text>
												<Typography.Text strong ellipsis title={title}>
													{title}
												</Typography.Text>
												<Typography.Text ellipsis title={subTitle}>
													{subTitle}
												</Typography.Text>
											</Styled.NotificationsContainer>
										</Menu.Item>
									)
								})}
							</Styled.NotificationsMenu>
						}
					>
						<Styled.Widget>
							<Styled.NotificationTrigger $hasNotifications={news.length > 0}>
								<Notification24 />
							</Styled.NotificationTrigger>
						</Styled.Widget>
					</Dropdown>
					{userIsAdminAtAnyBranch && (
						<Styled.Widget $primary>
							<Link href={appRoutes.users()} passHref>
								<a>
									<Group24 />
								</a>
							</Link>
						</Styled.Widget>
					)}
					<Dropdown
						placement={'bottomRight'}
						overlay={
							<Menu>
								{profileItems.map(({ label, onClick, link }, index) =>
									link ? (
										<Menu.Item key={index}>
											<Link href={link} passHref>
												<a>
													<Styled.ProfileItem>{label}</Styled.ProfileItem>
												</a>
											</Link>
										</Menu.Item>
									) : (
										<Menu.Item key={index} onClick={onClick}>
											<Styled.ProfileItem>{label}</Styled.ProfileItem>
										</Menu.Item>
									)
								)}
								<Styled.MenuDivider />
								<Menu.Item onClick={onBuildIdClick}>
									<Styled.ProfileItem>
										<Container display={'flex'} flexDirection={'column'}>
											<Styled.BuildIdText type={'secondary'}>{t('common.terms.buildId')}</Styled.BuildIdText>
											<Styled.BuildIdText type={'secondary'}>{buildId}</Styled.BuildIdText>
										</Container>
									</Styled.ProfileItem>
								</Menu.Item>
							</Menu>
						}
					>
						<Styled.Widget>
							<Styled.ProfileTrigger>
								<div>
									<Avatar src={undefined}>{user?.initials || '?'}</Avatar>
								</div>
							</Styled.ProfileTrigger>
						</Styled.Widget>
					</Dropdown>
				</Container>
			)}
			{match.lte('md') && (
				<Fragment>
					<Styled.Widget onClick={() => setIsDrawerActive(prevState => !prevState)}>
						<Styled.Burger />
					</Styled.Widget>
					<Drawer
						width={match.map({ xxs: '100vw', xs: '60vw', md: '40vw' })}
						visible={isDrawerActive}
						onClose={() => setIsDrawerActive(false)}
						footer={
							<Styled.DrawerMenu>
								<Styled.DrawerMenuItem onClick={() => setIsLanguageDrawerActive(true)}>
									<EarthFilled24 />
									<span>{t('navigation.secondaryMenu.language')}</span>
								</Styled.DrawerMenuItem>
								<Styled.DrawerMenuItem onClick={() => setIsNotificationsDrawerActive(true)}>
									<Notification24 />
									<span>{t('navigation.secondaryMenu.notifications')}</span>
								</Styled.DrawerMenuItem>
								{userIsAdminAtAnyBranch && (
									<Link href={appRoutes.users()} passHref>
										<Styled.DrawerMenuItem onClick={() => setIsDrawerActive(false)}>
											<Group24 />
											<span>{t('navigation.secondaryMenu.userManagement')}</span>
										</Styled.DrawerMenuItem>
									</Link>
								)}
								{profileItems.map(({ label, icon, link, onClick }, index) =>
									link ? (
										<Link key={index} href={link} passHref>
											<Styled.DrawerMenuItem onClick={() => setIsDrawerActive(false)}>
												{icon}
												<span>{label}</span>
											</Styled.DrawerMenuItem>
										</Link>
									) : (
										<Styled.DrawerMenuItem
											key={index}
											as={'div'}
											onClick={() => {
												setIsDrawerActive(false)
												onClick?.()
											}}
										>
											{icon}
											<span>{label}</span>
										</Styled.DrawerMenuItem>
									)
								)}
							</Styled.DrawerMenu>
						}
					>
						<Styled.DrawerNav>
							{navItems.map(({ icon, label, href, onClick, target }, index) => {
								const navItem = (
									<Styled.NavMenuItem
										key={index}
										$active={href ? router.pathname === href : false}
										onClick={e => {
											setIsDrawerActive(false)
											onClick?.(e)
										}}
										target={target}
									>
										{typeof icon === 'string' ? <img src={icon} alt="Shine logo" /> : icon}
										{label && <span>{label}</span>}
									</Styled.NavMenuItem>
								)

								const tasksNavItem = (
									<Styled.NavMenuItem
										key={index}
										$active={href ? router.pathname === href : false}
										onClick={e => {
											setIsDrawerActive(false)
											onClick?.(e)
										}}
										target={target}
										$hasBadge
									>
										{icon}
										<Badge count={totalTasks} overflowCount={999} offset={[calculatedOffset, 0]}>
											{label}
										</Badge>
									</Styled.NavMenuItem>
								)

								if (!href) {
									return navItem
								}

								if (href === appRoutes.tasks.list()) {
									return (
										<Link key={index} href={href} passHref>
											{tasksNavItem}
										</Link>
									)
								}

								return (
									<Link key={index} href={href} passHref>
										{navItem}
									</Link>
								)
							})}
						</Styled.DrawerNav>
					</Drawer>
					<Drawer
						title={
							<Container display={'flex'} alignItems={'center'}>
								<Styled.DrawerBackButton onClick={() => setIsNotificationsDrawerActive(false)}>
									<ChevronLeft24 />
								</Styled.DrawerBackButton>
								<span>Notifications</span>
							</Container>
						}
						width={match.map({ xxs: '100vw', xs: '80vw', md: '60vw' })}
						visible={isNotificationsDrawerActive}
						closeIcon={false}
						onClose={() => setIsNotificationsDrawerActive(false)}
					>
						<Styled.DrawerNav>
							{news.map(({ id, date, title, subTitle }) => {
								return (
									<Styled.NotificationsContainer
										key={id}
										onClick={() => {
											setIsDrawerActive(false)
											setIsNotificationsDrawerActive(false)
											showModal('news', { id })
										}}
									>
										<Typography.Text>{dayjs(date).format('DD-MM-YYYY')}</Typography.Text>
										<Typography.Title level={5} ellipsis={{ rows: 2 }}>
											{title}
										</Typography.Title>
										<Typography.Paragraph ellipsis={{ rows: 4 }}>{subTitle}</Typography.Paragraph>
									</Styled.NotificationsContainer>
								)
							})}
						</Styled.DrawerNav>
					</Drawer>
					<Drawer
						title={
							<Container display={'flex'} alignItems={'center'}>
								<Styled.DrawerBackButton onClick={() => setIsLanguageDrawerActive(false)}>
									<ChevronLeft24 />
								</Styled.DrawerBackButton>
								<span>Language</span>
							</Container>
						}
						width={match.map({ xxs: '100vw', xs: '60vw', md: '40vw' })}
						visible={isLanguageDrawerActive}
						closeIcon={false}
						onClose={() => setIsLanguageDrawerActive(false)}
					>
						<Styled.DrawerNav>
							{locales.map(({ code, name }) => {
								let iconName = code

								if (code?.includes('en')) iconName = 'en'
								if (code === 'da-DK') iconName = 'da'
								if (code === 'sv-SE') iconName = 'sv'
								if (code === 'nb-NO' || code === 'nn-NO') iconName = 'no'

								return (
									<Styled.DrawerLanguageItem
										key={code}
										onClick={() => {
											setIsLanguageDrawerActive(false)
											updateLocale(code)
										}}
									>
										<Styled.LanguageIcon $small src={`/locales/${iconName}.svg`} alt={name} />
										<span>{name}</span>
									</Styled.DrawerLanguageItem>
								)
							})}
						</Styled.DrawerNav>
					</Drawer>
				</Fragment>
			)}
		</Styled.Wrapper>
	)
}

export default Header
