import { PointType, dividePoints } from '@libs/util'
import { PopoverProps } from 'antd'

export function getContainerScale(container: HTMLElement, originalSize: PointType) {
	return dividePoints([container.clientWidth, container.clientHeight], originalSize)
}

export function getPopoverPlacement(container: HTMLElement, [x, y]: PointType): Required<PopoverProps>['placement'] {
	const horizontalSplit = container.clientWidth / 3
	const verticalSplit = container.clientHeight / 2

	// First horizontal frame
	if (x < horizontalSplit) {
		return y < verticalSplit ? 'bottomLeft' : 'topLeft'
	}
	// Middle horizontal frame
	else if (x > horizontalSplit && x < 2 * horizontalSplit) {
		return y < verticalSplit ? 'bottom' : 'top'
	}
	// Last horizontal frame
	else {
		return y < verticalSplit ? 'bottomRight' : 'topRight'
	}
}
