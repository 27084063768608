import React, { FC, Fragment, useEffect, useRef, useState } from 'react'
import Styled from './Styled'
import { Typography, Divider } from 'antd'
import { Document24, ArrowRight24, CloseFilled24, CheckmarkFilled24 } from '@carbon/icons-react'
import { useLocalization } from '@libs/localization'
import appRoutes from 'app.routes'
import { OrderStatus, ISimplifiedOrder, IDatahubCase } from '@libs/api'
import Link from 'next/link'
import { Anchor, Container, Skeleton } from '@libs/components'
import { useScreenMatch } from '@libs/theme'

interface CaseOrdersProps {
	caseId: IDatahubCase['id']
	orders: ISimplifiedOrder[]
	isLoading: boolean
}

const CaseOrders: FC<CaseOrdersProps> = ({ caseId, orders, isLoading }) => {
	const { f, t } = useLocalization()

	// Enable scrolling through container at n <= 3
	const orderContainerRef = useRef<HTMLDivElement>(null)
	const [orderContainerMaxHeight, setOrderContainerMaxHeight] = useState<number>(0)

	useEffect(() => {
		if (orderContainerRef.current && !orderContainerMaxHeight) {
			// reduce height by 30px to prevent container oversizing
			setOrderContainerMaxHeight(orderContainerRef.current.clientHeight - 30)
		}
	}, [])

	const match = useScreenMatch()

	return (
		<Styled.Card title={t('common.terms.orders')}>
			<Styled.OrderContainer ref={orderContainerRef} style={{ maxHeight: orderContainerMaxHeight || 'auto' }}>
				{isLoading ? (
					<Skeleton.Paragraph active rows={3} />
				) : (
					orders
						.slice(0, orderContainerMaxHeight ? undefined : 3)
						.map(({ createdDate, id, status, salesId }, index, arr) => (
							<Fragment key={id}>
								<Styled.OrderItem display="flex" alignItems="center" lineThough={status === OrderStatus.Cancelled}>
									<Document24 />
									{match.map({
										xxs: (
											<Container display={'flex'} flexDirection={'column'}>
												<Typography.Text>{f(createdDate, { date: 'short', time: 'short' })}</Typography.Text>
												<Typography.Text>{salesId}</Typography.Text>
											</Container>
										),
										sm: (
											<Typography.Text>
												{f(createdDate, { date: 'short', time: 'short' })} - {salesId}
											</Typography.Text>
										),
									})}
									<Styled.IconWrapper display="flex" alignItems="center" orderStatus={status as OrderStatus}>
										{(status === OrderStatus.Delivered || status === OrderStatus.Invoiced) && <CheckmarkFilled24 />}
										{status === OrderStatus.Cancelled && <CloseFilled24 />}
										<Link href={appRoutes.cases.viewOrder(caseId, id)} passHref>
											<Anchor>
												<ArrowRight24 />
											</Anchor>
										</Link>
									</Styled.IconWrapper>
								</Styled.OrderItem>
								{index !== arr.length - 1 && <Divider />}
							</Fragment>
						))
				)}
				{orders.length === 0 && !isLoading && (
					<Styled.NoOrders>{t('cases.pages.details.noOrdersMessage')}</Styled.NoOrders>
				)}
			</Styled.OrderContainer>
		</Styled.Card>
	)
}

export default CaseOrders
