import React, { FC, useState, useCallback, useEffect, useMemo } from 'react'
import { Divider, Typography, Form, Spin, DatePicker } from 'antd'
import Styled from './Styled'
import { Grid, Container } from '@libs/components'
import dayjs, { Dayjs } from 'dayjs'
import { useLocalization } from '@libs/localization'
import TimeSelector from '../TimeSelector'
import { useCallProposal } from '../TaskBookPhotographerModal/hooks'
import { IBookingInfo, IPhotographerBookingData } from '@libs/api'
import { useScreenMatch } from '@libs/theme'
import { fixBookingTimeToInterval } from '../TaskBookPhotographerModal/helpers'

const { Row, Col } = Grid

export interface IBookPhotographerCalendarView {
	bookingId?: IBookingInfo['bookingId']
	timeForTask: IPhotographerBookingData['timeForTask']
	onSelect: (secondaryProposal?: IPhotographerBookingData) => void
}

const BookPhotographerCalendarView: FC<IBookPhotographerCalendarView> = ({ bookingId, timeForTask, onSelect }) => {
	const { t, f } = useLocalization()
	const [selectedDate, selectDate] = useState<Dayjs | null>()
	const [selectedTime, selectTime] = useState<Dayjs>()
	const { data: proposal, isLoading, isFetched } = useCallProposal(bookingId)

	const foundSelectedProposal = useMemo(() => {
		if (!selectedDate || !selectedTime) return null

		return proposal?.secondary.find(
			({ arrival }) =>
				dayjs(arrival).isSame(selectedDate, 'day') && dayjs(arrival).format('HH') === dayjs(selectedTime).format('HH')
		)
	}, [proposal, selectedDate, selectedTime])

	const disabledDate = useCallback(
		(current: Dayjs) => {
			if (current.isBefore(dayjs().startOf('day'))) return true
			if (!proposal?.secondary.some(({ arrival }) => dayjs(arrival).isSame(current, 'day'))) return true

			return false
		},
		[proposal]
	)

	const foundSecondaryProposal = useMemo(() => {
		if (selectedDate && selectedTime && proposal) {
			return proposal.secondary.find(
				({ arrival }) =>
					dayjs(arrival).isSame(selectedDate, 'day') && dayjs(arrival).get('hours') === selectedTime.get('hours')
			)
		}
	}, [proposal, selectedDate, selectedTime])

	useEffect(() => {
		if (foundSecondaryProposal) {
			onSelect(foundSecondaryProposal)
			return
		}

		onSelect()
	}, [foundSecondaryProposal])

	const secondaryAvailableDates = useMemo(
		() =>
			proposal
				? proposal.secondary
						.filter(({ arrival }) => dayjs(arrival).isSame(selectedDate, 'day'))
						.map(item => item.arrival)
				: [],
		[proposal, selectedDate]
	)

	// set initial date for datepicker
	useEffect(() => {
		if (isFetched && proposal?.secondary.length) {
			const initialTime = dayjs(proposal.secondary[0].arrival)
			selectDate(initialTime)
			selectTime(dayjs().hour(initialTime.hour()).minute(0))
		}
	}, [isFetched, proposal])

	const match = useScreenMatch()

	return (
		<Styled.Wrapper>
			<Form layout={'vertical'}>
				<Row gutter={['sm', 'xs']}>
					<Col xs={24}>
						<Typography.Title level={5}>
							{t('modals.bookPhotographer.calendarSubTitle')} {fixBookingTimeToInterval(timeForTask)}
						</Typography.Title>
					</Col>
					<Col span={24}>
						<Container
							display={'flex'}
							flexDirection={match.map({ xxs: 'column', md: 'row' })}
							justifyContent={match.map({ xxs: 'flex-start', md: 'space-between' })}
							alignItems={'stretch'}
							space={'sm'}
							spaceDirection={match.map({ xxs: 'vertical', md: 'horizontal' })}
						>
							<Container flex={match.map({ xxs: 'auto', md: '1 1 50%' })}>
								<Form.Item label={t('common.placeholders.selectDate')}>
									<DatePicker
										value={selectedDate}
										disabledDate={disabledDate}
										onChange={date => {
											selectDate(date)
											selectTime(undefined)
										}}
									/>
									{isLoading && (
										<Styled.SpinnerOverlay display="flex" alignItems="center" justifyContent="center">
											<Spin />
										</Styled.SpinnerOverlay>
									)}
								</Form.Item>
							</Container>
							{match.gte('md') && (
								<Container>
									<Divider type={'vertical'} />
								</Container>
							)}
							<Container flex={match.map({ xxs: 'auto', md: '1 1 50%' })}>
								<Form.Item label={t('common.placeholders.selectTime')}>
									<TimeSelector value={selectedTime} onChange={selectTime} availableDates={secondaryAvailableDates} />
								</Form.Item>
								{foundSecondaryProposal && (
									<Container display="flex" flexDirection="column">
										{foundSelectedProposal?.photographerName && (
											<>
												<Typography.Text type="secondary">{t('common.terms.photographer')}</Typography.Text>
												<Container margin={{ bottom: 'xxs' }}>
													<Typography.Text type="secondary">{foundSelectedProposal.photographerName}</Typography.Text>
												</Container>
											</>
										)}
										<Typography.Text strong>{t('modals.bookPhotographer.calendarSelectedChoise')}</Typography.Text>
										<Typography.Text strong>
											{f(foundSecondaryProposal.start, { date: 'medium' })}
											{', '}
											<Styled.ArrivalTime strong>
												{f(foundSecondaryProposal.start, { time: 'short' })} -{' '}
												{f(foundSecondaryProposal.end, { time: 'short' })}
											</Styled.ArrivalTime>
										</Typography.Text>
									</Container>
								)}
							</Container>
						</Container>
					</Col>
				</Row>
			</Form>
		</Styled.Wrapper>
	)
}

export default BookPhotographerCalendarView
