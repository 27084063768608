import { useMutation } from 'react-query'
import { TaskModalCommonProps } from '../types'
import { ISimplifiedVisualMaterial, TasksApi } from '@libs/api'
import { useCallback, useMemo, useState } from 'react'
import { message } from 'antd'
import { useHideModal } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import { useVisualMaterials } from 'modules/material/hooks'
import { useOrderLineById } from 'modules/cases/CaseOrderDetailsPageContent/hooks'

export function useTaskImageSelectorModal({ task, onTaskComplete }: TaskModalCommonProps) {
	const hideModal = useHideModal()
	const { t } = useLocalization()
	const invalidateTaskQueries = useTasksQueriesInvalidation()

	const { visualMaterials, isLoading: areMaterialsLoading } = useVisualMaterials(task.caseId)
	const { data: foundOrderline } = useOrderLineById({ orderId: task.orderId, orderlineId: task.orderlineId })

	const pictureMaterials = useMemo(
		() => visualMaterials.filter(({ type }) => type === 'Floorplan' || type === 'Picture'),
		[visualMaterials]
	)

	const [selectedMaterialIds, selectMaterialIds] = useState<ISimplifiedVisualMaterial['id'][]>([])

	const { mutateAsync: submit, isLoading: isCompletingTask } = useMutation(TasksApi.completeImageSelection, {
		onError: (err: Error) => {
			message.error(err.message)
		},
		onSuccess: res => {
			if (!res) {
				message.error(t('modals.imageSelector.error'))
				return
			}

			invalidateTaskQueries()
			message.success(t('modals.imageSelector.success'))

			if (onTaskComplete) {
				onTaskComplete(task)
				return
			}

			hideModal()
		},
	})

	const completeTask = useCallback(() => {
		submit({
			taskId: task.id,
			materialIds: selectedMaterialIds,
		})
	}, [submit, task, selectedMaterialIds])

	return {
		materials: pictureMaterials,
		areMaterialsLoading,
		completeTask,
		isCompletingTask: isCompletingTask,
		selectedMaterialIds,
		selectMaterialIds: selectMaterialIds,
		foundOrderline,
	}
}
