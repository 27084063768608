import React, { FC, useMemo } from 'react'
import { Typography, Select } from 'antd'
import { Grid, Container } from '@libs/components'
import { useLocalization } from '@libs/localization'

export interface SelectLabelStepProps {
	selectedValue?: string
	selectLabel: (label: string) => void
}

const SelectLabelStep: FC<SelectLabelStepProps> = ({ children, selectedValue, selectLabel }) => {
	const { t } = useLocalization()

	const labelOptions = useMemo(
		() =>
			[
				'',
				'INTERIOR_DESIGN_SUGGESTION',
				'FACADE_SUGGESTION',
				'ILLUSTRATION_IMAGE',
				'STYLING_SUGGESTION',
				'ALTERNATIVE_DECOR',
				'SUGGESTION',
			].map(item => {
				const translatedValue = item ? t(`tasks.virtualStagingLabels.${item}`) : ''
				return {
					value: item && translatedValue,
					label: item ? translatedValue : '--',
				}
			}) ?? [],
		[t]
	)

	return (
		<Container>
			<Grid.Row>
				{children}
				<Grid.Col flex="1 1 0%">
					<Typography.Title level={5}>{t('modals.virtualStaging.selectLabelStep.title')}</Typography.Title>
					<Container space="md" spaceDirection="vertical" margin={{ bottom: 'xxs' }}>
						<Typography.Paragraph>{t('modals.virtualStaging.selectLabelStep.instructions')}</Typography.Paragraph>
						<Select value={selectedValue} options={labelOptions} onChange={selectLabel} />
					</Container>
				</Grid.Col>
			</Grid.Row>
		</Container>
	)
}

export default SelectLabelStep
