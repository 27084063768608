import styled from 'styled-components'
import { borderRadius, color, padding } from '@libs/theme/mixins'

const RoomTypeCard = styled.div<{ $selected: boolean }>`
	border: 2px solid ${props => (props.$selected ? color('primary') : 'transparent')};
	border-radius: ${borderRadius('md')};
	box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
	cursor: pointer;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	position: relative;

	.ant-typography {
		padding: ${padding('xs')};
		white-space: nowrap;
	}
`

const ImageWrapper = styled.div`
	position: relative;
	overflow: hidden;
	height: 0;
	padding-top: 56.25%;

	img {
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		object-fit: cover;
		z-index: 0;
		width: 100%;
	}
`

export default {
	RoomTypeCard,
	ImageWrapper,
}
