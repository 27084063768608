import React, { FC } from 'react'
import { Modal, Button, Typography, Row, Col } from 'antd'
import { Form, FormItem, Radio, Switch, Select } from 'formik-antd'
import { Formik } from 'formik'
import { Autolocalize, FormikInput, useHideModal, UserInteraction } from '@libs/components'
import { IDatahubCase, ISimplifiedVisualMaterial, MaterialApi } from '@libs/api'
import { useMutation } from 'react-query'
import { message } from 'antd'
import { useDebounce } from 'use-debounce/lib'
import { useAuth } from 'modules/auth/hooks'
import { useVisualMaterials } from '../hooks'
import { useLocalization } from '@libs/localization'
import { ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME, useScreenMatch } from '@libs/theme'
import { Close24 } from '@carbon/icons-react'

interface IFormValues {
	email: string
	highQuality: boolean
	filter: 'all' | 'active' | 'selected'
}

export interface MaterialDownloadModalProps {
	caseId: IDatahubCase['id']
	selectedMaterialIds?: ISimplifiedVisualMaterial['id'][]
}

const initialFormValues: IFormValues = {
	email: '',
	highQuality: true,
	filter: 'all',
}

const MaterialDownloadModal: FC<MaterialDownloadModalProps> = ({ caseId, selectedMaterialIds }) => {
	const { user } = useAuth()
	const hideModal = useHideModal()
	const { visualMaterials: materials } = useVisualMaterials(caseId)
	const { t } = useLocalization()

	const { mutateAsync, isLoading } = useMutation(
		({ email, highQuality, filter }: IFormValues) =>
			MaterialApi.sendDownloadInfo({
				email,
				webResolution: !highQuality, // web resolution means low quality
				caseId,
				materialIds: (filter === 'active'
					? materials.filter(({ isActive }) => isActive)
					: filter === 'selected'
					? materials.filter(({ id }) => selectedMaterialIds?.includes(id))
					: materials
				).map(({ id }) => id?.toString()),
			}),
		{
			onSuccess: response => {
				if (response.success) {
					message.success(t('modals.downloadMaterial.success'))
				}
			},
			onError: (err: Error) => {
				message.error(err.message)
			},
		}
	)

	const [isStillLoading] = useDebounce(isLoading, 500)
	const match = useScreenMatch()

	return (
		<Modal
			visible
			closeIcon={<Close24 />}
			title={t('modals.downloadMaterial.title')}
			onCancel={hideModal}
			footer={[
				<Button form="download-material-form" key="submit" htmlType="submit" type="primary" loading={isStillLoading}>
					{t('common.actions.download')}
				</Button>,
			]}
		>
			<Formik<IFormValues>
				initialValues={{
					...initialFormValues,
					email: user?.email ?? '',
				}}
				onSubmit={async (values, { resetForm }) => {
					await mutateAsync(values)
					resetForm()
					hideModal()
				}}
			>
				{({ isSubmitting }) => (
					<UserInteraction enabled={!isSubmitting}>
						<Form id="download-material-form" layout="vertical">
							<Autolocalize />
							<Typography.Paragraph>{t('modals.downloadMaterial.instructions')}</Typography.Paragraph>
							<FormItem name="email" label={t('common.terms.recipient')} required>
								<FormikInput name="email" required autoFocus />
							</FormItem>
							<Row>
								<Col xs={24} md={16}>
									<FormItem
										name={'filter'}
										label={t('common.terms.filter')}
										className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
									>
										{match.map({
											xxs: (
												<Select name={'filter'}>
													<Select.Option value={'all'}>{t('common.terms.all')}</Select.Option>
													<Select.Option value={'active'}>{t('common.terms.active')}</Select.Option>
													{selectedMaterialIds && !!selectedMaterialIds.length && (
														<Select.Option value={'selected'}>{t('common.terms.marked')}</Select.Option>
													)}
												</Select>
											),
											md: (
												<Radio.Group name={'filter'}>
													<Radio.Button value={'all'}>{t('common.terms.all')}</Radio.Button>
													<Radio.Button value={'active'}>{t('common.terms.active')}</Radio.Button>
													{selectedMaterialIds && !!selectedMaterialIds.length && (
														<Radio.Button value={'selected'}>{t('common.terms.marked')}</Radio.Button>
													)}
												</Radio.Group>
											),
										})}
									</FormItem>
								</Col>
								<Col xs={24} md={8}>
									<FormItem name={'highQuality'} label={t('common.terms.highQuality')}>
										<Switch name={'highQuality'} />
									</FormItem>
								</Col>
							</Row>
						</Form>
					</UserInteraction>
				)}
			</Formik>
		</Modal>
	)
}

export default MaterialDownloadModal
