import { color, easing, fontSize, margin, padding, scrollbar } from '@libs/theme/mixins'
import { Button, Menu, Typography } from 'antd'
import { TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'

const TransitionManager = styled(TransitionGroup)<{
	$duration: number
	$active: boolean
}>`
	position: fixed;
	right: ${margin('md')};
	bottom: ${margin('md')};

	> * {
		opacity: ${({ $active }) => ($active ? 1 : 0)};
		transform: scale(${({ $active }) => ($active ? 1 : 0)});
	}

	.transition-enter {
		transition: none;
	}

	.transition-enter-active {
		animation: bounce;
		animation-duration: ${({ $duration }) => $duration}ms;
		animation-timing-function: ${easing('inOut')};
	}

	.transition-enter-done {
		opacity: 1;
		transform: scale(1);
	}

	.transition-exit {
		transition: none;
	}

	.transition-exit-active {
		animation: bounce;
		animation-duration: ${({ $duration }) => $duration}ms;
		animation-timing-function: ${easing('inOut')};
		animation-direction: reverse;
	}

	.transition-exit-done {
		opacity: 0;
		transform: scale(0);
	}
`

const Trigger = styled(Button)`
	background-color: ${color(['info', '600'])};
	color: ${color('white')};
	border-color: ${color('white')};

	&:hover,
	&:active,
	&:focus {
		background-color: ${color(['info', '500'])};
		color: ${color('white')};
		border-color: ${color('white')};
	}
`

const PopoverMenu = styled(Menu)`
	max-height: 500px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0;
	${scrollbar()}
`

const PopoverMenuItem = styled(Menu.Item)`
	padding: ${padding('md', 'sm')};
	border-bottom: 1px solid ${color(['primary', '200'])};
	&:last-child {
		border-bottom: none;
	}
`

const PopoverMenuTitle = styled(Typography.Text)`
	font-weight: 500;
`

const PopoverMenuText = styled(Typography.Text)`
	font-size: ${fontSize('sm')};
	b {
		font-weight: 500;
	}
`

export default {
	TransitionManager,
	Trigger,
	PopoverMenu,
	PopoverMenuItem,
	PopoverMenuTitle,
	PopoverMenuText,
}
