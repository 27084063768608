import { useMutation } from 'react-query'
import { TaskModalCommonProps } from '../types'
import { TasksApi, TaskCategory, ISimplifiedVisualMaterial } from '@libs/api'
import { useCallback, useMemo, useState } from 'react'
import { message } from 'antd'
import { useHideModal } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import { useVisualMaterials } from 'modules/material/hooks'

export function useTaskBeforeAfterModalData({ task, onTaskComplete }: TaskModalCommonProps) {
	const hideModal = useHideModal()
	const { t } = useLocalization()
	const invalidateTaskQueries = useTasksQueriesInvalidation()

	const { visualMaterials, isLoading: areMaterialsLoading } = useVisualMaterials(task.caseId)

	const pictureMaterials = useMemo(
		() => visualMaterials.filter(({ type }) => type === 'Floorplan' || type === 'Picture') || [],
		[visualMaterials]
	)

	const [beforeSelection, setBeforeSelection] = useState<ISimplifiedVisualMaterial>()
	const [afterSelection, setAfterSelection] = useState<ISimplifiedVisualMaterial>()

	const materialBeforePool = useMemo(
		() => pictureMaterials.filter(candidate => candidate !== afterSelection),
		[pictureMaterials, afterSelection]
	)
	const materialAfterPool = useMemo(
		() => pictureMaterials.filter(candidate => candidate !== beforeSelection),
		[pictureMaterials, beforeSelection]
	)

	const onSliderSuccess = useCallback(() => {
		invalidateTaskQueries()
		message.success(t('modals.beforeAfter.success'))

		if (onTaskComplete) {
			onTaskComplete(task)
			return
		}

		hideModal()
	}, [invalidateTaskQueries, onTaskComplete, hideModal, t, task])

	const { mutateAsync: submit, isLoading: isCompletingTask } = useMutation(TasksApi.createBeforeAfterSlider, {
		onSuccess: onSliderSuccess,
	})

	const { mutateAsync: submitSomeGif, isLoading: isSomeGifCompleting } = useMutation(TasksApi.completeSoMeGif, {
		onSuccess: onSliderSuccess,
	})

	const completeTask = useCallback(() => {
		if (beforeSelection && afterSelection) {
			if (task.type === TaskCategory.SomeGif) {
				submitSomeGif({
					taskId: task.id,
					beforeMaterialId: beforeSelection.id,
					afterMaterialId: afterSelection.id,
				})
				return
			}
			submit({
				taskId: task.id,
				assetIds: [beforeSelection.id.toString(), afterSelection.id.toString()],
			})
		}
	}, [submit, task, beforeSelection, afterSelection, submitSomeGif])

	return {
		materials: pictureMaterials,
		materialBeforePool,
		materialAfterPool,
		areMaterialsLoading,
		completeTask,
		isCompletingTask: isCompletingTask || isSomeGifCompleting,
		beforeSelection,
		setBeforeSelection,
		afterSelection,
		setAfterSelection,
	}
}
