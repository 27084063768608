import React, { FC } from 'react'
import { Autolocalize, FormikTextArea, useHideModal, UserInteraction } from '@libs/components'
import { Button, Modal, Typography, Space } from 'antd'
import { Form, FormItem } from 'formik-antd'
import { Formik } from 'formik'
import { useNoteSubmitter } from 'modules/tasks/hooks'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { Close24 } from '@carbon/icons-react'
import TaskModalTitle from 'modules/common/TaskModalTitle'

export interface TaskBasicModalProps extends TaskModalCommonProps {
	optional?: boolean
}

interface IBasicForm {
	noteText: string
}

const TaskBasicModal: FC<TaskBasicModalProps> = ({ task, optional, onTaskCancel, onTaskComplete }) => {
	const { Text } = Typography
	const hideModal = useHideModal()
	const { submit } = useNoteSubmitter(task)
	const { t } = useLocalization()

	const onSubmit = async (data: IBasicForm) => {
		const isSuccess = await submit(data.noteText)

		if (isSuccess) {
			if (onTaskComplete) {
				onTaskComplete(task)
				return
			}

			hideModal()
		}
	}

	const initialValues: IBasicForm = {
		noteText: '',
	}

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit}>
			{({ values, isSubmitting }) => (
				<Modal
					visible
					closeIcon={<Close24 />}
					title={<TaskModalTitle title={t('modals.basic.title')} caseId={task.caseReference} />}
					onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
					footer={
						<Button
							form="task-basic-opt-form"
							htmlType="submit"
							type="primary"
							loading={isSubmitting}
							disabled={!optional && values.noteText.length === 0}
						>
							{t('common.actions.completeTask')}
						</Button>
					}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form id="task-basic-opt-form" layout="vertical">
							<Autolocalize />
							<Space direction="vertical" size="middle">
								<Text>{t('modals.basic.instructions')}</Text>
								<FormItem name="noteText">
									<FormikTextArea name="noteText" autoSize={{ minRows: 2, maxRows: 4 }} size="small" />
								</FormItem>
							</Space>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default TaskBasicModal
