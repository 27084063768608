import React from 'react'
import { Grid, Skeleton } from '@libs/components'
import { Divider } from 'antd'

const EditUserForm = () => (
	<>
		<Grid.Row gutter={['sm', 0]}>
			<Grid.Col xs={24} xl={12}>
				<Skeleton.Paragraph active titleWidth={100} rows={2} />
			</Grid.Col>
			<Grid.Col xs={24} xl={12}>
				<Skeleton.Paragraph active titleWidth={100} rows={2} />
			</Grid.Col>
			<Grid.Col xs={24} xl={12}>
				<Skeleton.Paragraph active titleWidth={60} rows={2} />
			</Grid.Col>
			<Grid.Col xs={24} xl={12}>
				<Skeleton.Paragraph active titleWidth={60} rows={2} />
			</Grid.Col>
		</Grid.Row>
		<Grid.Row gutter={['sm', 0]}>
			<Grid.Col xs={24} xl={12}>
				<Skeleton.Paragraph active titleWidth={110} rows={2} />
			</Grid.Col>
			<Grid.Col xs={24} xl={12}>
				<Skeleton.Paragraph active titleWidth={110} rows={2} />
			</Grid.Col>
		</Grid.Row>
		<Divider />
		<Grid.Row gutter={['sm', 0]}>
			<Grid.Col xs={24} xl={12}>
				<Skeleton.Paragraph active titleWidth={50} rows={2} rowWidth={200} />
			</Grid.Col>
			<Grid.Col xs={24} xl={12}>
				<Skeleton.Paragraph active titleWidth={80} rows={2} />
			</Grid.Col>
		</Grid.Row>
		<Divider />
	</>
)

export default { EditUserForm }
