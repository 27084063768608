export default {
	additionalProducts: 'additionalProducts',
	addressAutocomplete: 'addressAutocomplete',
	addressFromPrediction: 'addressFromPrediction',
	admins: 'admins',
	bookingById: 'bookingById',
	bookingByOrderId: 'bookingByOrderId',
	bookingTaskByBookingId: 'bookingTaskByBookingId',
	bookingProposals: 'bookingProposals',
	branchById: 'branchById',
	branches: 'brances',
	callProposal: 'callProposal',
	caseAddress: 'caseAddress',
	caseByUser: 'caseByUser',
	caseFilters: 'caseFilters',
	caseImages: 'caseImages',
	caseDetails: 'caseDetails',
	cases: 'cases',
	canCreateOrderCheck: 'canCreateOrderCheck',
	caseWithSimpleOrderList: 'caseWithSimpleOrderList',
	caseTexts: 'caseTexts',
	caseTypes: 'caseTypes',
	checkIfCaseExists: 'caseExists',
	checkIfCaseExistsByCaseId: 'caseExistsByCaseId',
	collagePricing: 'collagePricing',
	contactPersonsByCaseId: 'contactPersonsByCaseId',
	contactPersonTypes: 'contactPersonTypes',
	copyWriteTexts: 'copyWriteTexts',
	departmentByUser: 'departmentByUser',
	departmentSearch: 'departmentSearch',
	documentMaterials: 'documentMaterials',
	getOrder: 'getOrder',
	getUploadedMaterialForOrderLine: 'getUploadedMaterialForOrderLine',
	individualProducts: 'individualProducts',
	inputForSpeakSlideShowText: 'inputForSpeakSlideShowText',
	interview: 'interview',
	introOutroPersons: 'introOutroPersons',
	inviteById: 'inviteById',
	invites: 'invites',
	linkMaterials: 'linkMaterials',
	membersByCompanyId: 'membersByCompanyId',
	mindworking: 'mindworking',
	news: 'news',
	newsById: 'newsById',
	orderById: 'orderById',
	orderLines: 'orderLines',
	orderList: 'orderList',
	orderListSimplified: 'orderListSimplified',
	orderTasks: 'orderTasks',
	orderWatchers: 'orderWatchers',
	packages: 'packages',
	prefilledText: 'prefilledText',
	presentationVisualMaterials: 'presentationVisualMaterials',
	propertyTypes: 'propertyTypes',
	s3Credentials: 's3Credentials',
	socialMediaSlideshowTypes: 'socialMediaSlideshowTypes',
	socialMediaTypes: 'socialMediaTypes',
	suggestedMaterialNames: 'suggestedMaterialNames',
	supportArticles: 'supportArticles',
	supportArticleContentById: 'supportArticleContentById',
	taskFilters: 'taskFilters',
	tasks: 'tasks',
	textGroups: 'textGroups',
	textHistory: 'textHistory',
	totalTasksByClientId: 'totalTasksByClientId',
	totalTasksForUser: 'totalTasksForUser',
	totalTasksByReference: 'totalTasksByReference',
	uploadsByTaskId: 'uploadsByTaskId',
	user: 'user',
	userByHash: 'userByHash',
	userById: 'userById',
	userList: 'userList',
	userListByBranchId: 'userListByBranchId',
	virtualStaging: 'virtualStaging',
	visualMaterials: 'visualMaterials',
	visualMaterialsSimplified: 'visualMaterialsSimplified',
	whitelabel: 'whitelabel',
}
