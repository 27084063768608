import { Close24 } from '@carbon/icons-react'
import { useHideModal } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { ANT_MODAL_VERTICAL_FOOTER_CLASS_NAME } from '@libs/theme'
import { Button, Typography, Modal } from 'antd'
import appRoutes from 'app.routes'
import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'
import { useDebounce } from 'use-debounce/lib'
import { forgetNewOrderState } from '../context'

const ConfirmNewOrderModal: FC = () => {
	const router = useRouter()
	const hideModal = useHideModal()
	const [isStartingOver, setIsStartingOver] = useState(false)
	const [isContinuing, setIsContinuing] = useState(false)

	const [isStartingOverStillLoading] = useDebounce(isStartingOver, 500)
	const [isContinuingStillLoading] = useDebounce(isContinuing, 500)

	const { t } = useLocalization()

	return (
		<Modal
			title={t('modals.confirmNewOrder.title')}
			visible
			closeIcon={<Close24 />}
			className={ANT_MODAL_VERTICAL_FOOTER_CLASS_NAME}
			onCancel={hideModal}
			footer={[
				<Button
					key="continue"
					type="primary"
					loading={isContinuingStillLoading}
					onClick={async () => {
						if (!isStartingOver && !isContinuing) {
							setIsContinuing(true)
							await router.push(appRoutes.newOrder())
							setIsContinuing(false)
							hideModal()
						}
					}}
				>
					{t('modals.confirmNewOrder.continue')}
				</Button>,
				<Button
					key="start-over"
					type={'text'}
					loading={isStartingOverStillLoading}
					onClick={async () => {
						if (!isStartingOver && !isContinuing) {
							setIsStartingOver(true)
							forgetNewOrderState()
							await router.push(appRoutes.newOrder())
							setIsStartingOver(false)
							hideModal()
						}
					}}
				>
					{t('modals.confirmNewOrder.create')}
				</Button>,
			]}
		>
			<Typography.Paragraph>{t('modals.confirmNewOrder.message')}</Typography.Paragraph>
		</Modal>
	)
}

export default ConfirmNewOrderModal
