import styled from 'styled-components'
import { Modal as _Modal } from 'antd'

const Modal = styled(_Modal)`
	max-width: 90vw;
	height: calc(100vh - 100px);

	.ant-modal-body {
		overflow: hidden;
		height: 100%;

		> div {
			height: 100%;
		}
	}

	.ant-modal-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
`

export default {
	Modal,
}
