import React, { FC } from 'react'
import { IconButton, Container } from '@libs/components'
import { Edit24 } from '@carbon/icons-react'
import { Typography } from 'antd'
import { useShowModal } from 'modules/common/GlobalModals'
import { IDatahubCase, INormalizedCaseText } from '@libs/api'
import Styled from './Styled'
import { CaseTextEditModalProps } from '../CaseTextEditModal'
import { useLocalization } from '@libs/localization'

interface IEditableDocumentText extends INormalizedCaseText {
	caseId: IDatahubCase['id']
}

const EditableDocumentText: FC<IEditableDocumentText> = ({ caseId, ...caseText }) => {
	const showModal = useShowModal()
	const { t } = useLocalization()
	const translatedTitle = t(`cases.textTypes.${caseText.normalizedTitle}`, { index: caseText.titleIndex })

	return (
		<Container display={'flex'} space={'sm'} spaceDirection={'horizontal'}>
			<IconButton
				onClick={() =>
					showModal('case.text.edit', {
						...caseText,
						caseId,
						translatedTitle,
					} as CaseTextEditModalProps)
				}
			>
				<Edit24 />
			</IconButton>
			<Container display={'flex'} flexDirection={'column'} flex={'0 1 auto'} min={{ width: 0 }}>
				<Typography.Text ellipsis strong>
					{translatedTitle}
				</Typography.Text>
				{caseText.content && (
					<Styled.TextContent
						ellipsis={{
							rows: 2,
						}}
					>
						{caseText.content}
					</Styled.TextContent>
				)}
			</Container>
		</Container>
	)
}

export default EditableDocumentText
