import { PointType } from '@libs/util'

export const ORIGINAL_SIZE: PointType = [1000, 600] // Because feedback in w2 is made at fixed width/height ( doh :( )
export const ARROW_SIZE = 50
export const ARROW_POINTER_SIZE = Math.sqrt(2 * Math.pow(ARROW_SIZE * 0.5, 2)) // Based on pythagorean theorem where arrow size = 2c and pointer size = i
export const POINT_SIZE = 50
export const ARROW_BODY_SIZE = 5
export const LASSO_BORDER_WIDTH = 2
export const LASSO_FILL_STYLE = 'rgba(0, 0, 0, 0.15)'
export const SELECTED_LASSO_FILL_STYLE = 'rgba(255, 130, 0, 0.2)'
export const LABEL_SIZE = 40 // approximately, needs clarifying
