import { useMemo, useCallback, useState, useEffect } from 'react'
import { message } from 'antd'
import { useMutation, useQuery } from 'react-query'
import {
	TasksApi,
	BookingTypes,
	IPhotographerBookingData,
	IDatahubTask,
	IBookingInfo,
	IUpdateBookingTypeParams,
} from '@libs/api'
import { useLocalization } from '@libs/localization'
import { IDropdownOption } from 'modules/common/types'
import { useHideModal } from '@libs/components'
import { useAuth } from 'modules/auth/hooks'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import { TaskModalCommonProps } from 'modules/tasks/types'
import QUERIES from 'modules/common/queries'
import { useBookingInfo } from 'modules/orders/hooks'
import { useCaseById } from 'modules/cases/hooks'
import { useShowModal } from 'modules/common/GlobalModals'

interface IUsePhotographerBookingModalProps {
	task: IDatahubTask
	onTaskComplete?: TaskModalCommonProps['onTaskComplete']
	injectedBooking?: boolean
}

export const useCallProposal = (bookingId?: IBookingInfo['bookingId']) =>
	useQuery(
		[QUERIES.bookingProposals, bookingId],
		async () => (bookingId ? await TasksApi.getCallProposal(bookingId) : undefined),
		{
			enabled: !!bookingId,
		}
	)

export const usePhotographerBookingModal = ({
	task,
	onTaskComplete,
	injectedBooking = false,
}: IUsePhotographerBookingModalProps) => {
	const hideModal = useHideModal()
	const showModal = useShowModal()
	const { t } = useLocalization()
	const invalidateTaskQueries = useTasksQueriesInvalidation()
	const [bookingType, setBookingType] = useState<BookingTypes>(
		injectedBooking ? BookingTypes.BookingSecondaryConfirmed : BookingTypes.BookingPrimaryConfirmed
	)

	const { data: foundCase } = useCaseById(task.caseId)
	const [selectedSecondaryItem, selectSecondaryItem] = useState<IPhotographerBookingData>()
	const [selectedContactPerson, selectContactPerson] = useState(foundCase?.contactPersons?.[0]?.email)

	useEffect(() => {
		if (foundCase?.contactPersons?.[0]?.email) {
			selectContactPerson(foundCase?.contactPersons?.[0]?.email)
		}
	}, [foundCase])

	useEffect(() => {
		if (foundCase?.contactPersons?.[0]?.email !== selectedContactPerson) {
			selectContactPerson(foundCase?.contactPersons?.[0]?.email)
		}
	}, [foundCase?.contactPersons])

	const { user } = useAuth()

	const { data: bookingInfo } = useBookingInfo(task.orderId)

	const { data: proposal, isLoading: isProposalLoading } = useCallProposal(bookingInfo?.bookingId)

	const onFlowFinish = useCallback(
		result => {
			if (result) {
				message.success(t('modals.bookPhotographer.success'))
				invalidateTaskQueries()

				if (onTaskComplete) {
					onTaskComplete(task)
					return
				}

				hideModal()

				if (
					bookingType === BookingTypes.BookingPrimaryConfirmed ||
					bookingType === BookingTypes.BookingSecondaryConfirmed
				) {
					showModal('tasks.book.confirmed', {
						title: t('modals.bookingConfirmed.title'),
						message: t('modals.bookingConfirmed.subtitle'),
						onConfirm: hideModal,
						isDanger: false,
						confirmLabel: t('common.actions.close'),
					})
				}
			} else {
				message.error(t('modals.bookPhotographer.failed'))
			}
		},
		[t, onTaskComplete, task, hideModal, invalidateTaskQueries, showModal, bookingType]
	)

	const { mutateAsync: partiallyFinishTask, isLoading: isTaskPartiallyFinishing } = useMutation(
		TasksApi.partiallyFinishTask,
		{
			onSuccess: onFlowFinish,
		}
	)

	const contactPersonOptions = useMemo<IDropdownOption[]>(
		() =>
			foundCase?.contactPersons.map(({ email = '', name, phone = '' }) => ({
				value: email,
				label: `${name} (${phone ? `${phone} ` : ''}${email})`,
				name,
			})) || [],
		[foundCase]
	)

	const contactPerson = useMemo(
		() => foundCase?.contactPersons.find(({ email }) => email === selectedContactPerson),
		[selectedContactPerson, foundCase]
	)

	const { mutateAsync: confirmBookingPhotographer, isLoading: isBookingLoading } = useMutation(
		TasksApi.confirmBookingPhotographer,
		{
			onSuccess: res => {
				if (!res?.success) {
					message.error(t('modals.bookPhotographer.failed'))
					return
				}
				onFlowFinish(res)
			},
		}
	)
	const { mutateAsync: contactPersonBooking, isLoading: isContactPersonBookingLoading } = useMutation(
		TasksApi.contactPersonBooking,
		{
			onSuccess: async () => partiallyFinishTask(task.id),
		}
	)
	const { mutateAsync: updateBookingType, isLoading: isBookingTypeUpdating } = useMutation(TasksApi.updateBookingType, {
		onSuccess: async response => {
			if (!response?.success) {
				message.error(t('modals.bookPhotographer.failed'))
				return
			}

			if (proposal) {
				switch (bookingType) {
					case BookingTypes.BookingEsoft:
						await partiallyFinishTask(task.id)
						break
					case BookingTypes.Booking3rdParty: {
						await contactPersonBooking({
							taskId: task.id,
							caseId: task.caseId,
							realtorName: user?.fullName || '',
							recipientEmail: contactPerson?.email || '',
							recipientName: contactPerson?.name || '',
							recipientPhone: contactPerson?.phone || '',
						})
						break
					}
					case BookingTypes.BookingSecondaryConfirmed:
						selectedSecondaryItem && (await confirmBookingPhotographer(selectedSecondaryItem))
						break
					case BookingTypes.BookingPrimaryConfirmed:
					default:
						if (proposal.primary) {
							await confirmBookingPhotographer(proposal.primary)
						}
				}
			}
		},
	})

	const onConfirmClick = useCallback(async () => {
		const payload: IUpdateBookingTypeParams = {
			taskId: task.id,
			bookingId: bookingInfo!.bookingId,
			bookingType,
			responsibleUser: foundCase?.contactPersons?.[0]?.name || '',
			person: '',
		}

		if (bookingType === BookingTypes.Booking3rdParty) {
			if (contactPerson) {
				payload.responsibleUser = user?.fullName || ''
				payload.person = contactPerson.name
				await updateBookingType(payload)
			}
		} else {
			if (bookingType === BookingTypes.BookingEsoft) {
				payload.responsibleUser = user?.fullName || ''
			}
			await updateBookingType(payload)
		}
	}, [task, bookingInfo, updateBookingType, bookingType, contactPerson, foundCase, user])

	const onAnotherDateClick = () => {
		setBookingType(BookingTypes.BookingSecondaryConfirmed)
	}

	const onBackClick = () => {
		setBookingType(BookingTypes.BookingPrimaryConfirmed)
	}

	const onContactPersonBooking = () => {
		setBookingType(BookingTypes.Booking3rdParty)
	}

	const onEsoftBooking = () => {
		setBookingType(BookingTypes.BookingEsoft)
	}

	return {
		user,
		proposal,
		isLoading: isProposalLoading,
		isSubmitting:
			isBookingLoading || isTaskPartiallyFinishing || isBookingTypeUpdating || isContactPersonBookingLoading,
		contactPersonOptions,
		bookingType,
		selectedContactPerson,
		selectedSecondaryItem,
		bookingInfo,
		onConfirmClick,
		onAnotherDateClick,
		onBackClick,
		onContactPersonBooking,
		onEsoftBooking,
		selectContactPerson,
		selectSecondaryItem,
	}
}
