import React, { FC, useMemo } from 'react'
import { Modal, Button, Divider, message } from 'antd'
import { Form, FormItem, Checkbox } from 'formik-antd'
import { Formik } from 'formik'
import { useHideModal, Grid, Autolocalize, FormikPhoneInput, UserInteraction, FormikInput } from '@libs/components'
import { UsersApi, DEFAULT_USER, IUser, isEsoftUser, UserAccess } from '@libs/api'
import DepartmentPermissionsTable from '../DepartmentPermissionsTable'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useAuth } from 'modules/auth/hooks'
import { useEffect } from 'react'
import Skeleton from './Skeleton'
import { useLocalization } from '@libs/localization'
import { createValidationSchema } from '@libs/util'
import { ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME } from '@libs/theme'
import { Close24 } from '@carbon/icons-react'
import { AUTH_RESET_PASSWORD_KEY } from '@libs/api/build/http'

export interface IEditUserFormValues extends IUser {}

export interface EditUserModalProps {
	userId: string
}

export const emptyUserFormValues: IEditUserFormValues = DEFAULT_USER

const formSchema = createValidationSchema(yup =>
	yup.object().shape({
		firstName: yup.string().required(),
		lastName: yup.string().required(),
		email: yup.string().email().required(),
		visiblePrices: yup.boolean(),
		phoneNumber: yup.string().min(8).phone(),
	})
)

const EditUserModal: FC<EditUserModalProps> = ({ userId }) => {
	const { data: user, isFetched } = useQuery([QUERIES.userById, userId], () => UsersApi.getUserById(userId))
	const { t } = useLocalization()
	const hideModal = useHideModal()
	const { user: currentUser } = useAuth()
	const queryClient = useQueryClient()

	const isEsoftUserValue = useMemo(() => isEsoftUser(), [])
	const { mutateAsync: updateUser, isLoading } = useMutation(UsersApi.updateUser, {
		onSuccess: () => {
			message.success(t('modals.editUser.success'))
			queryClient.invalidateQueries(QUERIES.user)
			queryClient.invalidateQueries(QUERIES.userList)
		},
		onError: () => {
			message.error(t('modals.editUser.failed'))
		},
	})

	useEffect(() => {
		return function cleanup() {
			queryClient.removeQueries([QUERIES.userById, userId])
		}
	}, [])

	const isCurrentUser = user?.id === currentUser?.id
	const isStillLoading = isLoading

	const isAdmin = useMemo(() => user?.branches?.some(({ access }) => access === UserAccess.Admin), [user])

	const onSubmit = async (values: IEditUserFormValues) => {
		await updateUser(values)
		hideModal()
	}

	const initialFormValues: IEditUserFormValues = useMemo(() => {
		if (user) {
			let normalizedPhoneNumber = user.phoneNumber?.replace(/ /g, '')

			if (user.phoneNumber && !user.phoneNumber.includes('+')) {
				normalizedPhoneNumber = `+${user.branches?.[0]?.countryCode === 'SE' ? '46' : '45'}${normalizedPhoneNumber}`
			}

			return {
				...user!,
				phoneNumber: normalizedPhoneNumber,
			} as IUser
		}
		return emptyUserFormValues as IUser
	}, [user])

	const resetPasswordLink = useMemo(
		() => (typeof window !== 'undefined' ? localStorage.getItem(AUTH_RESET_PASSWORD_KEY) : undefined),
		[]
	)

	return (
		<Formik<IEditUserFormValues>
			initialValues={initialFormValues}
			enableReinitialize
			validationSchema={formSchema}
			onSubmit={onSubmit}
		>
			{({ values, isValid, dirty, isSubmitting }) => (
				<Modal
					visible
					closeIcon={<Close24 />}
					title={user?.fullName?.trim() || user?.email || t('common.terms.user')}
					onCancel={hideModal}
					width={1200}
					footer={
						<Button
							form="edit-user-form"
							disabled={!isValid || !dirty}
							loading={isStillLoading}
							htmlType="submit"
							type="primary"
						>
							{t('common.actions.save')}
						</Button>
					}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form id="edit-user-form" layout="vertical">
							{!isFetched ? (
								<Skeleton.EditUserForm />
							) : (
								<>
									<Autolocalize />
									<Grid.Row gutter={['sm', 0]}>
										<Grid.Col xs={24} xl={12}>
											<FormItem
												name="firstName"
												label={t('common.terms.firstName')}
												required
												className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
											>
												<FormikInput name="firstName" disabled={isStillLoading} />
											</FormItem>
										</Grid.Col>
										<Grid.Col xs={24} xl={12}>
											<FormItem
												name="lastName"
												label={t('common.terms.lastName')}
												required
												className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
											>
												<FormikInput name="lastName" disabled={isStillLoading} />
											</FormItem>
										</Grid.Col>
										<Grid.Col xs={24} xl={12}>
											<FormItem
												name="email"
												label={t('common.terms.email')}
												required
												className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
											>
												<FormikInput name="email" disabled={isStillLoading} />
											</FormItem>
										</Grid.Col>
										<Grid.Col xs={24} xl={12}>
											<FormItem
												name="phoneNumber"
												label={t('common.terms.phone')}
												required
												className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
											>
												<FormikPhoneInput
													name="phoneNumber"
													country={user?.branches?.[0]?.countryCode}
													disabled={isStillLoading}
												/>
											</FormItem>
										</Grid.Col>
									</Grid.Row>
									{isCurrentUser && resetPasswordLink && (
										<>
											<Grid.Row gutter={['sm', 0]}>
												<Grid.Col xs={24} xl={12}>
													<Button
														type="primary"
														onClick={() => {
															window.open(resetPasswordLink, '_blank')
														}}
													>
														{t('common.actions.changePassword')}
													</Button>
												</Grid.Col>
											</Grid.Row>
											<Divider />
										</>
									)}
									<Grid.Row gutter={['sm', 0]}>
										{(isEsoftUserValue || (isCurrentUser && isAdmin)) && (
											<Grid.Col xs={24} xl={12}>
												<FormItem
													name="visiblePrices"
													label={t('common.terms.prices')}
													className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
												>
													<Checkbox name="visiblePrices">{t('modals.editUser.seePricesAction')}</Checkbox>
												</FormItem>
											</Grid.Col>
										)}
									</Grid.Row>
								</>
							)}
							{!isCurrentUser && user && (
								<>
									<Divider />
									<DepartmentPermissionsTable
										userId={user.id!}
										disabled={isStillLoading}
										branches={values['branches']}
										loading={!isFetched}
									/>
								</>
							)}
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default EditUserModal
