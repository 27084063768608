import styled, { css } from 'styled-components'
import { Button, Card as _Card } from 'antd'
import { color, margin, padding, screen, scrollbar, typography } from '@libs/theme/mixins'
import { Container } from '@libs/components'
import { OrderStatus } from '@libs/api'

const Card = styled(_Card)`
	.ant-card-body {
		// leave enough so that the scrollbar does not overlap the borders
		padding: 3px 3px 3px 0 !important;
	}
`

const OrderContainer = styled.div`
	height: 100%;
	overflow: auto;
	padding: ${padding('xl')};
	${scrollbar()}

	${screen.lte.xs`
		padding: ${padding('lg')};
	`}
`

const OrderItem = styled(Container)<{ lineThough: boolean }>`
	> *:first-child {
		margin-right: ${margin('xxs')};
		flex: 0 0 auto;
	}

	${({ lineThough }) =>
		lineThough &&
		css`
			text-decoration: line-through;
		`}
`

const LinkButton = styled(Button)`
	margin-top: ${margin('lg')};
	padding: 0;
	display: flex;
	align-items: center;
	height: auto;
	text-transform: uppercase;

	> svg {
		margin-left: ${margin('xxs')};
	}
`

const NoOrders = styled.div`
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	${typography('md')};
	font-weight: 500;
	color: ${color(['gray', '700'])};
`

const IconWrapper = styled(Container)<{ orderStatus: OrderStatus }>`
	margin-left: auto;

	${({ orderStatus }) => css`
		> :first-child {
			color: ${orderStatus === OrderStatus.Delivered || orderStatus === OrderStatus.Invoiced
				? color('success')
				: orderStatus === OrderStatus.Cancelled && color('error')};
		}
	`}

	> *:not(:last-child) {
		margin-right: ${margin('xxs')};
	}

	a {
		display: flex;
		cursor: pointer;
	}
`

export default {
	Card,
	OrderItem,
	LinkButton,
	OrderContainer,
	NoOrders,
	IconWrapper,
}
