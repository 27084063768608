import styled from 'styled-components'
import { Container, FormikInput, SelectAutocomplete } from '@libs/components'
import React from 'react'
import { color, height, margin, typography, screen } from '@libs/theme/mixins'
import { Divider, Button } from 'antd'
import { FormItem } from 'formik-antd'

const WatchersList = styled(Container)`
	min-height: ${height('xl')};
	flex-wrap: wrap;

	.ant-avatar {
		margin: ${margin('xxs')} ${margin('sm')} ${margin('xxs')} 0;
		min-width: ${height('xl')};
	}

	.ant-divider-vertical {
		height: ${height('xl')};
		margin: 0 ${margin('md')};
	}

	.ant-btn {
		padding: 0;
	}
`

const ButtonWithIcon = styled(Button)`
	display: inline-flex;
	align-items: center;

	svg {
		margin-right: ${margin('xs')};
	}
`

const InputNumber = styled(FormikInput)`
	width: 100%;
`

const SectionDivider = styled(({ className }) => <Divider className={className} />)`
	margin: ${margin('lg', 0)};
	border-top-width: 2px;
	border-top-color: ${color(['gray', '600'])};
`

const SectionTitle = styled.div<{
	$primary?: boolean
	$inline?: boolean
	$space?: boolean
}>`
	${typography('xl')}
	display: ${({ $inline }) => ($inline ? 'inline-block' : 'block')};
	color: ${({ $primary }) => color($primary ? 'primary' : 'black')};
	font-weight: 500;
	margin-bottom: ${({ $space }) => ($space ? margin('xxs') : 0)};
`

const FormItemCheckbox = styled(FormItem)`
	.ant-form-item-control-input {
		margin-top: ${margin('xxs')};
		min-height: 0;
	}

	.ant-form-item-control-input-content {
		display: flex;
	}
`

const SectionSubTitle = styled.div`
	${typography('md')};
	font-style: italic;
	display: inline-block;
`

const EditButton = styled(Button)`
	position: absolute;
	right: 0;

	${screen.lte.xs`
		position: relative;
		right: 0;
		padding-left: 0;
		padding-right: 0;
		width: auto !important;
		align-self: flex-start;
	`}
`

const AddressAutoComplete = styled(SelectAutocomplete)`
	.ant-select-selection-search {
		right: 170px !important;
	}
`

export default {
	WatchersList,
	InputNumber,
	SectionDivider,
	SectionTitle,
	SectionSubTitle,
	ButtonWithIcon,
	FormItemCheckbox,
	EditButton,
	AddressAutoComplete,
}
