import React, { FC } from 'react'
import { Skeleton, Button, Typography } from 'antd'
import { ISimplifiedVisualMaterial } from '@libs/api'
import { Container, useHideModal, Stepper } from '@libs/components'
import Styled from './Styled'
import { useTaskVirtualStagingModal } from './hooks'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { Close24 } from '@carbon/icons-react'
import TaskModalTitle from 'modules/common/TaskModalTitle'

export interface TaskVirtualStagingModalProps extends TaskModalCommonProps {
	filteredMaterialTypes: ISimplifiedVisualMaterial['type'][]
}

const TaskVirtualStagingModal: FC<TaskVirtualStagingModalProps> = ({
	task,
	filteredMaterialTypes,
	onTaskComplete,
	onTaskCancel,
}) => {
	const hideModal = useHideModal()
	const [
		{ currentStep = 0, isLoading, isFetched, isOrderLoading, steps = [], isNextEnabled, innerVisualMaterials = [] },
		{ onBackClick, onNextClick, setLastStep },
	] = useTaskVirtualStagingModal({ task, filteredMaterialTypes, onTaskComplete })
	const { t } = useLocalization()

	return (
		<Styled.Modal
			visible
			closeIcon={<Close24 />}
			onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
			width="auto"
			title={<TaskModalTitle title={t('modals.virtualStaging.title')} caseId={task.caseReference} />}
			footer={[
				<Button disabled={currentStep === 0 || isLoading} key="back" onClick={onBackClick}>
					{t('navigation.back')}
				</Button>,
				<Button
					disabled={isLoading || !isNextEnabled}
					loading={isOrderLoading}
					type="primary"
					key="next"
					onClick={onNextClick}
				>
					{currentStep === 4 ? t('common.actions.confirm') : t('navigation.next')}
				</Button>,
			]}
		>
			{!isFetched ? (
				<Container margin="xxl" display={'flex'} justifyContent={'center'}>
					<Skeleton active paragraph={{ rows: 5 }} />
				</Container>
			) : innerVisualMaterials.length > 0 ? (
				<Stepper current={currentStep} onTransitionComplete={() => setLastStep?.(currentStep)}>
					{steps.map(({ title, content }, index) => (
						<Stepper.Step key={index} title={title}>
							{content}
						</Stepper.Step>
					))}
				</Stepper>
			) : (
				<Container margin="xxl" display={'flex'} justifyContent={'center'}>
					<Typography.Paragraph>{t('modals.virtualStaging.emptyList')}</Typography.Paragraph>
				</Container>
			)}
		</Styled.Modal>
	)
}

export default TaskVirtualStagingModal
