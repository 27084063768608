import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from 'react'

import 'react-advanced-cropper/dist/style.css'
import CollageCell from './CollageCell'

import Styled from './Styled'
import { ICollageTemplate } from './types'
import { COLLAGE_OUTPUT_HEIGHT, COLLAGE_OUTPUT_WIDTH } from './consts'

export interface CollageEditorProps extends ICollageTemplate {
	images: string[]
	onChangeSource: (imageUrl: string, index: number) => void
	onChangeOutput: (dateUrl: string, index: number) => void
	disabled?: boolean
	setTemplateContainerHeight: (height?: number) => void
}

const CollageEditor: FC<CollageEditorProps> = ({
	grid,
	layout,
	items,
	images,
	onChangeSource,
	onChangeOutput,
	disabled,
	setTemplateContainerHeight,
}) => {
	const gridRef = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>
	const [containerSizes, setContainerSizes] = useState<
		| {
				width: number
				height: number
		  }
		| undefined
	>()

	const recalculateSizes = useCallback(() => {
		if (!gridRef.current?.clientWidth) return
		const ratio =
			gridRef.current?.clientWidth / (layout === 'horizontal' ? COLLAGE_OUTPUT_WIDTH : COLLAGE_OUTPUT_HEIGHT)

		setContainerSizes({
			width: gridRef.current.clientWidth,
			height: ratio * (layout === 'horizontal' ? COLLAGE_OUTPUT_HEIGHT : COLLAGE_OUTPUT_WIDTH),
		})
	}, [layout])

	useEffect(() => {
		setTemplateContainerHeight(containerSizes?.height)
	}, [setTemplateContainerHeight, containerSizes?.height])

	useEffect(() => {
		window.addEventListener('resize', recalculateSizes)

		return () => {
			window.removeEventListener('resize', recalculateSizes)
		}
	}, [])

	useEffect(() => {
		recalculateSizes()
	}, [recalculateSizes])

	return (
		<Styled.GridTemplate ref={gridRef} $grid={grid} $disabled={disabled} $height={containerSizes?.height}>
			{items.map((item, index) => (
				<CollageCell
					{...item}
					containerSizes={containerSizes}
					key={index}
					imageUrl={images[index]}
					onChangeSource={props => onChangeSource(props, index)}
					onChangeOutput={props => onChangeOutput(props, index)}
				/>
			))}
		</Styled.GridTemplate>
	)
}

export default CollageEditor
