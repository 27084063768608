import React, { FC } from 'react'
import { ITaskCreateCommentParams, TaskCategory, TasksApi } from '@libs/api'
import {
	Autolocalize,
	FormikInput,
	FormikPhoneInput,
	FormikTextArea,
	useHideModal,
	UserInteraction,
} from '@libs/components'
import { Button, Modal, Typography, message, Space } from 'antd'
import { Form, FormItem } from 'formik-antd'
import { Formik } from 'formik'
import { useMutation } from 'react-query'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { createValidationSchema } from '@libs/util'
import { useAuth } from 'modules/auth/hooks'
import { Close24 } from '@carbon/icons-react'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import TaskModalTitle from 'modules/common/TaskModalTitle'

export interface TaskPersonInfoModalProps extends TaskModalCommonProps {
	optional?: boolean
}

interface IBasicForm {
	emailRequired: boolean
	phoneRequired: boolean
	personsText: string
	phoneText: string
	emailText: string
	noteText: string
}

const formSchema = createValidationSchema(yup =>
	yup.object().shape({
		emailRequired: yup.boolean(),
		phoneRequired: yup.boolean(),
		personsText: yup.string().required(),
		emailText: yup.string().email().when('emailRequired', {
			is: true,
			then: yup.string().required(),
		}),
		phoneText: yup.string().when('phoneRequired', {
			is: true,
			then: yup.string().min(8).phone().required(),
		}),
	})
)

//This modal handles tasks of type PERSON and PERSON_INFO accordingly
const TaskPersonInfoModal: FC<TaskPersonInfoModalProps> = ({ task, optional, onTaskComplete, onTaskCancel }) => {
	const { user } = useAuth()
	const hideModal = useHideModal()
	const { t } = useLocalization()
	const invalidateTaskQueries = useTasksQueriesInvalidation()
	const { Text } = Typography
	const isPersonCategory = task.type == TaskCategory.Person
	const isPersonInfoCategory = task.type == TaskCategory.PersonInfo

	const { mutateAsync: completeTask, isLoading: isCompleteTaskLoading } = useMutation(TasksApi.completeTask, {
		onSuccess: response => {
			if (response.success) {
				message.success(t('modals.personInfo.success'))
				invalidateTaskQueries()

				if (onTaskComplete) {
					onTaskComplete(task)
					return
				}

				hideModal()
			} else {
				message.error(t('modals.personInfo.failed'))
			}
		},
		onError: () => {
			message.error(t('modals.personInfo.failed'))
		},
	})

	const onSubmit = async (data: IBasicForm) => {
		const commentPayload: ITaskCreateCommentParams = {
			comment: '',
			taskId: task.id,
			email: user?.email || '',
		}
		if (isPersonInfoCategory) {
			commentPayload.comment = `${t('common.terms.name')}: ${data.personsText}, ${t('common.terms.email')}: ${
				data.emailText
			}, ${t('common.terms.phone')}: ${data.phoneText}, ${t('common.terms.comment')}: ${data.noteText}`
		}

		if (isPersonCategory) {
			commentPayload.comment = `${t('common.terms.person')}: ${data.personsText}, ${t('common.terms.comment')}: ${
				data.noteText
			}`
		}

		createComment(commentPayload)
	}

	const { mutateAsync: createComment, isLoading: isCreateCommentLoading } = useMutation(TasksApi.createComment, {
		onSuccess: response => {
			if (response?.success) {
				completeTask(task.id)
			} else {
				message.error(t('modals.personInfo.failed'))
			}
		},
		onError: () => {
			message.error(t('modals.personInfo.failed'))
		},
	})

	const initialValues: IBasicForm = {
		phoneRequired: isPersonInfoCategory,
		emailRequired: isPersonInfoCategory,
		personsText: '',
		phoneText: '',
		emailText: '',
		noteText: '',
	}

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={formSchema} validateOnMount>
			{({ isValid, isSubmitting }) => (
				<Modal
					visible
					closeIcon={<Close24 />}
					title={<TaskModalTitle title={t('modals.personInfo.title')} caseId={task.caseReference} />}
					onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
					width={500}
					footer={
						<Button
							form="task-person-info-form"
							htmlType="submit"
							type="primary"
							disabled={!optional && !isValid}
							loading={isCreateCommentLoading || isCompleteTaskLoading}
						>
							{t('modals.personInfo.submit')}
						</Button>
					}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form id="task-person-info-form" layout="vertical">
							<Autolocalize />
							<Space direction="vertical" size="middle">
								<Text>
									{isPersonInfoCategory && t('modals.personInfo.recordedInformationInstructions')}
									{isPersonCategory && t('modals.personInfo.photographedInformationInstructions')}
								</Text>

								<FormItem
									name="personsText"
									label={isPersonCategory ? t('common.terms.names') : t('common.terms.name')}
									required
								>
									<FormikInput name="personsText" />
								</FormItem>
								{/* These fields are only necessary when someone needs to be recorded */}
								{isPersonInfoCategory && (
									<>
										<FormItem name="emailText" label={t('common.terms.email')} required>
											<FormikInput name="emailText" />
										</FormItem>
										<FormItem name="phoneText" label={t('common.terms.phone')} required>
											<FormikPhoneInput country={user?.branches?.[0]?.countryCode?.toLowerCase()} name="phoneText" />
										</FormItem>
									</>
								)}
								<FormItem name="noteText" label={t('common.terms.comment')}>
									<FormikTextArea name="noteText" autoSize={{ minRows: 3, maxRows: 5 }} />
								</FormItem>
							</Space>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default TaskPersonInfoModal
