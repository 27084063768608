import styled from 'styled-components'
import { color } from '@libs/theme/mixins'

const FormWrapper = styled.div`
	.ant-form-item-required {
		svg {
			order: -1;
		}
	}

	label > svg:hover {
		color: ${color(['primary', '500'])};
	}
`

export default {
	FormWrapper,
}
