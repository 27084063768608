import React, { FC } from 'react'
import { FormItem, Select } from 'formik-antd'
import { useFormikContext } from 'formik'
import { Container, FormikPhoneInput, FormikInput } from '@libs/components'
import { IPropertyStepThreeFormValues } from '../NewOrderStepThree'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'
import { useAuth } from 'modules/auth/hooks'
import { useContactPersons } from '../hooks'
import { HolderOutlined } from '@ant-design/icons'

interface ContactPersonProps {
	/** Contact person index in formik context */
	index: number
}

const ContactPerson: FC<ContactPersonProps> = ({ index }) => {
	const { user } = useAuth()
	const { values, setFieldValue } = useFormikContext<IPropertyStepThreeFormValues>()
	const { contactPersonTypeOptions } = useContactPersons()
	const { t } = useLocalization()

	return (
		<Container>
			<Container display={'flex'} justifyContent={'space-between'} alignItems={'center'} margin={{ bottom: 'md' }}>
				<Styled.Title>
					<HolderOutlined />
					{index === 0 && t('orders.pages.new.stepThree.contactPersons.primary')}
					{index === 1 && t('orders.pages.new.stepThree.contactPersons.secondary')}
					{index === 2 && t('orders.pages.new.stepThree.contactPersons.tertiary')}
				</Styled.Title>
				{index !== 0 && (
					<Styled.RemoveButton
						type="link"
						onClick={() => {
							setFieldValue(
								'contactPersons',
								values.contactPersons?.filter((_, innerIndex) => innerIndex !== index)
							)
						}}
					>
						{t('common.actions.remove')}
					</Styled.RemoveButton>
				)}
			</Container>
			<FormItem name={`contactPersons[${index}].type`} label={t('common.terms.contactType')} required>
				<Select name={`contactPersons[${index}].type`} options={contactPersonTypeOptions} />
			</FormItem>
			<FormItem name={`contactPersons[${index}].name`} label={t('common.terms.name')} required>
				<FormikInput name={`contactPersons[${index}].name`} />
			</FormItem>
			<FormItem name={`contactPersons[${index}].phone`} label={t('common.terms.phone')} required>
				<FormikPhoneInput
					country={user?.branches?.[0]?.countryCode?.toLowerCase()}
					value={values.contactPersons?.[index]?.phone}
					name={`contactPersons[${index}].phone`}
				/>
			</FormItem>
			{/* <FormItem name={`contactPersons[${index}].phoneAlt`} label={t('common.terms.phoneAlt')}>
				<FormikPhoneInput
					country={user?.branches?.[0]?.countryCode?.toLowerCase()}
					value={values.contactPersons?.[index].phoneAlt}
					name={`contactPersons[${index}].phoneAlt`}
				/>
			</FormItem> */}
			<FormItem name={`contactPersons[${index}].email`} label={t('common.terms.email')}>
				<FormikInput type={'email'} name={`contactPersons[${index}].email`} />
			</FormItem>
		</Container>
	)
}

export default ContactPerson
