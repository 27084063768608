import React, { FC, useMemo } from 'react'
import { Modal, Button, message } from 'antd'
import { Autolocalize, FormikInput, useHideModal, UserInteraction } from '@libs/components'
import { Formik } from 'formik'
import { Form, FormItem } from 'formik-antd'
import { UserAccess, UsersApi } from '@libs/api'
import DepartmentPermissionsTable from '../DepartmentPermissionsTable'
import { useAuth } from 'modules/auth/hooks'
import { IEditUserFormValues } from '../EditUserModal'
import { useMutation, useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useLocalization } from '@libs/localization'
import { createValidationSchema } from '@libs/util'
import { Close24 } from '@carbon/icons-react'

interface IFormValues extends Pick<IEditUserFormValues, 'email' | 'branches'> {
	newUserEmail: string
}

const schema = createValidationSchema(yup =>
	yup.object().shape({
		newUserEmail: yup.string().required().required().businessEmail(),
	})
)

export interface InviteUserModalProps {}

const InviteUserModal: FC<InviteUserModalProps> = () => {
	const { t } = useLocalization()
	const hideModal = useHideModal()
	const { user } = useAuth()
	const queryClient = useQueryClient()
	const { mutateAsync: sendInvite } = useMutation(UsersApi.sendInvite, {
		onSuccess: response => {
			if (response.success) {
				queryClient.invalidateQueries(QUERIES.invites)
				message.success(t('modals.inviteUser.success'))
				hideModal()
				return
			}

			message.error(t('modals.inviteUser.failed'))
		},
	})

	const onSubmit = async (values: IFormValues) => {
		const branchesToProvideAccess = values.branches.filter(branch => branch.access !== UserAccess.None)

		if (branchesToProvideAccess.length === 0) {
			message.error(t('modals.inviteUser.noBranchesSelected'))
			return
		}

		await sendInvite({
			email: values.newUserEmail,
			branches: branchesToProvideAccess.map(branch => ({
				branchId: branch.id,
				isAdmin: branch.access === UserAccess.Admin,
			})),
		})
	}

	const initialValues: IFormValues = useMemo(() => {
		const branches = (user?.branches || []).map(currentDepartment => ({
			...currentDepartment,
			access: UserAccess.None,
		}))

		return {
			email: user?.email || '',
			newUserEmail: '',
			branches,
		}
	}, [user])

	return (
		<Formik<IFormValues> initialValues={initialValues} validationSchema={schema} enableReinitialize onSubmit={onSubmit}>
			{({ values, dirty, isValid, isSubmitting }) => (
				<Modal
					visible
					title={t('modals.inviteUser.title')}
					onCancel={hideModal}
					width={800}
					closeIcon={<Close24 />}
					footer={[
						<Button
							loading={isSubmitting}
							disabled={!dirty || !isValid}
							form="user-invite-form"
							key="submit"
							htmlType="submit"
							type="primary"
						>
							{t('modals.inviteUser.submit')}
						</Button>,
					]}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form layout="vertical" id="user-invite-form">
							<Autolocalize />
							<FormItem name="newUserEmail" label={t('common.terms.email')} required>
								<FormikInput type="email" name="newUserEmail" />
							</FormItem>
							<DepartmentPermissionsTable branches={values.branches} />
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default InviteUserModal
