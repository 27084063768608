import React, { FC } from 'react'
import { Modal, Button, message } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem } from 'formik-antd'
import { TasksApi } from '@libs/api'
import { Autolocalize, FormikInput, FormikTextArea, useHideModal, UserInteraction } from '@libs/components'
import { useMutation, useQueryClient } from 'react-query'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { createValidationSchema } from '@libs/util'
import { Close24 } from '@carbon/icons-react'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import TaskModalTitle from 'modules/common/TaskModalTitle'
import { useAuth } from 'modules/auth/hooks'
import queries from 'modules/common/queries'

interface ITaskVideoInformationFormValues {
	companyName: string
	website: string
	address: string
	companyDescription: string
	endText: string
	comment: string
}

export interface TaskVideoInformationModalProps extends TaskModalCommonProps {}

const initialValues: ITaskVideoInformationFormValues = {
	companyName: '',
	website: '',
	address: '',
	companyDescription: '',
	endText: '',
	comment: '',
}

const schema = createValidationSchema(yup =>
	yup.object().shape({
		companyName: yup.string().required(),
		website: yup.string(),
		address: yup.string(),
		companyDescription: yup.string(),
		endText: yup.string(),
		comment: yup.string().required(),
	})
)

const TaskVideoInformationModal: FC<TaskVideoInformationModalProps> = ({ task, onTaskComplete, onTaskCancel }) => {
	const { t } = useLocalization()
	const hideModal = useHideModal()
	const { user } = useAuth()
	const invalidateTaskQueries = useTasksQueriesInvalidation()
	const queryClient = useQueryClient()
	const { mutateAsync: completeTask, isLoading: isCompleteTaskLoading } = useMutation(TasksApi.completeTask, {
		onSuccess: response => {
			if (response.success) {
				queryClient.invalidateQueries([queries.orderTasks, task.orderId])
				queryClient.invalidateQueries(queries.tasks)
			}
		},
	})
	const { mutateAsync: createComment, isLoading: isCreateCommentLoading } = useMutation(TasksApi.createComment, {
		onSuccess: async result => {
			if (result?.success) {
				const isTaskCompleted = await completeTask(task.id)

				if (!isTaskCompleted) {
					message.error(t('modals.videoInformation.failed'))
					return
				}

				invalidateTaskQueries()
				message.success(t('modals.videoInformation.success'))

				if (onTaskComplete) {
					onTaskComplete(task)
					return
				}

				hideModal()
			} else {
				message.error(t('modals.videoInformation.failed'))
			}
		},
	})

	const isLoading = isCompleteTaskLoading || isCreateCommentLoading

	const onSubmit = async (values: ITaskVideoInformationFormValues) => {
		const noteText = Object.keys(values)
			.filter(key => values[key as keyof ITaskVideoInformationFormValues])
			.reduce((memo, key, index, arr) => {
				const typedKey = key as keyof ITaskVideoInformationFormValues
				const suffix = index === arr.length - 1 ? '. ' : ', '

				switch (typedKey) {
					case 'companyName':
						memo += `${t('common.terms.companyName')}: `
						break
					case 'website':
						memo += `${t('common.terms.website')}: `
						break
					case 'address':
						memo += `${t('common.terms.address')}: `
						break
					case 'companyDescription':
						memo += `${t('common.terms.companyDescription')}: `
						break
					case 'endText':
						memo += `${t('modals.videoInformation.unsubscribeLabel')}: `
						break
					case 'comment':
						memo += `${t('common.terms.comment')}: `
						break
				}
				memo += `${values[typedKey]}${suffix}`
				return memo
			}, '')

		await createComment({
			comment: noteText.trim(),
			taskId: task.id,
			email: user?.email || '',
		})
	}

	return (
		<Formik<ITaskVideoInformationFormValues>
			initialValues={initialValues}
			validationSchema={schema}
			onSubmit={onSubmit}
		>
			{({ dirty, isValid, isSubmitting }) => (
				<Modal
					visible
					closeIcon={<Close24 />}
					title={<TaskModalTitle title={t('modals.videoInformation.title')} caseId={task.caseReference} />}
					onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
					width={800}
					footer={
						<Button
							form="task-video-information-form"
							htmlType="submit"
							type="primary"
							disabled={!dirty || !isValid}
							loading={isLoading}
						>
							{t('common.actions.completeTask')}
						</Button>
					}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form id="task-video-information-form" layout="vertical">
							<Autolocalize />
							<FormItem name="companyName" label={t('common.terms.companyName')} required>
								<FormikInput name="companyName" />
							</FormItem>
							<FormItem name="website" label={t('common.terms.website')}>
								<FormikInput name="website" />
							</FormItem>
							<FormItem name="address" label={t('common.terms.address')}>
								<FormikInput name="address" />
							</FormItem>
							<FormItem name="companyDescription" label={t('common.terms.companyDescription')}>
								<FormikTextArea name="companyDescription" />
							</FormItem>
							<FormItem name="endText" label={t('modals.videoInformation.unsubscribeLabel')}>
								<FormikTextArea name="endText" />
							</FormItem>
							<FormItem name="comment" label={t('common.terms.comment')} required>
								<FormikTextArea name="comment" />
							</FormItem>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default TaskVideoInformationModal
