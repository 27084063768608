import Container from '@libs/components/src/Container'
import { color, typography } from '@libs/theme/mixins'
import styled from 'styled-components'

const TitleWrapper = styled(Container)`
	line-height: ${({ theme }) => theme.typography.lineHeightBase};
`

const Subtitle = styled.div`
	${typography('sm')}
	color: ${color('black')};
	font-weight: 400;
`

export default { TitleWrapper, Subtitle }
