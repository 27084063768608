import React, { FC, useEffect, useMemo } from 'react'
import { Button, Typography } from 'antd'
import { FormItem, AutoComplete } from 'formik-antd'
import { useFormikContext } from 'formik'
import Styled from './Styled'
import { ICopyMaterialFormValues } from '../CopyMaterialModal'
import { useCopyMaterialItem } from '../hooks'
import { useLocalization } from '@libs/localization'
import { ANT_BUTTON_AUTO_WIDTH_CLASS_NAME } from '@libs/theme'
import { SelectAutocomplete } from '@libs/components'
import { AddressApi, IAddress, isEsoftUser } from '@libs/api'
import { useAuth } from 'modules/auth/hooks'
import { useAddressSelector } from 'modules/common/hooks'
import { Grid } from '@libs/components'

interface ICopyMaterialItemProps {
	index: number
	defaultCaseAddress?: IAddress
}

const CopyMaterialItem: FC<ICopyMaterialItemProps> = ({ index, defaultCaseAddress }) => {
	const { t } = useLocalization()
	const { user } = useAuth()
	const { values, setFieldValue } = useFormikContext<ICopyMaterialFormValues>()
	const { setCaseSearchKey, setDepartmentSearchKey, departments, cases } = useCopyMaterialItem({
		currentItem: values.copies[index],
	})
	const reference = values.copies[index].reference
	const isValidQuery = (reference?.length || 0) >= 3

	const isNewCase =
		isValidQuery &&
		values.copies[index].reference &&
		!cases.some(caseItem => caseItem.reference === values.copies[index].reference)

	const {
		address,
		setPrediction: setAddressPrediction,
		setQuery: setAddressQuery,
		autocomplete: addressAutocomplete,
	} = useAddressSelector()

	useEffect(() => {
		if (defaultCaseAddress && isNewCase) {
			setFieldValue(`copies[${index}].address`, defaultCaseAddress)
		} else {
			setFieldValue(`copies[${index}].address`, undefined)
		}
	}, [defaultCaseAddress, isNewCase])

	useEffect(() => {
		address && setFieldValue(`copies[${index}].address`, address)
	}, [address])

	const existingCaseOptions = useMemo(
		() => cases.map(caseItem => ({ value: caseItem.reference, label: caseItem.reference, data: caseItem.id })),
		[cases]
	)
	const providerSupportsSubpremise =
		!!user && AddressApi.providerSupportsSubpremise(user?.branches?.[0]?.countryCode?.toLowerCase())

	const caseOptions = useMemo(() => {
		const newCaseOption =
			isValidQuery && reference && !cases.find(({ id }) => id === reference)
				? {
						value: reference,
						label: `${t('cases.actions.createNew')} "${reference}"`,
						data: undefined,
				  }
				: undefined

		return newCaseOption ? [newCaseOption, ...existingCaseOptions] : existingCaseOptions
	}, [isValidQuery, cases, reference, existingCaseOptions, t])

	const isEsoftUserValue = useMemo(() => isEsoftUser(), [])

	return (
		<Styled.CopyBlock>
			{isEsoftUserValue && (
				<FormItem
					name={`copies[${index}].departmentId`}
					label={
						<Styled.CustomLabel>
							{t('common.terms.department')}
							<Button
								className={ANT_BUTTON_AUTO_WIDTH_CLASS_NAME}
								type="link"
								onClick={() => setFieldValue('copies', values.copies.slice(0, -1))}
							>
								{t('common.actions.remove')}
							</Button>
						</Styled.CustomLabel>
					}
				>
					<AutoComplete
						showSearch
						name={`copies[${index}].departmentId`}
						placeholder={t('common.placeholders.minChars', { min: 3 })}
						onSearch={setDepartmentSearchKey}
						value={departments.find(({ id }) => id === values.copies[index].departmentId)?.name}
						onSelect={value => {
							setFieldValue(`copies[${index}].departmentId`, value)
							setFieldValue(`copies[${index}].case`, '')
						}}
						options={departments.map(({ id, name }) => ({ value: id, label: name }))}
					/>
				</FormItem>
			)}
			<FormItem
				name={`copies[${index}].reference`}
				label={
					<Styled.CustomLabel>
						{t('common.terms.case')}
						{!isEsoftUserValue && (
							<Button
								className={ANT_BUTTON_AUTO_WIDTH_CLASS_NAME}
								type="link"
								onClick={() => setFieldValue('copies', values.copies.slice(0, -1))}
							>
								{t('common.actions.remove')}
							</Button>
						)}
					</Styled.CustomLabel>
				}
			>
				<AutoComplete
					showSearch
					name={`copies[${index}].reference`}
					options={caseOptions}
					disabled={!values.copies[index].departmentId}
					onSearch={value => {
						setCaseSearchKey(value)
						setFieldValue(`copies[${index}].caseId`, '')
					}}
					onSelect={value => {
						const searchValue = caseOptions.find(item => item.value === value)?.value || value
						setCaseSearchKey(searchValue)
						setFieldValue(`copies[${index}].reference`, searchValue)
						setFieldValue(`copies[${index}].caseId`, caseOptions.find(item => item.value === value)?.data)
					}}
					placeholder={t('common.placeholders.minChars', { min: 3 })}
				/>
			</FormItem>
			{isNewCase && (
				<>
					<Typography.Paragraph>
						{t('cases.actions.createNew')} &quot;{reference}&quot;
					</Typography.Paragraph>
					<Grid.Row>
						<Grid.Col xs={24}>
							<SelectAutocomplete
								name={`copies[${index}].address.full`}
								loading={addressAutocomplete.isStillLoading}
								options={addressAutocomplete.options}
								placeholder={
									providerSupportsSubpremise
										? t('common.placeholders.minChars', { min: 3 })
										: t('common.placeholders.specificAddressAutocomplete')
								}
								onSearch={setAddressQuery}
								onChange={(_, option: any) => {
									setAddressPrediction(option.data)
								}}
							/>
						</Grid.Col>
					</Grid.Row>
				</>
			)}
		</Styled.CopyBlock>
	)
}

export default CopyMaterialItem
