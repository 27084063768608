import { Close24 } from '@carbon/icons-react'
import { Container, useHideModal } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { Button, Spin, Typography } from 'antd'
import TaskModalTitle from 'modules/common/TaskModalTitle'
import MaterialList from 'modules/material/MaterialList'
import React, { FC } from 'react'
import { TaskModalCommonProps } from '../types'
import { useTaskImageSelectorModal } from './hooks'
import Styled from './Styled'

export interface TaskImageSelectorModalProps extends TaskModalCommonProps {}

const TaskImageSelectorModal: FC<TaskImageSelectorModalProps> = props => {
	const { t } = useLocalization()
	const hideModal = useHideModal()

	const {
		materials,
		foundOrderline,
		areMaterialsLoading,
		selectMaterialIds,
		selectedMaterialIds,
		completeTask,
		isCompletingTask,
	} = useTaskImageSelectorModal(props)

	return (
		<Styled.Modal
			visible
			closeIcon={<Close24 />}
			onCancel={props.onTaskCancel ? () => props.onTaskCancel?.(props.task) : hideModal}
			width="auto"
			title={<TaskModalTitle title={t('modals.imageSelector.title')} caseId={props.task.caseReference} />}
			footer={[
				<Button
					key="submit"
					form="task-image-selector-form"
					htmlType="button"
					type="primary"
					loading={isCompletingTask}
					disabled={selectedMaterialIds.length !== foundOrderline?.orderedQuantity}
					onClick={completeTask}
				>
					{t('common.actions.completeTask')}
				</Button>,
			]}
		>
			{areMaterialsLoading ? (
				<Container margin="xxl" display={'flex'} justifyContent={'center'}>
					<Spin />
				</Container>
			) : materials.length >= 1 ? (
				<Container>
					<Typography.Paragraph>
						{t('modals.imageSelector.message', {
							amount: selectedMaterialIds.length,
							total: foundOrderline?.orderedQuantity || 0,
						})}
					</Typography.Paragraph>
					<MaterialList
						showAll
						disabledInactiveOverlay
						visualMaterials={materials.map(material =>
							selectedMaterialIds.includes(material.id)
								? {
										...material,
										isSelected: true,
								  }
								: material
						)}
						axis="xy"
						onItemClick={materialId =>
							selectMaterialIds(prev =>
								prev.includes(materialId) ? prev.filter(id => id !== materialId) : prev.concat([materialId])
							)
						}
					/>
				</Container>
			) : (
				<Container margin="xxl" display={'flex'} justifyContent={'center'}>
					<Typography.Paragraph>{t('modals.beforeAfter.notEnoughMaterials')}</Typography.Paragraph>
				</Container>
			)}
		</Styled.Modal>
	)
}

export default TaskImageSelectorModal
