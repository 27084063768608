import React from 'react'
import { Container } from '@libs/components'
import NewOrderSteps from '../NewOrderSteps'

const NewOrderPageContent = () => (
	<Container padding={['xl', 0]}>
		<NewOrderSteps />
	</Container>
)

export default NewOrderPageContent
