import { ISimplifiedVisualMaterial, TaskCategory, TasksApi } from '@libs/api'
import { useMutation } from 'react-query'
import React, { useState, useEffect, useMemo, useCallback, useRef, RefObject } from 'react'
import MaterialList, { IMaterialList } from 'modules/material/MaterialList'
import { Typography, message } from 'antd'
import ObjectsRemovalStep from '../ObjectsRemovalStep'
import { Container, useHideModal } from '@libs/components'
import { ITaskRetouchingFormValues } from '.'
import { TaskModalCommonProps } from '../types'
import { useLocalization } from '@libs/localization'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import { useVisualMaterials } from 'modules/material/hooks'
import { useTryUploadResultImageIntoS3 } from 'modules/cases/hooks'

export const useTaskRetouchingModal = ({ task, onTaskComplete }: TaskModalCommonProps) => {
	const { t } = useLocalization()
	const [currentStep, setCurrentStep] = useState(0)
	const modalWrapperRef = useRef() as RefObject<HTMLDivElement>
	const hideModal = useHideModal()
	const invalidateTaskQueries = useTasksQueriesInvalidation()
	const [innerVisualMaterials, setInnerVisualMaterials] = useState<ISimplifiedVisualMaterial[]>([])
	const { mutateAsync: requestImageObjectsRemoval } = useMutation(TasksApi.requestImageObjectsRemoval, {
		onSuccess: result => {
			if (result.success) {
				invalidateTaskQueries()
				message.success(t('modals.retouchTask.removal.success'))
			} else {
				message.error(t('modals.retouchTask.removal.failed'))
			}
		},
	})
	const drawingComments = useRef([]).current

	const tryUploadResultImageIntoS3 = useTryUploadResultImageIntoS3({
		drawingComments,
		branchId: task.branchId,
		orderlineId: task.orderlineId,
	})

	const { mutateAsync: requestRenovation } = useMutation(TasksApi.requestRenovation, {
		onSuccess: result => {
			if (result.success) {
				invalidateTaskQueries()
				message.success(t('modals.retouchTask.removal.success'))
			} else {
				message.error(t('modals.retouchTask.removal.failed'))
			}
		},
	})

	const title = useMemo(() => {
		switch (task.type) {
			case TaskCategory.VirtualStagingIndoorRenovation:
				return t('modals.retouchTask.indoorRenovation.title')
			case TaskCategory.VirtualStagingOutdoorRenovation:
				return t('modals.retouchTask.outdoorRenovation.title')
			// TaskCategory.VirtualStagingRemoveObjects
			default:
				return t('modals.retouchTask.removal.title')
		}
	}, [task, t])

	const { visualMaterials, isLoading } = useVisualMaterials(task.caseId)

	const selectedVisualMaterial = innerVisualMaterials.find(({ isSelected }) => isSelected)

	const onFormSubmit = async (values: ITaskRetouchingFormValues) => {
		try {
			if (selectedVisualMaterial) {
				const imageUrl = await tryUploadResultImageIntoS3(selectedVisualMaterial, values.drawings)

				const functionToCall =
					task.type === TaskCategory.VirtualStagingIndoorRenovation ||
					task.type === TaskCategory.VirtualStagingOutdoorRenovation
						? requestRenovation
						: requestImageObjectsRemoval

				const response = await functionToCall({
					taskId: task.id,
					materialId: selectedVisualMaterial.id.toString(),
					materialUrl: imageUrl,
					comment: `${drawingComments.join('\n')}`,
				})

				if (!response.success) {
					return
				}

				if (onTaskComplete) {
					onTaskComplete(task)
					return
				}

				hideModal()
			}
		} catch {
			message.error(t('modals.retouchTask.removal.failed'))
		}
	}

	useEffect(() => {
		if (Array.isArray(visualMaterials) && visualMaterials.length > 0) {
			setInnerVisualMaterials(visualMaterials.filter(({ type }) => type === 'Picture'))
		}
	}, [visualMaterials])

	const selectMaterialItem: IMaterialList['onItemClick'] = useCallback(
		(materialId: ISimplifiedVisualMaterial['id']) => {
			setInnerVisualMaterials(
				innerVisualMaterials.map(material => ({
					...material,
					isSelected: material.id === materialId ? !material.isSelected : false,
				}))
			)
		},
		[innerVisualMaterials]
	)

	const steps = useMemo(() => {
		let selectionStepTitle = t('modals.retouchTask.removal.objectRemovalStep')

		switch (task.type) {
			case TaskCategory.VirtualStagingIndoorRenovation:
				selectionStepTitle = t('modals.retouchTask.indoorRenovation.selectionStep')
				break
			case TaskCategory.VirtualStagingOutdoorRenovation:
				selectionStepTitle = t('modals.retouchTask.outdoorRenovation.selectionStep')
				break
		}

		return [
			{
				title: t('modals.retouchTask.removal.itemSelectionStep'),
				content: (
					<Container>
						<Typography.Title level={4}>{t('modals.retouchTask.removal.imageSelectionInstructions')}</Typography.Title>
						<MaterialList
							disabledInactiveOverlay
							visualMaterials={innerVisualMaterials}
							axis="xy"
							onItemClick={selectMaterialItem}
							showAll
						/>
					</Container>
				),
			},
			{
				title: selectionStepTitle,
				content: (
					<ObjectsRemovalStep
						task={task}
						scrollableRef={
							modalWrapperRef.current?.parentElement?.parentElement?.parentElement?.parentElement as HTMLElement
						}
						source={selectedVisualMaterial?.source as string}
					/>
				),
			},
		]
	}, [innerVisualMaterials, selectMaterialItem, selectedVisualMaterial, t, task])

	const onBackClick = useCallback(() => setCurrentStep(prev => (prev === 0 ? 0 : prev - 1)), [])

	const onNextClick = useCallback(() => setCurrentStep(prev => prev + 1), [])

	return [
		{
			currentStep,
			isLoading,
			selectedVisualMaterial,
			steps,
			innerVisualMaterials,
			modalWrapperRef,
			title,
		},
		{
			onBackClick,
			onNextClick,
			onFormSubmit,
		},
	]
}
