import React, { FC, ReactNode, RefObject, useMemo, useRef, useEffect, useState } from 'react'
import {
	checkIfPointBelongsToArea,
	getSquareDimensionsOfArea,
	getPointInElement,
	getVectorAngle,
	getVectorLength,
	PointType,
} from '@libs/util'
import Styled from './Styled'
import { ARROW_POINTER_SIZE, ARROW_SIZE, POINT_SIZE } from './constants'
import { WarningAlt24 } from '@carbon/icons-react'
import { Button, Popover, PopoverProps } from 'antd'
import { Container } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { IFeedbackLasso } from '@libs/api'
import { useTheme } from 'styled-components'
import { LASSO_BORDER_WIDTH, LASSO_FILL_STYLE, SELECTED_LASSO_FILL_STYLE } from './constants'
import { getIn, useFormikContext } from 'formik'

const PopoverContent: FC<{
	onSave?: () => void
	onRemove?: () => void
	index?: number
	cancelText?: boolean
	selectedImageIndex?: number
}> = ({ onSave, onRemove, children, cancelText = false, index, selectedImageIndex }) => {
	const { t } = useLocalization()
	const { values } = useFormikContext()
	const commentValue =
		index !== undefined
			? getIn(values, `imageFeedback[${selectedImageIndex}][${index}].comment`) ||
			  getIn(values, `drawings[${index}].comment`)
			: undefined

	return (
		<Container display={'flex'} flexDirection={'column'} space={'md'} spaceDirection={'vertical'}>
			<Container display={'flex'} flexDirection={'column'} space={'xs'} spaceDirection={'vertical'}>
				{children}
			</Container>
			<Container display={'flex'} justifyContent={'flex-end'} space={'xs'} spaceDirection={'horizontal'}>
				<Button danger size={'small'} onClick={onRemove}>
					{cancelText ? t('common.actions.cancel') : t('common.actions.remove')}
				</Button>
				<Button type={'primary'} size={'small'} disabled={index !== undefined && !commentValue} onClick={onSave}>
					{t('common.actions.save')}
				</Button>
			</Container>
		</Container>
	)
}

const Arrow: FC<{
	p0: PointType
	p1: PointType
	popoverContent?: ReactNode
	popoverPlacement?: PopoverProps['placement']
	selected?: boolean
	onSelectedChange?: (selected: boolean) => void
	onRemove?: () => void
	index?: number
	selectedImageIndex?: number
}> = ({
	p0,
	p1,
	selected = false,
	popoverContent,
	popoverPlacement,
	onSelectedChange,
	onRemove,
	index,
	selectedImageIndex,
}) => {
	const length = useMemo(() => getVectorLength(p0, p1), [p0, p1])
	const rotation = useMemo(() => getVectorAngle(p0, p1), [p0, p1])

	return (
		<Styled.Arrow
			style={{
				top: `${p0[1]}px`,
				left: `${p0[0]}px`,
				width: `${length}px`,
			}}
		>
			<Popover
				trigger={['click']}
				visible={selected}
				placement={popoverPlacement}
				onVisibleChange={onSelectedChange}
				content={
					<PopoverContent
						index={index}
						selectedImageIndex={selectedImageIndex}
						onSave={() => onSelectedChange?.(false)}
						onRemove={() => {
							onRemove?.()
						}}
					>
						{popoverContent}
					</PopoverContent>
				}
			>
				<Styled.ArrowOrigin
					$selected={selected}
					style={{
						width: `${ARROW_SIZE}px`,
						height: `${ARROW_SIZE}px`,
					}}
					onClick={e => {
						e.preventDefault()
						e.stopPropagation()
						onSelectedChange?.(true)
					}}
					onMouseDown={e => {
						e.preventDefault()
						e.stopPropagation()
					}}
					onMouseUp={e => {
						e.preventDefault()
						e.stopPropagation()
					}}
				>
					<WarningAlt24 />
				</Styled.ArrowOrigin>
			</Popover>
			<Styled.ArrowBody
				style={{
					transform: `rotate(${rotation}deg)`,
				}}
			>
				<Styled.ArrowBodyTail
					style={{
						left: `${ARROW_SIZE * 0.5}px`,
					}}
				/>
				<Styled.ArrowBodyPointer
					style={{
						width: `${ARROW_POINTER_SIZE}px`,
						height: `${ARROW_POINTER_SIZE}px`,
					}}
				/>
			</Styled.ArrowBody>
		</Styled.Arrow>
	)
}

const Point: FC<{
	p0: PointType
	popoverContent?: ReactNode
	popoverPlacement?: PopoverProps['placement']
	selected?: boolean
	onSelectedChange?: (selected: boolean) => void
	onRemove?: () => void
	index?: number
	selectedImageIndex?: number
}> = ({
	p0,
	selected = false,
	popoverContent,
	popoverPlacement,
	onSelectedChange,
	onRemove,
	index,
	selectedImageIndex,
}) => {
	return (
		<Popover
			trigger={['click']}
			visible={selected}
			placement={popoverPlacement}
			onVisibleChange={onSelectedChange}
			content={
				<PopoverContent
					index={index}
					selectedImageIndex={selectedImageIndex}
					onSave={() => onSelectedChange?.(false)}
					onRemove={() => {
						onRemove?.()
					}}
				>
					{popoverContent}
				</PopoverContent>
			}
		>
			<Styled.Point
				$selected={selected}
				style={{
					width: `${POINT_SIZE}px`,
					height: `${POINT_SIZE}px`,
					top: `${p0[1]}px`,
					left: `${p0[0]}px`,
				}}
				onClick={e => {
					e.preventDefault()
					e.stopPropagation()
					onSelectedChange?.(true)
				}}
				onMouseDown={e => {
					e.preventDefault()
					e.stopPropagation()
				}}
				onMouseUp={e => {
					e.preventDefault()
					e.stopPropagation()
				}}
			>
				<WarningAlt24 />
			</Styled.Point>
		</Popover>
	)
}

const Lasso: FC<{
	points: IFeedbackLasso['points']
	position?: PointType
	isDrawing: boolean
	size?: [number, number]
	popoverContent?: ReactNode
	popoverPlacement?: PopoverProps['placement']
	selected?: boolean
	onSelectedChange?: (selected: boolean) => void
	onRemove?: () => void
	index?: number
	selectedImageIndex?: number
}> = ({
	points,
	position,
	isDrawing,
	size,
	selected = false,
	popoverContent,
	popoverPlacement,
	onSelectedChange,
	onRemove,
	index,
	selectedImageIndex,
}) => {
	const canvasRef = useRef() as RefObject<HTMLCanvasElement>
	const [isMouseOnArea, setIsMouseOnArea] = useState(false)
	const theme = useTheme()
	const [width, height] = size || getSquareDimensionsOfArea(points, LASSO_BORDER_WIDTH)

	useEffect(() => {
		const context = canvasRef.current?.getContext('2d')

		if (context) {
			context.fillStyle = selected ? SELECTED_LASSO_FILL_STYLE : LASSO_FILL_STYLE
			context.strokeStyle = theme.colors.primary['500']
			context.lineWidth = LASSO_BORDER_WIDTH
			context.beginPath()

			if (isDrawing && points.length > 2) {
				context.moveTo(points[points.length - 2][0], points[points.length - 2][1])
				context.lineTo(points[points.length - 1][0], points[points.length - 1][1])
				context.stroke()
			}

			if (!isDrawing) {
				context.clearRect(0, 0, width, height)
				context.moveTo(points[0][0], points[0][1])

				for (let i = 1; i < points.length; i++) {
					context.lineTo(points[i][0], points[i][1])
				}

				context.closePath()
				context.stroke()
				context.fill()
			}
		}
	}, [points, isDrawing, selected, theme.colors.primary, width, height])

	const handleAreaHover = (x: number, y: number) => {
		if (canvasRef.current && !isDrawing) {
			const pointerPos = getPointInElement(canvasRef.current, [x, y])

			if (checkIfPointBelongsToArea(pointerPos, points)) {
				setIsMouseOnArea(true)
			} else if (isMouseOnArea) {
				setIsMouseOnArea(false)
			}
		}
	}

	return (
		<Styled.Lasso $top={position?.[1]} $left={position?.[0]} $hovered={isMouseOnArea}>
			<Popover
				trigger={isMouseOnArea ? 'click' : 'contextMenu'} // workaround to exclude triggering on left mouse click at empty canvas area
				visible={selected}
				placement={popoverPlacement}
				onVisibleChange={onSelectedChange}
				content={
					<PopoverContent
						index={index}
						selectedImageIndex={selectedImageIndex}
						onSave={() => onSelectedChange?.(false)}
						onRemove={() => {
							onRemove?.()
						}}
						cancelText
					>
						{popoverContent}
					</PopoverContent>
				}
			>
				<canvas
					ref={canvasRef}
					width={width}
					height={height}
					onClick={e => {
						e.preventDefault()
						e.stopPropagation()
						if (isMouseOnArea) {
							onSelectedChange?.(true)
						}
					}}
					onMouseDown={e => {
						e.preventDefault()
						e.stopPropagation()
					}}
					onMouseUp={e => {
						e.preventDefault()
						e.stopPropagation()
					}}
					onMouseMove={e => {
						handleAreaHover(e.clientX, e.clientY)
					}}
				/>
			</Popover>
		</Styled.Lasso>
	)
}

export default {
	Arrow,
	Point,
	Lasso,
}
