import styled from 'styled-components'
import { Typography } from 'antd'
import { fontSize, margin } from '@libs/theme/build/mixins'

const SectionTitle = styled(Typography.Text)<{ $small?: boolean }>`
	display: block;
	font-size: ${({ $small }) => fontSize($small ? 'lg' : 'xxl')};
	font-weight: 600;
	margin-bottom: ${margin('sm')};
`

export default {
	SectionTitle,
}
