import { ChevronLeft24 } from '@carbon/icons-react'
import { useLocalization } from '@libs/localization'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import Styled from './Styled'

interface BackAnchorProps {
	onBack?: () => void
	title?: string
}

const BackAnchor: FC<BackAnchorProps> = ({ onBack, title }) => {
	const router = useRouter()
	const hasPreviousPage = typeof window !== 'undefined' && window.history.length > 1
	const { t } = useLocalization()
	return hasPreviousPage || onBack ? (
		<Styled.Wrapper
			display={'inline-flex'}
			alignItems={'center'}
			space={'xs'}
			spaceDirection={'horizontal'}
			onClick={e => {
				e.preventDefault()
				e.stopPropagation()
				if (typeof onBack === 'function') {
					onBack()
				} else {
					router.back()
				}
			}}
		>
			<ChevronLeft24 />
			<span>{title || t('navigation.back')}</span>
		</Styled.Wrapper>
	) : null
}

export default BackAnchor
