import styled from 'styled-components'
import { Card as _Card, Typography } from 'antd'
import { color, margin, screen } from '@libs/theme/mixins'

const Card = styled(_Card)`
	height: 100%;

	.ant-card-body {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
`

const ImageContainer = styled.div`
	min-height: 170px;
	max-height: 200px;
	margin-bottom: ${margin('sm')};
	background-color: ${color(['gray', '900'])};
	position: relative;
	padding-top: 45.24%;
	height: 0;

	> div {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		> svg {
			width: 80px;
			height: 80px;
			color: ${color(['gray', '000'])};
		}
	}
`

const Title = styled(Typography.Paragraph)`
	min-height: 48px;
	${screen.lte.md`
		text-align: center;
	`}
`

export default {
	Card,
	Title,
	ImageContainer,
}
