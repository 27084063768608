import { CloudUpload16, CloudUpload24 } from '@carbon/icons-react'
import { useScreenMatch } from '@libs/theme'
import { Dropdown } from 'antd'
import React, { FC } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTaskUploaderContext } from './context'
import Styled from './Styled'
import TaskUploaderEntry from './TaskUplaoderEntry'

const TRANSITION_DURATION = 500

const TaskUploader: FC = () => {
	const { state } = useTaskUploaderContext()
	const match = useScreenMatch()

	if (!state.uploaderVisible) return null

	return (
		<Styled.TransitionManager $duration={TRANSITION_DURATION} $active={!!state.entries.length}>
			{!!state.entries.length && (
				<CSSTransition timeout={TRANSITION_DURATION} classNames={'transition'}>
					<Dropdown
						getPopupContainer={trigger => trigger.parentElement!}
						trigger={['click']}
						placement={'topLeft'}
						arrow
						overlay={
							<Styled.PopoverMenu>
								{state.entries.map(({ task, material }) => (
									<TaskUploaderEntry key={task.id} task={task} materials={material} />
								))}
							</Styled.PopoverMenu>
						}
					>
						<Styled.Trigger shape={'circle'} size={match.map({ xxs: 'small', lg: 'middle' })}>
							{match.map({
								xxs: <CloudUpload16 />,
								lg: <CloudUpload24 />,
							})}
						</Styled.Trigger>
					</Dropdown>
				</CSSTransition>
			)}
		</Styled.TransitionManager>
	)
}

export default TaskUploader
