import React, { FC, useMemo, useState } from 'react'
import { Button } from 'antd'
import { IPackage } from '@libs/api'
import Styled from './Styled'
import { Container } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { useAuth } from 'modules/auth/hooks'
import { useScreenMatch } from '@libs/theme'
import { SpacingMixinParamType } from 'styled-components'

interface PackageCardProps {
	disabled?: boolean
	package: IPackage
	onSelect: (packageObj: IPackage) => void
}

const FALLBACK_THUMBNAIL = '/placeholders/logo-placeholder.jpeg'

const PackageCard: FC<PackageCardProps> = ({ package: packageObj, onSelect, disabled }) => {
	const { user } = useAuth()
	const { thumbnail, name, totalPrice, description } = packageObj
	const [shouldUseFallbackThumbnail, setShouldUseFallbackThumbnail] = useState(!thumbnail)
	const thumbnailSrc = shouldUseFallbackThumbnail ? FALLBACK_THUMBNAIL : thumbnail
	const { f, t } = useLocalization()
	const match = useScreenMatch()

	const resultCurrency = useMemo(
		() => packageObj.currency ?? packageObj.products?.find(({ currency }) => !!currency)?.currency,
		[packageObj]
	)

	return (
		<Styled.Card size="small">
			<Container flex={'1 0 100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
				<Container>
					<Styled.ImageContainer>
						<img src={thumbnailSrc} alt={name} onError={() => setShouldUseFallbackThumbnail(true)} />
					</Styled.ImageContainer>
					<Styled.Title
						ellipsis={{
							rows: 2,
						}}
						title={name}
						strong
					>
						{name}
					</Styled.Title>
					{description && (
						<Styled.Title
							ellipsis={{
								rows: 2,
							}}
							title={description}
						>
							{description}
						</Styled.Title>
					)}
				</Container>
				<Container
					display={'flex'}
					flexDirection={match.map({ xxs: 'column', lg: 'row' })}
					alignItems={match.map({ xxs: 'stretch', lg: 'center' })}
					justifyContent={match.map({ xxs: 'flex-start', lg: 'space-between' })}
					space={match.map<SpacingMixinParamType>({ xxs: 'sm', lg: 0 })}
					spaceDirection={match.map({ xxs: 'vertical', lg: 'horizontal' })}
				>
					<Styled.Price>
						{user?.visiblePrices && f(totalPrice || 0, { currency: resultCurrency || 'DKK', decimals: 2 })}
					</Styled.Price>
					<Button type="primary" shape="round" size="small" disabled={disabled} onClick={() => onSelect(packageObj)}>
						{t('common.actions.selectPackage')}
					</Button>
				</Container>
			</Container>
		</Styled.Card>
	)
}

export default PackageCard
