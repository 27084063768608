import { INews, NewsApi } from '@libs/api'
import { ArticleModal } from '@libs/components'
import React, { FC } from 'react'
import Queries from 'modules/common/queries'
import { useQuery } from 'react-query'
import dayjs from 'dayjs'
import { useLocalization } from '@libs/localization'

export interface NewsArticleModalProps {
	id: INews['id']
}

const NewsArticleModal: FC<NewsArticleModalProps> = ({ id }) => {
	const { locale } = useLocalization()
	const { data: item, isLoading } = useQuery([Queries.userById, id, locale], () =>
		NewsApi.getNewsById(id as string, locale)
	)

	return (
		<ArticleModal title={item?.title} subtitle={item && dayjs(item.date).format('DD-MM-YYYY')} loading={isLoading}>
			<div dangerouslySetInnerHTML={{ __html: item?.content ?? '' }} />
		</ArticleModal>
	)
}

export default NewsArticleModal
