import { AuthApi, IS_ESOFT_USER_KEY, UserAccess } from '@libs/api'
import Head from 'next/head'
import appRoutes from 'app.routes'
import { useRouter } from 'next/router'
import React, { FC, useEffect, useMemo, useState } from 'react'
import PageLayout from './PageLayout'
import { PageLayoutType } from './types'
import { useAuth, useMindworkingUrl } from 'modules/auth/hooks'
import { useLocalization } from '@libs/localization'
import { AUTH_LOGIN_KEY, AUTH_REFRESHING } from '@libs/api/build/http'

// kick-off token refresh on initial load
typeof localStorage !== 'undefined' && window.self !== window.top && localStorage.setItem(AUTH_REFRESHING, 'true')

interface PageProps {
	layout?: PageLayoutType
	guard?: boolean | 'admin'
	titleKey: string
	titleParams?: Record<string, any>
}

const Page: FC<PageProps> = ({ layout = 'app', guard, titleKey, titleParams, children }) => {
	const { t } = useLocalization()
	const router = useRouter()
	const userAuth = useAuth()
	const userIsAdmin =
		!userAuth.user?.isInternalUser ||
		!!userAuth.user?.branches.some(branch => branch.access === UserAccess.Admin) ||
		userAuth.userIsLoading
	const isGuarded = (guard && !AuthApi.isAuthenticated()) || (guard == 'admin' && !userIsAdmin)
	const [redirectChecked, setRedirectChecked] = useState(false)
	const { isMindworking } = useMindworkingUrl()

	const authUrl = useMemo(
		() => (typeof localStorage !== 'undefined' ? localStorage.getItem(AUTH_LOGIN_KEY) : undefined),
		[]
	)

	useEffect(() => {
		if (document.location.hash.includes('#/interview')) {
			const params = document.location.hash.split('?')[1] || ''
			window.location.href = `${window.location.origin}/interview?${params}`
			return
		}
		if (document.location.hash.includes('#/adduser')) {
			const params = document.location.hash.split('?')[1] || ''
			window.location.href = `${window.location.origin}/accept-invite?${params}`
			return
		}
		if (document.location.hash.includes('#/presentation')) {
			const params = document.location.hash.split('?')[1]
			if (params) {
				const query = new URLSearchParams(params)
				const { caseId, clientId, filter } = {
					caseId: query.get('r'),
					clientId: query.get('c'),
					filter: query.get('v'),
				}
				window.location.href = `${window.location.origin}/presentation?${caseId ? `caseId=${caseId}&` : ''}${
					clientId ? `clientId=${clientId}&` : ''
				}${filter ? `filter=${filter}` : ''}`
				return
			}
		}
		if (document.location.hash.includes('#/booking')) {
			const params = document.location.hash.split('?')[1] || ''
			window.location.href = `${window.location.origin}/booking?${params}`
			return
		}
		if (document.location.hash.includes('#/resetpassword')) {
			const params = document.location.hash.split('?')[1] || ''
			window.location.href = `${window.location.origin}/reset-password?${params}`
			return
		}
		setRedirectChecked(true)
	}, [])

	useEffect(() => {
		if (isGuarded) {
			if (guard == 'admin') {
				router.replace(appRoutes.dashboard())
				return
			}

			userAuth.persistRedirectUrl()

			if (isMindworking) return

			if (localStorage.getItem(IS_ESOFT_USER_KEY) === 'true') {
				router.replace(appRoutes.esoftLogin())
				return
			}

			router.replace(appRoutes.login())
		}
	}, [isGuarded])

	if (isGuarded || !redirectChecked) {
		return null
	}

	return (
		<>
			<Head>
				<title>{t(`navigation.pageTitles.${titleKey}`, titleParams)}</title>
			</Head>
			<PageLayout.Consumer
				contentKey={router.pathname}
				type={layout}
				systemMessage={
					userAuth.user?.branches?.[0]?.countryCode === 'DK' ? t('common.messages.notificationHeader') : ''
				}
			>
				{children}
			</PageLayout.Consumer>
			{/* Implicit flow, refresh id token */}
			{authUrl && !router.pathname.includes(appRoutes.authRedirect()) ? (
				<iframe src={authUrl} style={{ display: 'none' }} />
			) : undefined}
		</>
	)
}

export default Page
