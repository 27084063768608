import React, { createContext, Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { CasesApi, ICreateOrderParams, ICreateOrderResponse, IDatahubCase, IPackage, ISimplifiedOrder } from '@libs/api'
import { useRouter } from 'next/router'
import { useShowModal } from 'modules/common/GlobalModals'
import appRoutes from 'app.routes'
import { useAppEvents } from 'modules/common/app.events'
import { v4 as uuidv4 } from 'uuid'

export interface INewOrderState extends Partial<Omit<ICreateOrderParams, 'user'>> {
	status?: 'draft' | 'processing' | 'done'
	step?: number
	explicitlySelectedDepartmentId?: boolean
	orderResponse?: ICreateOrderResponse
	packageType?: IPackage['type']
	orderId: ISimplifiedOrder['id']
}

const STORAGE_KEY = 'new-order'

function getStorageState(): INewOrderState {
	try {
		return JSON.parse(localStorage.getItem(STORAGE_KEY) as string) || {}
	} catch (err) {
		return {} as INewOrderState
	}
}

export function setNewOrderStorageState(state: INewOrderState) {
	localStorage.setItem(STORAGE_KEY, JSON.stringify(state))
}

function clearStorageState() {
	localStorage.removeItem(STORAGE_KEY)
}

export async function getInitialNewOrderState({
	case: caseObj,
	departmentId,
	branchId,
	explicitlySelectedDepartmentId,
	packageType,
}: Pick<Required<INewOrderState>, 'case' | 'branchId' | 'departmentId' | 'explicitlySelectedDepartmentId'> & {
	packageType: IPackage['type']
}): Promise<INewOrderState> {
	const {
		address = {},
		property,
		contactPersons = [
			{
				type: '',
				name: '',
				phone: '',
				phoneAlt: '',
			},
		],
	} = caseObj.id ? (await CasesApi.getDatahubCase(caseObj.id)) || {} : ({} as IDatahubCase)

	return {
		status: 'draft',
		step: 0,
		case: caseObj,
		departmentId,
		branchId,
		address,
		property: property ? property : undefined,
		contactPersons,
		products: [],
		explicitlySelectedDepartmentId,
		packageType,
		orderId: uuidv4(),
	}
}

export function forgetNewOrderState() {
	clearStorageState()
}

const Context = createContext<[INewOrderState, Dispatch<SetStateAction<INewOrderState>>]>([
	{} as INewOrderState,
	() => {
		/* noop */
	},
])

export function useNewOrderContext() {
	return useContext(Context)
}

export function useNavigateToNewOrder() {
	const router = useRouter()
	const showModal = useShowModal()
	const appEvents = useAppEvents()

	return useCallback(() => {
		const { status, step } = getStorageState()
		if (location.pathname !== appRoutes.newOrder()) {
			if (status === 'draft' && typeof step === 'number') {
				showModal('order.new.confirm')
			} else {
				if (status === 'done') {
					forgetNewOrderState()
				}

				router.push(appRoutes.newOrder())
			}
		} else {
			if (typeof step === 'number' && status !== 'done') {
				showModal('order.new.leave.confirm')
			} else {
				forgetNewOrderState()
				appEvents.dispatch('orders.new.context.clear')
				router.push(appRoutes.newOrder())
			}
		}
	}, [router, showModal, appEvents])
}

export const NewOrderContextProvider: FC = ({ children }) => {
	const [state, setState] = useState(getStorageState)
	const appEvents = useAppEvents()

	// Save state to local storage
	useEffect(() => {
		setNewOrderStorageState(state)
	}, [state])

	// Clear order context when app event is triggered
	useEffect(() =>
		appEvents.subscribe('orders.new.context.clear', () => {
			setState({} as INewOrderState)
		})
	)

	// Scroll content to top when step changes
	const { step } = state

	useEffect(() => {
		document.scrollingElement?.scrollTo({ top: 0 })
	}, [step])

	return <Context.Provider value={[state, setState]}>{children}</Context.Provider>
}
