import React, { FC, useMemo } from 'react'
import { useHideModal } from '@libs/components/build/Modals/context'
import { Modal, Button, message } from 'antd'
import { Form } from 'formik-antd'
import { Formik } from 'formik'
import {
	CasesApi,
	ISimplifiedVisualMaterial,
	IAddress,
	IDatahubCase,
	MaterialApi,
	isEsoftUser,
	IBranch,
} from '@libs/api'
import CopyMaterialItem from '../CopyMaterialItem'
import { useMutation } from 'react-query'
import { useLocalization } from '@libs/localization'
import { Autolocalize, Container, UserInteraction } from '@libs/components'
import { createValidationSchema } from '@libs/util'
import { Add24, Close24 } from '@carbon/icons-react'
import { ANT_BUTTON_AUTO_WIDTH_CLASS_NAME } from '@libs/theme'
import { useAuth } from 'modules/auth/hooks'
import { useCaseById } from 'modules/cases/hooks'

export interface CopyMaterialModalProps {
	title: string
	materials: ISimplifiedVisualMaterial[]
	caseId: IDatahubCase['id']
	branchId: IBranch['id']
	copyAll: boolean
}

export interface ICopyMaterialItem {
	caseId?: string
	departmentId?: string
	reference?: string
	address?: IAddress
}

export interface ICopyMaterialFormValues {
	currentCaseAddress?: IAddress
	copies: ICopyMaterialItem[]
}

const formSchema = createValidationSchema(yup =>
	yup.object().shape({
		copies: yup
			.array()
			.of(
				yup
					.object()
					.shape({
						departmentId: yup.string().required(),
						reference: yup.string().required(),
					})
					.required()
			)
			.required()
			.min(1),
	})
)

const CopyMaterialModal: FC<CopyMaterialModalProps> = ({ title, caseId, branchId, materials, copyAll }) => {
	const hideModal = useHideModal()
	const { mutateAsync: copyMaterials, isLoading: isCopyingLoading } = useMutation(MaterialApi.copyMaterials)
	const { mutateAsync: upsertCase, isLoading: isCreateCaseLoading } = useMutation(CasesApi.upsertCase)
	const { t } = useLocalization()
	const { user } = useAuth()
	const { data: foundCase } = useCaseById(caseId)
	const isEsoftUserValue = useMemo(() => isEsoftUser(), [])

	const initialFormValues: ICopyMaterialFormValues = useMemo(
		() => ({
			copies: isEsoftUserValue
				? [{}]
				: [
						{
							departmentId: branchId,
						},
				  ],
		}),
		[isEsoftUserValue, branchId]
	)

	const onSubmit = async (values: ICopyMaterialFormValues) => {
		const batchCopyReponses = await Promise.all(
			values.copies.map(async value => {
				const foundBranchId = user?.branches.find(({ id }) => id === value.departmentId)?.id
				if (foundBranchId && value.departmentId && value.reference) {
					if (value.address) {
						try {
							await upsertCase({
								case: { reference: value.reference },
								address: value.address,
								branchId: foundBranchId,
							})
						} catch (err) {
							// error displayed on service layer
							return false
						}
					}

					const response = await copyMaterials({
						caseId,
						copyAll,
						assetIds: materials.map(({ id }) => id),
						reference: value.reference,
						branchId: foundBranchId,
					})

					if (!response.success) {
						return false
					}

					return true
				}

				return false
			})
		)

		if (batchCopyReponses.every(response => response)) {
			message.success(t('modals.copyMaterial.success'))
			hideModal()
		} else {
			message.error(t('modals.copyMaterial.error'))
		}
	}

	return (
		<Formik<ICopyMaterialFormValues>
			initialValues={initialFormValues}
			validationSchema={formSchema}
			onSubmit={onSubmit}
		>
			{({ values, setFieldValue, isValid, dirty, isSubmitting }) => (
				<Modal
					visible
					closeIcon={<Close24 />}
					onCancel={hideModal}
					title={title}
					footer={[
						<Button
							form="copy-material-form-id"
							disabled={!isValid || !dirty}
							loading={isCopyingLoading || isCreateCaseLoading}
							key="submit"
							htmlType="submit"
							type="primary"
						>
							{t('modals.copyMaterial.submit')}
						</Button>,
					]}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form id="copy-material-form-id" layout="vertical">
							<Autolocalize />
							<Container space={'lg'} spaceDirection={'vertical'}>
								<Container>
									{values.copies.map((_, index) => (
										<CopyMaterialItem
											key={index}
											index={index}
											defaultCaseAddress={foundCase?.addressObj as IAddress}
										/>
									))}
								</Container>
								<Container display={'flex'} justifyContent={'flex-start'}>
									<Button
										className={ANT_BUTTON_AUTO_WIDTH_CLASS_NAME}
										icon={<Add24 />}
										type={'text'}
										onClick={() =>
											setFieldValue('copies', [
												...values.copies,
												{
													department: '',
													...(!isEsoftUserValue && { departmentId: branchId }),
												},
											])
										}
									>
										{t('modals.copyMaterial.copyIntoMoreAction')}
									</Button>
								</Container>
							</Container>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default CopyMaterialModal
