import React, { Fragment, useMemo } from 'react'
import { Card, Typography, Radio, Space } from 'antd'
import { Container, Grid, Tooltip } from '@libs/components'
import { Help24 } from '@carbon/icons-react'
import Styled from './Styled'
import { useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import { PackagesApi, IProduct } from '@libs/api'
import { useLocalization } from '@libs/localization'
import { useNewOrderContext } from '../context'
import OrderSummary from '../OrderSummary'
import { useScreenMatch } from '@libs/theme'
import { useAuth } from 'modules/auth/hooks'
import { useNewOrderNavigation } from '../hooks'
import ProductList from 'modules/orders/ProductList'

const NewOrderStepTwo = () => {
	const [{ package: packageObj, products: selectedProducts = [], departmentId }, setOrderState] = useNewOrderContext()
	const { f, t } = useLocalization()
	const { user } = useAuth()
	const match = useScreenMatch()
	const { onContinue } = useNewOrderNavigation()

	const foundBranchId = useMemo(
		() => (user?.isInternalUser ? user?.branches.find(({ id }) => id === departmentId)?.id : departmentId),
		[user, departmentId]
	)

	const { data: additionalProducts = [], isFetched: areAdditionalroductsFetched } = useQuery(
		[QUERIES.additionalProducts, foundBranchId],
		() => PackagesApi.getAdditionalProducts(foundBranchId!),
		{ enabled: !!foundBranchId && !!packageObj }
	)

	const { data: emptyPackageProducts = [], isFetched: areEmptyPackageProductsFetched } = useQuery(
		[QUERIES.individualProducts, foundBranchId],
		() => PackagesApi.getEmptyPackageProducts(foundBranchId!),
		{ enabled: !!foundBranchId && !packageObj }
	)

	const areExtraProductsFetched = useMemo(
		() => (packageObj ? areAdditionalroductsFetched : areEmptyPackageProductsFetched),
		[areAdditionalroductsFetched, areEmptyPackageProductsFetched, packageObj]
	)
	const allExtraProducts = useMemo(
		() => (packageObj ? additionalProducts : emptyPackageProducts),
		[additionalProducts, emptyPackageProducts, packageObj]
	)

	const isValid = useMemo(() => !packageObj?.choices?.find(({ selectedProductId }) => !selectedProductId), [packageObj])

	const handleQuantityChange = (newQuantity: number, product: IProduct) => {
		const foundIndex = selectedProducts ? selectedProducts.findIndex(({ id }) => product.id === id) : -1

		if (!newQuantity) {
			setOrderState(prevState => ({
				...prevState,
				products: prevState.products?.filter((_, currentIndex) => currentIndex !== foundIndex),
			}))
		} else if (foundIndex === -1) {
			setOrderState(prevState => ({
				...prevState,
				products: (prevState.products || []).concat([
					{
						...product,
						quantity: newQuantity,
					},
				]),
			}))
		} else {
			setOrderState(prevState => ({
				...prevState,
				products: prevState.products?.map(item => ({
					...item,
					quantity: item.id === product.id ? newQuantity : item.quantity,
				})),
			}))
		}
	}

	return (
		<Grid.Row>
			<Grid.Col span={24} xxl={16}>
				<Grid.Row>
					{packageObj && (
						<Fragment>
							<Grid.Col span={24}>
								<Card title={t('orders.pages.new.stepTwo.packageCardTitle')} size="small">
									<Typography.Title level={3}>{packageObj?.name}</Typography.Title>
									<Styled.PackageContentList gutter={['xs', 0]}>
										{packageObj?.packages?.map(({ name }, index) => (
											<Fragment key={index}>
												<Grid.Col flex="60px">
													<Typography.Text>1 x</Typography.Text>
												</Grid.Col>
												<Grid.Col flex="calc(100% - 60px)">
													<Typography.Text>{name}</Typography.Text>
												</Grid.Col>
												<Grid.Col flex="100%">
													<Styled.Divider />
												</Grid.Col>
											</Fragment>
										))}
										{packageObj?.products.map(({ quantity, name }, index, source) => (
											<Fragment key={index}>
												<Grid.Col flex="60px">
													<Typography.Text>{quantity} x</Typography.Text>
												</Grid.Col>
												<Grid.Col flex="calc(100% - 60px)">
													<Typography.Text>{name}</Typography.Text>
												</Grid.Col>
												{source.length - 1 !== index && (
													<Grid.Col flex="100%">
														<Styled.Divider />
													</Grid.Col>
												)}
											</Fragment>
										))}
									</Styled.PackageContentList>
								</Card>
							</Grid.Col>
							{areExtraProductsFetched &&
								packageObj?.choices?.map(
									({ products, packages = [], selectedProductId, selectedPackageId }, choiceIndex) => (
										<Grid.Col key={choiceIndex} span={24}>
											<Typography.Title level={5}>
												{t('common.terms.choice')} {choiceIndex + 1}
											</Typography.Title>
											<Grid.Row>
												<Styled.RadioContainer
													value={selectedPackageId || selectedProductId}
													onChange={e => {
														const { value } = e.target
														const foundPackage = packages.find(({ id }) => id === value)
														setOrderState(prevState => ({
															...prevState,
															package: prevState.package && {
																...prevState.package,
																choices: prevState.package.choices?.map((choice, index) => ({
																	...choice,
																	selectedProductId:
																		choiceIndex === index
																			? foundPackage
																				? foundPackage.products.map(({ id }) => id)
																				: value
																			: choice.selectedProductId,
																	selectedPackageId:
																		choiceIndex === index ? foundPackage?.id : choice.selectedPackageId,
																})),
															},
														}))
													}}
												>
													{packages.map(({ name, basePrice, currency, thumbnail, id }) => (
														<Grid.Col key={id} xs={24} lg={12}>
															<Card size="small">
																<Container
																	display={'flex'}
																	alignItems={'center'}
																	justifyContent={'space-between'}
																	fill
																	spaceDirection={'vertical'}
																>
																	<Radio value={id}>1 x {name}</Radio>
																	<Space size={'large'}>
																		<Tooltip
																			placement={'topLeft'}
																			imgUrl={thumbnail}
																			text={name}
																			maxWidth={320}
																			lightTheme
																		>
																			<Help24 />
																		</Tooltip>
																		<Styled.Prices>
																			{user?.visiblePrices &&
																				basePrice !== undefined &&
																				f(basePrice, { currency: currency || 'DKK', decimals: 2 })}
																		</Styled.Prices>
																	</Space>
																</Container>
															</Card>
														</Grid.Col>
													))}
													{products.map(({ name, quantity, price, currency, thumbnail, id }) => (
														<Grid.Col key={id} xs={24} lg={12}>
															<Card size="small">
																<Container
																	display={'flex'}
																	alignItems={'center'}
																	justifyContent={'space-between'}
																	fill
																	spaceDirection={'vertical'}
																>
																	<Radio value={id}>
																		{quantity} x {name}
																	</Radio>
																	<Space size={'large'}>
																		<Tooltip
																			placement={'topLeft'}
																			imgUrl={thumbnail}
																			text={name}
																			maxWidth={320}
																			lightTheme
																		>
																			<Help24 />
																		</Tooltip>
																		<Styled.Prices>
																			{user?.visiblePrices &&
																				price !== undefined &&
																				f(price, { currency: currency || 'DKK', decimals: 2 })}
																		</Styled.Prices>
																	</Space>
																</Container>
															</Card>
														</Grid.Col>
													))}
													)
												</Styled.RadioContainer>
											</Grid.Row>
										</Grid.Col>
									)
								)}
						</Fragment>
					)}
					<ProductList
						products={allExtraProducts}
						areProductsFetched={areExtraProductsFetched}
						selectedProducts={selectedProducts}
						onQuantityChange={handleQuantityChange}
						smallTitle={!!packageObj}
						shouldAskForCopyright
					/>
				</Grid.Row>
			</Grid.Col>
			<Grid.Col span={24} xxl={8}>
				<OrderSummary disabled={!isValid} sticky={match.eq('xxl')} onContinue={onContinue} />
			</Grid.Col>
		</Grid.Row>
	)
}

export default NewOrderStepTwo
