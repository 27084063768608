import React, { useMemo } from 'react'
import { Help24, Home24, ListBoxes24, ListChecked24, Power24, User24 } from '@carbon/icons-react'
import { useLocalization } from '@libs/localization'
import { INavMenuItem, IProfileMenuItem } from './types'
import appRoutes from 'app.routes'
import { useAuth } from 'modules/auth/hooks'
import { useShowModal } from '../GlobalModals'
import { AuthApi } from '@libs/api'
import { useRouter } from 'next/router'

export function useNavItems(): INavMenuItem[] {
	const { t } = useLocalization()
	const router = useRouter()

	return useMemo(
		() => [
			{ icon: <Home24 />, label: t('navigation.mainMenu.dashboard'), href: appRoutes.dashboard() },
			{ icon: <ListBoxes24 />, label: t('navigation.mainMenu.cases'), href: appRoutes.cases.list() },
			{ icon: <ListChecked24 />, label: t('navigation.mainMenu.tasks'), href: appRoutes.tasks.list() },
			{
				icon: '/shine-logo.png',
				onClick: async () => {
					try {
						const { TokenValue } = await AuthApi.getShineToken()
						window.open(appRoutes.externalLinks.shineLogin({ token: TokenValue }))
					} catch (err) {
						// redirect to root anyway for better UX
						window.open(appRoutes.externalLinks.shine())
					}
				},
			},
		],
		[t, router]
	)
}

export function useProfileMenuItems(): IProfileMenuItem[] {
	const showModal = useShowModal()
	const { user, softLogout } = useAuth()
	const { t } = useLocalization()

	return useMemo(
		() => [
			{
				label: t('navigation.mainMenu.myProfile'),
				icon: <User24 />,
				onClick: () => {
					showModal('users.edit', {
						userId: user ? user.id : '',
					})
				},
			},
			{
				label: t('navigation.mainMenu.customerSupport'),
				link: appRoutes.support(),
				icon: <Help24 />,
			},
			{
				label: t('navigation.mainMenu.logout'),
				onClick: softLogout,
				icon: <Power24 />,
			},
		],
		[user, softLogout, showModal, t]
	)
}
