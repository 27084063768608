import { IArticle, SupportApi } from '@libs/api'
import { ArticleModal } from '@libs/components'
import React, { FC } from 'react'
import Queries from 'modules/common/queries'
import { useQuery } from 'react-query'
import { useLocalization } from '@libs/localization'

export interface SupportArticleModalProps {
	article: IArticle
}

const SupportArticleModal: FC<SupportArticleModalProps> = ({ article }) => {
	const { locale } = useLocalization()
	const { data: content = '', isLoading } = useQuery([Queries.supportArticleContentById, article.id, locale], () =>
		SupportApi.getArticleContentById(article.id, locale)
	)

	const isFAQ = content.includes('Link til FAQ')
	const truncatedSrc = content.substring(content.indexOf('href') + 6)
	const link = truncatedSrc.substring(0, truncatedSrc.indexOf('"'))

	return (
		<ArticleModal width="90vw" title={article.title} fullHeight={isFAQ} loading={isLoading}>
			{isFAQ && link ? (
				<iframe width="100%" height="100%" src={link} />
			) : (
				<div dangerouslySetInnerHTML={{ __html: content }} />
			)}
		</ArticleModal>
	)
}

export default SupportArticleModal
