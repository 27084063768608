import React, { FC, useEffect, useMemo } from 'react'
import { ITaskCopywriteTextWithReview, FeedbackType } from '@libs/api'
import { Autolocalize, UserInteraction, Tooltip, useHideModal, FormikTextArea } from '@libs/components'
import { Close24, Document24 } from '@carbon/icons-react'
import { Button, Modal, Divider, Typography, Skeleton, Input } from 'antd'
import { Form, FormItem, Radio } from 'formik-antd'
import { Formik, FormikValues, Field } from 'formik'
import { Grid, Container } from '@libs/components'
import Styled from './Styled'
import QUERIES from 'modules/common/queries'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { createValidationSchema } from '@libs/util'
import { useQueryClient } from 'react-query'
import { useTaskCopywriteTextModal } from 'modules/tasks/TaskCopywriteTextModal/hooks'
import { FieldProps } from 'formik/dist/Field'
import TaskModalTitle from 'modules/common/TaskModalTitle'

export interface TaskCopywriteTextModalProps extends TaskModalCommonProps {}

const initialValues: Partial<ITaskCopywriteTextWithReview> = {
	feedbackType: FeedbackType.Feedback,
}

const TaskCopywriteTextModal: FC<TaskCopywriteTextModalProps> = ({ task, onTaskComplete, onTaskCancel }) => {
	const hideModal = useHideModal()
	const { t, f } = useLocalization()
	const queryClient = useQueryClient()
	const { Row, Col } = Grid
	const [
		{ isRejectingText, isLoading, isTooLateToReject, copyWriteTexts = [], isReviewLoading, isTextLoading },
		{ setIsRejectingText, onApprove, onReviewAndReject },
	] = useTaskCopywriteTextModal({ task, onTaskComplete })

	useEffect(() => {
		return () => {
			queryClient.removeQueries([QUERIES.copyWriteTexts, task.orderlineId])
		}
	}, [queryClient, task.orderlineId])

	const formSchema = createValidationSchema(yup =>
		yup.object().shape({
			comment: isRejectingText ? yup.string().required() : yup.string(),
			feedbackType: isRejectingText ? yup.string().required() : yup.string(),
		})
	)

	const Footer = ({ dirty, isValid, values }: FormikValues) =>
		isRejectingText ? (
			<Styled.FooterWrapper>
				<Container padding={{ top: 'lg' }}>
					<FormItem
						name={'feedbackType'}
						label={t('modals.copywriteText.giveFeedback')}
						className={'typeSelector'}
						required
					>
						<Radio.Group name={'feedbackType'}>
							<Radio name={'feedbackType'} value={FeedbackType.Feedback}>
								{t('common.terms.generalFeedback')}
							</Radio>
							<Radio name={'feedbackType'} value={FeedbackType.Change}>
								{t('common.terms.requestChanges')}
							</Radio>
							<Radio name={'feedbackType'} value={FeedbackType.Complaint}>
								{t('common.terms.complaint')}
							</Radio>
						</Radio.Group>
					</FormItem>
					<FormItem name={'comment'}>
						<FormikTextArea name={'comment'} autoSize={{ minRows: 2, maxRows: 4 }} />
					</FormItem>
				</Container>
				<Container display="flex" justifyContent="center" space="sm" padding={{ top: 'lg', bottom: 'lg' }}>
					<Button
						name="cancelReject"
						form="task-copywrite-text-form"
						disabled={isReviewLoading}
						onClick={() => setIsRejectingText?.(false)}
					>
						{t('common.actions.cancel')}
					</Button>
					<Button
						name="reviewAndReject"
						form="task-copywrite-text-form"
						type="primary"
						loading={isReviewLoading}
						disabled={!dirty || !isValid}
						onClick={() => onReviewAndReject?.(values)}
					>
						{t('modals.copywriteText.leaveFeedbackAndReject')}
					</Button>
				</Container>
			</Styled.FooterWrapper>
		) : (
			<Styled.FooterWrapper>
				<Container display="flex" justifyContent="center" space="sm" padding={{ top: 'lg', bottom: 'lg' }}>
					<Tooltip title={t('modals.copywriteText.tooLateToApproveTooltip')} disabled={!isTooLateToReject}>
						<Button
							danger
							name="reject"
							onClick={() => setIsRejectingText?.(true)}
							form="task-copywrite-text-form"
							disabled={isLoading || isTooLateToReject}
						>
							{t('modals.copywriteText.rejectText')}
						</Button>
					</Tooltip>
					<Button name="approve" form="task-copywrite-text-form" htmlType="submit" type="primary" loading={isLoading}>
						{t('modals.copywriteText.approveText')}
					</Button>
				</Container>
			</Styled.FooterWrapper>
		)

	const textSections = useMemo(
		() =>
			copyWriteTexts.map((section, index, arr) => {
				const { content: originalText, sectionName: originalDescription } = section
				const timeRegExp = /(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])\/\d{4}(.*)\(UTC\)/g
				const description =
					originalDescription && ~originalDescription.search(timeRegExp)
						? originalDescription.replace(
								timeRegExp,
								f(new Date(originalDescription), { date: 'short', time: 'short' })
						  )
						: originalDescription
				// const title = copywriteTextType
				// 	.split('_')
				// 	.map(word => word.charAt(0).toUpperCase() + word.slice(1))
				// 	.join(' ')

				initialValues[originalDescription] = originalText

				return (
					<div key={originalDescription}>
						<Styled.SectionHeader>
							<Typography.Title level={5}>
								<Document24 /> {originalDescription}
							</Typography.Title>
							<Typography.Text type="secondary">{description}</Typography.Text>
						</Styled.SectionHeader>
						<Field name={originalDescription}>
							{({ field }: FieldProps) => (
								<Input.TextArea
									placeholder={originalDescription}
									autoSize
									size="small"
									disabled={isRejectingText}
									{...field}
								/>
							)}
						</Field>
						{index < arr.length - 1 && <Divider />}
					</div>
				)
			}),
		[copyWriteTexts, isRejectingText, f]
	)

	return (
		<Modal
			closeIcon={<Close24 />}
			visible
			title={<TaskModalTitle title={t('modals.copywriteText.approveText')} caseId={task.caseId} />}
			onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
			footer={null}
		>
			{isTextLoading ? (
				<Skeleton active />
			) : copyWriteTexts && copyWriteTexts.length > 0 ? (
				<Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={onApprove!}>
					{({ values, isSubmitting, dirty, isValid }) => (
						<UserInteraction enabled={!isSubmitting}>
							<Form id="task-copywrite-text-form" layout="vertical">
								<Autolocalize />
								<Row>
									<Col span={24}>
										{textSections}
										<Footer values={values} dirty={dirty} isValid={isValid} />
									</Col>
								</Row>
							</Form>
						</UserInteraction>
					)}
				</Formik>
			) : (
				<Container
					display="flex"
					flexDirection="column"
					alignItems="center"
					padding={{ bottom: 'md' }}
					spaceDirection="vertical"
					space="sm"
				>
					<Typography.Text>{t('modals.copywriteText.noTextToApprove')}</Typography.Text>
					<Button type="primary" onClick={onTaskCancel ? () => onTaskCancel(task) : hideModal}>
						{t('common.actions.close')}
					</Button>
				</Container>
			)}
		</Modal>
	)
}

export default TaskCopywriteTextModal
