import React, { FC, useMemo } from 'react'
import { Tabs, Card, Typography } from 'antd'
import EditableDocumentText from '../EditableDocumentText'
import { ICaseTextGroup, IDatahubCase } from '@libs/api'
import { useLocalization } from '@libs/localization'
import { Container } from '@libs/components'

interface TextDocumentsProps {
	caseId: IDatahubCase['id']
	translatedTexts: ICaseTextGroup[]
}

const TextDocuments: FC<TextDocumentsProps> = ({ caseId, translatedTexts }) => {
	const { t } = useLocalization()
	const defaultActiveKey = useMemo(() => translatedTexts?.[0]?.groupName, [translatedTexts])

	return (
		<Card title={t('common.terms.textDocuments')}>
			{translatedTexts.length ? (
				<Tabs defaultActiveKey={defaultActiveKey}>
					{translatedTexts.map(({ groupName, texts }) => (
						<Tabs.TabPane tab={groupName} key={groupName}>
							<Container space={'md'} spaceDirection={'vertical'}>
								{texts.map(item => (
									<EditableDocumentText key={item.name} caseId={caseId} {...item} />
								))}
							</Container>
						</Tabs.TabPane>
					))}
				</Tabs>
			) : (
				<Typography.Text>{t('cases.pages.details.emptyTexts')}</Typography.Text>
			)}
		</Card>
	)
}

export default TextDocuments
