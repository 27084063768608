import styled from 'styled-components'
import { Divider as _Divider, Typography } from 'antd'
import { padding, color, fontSize, screen } from '@libs/theme/mixins'
import { Grid } from '@libs/components'

const Col = styled(Grid.Col)`
	align-self: flex-start;

	${screen.gte.lg`
		position: sticky;
		top: 0;
		left: 0;
	`}
`

const Wrapper = styled.div`
	height: auto;
	img {
		width: 100%;
	}
`

const Divider = styled(_Divider)`
	height: 100%;
`

const ImageWrapper = styled.div`
	position: relative;
`

const LabelContainer = styled.div`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	background: rgba(0, 0, 0, 0.6);
	padding: ${padding('xxs')};
	color: ${color(['gray', '000'])};
	writing-mode: vertical-rl;
	font-size: ${fontSize('xxs')};
`

const RightAlignedText = styled(Typography.Text)`
	text-align: right;
`

export default { Col, Wrapper, ImageWrapper, Divider, LabelContainer, RightAlignedText }
