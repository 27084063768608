import styled, { css } from 'styled-components'
import React from 'react'
import { color, easing, fontSize, height, margin, padding, typography, screen, borderRadius } from '@libs/theme/mixins'
import { Container } from '@libs/components'
import { Divider, Typography, Menu } from 'antd'

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: stretch;
	height: ${height('xxl')};

	${screen.lte.xs`
		height: ${height('xl')};
	`}
`

const NavMenu = styled.div`
	display: flex;
	flex-direction: row;
	align-content: center;
	margin-right: clamp(${margin('xs')}, 2vw, ${margin('xl')});
`

const Logo = styled.img`
	/* width: 120px; */
	height: 43px;
	margin-right: ${margin('xxl')};

	${screen.lte.xl`
		margin-right: clamp(${margin('xs')}, 2vw, ${margin('xl')});
	`}

	${screen.lte.xs`
		width: 96px;
	`}
`

const NavMenuItem = styled.a<{ $active?: boolean; $hasBadge?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	${typography('md')}
	text-transform: uppercase;
	font-weight: 600;
	white-space: nowrap;
	color: ${({ $active }) => color(['gray', $active ? '900' : '700'])};
	margin-right: clamp(${margin('xxs')}, 2vw, ${margin('lg')});

	${({ $hasBadge }) =>
		$hasBadge &&
		css`
			.ant-badge {
				color: inherit;
			}

			padding-right: ${padding('lg')};
		`}

	&:hover {
		color: ${color(['gray', '900'])};
	}

	&:last-child {
		margin-right: 0;
	}

	> svg {
		margin-right: ${margin('xs')};

		${screen.lte.xl`
			margin-right: ${margin('xxs')};
		`}
	}

	img {
		height: 19px;
		margin-bottom: 3px; // temporary position fix for shine link
	}
`

const Widget = styled.div<{ $primary?: boolean }>`
	width: clamp(${height('lg')}, 6.5vw, ${height('xxl')});
	height: ${height('xxl')};
	cursor: pointer;
	color: ${color('black')};
	background-color: ${color('white')};
	border-left: 1px solid ${color(['primary', '200'])};
	border-bottom: 1px solid ${color(['primary', '200'])};

	${screen.lte.xs`
		height: ${height('xl')};
	`}

	> a {
		display: block;
		flex: 1;
		height: 100%;
	}

	&,
	& > a {
		color: ${color('black')};
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&:hover,
	&:hover * {
		color: ${({ $primary }) => color($primary ? 'white' : 'black')};
		text-decoration: none;
	}

	&:hover {
		background-color: ${({ $primary }) => color($primary ? ['primary', '500'] : ['gray', '200'])};
	}

	&:last-child {
		border-right: 1px solid ${color(['primary', '200'])};
	}
`

const LanguageIcon = styled.img<{ $small?: boolean }>`
	width: ${({ $small }) => ($small ? 24 : 36)}px;
	height: ${({ $small }) => ($small ? 16 : 24)}px;
	object-fit: cover;
`

const LanguageItem = styled.div`
	padding: ${padding('xxs')};

	img {
		margin-right: ${margin('xxs')};
	}
`

const NotificationTrigger = styled.div<{ $hasNotifications?: boolean }>`
	display: flex;
	flex: 0;
	position: relative;
	&:after {
		position: absolute;
		top: 2px;
		right: 2px;
		display: ${({ $hasNotifications }) => ($hasNotifications ? 'block' : 'none')};
		content: ' ';
		border-radius: 50%;
		width: 8px;
		height: 8px;
		background-color: ${color(['error', '700'])};
	}
`

const ProfileTrigger = styled.div`
	display: flex;
	flex: 1;
	height: 100%;
	justify-content: center;
	align-items: center;

	> div {
		display: flex;
		flex: 0;
		border-radius: 50%;
		border: 2px solid ${color('primary')};
		transition: border-color 200ms ${easing('inQuint')};

		.ant-avatar {
			background: ${color(['primary', '800'])};
			color: ${color('primary')};
		}
	}
`

const ProfileItem = styled.div`
	padding: ${padding('xxs')};
	min-width: 200px;
`

const NotificationsContainer = styled(Container)`
	cursor: pointer;
	max-width: 500px;
	margin: ${margin('xxs')} ${margin('sm')};
`

const NotificationsMenu = styled(Menu)`
	padding-top: ${padding('sm')};
	padding-bottom: ${padding('sm')};
`

const MenuDivider = styled(({ className }) => <Divider className={className} />)`
	margin: 0;
`

const BuildIdText = styled(Typography.Text)`
	font-size: ${fontSize('sm')};
`

const BURGER_BAR_WIDTH = 32
const BURGER_BAR_HEIGHT = 4
const Burger = styled.div`
	position: relative;

	&,
	&:before,
	&:after {
		width: ${BURGER_BAR_WIDTH}px;
		height: ${BURGER_BAR_HEIGHT}px;
		background-color: ${color(['gray', '700'])};
		border-radius: ${borderRadius('sm')};
		transition: background-color 250ms;
	}

	&:before,
	&:after {
		position: absolute;
		display: block;
		content: ' ';
	}

	&:before {
		bottom: ${2 * BURGER_BAR_HEIGHT}px;
	}

	&:after {
		top: ${2 * BURGER_BAR_HEIGHT}px;
	}

	&:focus,
	&:active {
		&,
		&:before,
		&:after {
			background-color: ${color(['gray', '900'])};
		}
	}
`

const DrawerNav = styled.div`
	display: flex;
	flex-direction: column;

	${NavMenuItem} {
		padding: ${padding('xxs', 0)};
	}
`

const DrawerMenu = styled.div`
	display: flex;
	flex-direction: column;
`

const DrawerMenuItem = styled.a`
	display: flex;
	align-items: center;
	padding: ${padding('xs', 'lg')};
	color: ${color('black')};
	font-weight: 500;
	transition: backround-color 250ms;

	> :first-child {
		margin-right: ${margin('sm')};
	}

	&:hover,
	&:active {
		background-color: ${color(['gray', '100'])};
		color: ${color('black')};
	}
`

const DrawerBackButton = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	width: ${height('sm')};
	height: ${height('sm')};
	border-radius: ${borderRadius('md')};
	background-color: ${color('white')};
	transition: background-color 250ms;

	&:focus,
	&:active {
		background-color: ${color(['gray', '100'])};
	}
`

const DrawerLanguageItem = styled(LanguageItem)`
	display: flex;
	align-items: center;

	img {
		margin-right: ${margin('sm')};
	}

	font-weight: 500;
`

export default {
	Wrapper,
	NavMenu,
	Logo,
	NavMenuItem,
	Widget,
	LanguageIcon,
	LanguageItem,
	NotificationTrigger,
	ProfileTrigger,
	ProfileItem,
	NotificationsContainer,
	BuildIdText,
	MenuDivider,
	Burger,
	DrawerNav,
	DrawerMenu,
	DrawerMenuItem,
	DrawerBackButton,
	DrawerLanguageItem,
	NotificationsMenu,
}
