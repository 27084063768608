import React, { FC, useMemo, Fragment } from 'react'
import { Modal, Button, Typography, Divider, message } from 'antd'
import { Form, FormItem } from 'formik-antd'
import { Formik } from 'formik'
import { Autolocalize, Container, FormikTextArea, useHideModal, UserInteraction } from '@libs/components'
import Styled from './Styled'
import { ICaseText, INormalizedCaseText, IDatahubCase } from '@libs/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { TextsApi } from '@libs/api'
import QUERIES from 'modules/common/queries'
import { useLocalization } from '@libs/localization'
import { useScreenMatch } from '@libs/theme'
import { Close24 } from '@carbon/icons-react'
import { createValidationSchema } from '@libs/util'

interface IFormValues {
	content: ICaseText['content']
}

const schema = createValidationSchema(yup =>
	yup.object({
		content: yup.string().required(),
	})
)

export interface CaseTextEditModalProps extends INormalizedCaseText {
	caseId: IDatahubCase['id']
	translatedTitle: string
}

const CaseTextEditModal: FC<CaseTextEditModalProps> = ({ content, name, caseId, translatedTitle }) => {
	const hideModal = useHideModal()
	const queryClient = useQueryClient()
	const { data: history = [], isLoading } = useQuery([QUERIES.textHistory, name, caseId], () =>
		TextsApi.getTextHistory({ name, caseId })
	)
	const { t } = useLocalization()

	const { mutateAsync: saveText } = useMutation(TextsApi.saveCaseText, {
		onSuccess: () => {
			message.success(t('modals.editText.success'))
			queryClient.invalidateQueries([QUERIES.caseTexts, caseId])
			queryClient.invalidateQueries([QUERIES.textHistory, name, caseId])
			hideModal()
		},
	})

	const initialFormValues: IFormValues = useMemo(
		() => ({
			content,
		}),
		[content]
	)

	const match = useScreenMatch()

	return (
		<Formik<IFormValues>
			initialValues={initialFormValues}
			validationSchema={schema}
			onSubmit={({ content: newContent }) =>
				saveText({
					name,
					content: newContent,
					caseId,
				})
			}
		>
			{({ dirty, isSubmitting, values, isValid }) => (
				<Modal
					visible
					onCancel={hideModal}
					title={translatedTitle}
					width={600}
					closeIcon={<Close24 />}
					footer={[
						<Button
							loading={isSubmitting}
							disabled={!dirty || !isValid}
							form="edit-document-text-form-id"
							key="submit"
							htmlType="submit"
							type="primary"
						>
							{t('common.actions.save')}
						</Button>,
					]}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form id="edit-document-text-form-id" layout="vertical">
							<Autolocalize />
							<FormItem name="content" label={t('common.terms.content')}>
								<FormikTextArea name="content" autoSize />
							</FormItem>
							<Divider />
							<Container display={'flex'} flexDirection={'column'}>
								<Typography.Text strong>{t('common.terms.history')}</Typography.Text>
								{history.length === 0 && !isLoading ? (
									<Typography.Text>{t('modals.editText.noRecentChanges')}</Typography.Text>
								) : (
									<Styled.HistoryContainer display="flex" flexDirection="column" margin={{ top: 'sm' }}>
										{history.map(({ content: historyContent }, index, array) => (
											<Fragment key={index}>
												<Container
													display={'flex'}
													flexDirection={match.map({ xxs: 'column', lg: 'row' })}
													justifyContent={match.map({ xxs: 'flex-start', lg: 'space-between' })}
													alignItems={match.map({ xxs: 'flex-start', lg: 'center' })}
													space={'sm'}
													spaceDirection={match.map({ xxs: 'vertical', lg: 'horizontal' })}
												>
													<Typography.Text>{historyContent}</Typography.Text>
													{historyContent === values.content.trim() ? (
														<Typography.Text type="success">{t('modals.editText.currentVersion')}</Typography.Text>
													) : (
														<Button
															type="link"
															onClick={() =>
																saveText({
																	name,
																	content: historyContent,
																	caseId,
																})
															}
														>
															{t('modals.editText.selectVersion')}
														</Button>
													)}
												</Container>
												{index !== array.length - 1 && <Divider />}
											</Fragment>
										))}
									</Styled.HistoryContainer>
								)}
							</Container>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default CaseTextEditModal
