import styled from 'styled-components'
import { margin, color } from '@libs/theme/mixins'

const CopyBlock = styled.div`
	margin-bottom: ${margin('sm')};

	label {
		width: 100%;
	}

	.ant-select {
		width: 100%;
	}
`

const CustomLabel = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;

	button {
		padding: 0;
		height: auto;
		color: ${color('error')};
	}
`

export default {
	CopyBlock,
	CustomLabel,
}
