import styled from 'styled-components'
import { Container } from '@libs/components'

const HistoryContainer = styled(Container)`
	.ant-btn-link {
		width: auto;
		height: auto;
		padding: 0;
	}

	.ant-typography {
		white-space: break-spaces;
	}
`

export default {
	HistoryContainer,
}
