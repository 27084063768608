import { ICollageTemplate } from './types'

export const COLLAGE_OUTPUT_WIDTH = 3456
export const COLLAGE_OUTPUT_HEIGHT = 2304
export const COLLAGE_GAP = 4

const halfWidth = COLLAGE_OUTPUT_WIDTH / 2
const halfHeight = COLLAGE_OUTPUT_HEIGHT / 2

export const COLLAGE_TEMPLATES: ICollageTemplate[] = [
	// 0
	{
		layout: 'horizontal',
		grid: `'a b' 'c b'`,
		items: [
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 1,
				area: 'a',
				startX: 0,
				startY: 0,
				width: halfWidth - COLLAGE_GAP / 2,
				height: halfHeight - COLLAGE_GAP / 2,
			},
			{
				layout: 'vertical',
				rowSpan: 2,
				columnSpan: 1,
				area: 'b',
				startX: halfWidth + COLLAGE_GAP / 2,
				startY: 0,
				width: halfWidth - COLLAGE_GAP / 2,
				height: COLLAGE_OUTPUT_HEIGHT - COLLAGE_GAP / 2,
			},
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 1,
				area: 'c',
				startX: 0,
				startY: halfHeight + COLLAGE_GAP / 2,
				width: halfWidth - COLLAGE_GAP / 2,
				height: halfHeight - COLLAGE_GAP,
			},
		],
	},
	// 1
	{
		layout: 'horizontal',
		grid: `'a b' 'c d'`,
		items: [
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 1,
				area: 'a',
				startX: 0,
				startY: 0,
				width: halfWidth - COLLAGE_GAP / 2,
				height: halfHeight - COLLAGE_GAP / 2,
			},
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 1,
				area: 'b',
				startX: halfWidth + COLLAGE_GAP / 2,
				startY: 0,
				width: halfWidth - COLLAGE_GAP / 2,
				height: halfHeight - COLLAGE_GAP / 2,
			},
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 1,
				area: 'c',
				startX: 0,
				startY: halfHeight + COLLAGE_GAP / 2,
				width: halfWidth - COLLAGE_GAP / 2,
				height: halfHeight - COLLAGE_GAP / 2,
			},
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 1,
				area: 'd',
				startX: halfWidth + COLLAGE_GAP / 2,
				startY: halfHeight + COLLAGE_GAP / 2,
				width: halfWidth - COLLAGE_GAP / 2,
				height: halfHeight - COLLAGE_GAP / 2,
			},
		],
	},
	// 2
	{
		layout: 'vertical',
		grid: `'a' 'b'`,
		items: [
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 2,
				area: 'a',
				startX: 0,
				startY: 0,
				width: COLLAGE_OUTPUT_HEIGHT,
				height: halfWidth - COLLAGE_GAP / 2,
			},
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 2,
				area: 'b',
				startX: 0,
				startY: halfWidth + COLLAGE_GAP / 2,
				width: COLLAGE_OUTPUT_HEIGHT,
				height: halfWidth - COLLAGE_GAP / 2,
			},
		],
	},
	// 3
	{
		layout: 'horizontal',
		grid: `'a b'`,
		items: [
			{
				layout: 'vertical',
				rowSpan: 2,
				columnSpan: 1,
				area: 'a',
				startX: 0,
				startY: 0,
				width: halfWidth - COLLAGE_GAP / 2,
				height: COLLAGE_OUTPUT_HEIGHT,
			},
			{
				layout: 'vertical',
				rowSpan: 2,
				columnSpan: 1,
				area: 'b',
				startX: halfWidth + COLLAGE_GAP / 2,
				startY: 0,
				width: halfWidth - COLLAGE_GAP / 2,
				height: COLLAGE_OUTPUT_HEIGHT,
			},
		],
	},
	// 4
	{
		layout: 'vertical',
		grid: `'a b' 'c c'`,
		items: [
			{
				layout: 'vertical',
				rowSpan: 1,
				columnSpan: 1,
				area: 'a',
				startX: 0,
				startY: 0,
				width: halfHeight - COLLAGE_GAP / 2,
				height: halfWidth - COLLAGE_GAP / 2,
			},
			{
				layout: 'vertical',
				rowSpan: 1,
				columnSpan: 1,
				area: 'b',
				startX: halfHeight + COLLAGE_GAP / 2,
				startY: 0,
				width: halfHeight - COLLAGE_GAP / 2,
				height: halfWidth - COLLAGE_GAP / 2,
			},
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 2,
				area: 'c',
				startX: 0,
				startY: halfWidth + COLLAGE_GAP / 2,
				width: COLLAGE_OUTPUT_HEIGHT,
				height: halfWidth - COLLAGE_GAP / 2,
			},
		],
	},
	// 5
	{
		layout: 'horizontal',
		grid: `'a b' 'a c'`,
		items: [
			{
				layout: 'vertical',
				rowSpan: 2,
				columnSpan: 1,
				area: 'a',
				startX: 0,
				startY: 0,
				width: halfWidth - COLLAGE_GAP / 2,
				height: COLLAGE_OUTPUT_HEIGHT,
			},
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 1,
				area: 'b',
				startX: halfWidth + COLLAGE_GAP / 2,
				startY: 0,
				width: halfWidth - COLLAGE_GAP / 2,
				height: halfHeight - COLLAGE_GAP / 2,
			},
			{
				layout: 'horizontal',
				rowSpan: 1,
				columnSpan: 1,
				area: 'c',
				startX: halfWidth + COLLAGE_GAP / 2,
				startY: halfHeight + COLLAGE_GAP / 2,
				width: halfWidth - COLLAGE_GAP / 2,
				height: halfHeight - COLLAGE_GAP / 2,
			},
		],
	},
]
