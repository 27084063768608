import React, { FC, useCallback, useState } from 'react'
import { Typography } from 'antd'
import { IDatahubTask, ISimplifiedVisualMaterial, TaskCategory } from '@libs/api'
import { Container } from '@libs/components'
import MaterialFeedback, { IPopoverContentProps, PopoverContent } from 'modules/material/MaterialFeedback'
import { useFormikContext } from 'formik'
import { ITaskRetouchingFormValues } from '../TaskRetouchingModal'
import { useLocalization } from '@libs/localization'

export interface ObjectsRemovalStepProps {
	source: ISimplifiedVisualMaterial['source']
	scrollableRef?: HTMLElement
	task: IDatahubTask
}

const ObjectsRemovalStep: FC<ObjectsRemovalStepProps> = ({ source, scrollableRef, task }) => {
	const [selectedDrawingIndex, setSelectedDrawingIndex] = useState(-1)
	const { values, setFieldValue } = useFormikContext<ITaskRetouchingFormValues>()
	const { t } = useLocalization()

	// text by default
	const popoverType = useCallback(
		(blurSupport?: boolean): IPopoverContentProps['type'] => {
			if (task.type === TaskCategory.VirtualStagingRemoveObjects && blurSupport) return 'radio'

			if (task.type === TaskCategory.VirtualStagingOutdoorRenovation) return 'dropdown'
		},
		[task]
	)

	return (
		<Container>
			<Container display="flex" flexDirection="column" margin={{ bottom: 'md' }}>
				<Typography.Paragraph>{t('modals.retouchTask.removal.instructionsLine1')}</Typography.Paragraph>
				<Typography.Paragraph>{t('modals.retouchTask.removal.instructionsLine2')}</Typography.Paragraph>
			</Container>
			<MaterialFeedback
				task={task}
				source={source}
				selectedItemIndex={selectedDrawingIndex}
				scrollableRef={scrollableRef}
				items={values.drawings ?? []}
				popoverContent={(_, index, blurSupport) => (
					<PopoverContent
						name={`drawings[${index}].comment`}
						visible={selectedDrawingIndex === index}
						type={popoverType(blurSupport)}
					/>
				)}
				onSelectionChange={setSelectedDrawingIndex}
				onAddItem={item => {
					setFieldValue(
						'drawings',
						values.drawings.concat({
							...item,
							comment: '',
						})
					)
					setSelectedDrawingIndex(values.drawings.length)
				}}
				onRemoveItem={item => {
					setFieldValue(
						'drawings',
						values.drawings.filter(candidate => candidate !== item)
					)
					setSelectedDrawingIndex(-1)
				}}
			/>
		</Container>
	)
}

export default ObjectsRemovalStep
