import React, { useState, useMemo, useEffect, FC } from 'react'
import { Typography, Button, message, Dropdown, Menu, Card } from 'antd'
import { FormItem, Select, Form } from 'formik-antd'
import { Formik } from 'formik'
import {
	View24,
	ViewOff24,
	Download24,
	Copy24,
	ChevronDown24,
	Error24,
	Save24,
	Compare24,
	TrashCan24,
	ListChecked24,
} from '@carbon/icons-react'
import MaterialList from '../MaterialList'
import { useCollagePricing, useVisualMaterials } from '../hooks'
import {
	IBranch,
	IDatahubCase,
	isEsoftUser,
	ISimplifiedOrder,
	IUpdateVisualMaterialsParams,
	MaterialApi,
} from '@libs/api'
import { useMutation, useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useShowModal } from 'modules/common/GlobalModals'
import { CopyMaterialModalProps } from '../CopyMaterialModal'
import { IDropdownOption } from 'modules/common/types'
import { Container, Autolocalize, UserInteraction, Grid } from '@libs/components'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'
import { ANT_DROPDOWN_BUTTON_ROUND_CLASS_NAME, useScreenMatch } from '@libs/theme'
import { SpacingMixinParamType } from 'styled-components'
import { useProductStorage, useSimplifiedCaseOrders } from 'modules/cases/hooks'
import { useBranchById } from 'modules/auth/ActiveDirectoryPageContent/hooks'

interface IVisualMaterialForm {
	mode: 'view' | 'edit'
	selectedOrderId: IDropdownOption['value']
	selectedProduct?: IDropdownOption['value']
	selectedEditor?: IDropdownOption['value']
}

interface IVisualMaterialProps extends ReturnType<typeof useVisualMaterials> {
	caseId: IDatahubCase['id']
	branchId?: IBranch['id']
	caseAddress?: string
}

const VisualMaterial: FC<IVisualMaterialProps> = ({
	caseId,
	visualMaterials,
	batchList,
	externalLinks,
	onSortCancel,
	isSortOrderHasChanged,
	filteredMaterialIds,
	setActivationStatusForSelected,
	onSortEnd,
	onDescriptionEdit,
	selectMaterialItem,
	toggleSelectAllMaterials,
	setFilteredMaterialIds,
	branchId,
}) => {
	const showModal = useShowModal()
	const [mode, setMode] = useState<'view' | 'edit'>('view')
	const queryClient = useQueryClient()
	const { t } = useLocalization()
	const { mutateAsync: updateMaterial, isLoading } = useMutation(MaterialApi.updateMaterials, {
		onSuccess: response => {
			if (response?.success) {
				message.success(t('materials.messages.materialUpdateSuccess'))
			} else {
				message.error(t('materials.messages.materialUpdateFailed'))
			}
			queryClient.invalidateQueries(QUERIES.visualMaterialsSimplified)
		},
	})
	const { data: foundBranch } = useBranchById(branchId)
	const { data: orders = [] } = useSimplifiedCaseOrders(caseId)
	const { getSelectedProduct, clearSelectedProduct } = useProductStorage()
	const storedProduct = getSelectedProduct()
	const match = useScreenMatch()
	const { data: pricing } = useCollagePricing(caseId)

	useEffect(() => {
		return () => {
			clearSelectedProduct()
		}
	}, [])

	useEffect(() => {
		if (mode === 'view') {
			toggleSelectAllMaterials(false)
		}
	}, [mode])

	const storedProductId = useMemo(
		() =>
			batchList
				.find(batch => batch.batchId === storedProduct?.batchId?.toString())
				?.products.find(product => product.productName === storedProduct?.productName)?.productId,
		[batchList, storedProduct]
	)

	const initialValues: IVisualMaterialForm = {
		mode: 'view',
		selectedOrderId: storedProduct?.orderId || 'all',
		selectedProduct: storedProductId || 'all',
		selectedEditor: 'none',
	}

	const orderOptions: IDropdownOption[] = useMemo(() => {
		const ordersWithMaterials = orders.reduce((memo, next) => {
			if (
				!memo.some(order => order.id === next.id) &&
				batchList.some(({ products }) => products.some(({ orderId }) => orderId === next.id))
			) {
				return memo.concat(next)
			}
			return memo
		}, [] as ISimplifiedOrder[])

		return [
			{
				value: 'all',
				label: t('common.placeholders.allOrders'),
			},
		].concat(
			ordersWithMaterials.map(({ id, salesId }) => ({
				value: id,
				label: salesId,
			}))
		)
	}, [batchList, orders, t])

	const editorOptions: IDropdownOption[] = useMemo(
		() =>
			externalLinks.map<IDropdownOption>(({ url, linkType }) => {
				let label

				switch (linkType) {
					case 'interactiveFloorPlanEditor':
						label = t('common.actions.editIfp')
						break
					case 'hotspotZip':
						label = t('common.actions.downloadZip')
						break
					case 'hotspotEditor':
						label = t('common.actions.editPoi')
						break
					// panoramas
					default:
						label = t('common.actions.editPanorama')
				}

				return {
					value: url,
					label,
				}
			}, []),
		[externalLinks, t]
	)

	const selectedMaterialIds = useMemo(
		() => visualMaterials.filter(({ isSelected }) => isSelected).map(({ id }) => id),
		[visualMaterials]
	)

	const isAllSelected = useMemo(
		() => visualMaterials.filter(({ id }) => filteredMaterialIds.includes(id)).every(({ isSelected }) => isSelected),
		[visualMaterials, filteredMaterialIds]
	)

	const onEditCancel = () => {
		setMode('view')
		onSortCancel()
	}

	const onApplyChanges = async () => {
		isSortOrderHasChanged &&
			(await updateMaterial({
				caseId,
				assets: visualMaterials.map<IUpdateVisualMaterialsParams['assets'][number]>((material, index) => ({
					assetId: material.id?.toString(),
					assetName: material.description ?? '',
					orderPosition: !material.isActive ? 0 : index + 1,
					tags: material.label ? [material.label.translateKey] : [],
				})),
			}))

		setMode('view')
	}

	const isEditMode = mode === 'edit'

	const isEsoftUserValue = useMemo(() => isEsoftUser(), [])

	if (visualMaterials.length === 0) return null

	return (
		<Card title={t('common.terms.visualMaterial')}>
			<Formik<IVisualMaterialForm> initialValues={initialValues} enableReinitialize onSubmit={console.log}>
				{({ values, setFieldValue }) => {
					const selectedOrderProducts = batchList.reduce<(typeof batchList)[number]['products']>((memo, next) => {
						const filteredProducts = next.products.filter(
							({ orderId, productId }) => orderId === values.selectedOrderId && !!productId
						)
						filteredProducts.forEach(product => {
							if (!memo.some(({ productId }) => productId === product.productId)) {
								memo.push(product)
							}
						})
						return memo
					}, [])
					const selectedOrderOrderLines = batchList.reduce((memo, next) => {
						if (values.selectedOrderId === 'all' && !memo.includes(next.orderLineId)) {
							return memo.concat([next.orderLineId])
						}

						const foundByOrderIdOrderLineIds = next.products
							.filter(({ orderId }) => orderId === values.selectedOrderId)
							.map(({ orderLineId }) => orderLineId)

						foundByOrderIdOrderLineIds.forEach(orderLineId => {
							if (!memo.includes(orderLineId)) {
								memo.push(orderLineId)
							}
						})

						return memo
					}, [] as string[])

					let filteredMaterials = visualMaterials.filter(
						({ productId, orderlineId }) =>
							values.selectedOrderId === 'all' ||
							(orderlineId &&
								selectedOrderOrderLines.includes(orderlineId) &&
								selectedOrderProducts.some(product => product.productId === productId || !product.productId))
					)
					const filteredIds = filteredMaterials.map(({ id }) => id)
					if (
						filteredMaterialIds.length !== filteredMaterials.length ||
						!filteredIds.every(id => filteredMaterialIds.includes(id))
					) {
						setFilteredMaterialIds(filteredIds)
					}

					const productOptions: IDropdownOption[] = [
						{
							value: 'all',
							label: t('common.placeholders.allProducts'),
						},
					].concat(
						selectedOrderProducts
							.filter(({ productId }) => !!productId)
							.map(({ productId, productName }) => ({
								value: productId,
								label: productName,
							}))
					)

					const onBatchRemove = () => {
						const foundBatch = batchList.find(({ products }) =>
							products.some(
								({ orderId, productId }) =>
									orderId === values.selectedOrderId &&
									(values.selectedProduct === 'all' || productId === values.selectedProduct)
							)
						)
						if (!foundBatch) {
							message.error(t('modals.materialBatchRemoval.noBatchId'))
							return
						}
						showModal('material.batch.remove', {
							batchId: foundBatch.batchId,
							orderId: values.selectedOrderId,
							orderLineIds: foundBatch.orderLineId ? [foundBatch.orderLineId] : [],
						})
					}

					const showCombineImage = !!foundBranch?.settings?.combineImageProductId && filteredMaterials.length > 0

					if (values.selectedOrderId !== 'all' && values.selectedProduct !== 'all') {
						filteredMaterials = filteredMaterials.filter(
							({ productId }) => productId === values.selectedProduct || !productId
						)
					}

					return (
						<UserInteraction enabled={!isLoading}>
							<Form layout="vertical">
								<Autolocalize />
								{!isEditMode && (
									<Container
										display={'flex'}
										flexDirection={match.map({ xxs: 'column', md: 'row' })}
										justifyContent={match.map({ xxs: 'flex-start', md: 'space-between' })}
										alignItems={match.map({ xxs: 'stretch', md: 'flex-start' })}
										space={match.map<SpacingMixinParamType>({ xxs: 'sm', md: 0 })}
										spaceDirection={match.map({ xxs: 'vertical', md: 'horizontal' })}
										margin={{ bottom: 'xl' }}
									>
										<Container
											display={'flex'}
											flexDirection={match.map({ xxs: 'column', lg: 'row' })}
											space={'sm'}
											spaceDirection={match.map({ xxs: 'vertical', lg: 'horizontal' })}
										>
											<Container>
												<FormItem name="selectedOrderId">
													<Select
														name="selectedOrderId"
														options={orderOptions}
														placeholder={t('common.placeholders.allOrders')}
														onChange={() => {
															setFieldValue('selectedProduct', 'all')
															clearSelectedProduct()
														}}
													/>
												</FormItem>
											</Container>
											{values.selectedOrderId !== 'all' && (
												<Styled.SelectContainer>
													<FormItem name="selectedProduct">
														<Styled.Select
															name="selectedProduct"
															options={productOptions}
															dropdownMatchSelectWidth={false}
															onChange={clearSelectedProduct}
															defaultActiveFirstOption
														/>
													</FormItem>
												</Styled.SelectContainer>
											)}
										</Container>
										<Styled.EditButtonContainer
											display={'flex'}
											flexDirection={match.map({ xxs: 'column', md: 'row' })}
											space="xxs"
											spaceDirection={match.map({ xxs: 'vertical', md: 'horizontal' })}
										>
											{pricing?.featureEnabled && (
												<Button onClick={() => showModal('material.collage', { caseId })}>
													{t('common.actions.createCollage')}
												</Button>
											)}
											{editorOptions.length > 0 ? (
												<Dropdown.Button
													type={'primary'}
													className={ANT_DROPDOWN_BUTTON_ROUND_CLASS_NAME}
													overlay={
														<Menu>
															{editorOptions.map(({ value, label }, index) => (
																<Menu.Item key={index}>
																	<a href={String(value)} target={'_blank'} rel={'noreferrer'}>
																		{label}
																	</a>
																</Menu.Item>
															))}
														</Menu>
													}
													onClick={() => setMode('edit')}
												>
													{t('common.actions.editMaterial')}
												</Dropdown.Button>
											) : (
												<Button type="primary" onClick={() => setMode('edit')}>
													{t('common.actions.editMaterial')}
												</Button>
											)}
										</Styled.EditButtonContainer>
									</Container>
								)}
								{isEditMode && (
									<Container margin={{ bottom: 'xl' }}>
										<Grid.Row gutter={'xs'}>
											<Grid.Col span={24}>
												<Container display={'flex'} alignItems={'center'}>
													<Typography.Text>
														{t('common.sentences.selectedOfTotal', {
															selected: selectedMaterialIds.length,
															total: filteredMaterials.length,
														})}
													</Typography.Text>
												</Container>
											</Grid.Col>
											{match.gte('xl') && (
												<Grid.Col xl={18}>
													<Container display={'flex'} flexWrap={'wrap'} space={'xs'}>
														<Styled.ActionButton
															icon={<ListChecked24 />}
															onClick={() => toggleSelectAllMaterials(!isAllSelected)}
														>
															{isAllSelected ? t('common.terms.deselectAll') : t('common.terms.selectAll')}
														</Styled.ActionButton>
														<Styled.ActionButton icon={<View24 />} onClick={() => setActivationStatusForSelected(true)}>
															{t('common.actions.activate')}
														</Styled.ActionButton>
														<Styled.ActionButton
															icon={<ViewOff24 />}
															onClick={() => setActivationStatusForSelected(false)}
														>
															{t('common.actions.deactivate')}
														</Styled.ActionButton>
														<Styled.ActionButton
															icon={<Download24 />}
															onClick={() =>
																showModal('material.download', {
																	caseId,
																	selectedMaterialIds,
																})
															}
														>
															{t('common.actions.download')}
														</Styled.ActionButton>
														<Dropdown
															trigger={['click']}
															placement={'bottomRight'}
															overlay={
																<Menu>
																	<Menu.Item
																		onClick={() => {
																			selectedMaterialIds.length
																				? showModal('material.copy', {
																						title: t('modals.copyMaterial.copySelectedTitle'),
																						materials: filteredMaterials.filter(({ isSelected }) => isSelected),
																						copyAll: false,
																						caseId,
																						branchId,
																				  } as CopyMaterialModalProps)
																				: message.error(t('materials.messages.noSelectedMaterial'))
																		}}
																	>
																		{t('materials.actions.copySelectedMaterial')}
																	</Menu.Item>
																	<Menu.Item
																		onClick={() =>
																			showModal('material.copy', {
																				title: t('modals.copyMaterial.copyAllTitle'),
																				materials: filteredMaterials,
																				copyAll: true,
																				caseId,
																				branchId,
																			} as CopyMaterialModalProps)
																		}
																	>
																		{t('materials.actions.copyAllMaterial')}
																	</Menu.Item>
																</Menu>
															}
														>
															<Styled.ActionButton icon={<Copy24 />}>{t('common.actions.copy')}</Styled.ActionButton>
														</Dropdown>
														{showCombineImage && (
															<Styled.ActionButton
																icon={<Compare24 />}
																onClick={() =>
																	showModal('case.combine.images', {
																		materials: filteredMaterials,
																		caseId,
																	})
																}
															>
																{t('tasks.categories.COMBINE_IMAGES')}
															</Styled.ActionButton>
														)}
														{isEsoftUserValue && (
															<Styled.ActionButton icon={<TrashCan24 />} onClick={onBatchRemove}>
																{t('materials.actions.removeBatch')}
															</Styled.ActionButton>
														)}
													</Container>
												</Grid.Col>
											)}
											{match.lte('lg') && (
												<Grid.Col xs={24} md={12} lg={12}>
													<Container display={'flex'} flexDirection={match.map({ xxs: 'column', md: 'row' })}>
														<Dropdown
															trigger={['click']}
															placement={'bottomLeft'}
															overlay={
																<Menu>
																	<Menu.Item onClick={() => toggleSelectAllMaterials(!isAllSelected)}>
																		{isAllSelected ? t('common.terms.deselectAll') : t('common.terms.selectAll')}
																	</Menu.Item>
																	<Menu.Item onClick={() => setActivationStatusForSelected(true)}>
																		{t('common.actions.activate')}
																	</Menu.Item>
																	<Menu.Item onClick={() => setActivationStatusForSelected(false)}>
																		{t('common.actions.deactivate')}
																	</Menu.Item>
																	<Menu.Item
																		onClick={() =>
																			showModal('material.download', {
																				caseId,
																				selectedMaterialIds,
																			})
																		}
																	>
																		{t('common.actions.download')}
																	</Menu.Item>
																	<Menu.Item
																		onClick={() => {
																			selectedMaterialIds.length
																				? showModal('material.copy', {
																						title: t('modals.copyMaterial.copySelectedTitle'),
																						materials: filteredMaterials.filter(({ isSelected }) => isSelected),
																						copyAll: false,
																						caseId,
																						branchId,
																				  } as CopyMaterialModalProps)
																				: message.error(t('materials.messages.noSelectedMaterial'))
																		}}
																	>
																		{t('materials.actions.copySelectedMaterial')}
																	</Menu.Item>
																	<Menu.Item
																		onClick={() =>
																			showModal('material.copy', {
																				title: t('modals.copyMaterial.copyAllTitle'),
																				materials: filteredMaterials,
																				copyAll: true,
																				caseId,
																				branchId,
																			} as CopyMaterialModalProps)
																		}
																	>
																		{t('materials.actions.copyAllMaterial')}
																	</Menu.Item>
																	{showCombineImage && (
																		<Menu.Item
																			onClick={() =>
																				showModal('case.combine.images', {
																					materials: filteredMaterials,
																					caseId,
																				})
																			}
																		>
																			{t('tasks.categories.COMBINE_IMAGES')}
																		</Menu.Item>
																	)}
																	{isEsoftUserValue && (
																		<Menu.Item onClick={onBatchRemove}>{t('materials.actions.removeBatch')}</Menu.Item>
																	)}
																</Menu>
															}
														>
															<Styled.ActionButton icon={<ChevronDown24 />} className={'actionsMenu'} menu>
																{t('common.terms.actions')}
															</Styled.ActionButton>
														</Dropdown>
													</Container>
												</Grid.Col>
											)}
											<Grid.Col xs={24} md={12} lg={12} xl={6}>
												<Container
													display={'flex'}
													flexDirection={match.map({ xxs: 'column', md: 'row' })}
													flexWrap={match.map({ xxs: 'wrap', xxl: 'nowrap' })}
													alignItems={match.map({ xxs: 'stretch', md: 'center' })}
													justifyContent={'flex-end'}
													space={match.map<SpacingMixinParamType>({ xxs: 'xs', md: 'sm', xl: 0, xxl: 'sm' })}
													spaceDirection={match.map({ xxs: 'vertical', md: 'horizontal' })}
												>
													<Styled.ActionButton onClick={onEditCancel}>
														{match.map({
															xxs: t('common.actions.cancel'),
															md: <Error24 />,
															lg: t('common.actions.cancel'),
														})}
													</Styled.ActionButton>
													<Styled.ActionButton loading={isLoading} type="primary" onClick={onApplyChanges}>
														{match.map({
															xxs: t('common.actions.saveChanges'),
															md: <Save24 />,
															lg: t('common.actions.saveChanges'),
														})}
													</Styled.ActionButton>
												</Container>
											</Grid.Col>
										</Grid.Row>
									</Container>
								)}
								<MaterialList
									visualMaterials={filteredMaterials}
									disabled={!isEditMode}
									sortable={isEditMode}
									axis="xy"
									onDescriptionEdit={isEditMode ? onDescriptionEdit : undefined}
									onSortStart={() => (document.body.style.cursor = 'grabbing')}
									onSortEnd={onSortEnd}
									onItemClick={selectMaterialItem}
									useWindowAsScrollContainer
									caseId={caseId}
								/>
							</Form>
						</UserInteraction>
					)
				}}
			</Formik>
		</Card>
	)
}

export default VisualMaterial
