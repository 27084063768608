import styled from 'styled-components'
import { Button, Typography } from 'antd'
import { color, margin, screen } from '@libs/theme/mixins'

const Editable = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: ${margin('xxs')};

	.ant-select {
		width: 100%;
	}

	.ant-select-selection-search {
		right: 170px !important;
	}

	.ant-typography {
		margin-bottom: 0;
	}

	.ant-select-auto-complete {
		min-width: 300px;

		${screen.lte.xs`
			width: 100%;
			min-width: auto;
		`}
	}

	> div {
		display: flex;
		align-items: center;
		min-width: 200px;
		width: 100%;
	}

	${screen.lte.xs`
		> div {
			min-width: auto;
			flex-direction: column;
		}

		.ant-select-selection-search {
			right: 19px !important;
		}
	`}

	svg {
		cursor: pointer;
		color: ${color(['gray', '700'])};
		margin-left: ${margin('xs')};
	}
`

const EmptyAddress = styled(Typography.Title)`
	color: ${color(['gray', '300'])} !important;
	font-style: italic;

	+ h3.ant-typography {
		margin-top: 0;
	}
`

const EditButton = styled(Button)`
	position: absolute;
	right: ${margin('xl')};

	${screen.lte.xs`
		position: relative;
		right: 0;
		padding-left: 0;
		padding-right: 0;
		width: auto !important;
		align-self: flex-start;
	`}
`

export default {
	EmptyAddress,
	Editable,
	EditButton,
}
