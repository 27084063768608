import React from 'react'
import { Skeleton } from '@libs/components'

const TextInputForm = () => (
	<>
		<Skeleton.Paragraph active titleWidth={200} rows={3} />
		<Skeleton.Paragraph active titleWidth={150} rows={2} />
		<Skeleton.Paragraph active titleWidth={160} rows={2} rowWidth={400} />
		<Skeleton.Paragraph active titleWidth={110} rows={2} rowWidth={350} />
		<Skeleton.Paragraph active titleWidth={190} rows={2} />
	</>
)

export default { TextInputForm }
