import styled from 'styled-components'
import { Modal as _Modal } from 'antd'
import { margin } from '@libs/theme/mixins'

const Modal = styled(_Modal)`
	max-width: 90vw;

	.ant-modal-content {
		margin-bottom: ${margin('xxl')};
	}
`

export default {
	Modal,
}
