import styled from 'styled-components'
import { color, margin } from '@libs/theme/build/mixins'
import { Container as _Container } from '@libs/components'

const Logo = styled.img`
	position: absolute;
	width: 120px;
	margin-top: ${margin('md')};
	margin-left: ${margin('xxl')};
`

const Container = styled(_Container)<{ standalonePage?: boolean }>`
	height: ${({ standalonePage = true }) => (standalonePage ? '100vh' : '80vh')};
	background-color: ${color(['primary', '800'])};

	svg {
		margin-top: ${margin('xxs')};
		height: 50px;
		width: 50px;
	}
`

export default {
	Logo,
	Container,
}
