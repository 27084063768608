import React, { FC, Fragment } from 'react'
import { BookingStatus, BookingTypes, IDatahubTask, IPhotographerBookingData, TaskStatus } from '@libs/api'
import { Container, Grid } from '@libs/components'
import { useScreenMatch } from '@libs/theme'
import { Button, Card, Divider, Dropdown, Form, Menu, Select, Skeleton, Typography } from 'antd'
import { usePhotographerBookingModal } from './hooks'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'
import { ChevronDown16 } from '@carbon/icons-react'
import BookPhotographerCalendarView from '../BookPhotographerCalendarView'
import { fixBookingTimeToInterval } from './helpers'

const { Text } = Typography

export interface ITaskBookPhotographerContent extends ReturnType<typeof usePhotographerBookingModal> {
	task: IDatahubTask
}

const TaskBookPhotographerContent: FC<ITaskBookPhotographerContent> = ({
	isLoading,
	proposal,
	task,
	bookingType,
	bookingInfo,
	isSubmitting,
	onConfirmClick,
	onAnotherDateClick,
	onContactPersonBooking,
	onEsoftBooking,
	selectContactPerson,
	selectedContactPerson,
	contactPersonOptions,
	selectSecondaryItem,
}) => {
	const { t, f } = useLocalization()

	const infoSection = (infoArr: IPhotographerBookingData['info']) => (
		<Container display="flex" flexDirection="column" spaceDirection="vertical" space="xxs" margin={{ bottom: 'sm' }}>
			<Text strong>{t('common.terms.photographerComment')}</Text>
			{infoArr &&
				infoArr.map(info => (
					<Container display="flex" flexDirection="column" key={info.text}>
						{info.time && <Text type="secondary">{f(info.time, { date: 'short', time: 'short' })}</Text>}
						<Text>{info.text}</Text>
					</Container>
				))}
		</Container>
	)

	const areaSection = (area: IPhotographerBookingData['area']) => (
		<Container display="flex" flexDirection="column" spaceDirection="vertical" space="xxs">
			<Text strong>{t('common.terms.livingArea')}</Text>
			<Text>
				{area} m<sup>2</sup>
			</Text>
		</Container>
	)

	const showBookedAlready =
		!isLoading && (bookingInfo?.status === BookingStatus.COMPLETE || bookingInfo?.status === BookingStatus.CANCELLED)
	const showCantBeBooked = !isLoading && !proposal && !showBookedAlready
	const showBooking = !isLoading && !showBookedAlready
	const isTaskFinished = task.status === TaskStatus.Complete

	const match = useScreenMatch()

	return (
		<>
			{bookingType === BookingTypes.BookingPrimaryConfirmed && (
				<Container
					{...(!proposal && {
						// dynamic props
						padding: {
							bottom: 'lg',
						},
					})}
				>
					{isLoading && (
						<Container display="flex" justifyContent="center" padding={{ bottom: 'lg' }}>
							<Skeleton />
						</Container>
					)}
					{showBookedAlready && (
						<Container display="flex" flexDirection="column" alignItems="center" padding={{ bottom: 'xxl', top: 'xl' }}>
							<Text>{t('modals.bookPhotographer.alreadyBooked')}</Text>
						</Container>
					)}
					{showCantBeBooked && (
						<Container display="flex" flexDirection="column" alignItems="center" padding={{ bottom: 'xxl', top: 'xl' }}>
							<Text>{t('modals.bookPhotographer.cannotBook')}</Text>
						</Container>
					)}
					{showBooking && proposal?.primary && (
						<>
							<Grid.Row gutter={['sm', 'md']}>
								{task.status === TaskStatus.Partial && (
									<Grid.Col span={24}>
										<Styled.WarningNotice>
											<Text type="danger">{t('modals.bookPhotographer.warning')}</Text>
										</Styled.WarningNotice>
									</Grid.Col>
								)}

								<Grid.Col span={24}>
									<Text strong>{t('modals.bookPhotographer.subTitle')}</Text>
								</Grid.Col>

								<Grid.Col span={24}>
									<Styled.CardWrapper>
										<Card size="small">
											<Container
												display={'flex'}
												justifyContent={match.map({ xxs: 'flex-start', md: 'space-evenly' })}
												flexDirection={match.map({ xxs: 'column', md: 'row' })}
												space={match.map({ xxs: 'sm', md: 'md' })}
												spaceDirection={match.map({ xxs: 'vertical', md: 'horizontal' })}
												alignItems={match.map({ xxs: 'flex-start', md: 'center' })}
											>
												<Container display="flex" flexDirection="column">
													<Text type="secondary">{t('common.terms.date')}</Text>
													<Text strong>{f(proposal.primary.start, { date: 'long' })}</Text>
												</Container>
												<Divider type={match.map({ xxs: 'horizontal', md: 'vertical' })} />
												<Container display="flex" flexDirection="column">
													<Text type="secondary">{t('common.terms.arrivalTime')}</Text>
													<Text strong>
														{f(proposal.primary.start, { time: 'short' })} -{' '}
														{f(proposal.primary.end, { time: 'short' })}
													</Text>
												</Container>
												<Divider type={match.map({ xxs: 'horizontal', md: 'vertical' })} />
												<Container display="flex" flexDirection="column">
													<Text type="secondary">{t('modals.bookPhotographer.timeForTask')}</Text>
													<Text strong>{fixBookingTimeToInterval(proposal.primary.timeForTask)}</Text>
												</Container>
											</Container>
										</Card>
									</Styled.CardWrapper>
								</Grid.Col>
								{proposal.primary.photographerName && (
									<Grid.Col span={24}>
										<Text strong>{t('common.terms.photographer')}: </Text>
										<Text>{proposal.primary.photographerName}</Text>
									</Grid.Col>
								)}
								<Grid.Col span={24}>
									<Container
										display={'flex'}
										flexDirection={match.map({ xxs: 'column', md: 'row' })}
										justifyContent={match.map({ xxs: 'flex-start', md: 'center' })}
										space={match.map({ xxs: 'xxs', md: 'sm' })}
										spaceDirection={match.map({ xxs: 'vertical', md: 'horizontal' })}
									>
										<Button
											type="primary"
											shape="round"
											size="small"
											loading={isSubmitting}
											disabled={isLoading || isTaskFinished}
											onClick={onConfirmClick}
										>
											{t('common.actions.confirm')}
										</Button>
										<Dropdown
											overlay={
												<Menu>
													<Menu.Item onClick={onAnotherDateClick}>{t('modals.bookPhotographer.anotherDate')}</Menu.Item>
													<Menu.Item onClick={onContactPersonBooking}>
														{t('modals.bookPhotographer.actions.contactPersonBooking')}
													</Menu.Item>
													<Menu.Item onClick={onEsoftBooking}>
														{t('modals.bookPhotographer.actions.esoftBooking')}
													</Menu.Item>
												</Menu>
											}
											disabled={isLoading || isTaskFinished}
										>
											<Button shape={'round'} size={'small'} disabled={isLoading || isTaskFinished}>
												<Container
													display={'flex'}
													justifyContent={'center'}
													alignItems={'center'}
													space={'xxs'}
													spaceDirection={'horizontal'}
												>
													<span>{t('modals.bookPhotographer.actions.otherOptions')}</span>
													<ChevronDown16 />
												</Container>
											</Button>
										</Dropdown>
									</Container>
								</Grid.Col>
							</Grid.Row>
						</>
					)}
				</Container>
			)}
			{bookingType === BookingTypes.Booking3rdParty && (
				<Container space="sm" spaceDirection="vertical">
					<Typography.Paragraph>{t('modals.bookPhotographer.contactPersonBookingSubTitle')}</Typography.Paragraph>
					<Form layout="vertical">
						<Grid.Col xs={24} xl={12}>
							<Form.Item label={t('common.terms.contactPerson')} required>
								<Select
									value={selectedContactPerson}
									optionLabelProp="name"
									onChange={selectContactPerson}
									options={contactPersonOptions}
								/>
							</Form.Item>
						</Grid.Col>
					</Form>
					<Typography.Text>{t('modals.bookPhotographer.partiallyBookingWarning')}</Typography.Text>
				</Container>
			)}
			{bookingType === BookingTypes.BookingEsoft && (
				<>
					<Typography.Title level={5}>{t('modals.bookPhotographer.esoftBookingTitle')}</Typography.Title>
					<Typography.Paragraph>{t('modals.bookPhotographer.esoftBookingSubTitle')}</Typography.Paragraph>
					<Typography.Paragraph>{t('modals.bookPhotographer.partiallyBookingWarning')}</Typography.Paragraph>
				</>
			)}
			{bookingType === BookingTypes.BookingSecondaryConfirmed && proposal && (
				<BookPhotographerCalendarView
					bookingId={bookingInfo?.bookingId}
					timeForTask={proposal.primary?.timeForTask || proposal.duration}
					onSelect={selectSecondaryItem}
				/>
			)}
			{proposal?.primary && (
				<Container padding={{ bottom: 'lg' }}>
					{(proposal.primary.info || proposal.primary.area) && (
						<Fragment>
							<Divider />
							<Grid.Row gutter={'sm'}>
								{proposal.primary.info && (
									<Grid.Col xs={24} md={12}>
										{infoSection(proposal.primary.info)}
									</Grid.Col>
								)}
								{proposal.primary.area && (
									<Grid.Col xs={24} md={12}>
										{areaSection(proposal.primary.area)}
									</Grid.Col>
								)}
							</Grid.Row>
						</Fragment>
					)}
				</Container>
			)}
		</>
	)
}

export default TaskBookPhotographerContent
