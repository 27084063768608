export const BASE_NON_VIDEO_EXTENSIONS = ['.gif', '.png', '.jpg', '.jpeg', '.pdf']

export const ALLOWED_NON_VIDEO_EXTENSIONS = [
	'.cr2',
	'.dng',
	'.tif',
	'.tiff',
	'.arw',
	'.cr3',
	'.xmp',
	'.nef',
	'.raf',
	'.crw',
	'.nrw',
	'.orf',
	'.rw2',
	'.srf',
	'.sr2',
	'.dwg',
] as const

type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never

export function mapExtensionToMimeType(extension: ElementType<typeof ALLOWED_NON_VIDEO_EXTENSIONS>) {
	switch (extension) {
		case '.cr2':
			return 'image/x-canon-cr2'
		case '.cr3':
			return 'image/x-canon-cr3'
		case '.arw':
			return 'image/x-sony-arw'
		case '.crw':
			return 'image/x-canon-crw'
		case '.dng':
		case '.dwg':
			return 'image/x-adobe-dng'
		case '.nef':
		case '.nrw':
			return 'image/x-nikon-nef'
		case '.orf':
			return 'image/x-olympus-orf'
		case '.raf':
			return 'image/x-fuji-raf'
		case '.rw2':
			return 'image/x-panasonic-rw2'
		case '.sr2':
			return 'image/x-sony-sr2'
		case '.srf':
			return 'image/x-sony-srf'
		case '.tif':
		case '.tiff':
			return 'image/tiff'
		case '.xmp':
			return ''
		default:
			return ''
	}
}

export const ALLOWED_NON_VIDEO_MIME_TYPES = ALLOWED_NON_VIDEO_EXTENSIONS.map(mapExtensionToMimeType)
