import React, { FC } from 'react'
import { Grid, Skeleton } from '@libs/components'
import Styled from './Styled'
import ProductCard from 'modules/orders/ProductCard'
import { IProduct } from '@libs/api'
import { useLocalization } from '@libs/localization'
import { useScreenMatch } from '@libs/theme'

interface ProductListProps {
	products: IProduct[]
	areProductsFetched: boolean
	selectedProducts: IProduct[]
	onQuantityChange: (quantity: number, product: IProduct) => void
	smallTitle?: boolean
	modalView?: boolean
	shouldAskForCopyright?: boolean
}

const ProductList: FC<ProductListProps> = ({
	products,
	areProductsFetched,
	selectedProducts,
	onQuantityChange,
	modalView,
	smallTitle,
	shouldAskForCopyright,
}) => {
	const { t } = useLocalization()
	const match = useScreenMatch()

	return (
		<Grid.Col span={24}>
			{areProductsFetched && (
				<>
					{!modalView && (
						<Styled.SectionTitle $small={smallTitle}>
							{t('common.terms.purchaseIndividualProductsTitle')}
						</Styled.SectionTitle>
					)}
					<Grid.Row>
						{products.map((product, index) => (
							<Grid.Col key={index} xs={24} md={12} xl={8} xxl={modalView ? 6 : 8}>
								<ProductCard
									product={product}
									quantity={selectedProducts?.find(({ id }) => product.id === id)?.quantity || 0}
									onQuantityChange={onQuantityChange}
									shouldAskForCopyright={shouldAskForCopyright}
								/>
							</Grid.Col>
						))}
					</Grid.Row>
				</>
			)}
			{!areProductsFetched && (
				<Skeleton.Cards
					size={'small'}
					span={match.map({ xxs: 24, md: 12, xl: 8, ...(modalView && { xxl: 6 }) })}
					items={match.map({ xxs: 4, xl: 6, ...(modalView && { xxl: 8 }) })}
				/>
			)}
		</Grid.Col>
	)
}

export default ProductList
