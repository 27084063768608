import React, { FC, useMemo } from 'react'
import { Modal, Button } from 'antd'
import { Form, FormItem } from 'formik-antd'
import { Formik } from 'formik'
import { Autolocalize, Container, FormikInput, Grid, useHideModal, UserInteraction } from '@libs/components'
import { IAddress, mapFullAddressFromCaseDetailsAddress } from '@libs/api'
import { useLocalization } from '@libs/localization'
import { Close24 } from '@carbon/icons-react'
import { createValidationSchema } from '@libs/util'
import { ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME } from '@libs/theme'

interface IFormValues extends Partial<IAddress> {}

export interface AddressModalProps {
	onSubmit: (address: Partial<IAddress>) => void
	address?: IAddress
}

const formSchema = createValidationSchema(yup =>
	yup.object().shape({
		street: yup.string().required(),
		houseNumber: yup.string().required(),
		appartmentNumber: yup.string().nullable(),
		floor: yup.string().nullable(),
		postNumber: yup.string().required(),
		city: yup.string().required(),
	})
)

const AddressModal: FC<AddressModalProps> = ({ address, onSubmit }) => {
	const hideModal = useHideModal()

	const { t } = useLocalization()

	const initialFormValues: IFormValues = useMemo(
		() =>
			address
				? { ...address, id: undefined }
				: {
						street: '',
						houseNumber: '',
						appartmentNumber: '',
						floor: '',
						postNumber: '',
						city: '',
				  },
		[address]
	)

	return (
		<Formik<IFormValues>
			initialValues={initialFormValues}
			validationSchema={formSchema}
			onSubmit={async values => {
				values.full = mapFullAddressFromCaseDetailsAddress({
					ReferenceAddressStreet: values.street!,
					ReferenceAddressHouseNumber: values.houseNumber!,
					ReferenceAddressFloor: values.floor,
					ReferenceAddressDoor: values.appartmentNumber,
					ReferenceAddressZip: values.postNumber!,
					ReferenceAddressCity: values.city!,
				})
				await onSubmit(values)
				hideModal()
			}}
		>
			{({ isValid, dirty, isSubmitting }) => (
				<Modal
					visible
					onCancel={hideModal}
					title={t('modals.editAddress.title')}
					width={800}
					closeIcon={<Close24 />}
					footer={[
						<Button
							loading={isSubmitting}
							disabled={!dirty || !isValid}
							form="edit-address-form-id"
							key="submit"
							htmlType="submit"
							type="primary"
						>
							{t('common.actions.save')}
						</Button>,
					]}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form id="edit-address-form-id" layout="vertical">
							<Autolocalize />
							<Container display={'flex'} flexDirection={'column'}>
								<Grid.Row gutter={['sm', 0]}>
									<Grid.Col xs={24}>
										<FormItem
											name="street"
											label={t('common.terms.street')}
											required
											className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
										>
											<FormikInput name="street" />
										</FormItem>
									</Grid.Col>
									<Grid.Col xs={24}>
										<FormItem
											name="houseNumber"
											label={t('common.terms.houseNumber')}
											required
											className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
										>
											<FormikInput name="houseNumber" />
										</FormItem>
									</Grid.Col>
									<Grid.Col xs={24}>
										<FormItem
											name="floor"
											label={t('common.terms.floor')}
											className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
										>
											<FormikInput name="floor" />
										</FormItem>
									</Grid.Col>
									<Grid.Col xs={24}>
										<FormItem
											name="appartmentNumber"
											label={t('common.terms.appartmentNumber')}
											className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
										>
											<FormikInput name="appartmentNumber" />
										</FormItem>
									</Grid.Col>
									<Grid.Col xs={24}>
										<FormItem
											name="postNumber"
											label={t('common.terms.postNumber')}
											required
											className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
										>
											<FormikInput name="postNumber" />
										</FormItem>
									</Grid.Col>
									<Grid.Col xs={24}>
										<FormItem
											name="city"
											label={t('common.terms.city')}
											required
											className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
										>
											<FormikInput name="city" />
										</FormItem>
									</Grid.Col>
								</Grid.Row>
							</Container>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default AddressModal
