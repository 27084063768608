import { getS3TaskUploadTempPath, IDatahubTask, IUser, MaterialApi, S3Api } from '@libs/api'
import { useAuth } from 'modules/auth/hooks'
import React, { Component, createContext, FC, Fragment, useContext } from 'react'
import { useQuery } from 'react-query'
import {
	ITaskUploaderContextValue,
	ITaskUploaderRemoveParams,
	ITaskUploaderUploadParams,
	ITaskUploderMaterial,
} from './types'
import Queries from 'modules/common/queries'
import { LocalizedError } from '@libs/localization'
import noop from 'lodash/noop'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import i18n from 'i18next'

interface HostProps {
	user: IUser
	invalidateTaskQueries: () => void
	s3Bucket: string
}

const asyncNoop = async () => {
	/* noop */
}

const Context = createContext<ITaskUploaderContextValue>({
	state: { entries: [], uploaderVisible: false },
	initialize: asyncNoop,
	upload: asyncNoop,
	remove: asyncNoop,
	seal: asyncNoop,
	hideIfNotActive: noop,
})

const STORAGE_KEY = 'tasks.uploader.pinned'

function loadTasksFromStorage(): IDatahubTask[] {
	try {
		const tasks = JSON.parse(localStorage.getItem(STORAGE_KEY) as string)
		return Array.isArray(tasks) ? tasks : []
	} catch (err) {
		return []
	}
}

function saveTasksToStorage(tasks: IDatahubTask[]) {
	localStorage.setItem(STORAGE_KEY, JSON.stringify(tasks))
}

class Host extends Component<HostProps, ITaskUploaderContextValue['state']> implements ITaskUploaderContextValue {
	constructor(props: HostProps) {
		super(props)
		this.state = {
			entries: [],
			uploaderVisible: false,
		}
	}

	async initialize(task: IDatahubTask) {
		if (!this.state.entries.find(candidate => candidate.task.id === task.id)) {
			this.setState(
				prevState => ({
					entries: prevState.entries.concat({
						task,
						material: [],
					}),
				}),
				async () => {
					const material = await MaterialApi.getUploadedMaterialForOrderLine(task.orderlineId)

					this.setState(prevState => ({
						entries: prevState.entries.map(entry =>
							entry.task.id === task.id
								? {
										task,
										material:
											material?.map(attrs => ({
												...attrs,
												status: 'done',
											})) || [],
								  }
								: entry
						),
					}))
				}
			)
		}
	}

	hideIfNotActive() {
		if (!this.state.entries.some(({ material }) => material.some(({ status }) => status !== 'done'))) {
			this.setState({
				uploaderVisible: false,
			})
		}
	}

	async upload({ taskId, materialFile, materialUuid, hasSameName }: ITaskUploaderUploadParams) {
		const task = this.state.entries.find(entry => entry.task.id === taskId)?.task

		if (!task) {
			throw new LocalizedError('modals.uploader.taskNotInitializedMessage', { taskId })
		}

		const materialName = `${hasSameName ? `${uuidv4()}-` : ''}${materialFile.name.replace(/[^\w.-_]/gi, '')}`
		const ext = materialName.substring(materialName.lastIndexOf('.') + 1)

		const s3Key = getS3TaskUploadTempPath({ task, fileUuid: materialUuid, fileExtension: ext })
		const uploadMaterial: ITaskUploderMaterial = {
			id: materialUuid,
			fileName: materialName,
			orderlineId: task.orderId,
			url: '',
			status: 'uploading',
		}

		this.setState(prevState => ({
			entries: prevState.entries.map(entry =>
				entry.task.id === taskId
					? {
							...entry,
							material: entry.material.concat(uploadMaterial),
					  }
					: entry
			),
			uploaderVisible: true,
		}))

		try {
			const { preSignedUrl } = await S3Api.signS3Upload({
				expiration: dayjs().add(2, 'minute').toISOString(),
				filePath: s3Key,
			})

			const fileUrl = preSignedUrl.split('?')[0]

			await S3Api.s3Upload({
				preSignedUrl,
				file: materialFile,
				onProgress: percent =>
					this.setState(prevState => ({
						entries: prevState.entries.map(entry =>
							entry.task.id === taskId
								? {
										...entry,
										material: entry.material.map(material =>
											material.id === materialUuid
												? {
														...material,
														url: fileUrl,
														percent,
												  }
												: material
										),
								  }
								: entry
						),
					})),
			})

			const uploadedFileResponse = await fetch(fileUrl)
			const uploadedFile = await uploadedFileResponse.blob()

			if (!uploadedFile.size) {
				throw new Error(i18n.t('modals.uploader.error'))
			}

			await MaterialApi.registerBatchMaterial({
				orderlineId: task.orderlineId,
				fileName: materialFile.name,
				url: fileUrl,
			})

			this.setState(
				prevState => ({
					entries: prevState.entries.map(entry =>
						entry.task.id === taskId
							? {
									...entry,
									material: entry.material.map(material =>
										material.id === materialUuid
											? {
													...material,
													status: 'done',
											  }
											: material
									),
							  }
							: entry
					),
				}),
				this.hideIfNotActive
			)
		} catch (err) {
			console.log('err', err)
			this.setState(prevState => ({
				entries: prevState.entries.map(entry =>
					entry.task.id === taskId
						? {
								...entry,
								material: entry.material.map(material =>
									material.id === materialUuid
										? {
												...material,
												status: 'error',
												error: err as Error,
										  }
										: material
								),
						  }
						: entry
				),
			}))
		}
	}

	async remove({ taskId, materialUuid }: ITaskUploaderRemoveParams) {
		const material = this.state.entries
			.find(entry => entry.task.id === taskId)
			?.material.find(candidate => candidate.id === materialUuid)

		if (!material) {
			throw new LocalizedError('modals.uploader.materialDoesNotExistMessage', { materialId: materialUuid })
		}

		this.setState(
			{
				uploaderVisible: true,
			},
			async () => {
				if (material.status === 'done') {
					await MaterialApi.removeBatchMaterial({ materialId: material.id })
				}

				this.setState(
					prevState => ({
						entries: prevState.entries.map(entry =>
							entry.task.id === taskId
								? {
										...entry,
										material: entry.material.filter(candidate => candidate.id !== materialUuid),
								  }
								: entry
						),
					}),
					this.hideIfNotActive
				)
			}
		)
	}

	async seal(taskId: IDatahubTask['id']) {
		const foundEntry = this.state.entries.find(entry => entry.task.id === taskId)

		if (!foundEntry?.task) {
			throw new LocalizedError('modals.uploader.taskNotInitializedMessage', { taskId })
		}

		await MaterialApi.sealBatchUploadedMaterial({
			orderlineId: foundEntry.task.orderlineId,
			uploads: foundEntry.material.map(({ fileName, url }) => ({
				name: fileName,
				url,
			})),
		})

		// Remove from storage
		saveTasksToStorage(this.state.entries.map(entry => entry.task).filter(candidate => candidate.id !== taskId))

		this.setState(prevState => ({
			entries: prevState.entries.filter(entry => entry.task.id !== taskId),
		}))

		// Invalidate tasks list query
		this.props.invalidateTaskQueries()
	}

	componentDidMount() {
		// Load tasks from storage
		const tasks = loadTasksFromStorage()
		Promise.all(tasks.map(({ orderlineId }) => MaterialApi.getUploadedMaterialForOrderLine(orderlineId))).then(
			material => {
				this.setState({
					entries: tasks.map((task, index) => ({
						task,
						material:
							material[index]?.map(attrs => ({
								...attrs,
								status: 'done',
							})) || [],
					})),
				})
			}
		)
	}

	render() {
		return (
			<Context.Provider
				value={{
					state: this.state,
					initialize: this.initialize.bind(this),
					upload: this.upload.bind(this),
					remove: this.remove.bind(this),
					seal: this.seal.bind(this),
					hideIfNotActive: this.hideIfNotActive.bind(this),
				}}
			>
				{this.props.children}
			</Context.Provider>
		)
	}
}

export const TaskUploaderProvider: FC = ({ children }) => {
	const { user } = useAuth()
	const { data: s3Bucket } = useQuery(Queries.s3Credentials, S3Api.getS3Bucket)
	const invalidateTaskQueries = useTasksQueriesInvalidation()

	return user && s3Bucket ? (
		<Host user={user} invalidateTaskQueries={invalidateTaskQueries} s3Bucket={s3Bucket}>
			{children}
		</Host>
	) : (
		<Fragment>{children}</Fragment>
	)
}

export function useTaskUploaderContext() {
	return useContext(Context)
}
