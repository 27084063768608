import React, { useMemo, useEffect } from 'react'
import { Row, Col, Steps } from 'antd'
import NewOrderStepOne from '../NewOrderStepOne'
import NewOrderStepTwo from '../NewOrderStepTwo'
import NewOrderStepThree from '../NewOrderStepThree'
import { useNewOrderContext } from '../context'
import { useLocalization } from '@libs/localization'
import { useNewOrderNavigation } from '../hooks'
import Styled from './Styled'
import appRoutes from 'app.routes'
import { useRouter } from 'next/router'
import { Skeleton } from '@libs/components'
import { useScreenMatch } from '@libs/theme'

const NewOrderSteps = () => {
	const [{ step, orderResponse, orderId, case: caseObj }] = useNewOrderContext()
	const { t } = useLocalization()
	const { onStepSelect, checkIfStepIsDisabled } = useNewOrderNavigation()
	const router = useRouter()
	const match = useScreenMatch()

	useEffect(() => {
		if (step === 3 && orderResponse && orderId && caseObj?.id)
			router.push(
				appRoutes.receipt({
					orderId,
					caseId: caseObj.id,
				})
			)
	}, [step])

	const steps = useMemo(
		() => [
			{
				title: t('orders.pages.new.stepOne.title'),
				content: <NewOrderStepOne />,
			},
			{
				title: t('orders.pages.new.stepTwo.title'),
				content: <NewOrderStepTwo />,
			},
			{
				title: t('orders.pages.new.stepThree.title'),
				content: <NewOrderStepThree />,
			},
		],
		[t]
	)

	return (
		<>
			<Row>
				<Col
					span={24}
					xxl={{
						offset: 4,
						span: 16,
					}}
				>
					<Styled.Container display={'flex'} justifyContent={'center'} margin={{ bottom: 'xl' }}>
						<Steps current={step} onChange={onStepSelect}>
							{steps.map(({ title }, index) => (
								<Steps.Step key={title} title={title} disabled={checkIfStepIsDisabled(index)} />
							))}
						</Steps>
					</Styled.Container>
				</Col>
			</Row>
			{typeof step === 'number' && step < 3 ? (
				steps[step].content
			) : (
				<Skeleton.Cards gutter={'sm'} size={'small'} span={match.map({ xxs: 24, xxl: 12 })} items={2} />
			)}
		</>
	)
}

export default NewOrderSteps
