import { Container, Grid } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { Typography } from 'antd'
import React, { FC, useMemo, useState } from 'react'
import CollageEditor from '../CollageEditor'
import { ISimplifiedVisualMaterial } from '@libs/api'
import { useFormikContext } from 'formik'
import { DndProvider, useDrag } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import { ICollageFormValues } from '.'
import { COLLAGE_TEMPLATES } from '../CollageEditor/consts'
import MaterialItemThumbnail from '../MaterialItem/MaterialItemThumbnail'
import { useScreenMatch } from '@libs/theme'
import { isTabletOrMobile, isIOSDevice } from '@libs/util'
import Styled from './Styled'

const dndOptions = {
	delayTouchStart: 200,
	ignoreContextMenu: true,
}

export interface CollageEditingStepProps {
	materials: ISimplifiedVisualMaterial[]
}

let closureValues: ICollageFormValues

const Draggable = (material: ISimplifiedVisualMaterial) => {
	const [, drag] = useDrag(() => ({
		type: 'asset',
		item: material,
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
	}))
	const tabletOrMobile = useMemo(() => isTabletOrMobile(), [])

	return (
		<div
			ref={drag}
			style={{
				cursor: 'move',
			}}
		>
			{/* disabled tooltip */}
			<MaterialItemThumbnail {...material} fileName={undefined} tags={[]} />
			{tabletOrMobile && <Styled.ImageBlocker />}
		</div>
	)
}

const CollageEditingStep: FC<CollageEditingStepProps> = ({ materials = [] }) => {
	const { t } = useLocalization()
	const { values, setFieldValue, isSubmitting } = useFormikContext<ICollageFormValues>()
	const [templateContainerHeight, setTemplateContainerHeight] = useState<number>()
	const match = useScreenMatch()
	closureValues = values

	const onCollageItemChange = (dataUrl: string, index: number) => {
		setFieldValue(
			'outputImages',
			closureValues.outputImages.map((item, innerIndex) => (index === innerIndex ? dataUrl : item))
		)
	}

	const onChangeSource = (imageUrl: string, index: number) => {
		setFieldValue(
			'images',
			closureValues.images.map((item, innerIndex) => (index === innerIndex ? imageUrl : item))
		)
	}

	const selectedTemplate = useMemo(() => COLLAGE_TEMPLATES[values.template || 0], [values.template])
	const isReducedSizes = useMemo(
		() => selectedTemplate.layout === 'vertical' && match.gte('lg'),
		[selectedTemplate, match]
	)

	const tabletOrMobile = useMemo(() => isTabletOrMobile(), [])
	const isIOS = useMemo(() => isIOSDevice(), [])

	return (
		<DndProvider backend={tabletOrMobile && !isIOS ? TouchBackend : HTML5Backend} options={dndOptions}>
			<Container space="md" spaceDirection="vertical">
				<Typography.Title level={5}>{t('modals.collage.editStep.subtitle')}</Typography.Title>

				<Grid.Row gutter="lg">
					<Grid.Col xs={24} lg={isReducedSizes ? 6 : 12}>
						<CollageEditor
							images={values.images}
							onChangeSource={onChangeSource}
							onChangeOutput={onCollageItemChange}
							disabled={isSubmitting}
							setTemplateContainerHeight={setTemplateContainerHeight}
							{...selectedTemplate}
						/>
					</Grid.Col>
					<Grid.Col
						xs={24}
						lg={isReducedSizes ? 18 : 12}
						style={{
							overflow: 'auto',
							maxHeight: templateContainerHeight
								? match.gte('lg')
									? templateContainerHeight
									: 'calc(100vh - 420px)'
								: 'auto',
						}}
					>
						<Grid.Row gutter="xs">
							{materials.map(material => (
								<Grid.Col key={material.id} xs={isReducedSizes ? 6 : 8}>
									<Draggable {...material} />
								</Grid.Col>
							))}
						</Grid.Row>
					</Grid.Col>
				</Grid.Row>
			</Container>
		</DndProvider>
	)
}

export default CollageEditingStep
