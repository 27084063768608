import React, { FC } from 'react'
import { useInputForSpeak, useNoteSubmitter } from 'modules/tasks/hooks'
import { TasksApi } from '@libs/api'
import { Autolocalize, Container, FormikTextArea, useHideModal, UserInteraction } from '@libs/components'
import { Button, Modal, Space, Spin, Typography } from 'antd'
import { Form, FormItem } from 'formik-antd'
import { Formik } from 'formik'
import { useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { Close24 } from '@carbon/icons-react'
import TaskModalTitle from 'modules/common/TaskModalTitle'

export interface TaskIntroOutroModalProps extends TaskModalCommonProps {
	speak?: boolean
}

interface IIntroOutroForm {
	noteText: string
	person: string
}

const TaskIntroOutroModal: FC<TaskIntroOutroModalProps> = ({ task, speak, onTaskComplete, onTaskCancel }) => {
	const hideModal = useHideModal()
	const { submit, isLoading: isSubmitting } = useNoteSubmitter(task)
	const isSpeakModal = !!speak
	let isLoading = false
	let isTextLoading = false
	let inputForSpeak = ''
	let prefilledText = ''

	const inputForSpeakQuery = useInputForSpeak(task.caseId)

	const prefilledTextQuery = useQuery(
		[QUERIES.prefilledText, task.caseId],
		async () => {
			const groupedTexts = await TasksApi.getPrefilledText(task.caseId)
			return groupedTexts
				.reduce(
					(memo, next) =>
						memo +
						' ' +
						next.texts
							.reduce((textMemo, textNext) => (textNext.content ? `${textMemo} ${textNext.content}` : textMemo), '')
							.trim(),
					''
				)
				.trim()
		},
		{
			enabled: isSpeakModal,
		}
	)

	if (isSpeakModal) {
		inputForSpeak = inputForSpeakQuery?.data || ''
		prefilledText = prefilledTextQuery?.data || ''
		isTextLoading = inputForSpeakQuery.isLoading || prefilledTextQuery.isLoading
		isLoading = isTextLoading || isSubmitting
	} else {
		isLoading = isSubmitting
	}

	const { t } = useLocalization()

	const inputArea = <FormikTextArea name="noteText" autoSize={{ minRows: 2, maxRows: 4 }} size="small" />

	const speakInputArea = (
		<FormItem name="noteText" label={t('modals.introOutro.inputForSpeechLabel')} required>
			{isTextLoading ? <Spin>{inputArea}</Spin> : inputArea}
		</FormItem>
	)

	const onSubmit = async (data: IIntroOutroForm) => {
		if (await submit(data.noteText, { person: data.person })) {
			if (onTaskComplete) {
				onTaskComplete(task)
				return
			}

			hideModal()
		}
	}

	const initialValues: IIntroOutroForm = {
		noteText: inputForSpeak || prefilledText || '',
		person: '',
	}

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			{({ values, isSubmitting: isSubmittingForm }) => (
				<Modal
					closeIcon={<Close24 />}
					visible
					title={<TaskModalTitle title={t('modals.introOutro.title')} caseId={task.caseReference} />}
					onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
					footer={
						<Button
							form="task-intro-outro-form"
							htmlType="submit"
							type="primary"
							loading={isLoading}
							disabled={isSpeakModal && values.noteText.length === 0}
						>
							{t('common.actions.completeTask')}
						</Button>
					}
				>
					<UserInteraction enabled={!isSubmittingForm}>
						<Form id="task-intro-outro-form" layout="vertical">
							<Autolocalize />
							<Space direction="vertical" size="middle">
								{isSpeakModal ? (
									speakInputArea
								) : (
									<Container
										display="flex"
										flexDirection="column"
										alignItems="center"
										padding={{ bottom: 'xxl', top: 'xl' }}
									>
										<Typography.Text>{values.noteText || t('modals.introOutro.emptyText')}</Typography.Text>
									</Container>
								)}
							</Space>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default TaskIntroOutroModal
