import React, { FC } from 'react'
import NewTasksList from 'modules/tasks/NewTasksList'
import { IDatahubTask } from '@libs/api'
import { Close24 } from '@carbon/icons-react'
import { Modal, Typography } from 'antd'
import { useHideModal, Container } from '@libs/components'
import { useLocalization } from '@libs/localization'

export interface NewTasksModalProps {
	tasks: IDatahubTask[]
}

const NewTasksModal: FC<NewTasksModalProps> = ({ tasks }) => {
	const hideModal = useHideModal()
	const { t } = useLocalization()

	return (
		<Modal
			visible
			closeIcon={<Close24 />}
			title={t('modals.addExtraOrderLine.orderUpdateSuccess')}
			onCancel={hideModal}
			footer={null}
		>
			<Container space={'lg'} spaceDirection={'vertical'}>
				<Typography.Paragraph>{t('orders.pages.new.receipt.tasksInstructions')}</Typography.Paragraph>
				<NewTasksList tasks={tasks} modal />
			</Container>
		</Modal>
	)
}

export default NewTasksModal
