import React, { FC, useState } from 'react'
import { ISimplifiedVisualMaterial } from '@libs/api'
import { DEFAULT_IMG_SOURCE } from '../MaterialItem'
import { CarouselModal } from '@libs/components'
import { CarouselModalSlideType } from '@libs/components/build/Modals/CarouselModal/types'

export interface MaterialCarouselModalProps {
	visualMaterials: ISimplifiedVisualMaterial[]
	initialMaterialId: ISimplifiedVisualMaterial['id']
}

const { Slide } = CarouselModal

const MaterialCarouselModal: FC<MaterialCarouselModalProps> = ({ visualMaterials, initialMaterialId }) => {
	const [current, setCurrent] = useState(() => visualMaterials.findIndex(({ id }) => id === initialMaterialId))

	return (
		<CarouselModal current={current} onChange={setCurrent}>
			{visualMaterials.map(({ source, sourceType }, index) => {
				// pre-render only current, previous and next
				if ((current >= index - 1 && current <= index + 1) || (current === 0 && index === visualMaterials.length - 1)) {
					return (
						<Slide
							key={index}
							type={sourceType.toLowerCase() as CarouselModalSlideType}
							source={source || DEFAULT_IMG_SOURCE}
							fallbackImageSource={DEFAULT_IMG_SOURCE}
						/>
					)
				}
				return <div key={index} />
			})}
		</CarouselModal>
	)
}

export default MaterialCarouselModal
