import React, { FC, SyntheticEvent, useCallback } from 'react'
import { Typography } from 'antd'
import { Container, Grid } from '@libs/components'
import Styled from './Styled'
import { ISimplifiedVisualMaterial, IVirtualStaging } from '@libs/api'
import { DEFAULT_IMG_SOURCE } from 'modules/material/MaterialItem'
import { useLocalization } from '@libs/localization'
import camelCase from 'lodash/camelCase'
import { useScreenMatch } from '@libs/theme'

export interface VirtualStagingSummaryProps {
	visualMaterial: ISimplifiedVisualMaterial
	selectedRoomType?: IVirtualStaging['roomTypeName']
	selectedInterior?: IVirtualStaging['interiorOptions'][number]['name']
	label?: string
}

const VirtualStagingSummary: FC<VirtualStagingSummaryProps> = ({
	visualMaterial,
	selectedRoomType,
	selectedInterior,
	label,
}) => {
	const onImageLoadError = useCallback(
		(event: SyntheticEvent<HTMLImageElement, Event>) => {
			const target = event.target as HTMLImageElement
			if (target.src !== visualMaterial.thumbnailUrl) {
				target.src = visualMaterial.thumbnailUrl || DEFAULT_IMG_SOURCE
			} else {
				target.src = DEFAULT_IMG_SOURCE
			}
		},
		[visualMaterial.thumbnailUrl]
	)

	const { t } = useLocalization()
	const match = useScreenMatch()

	return (
		<>
			<Styled.Col xs={24} lg={7}>
				<Typography.Title level={5}>{t('modals.virtualStaging.summaryStep.yourChoiceText')}</Typography.Title>
				<Styled.Wrapper>
					<Styled.ImageWrapper>
						<img
							src={visualMaterial.source || visualMaterial.thumbnailUrl}
							onError={onImageLoadError}
							alt="Material image"
						/>
						{label && <Styled.LabelContainer>{label}</Styled.LabelContainer>}
					</Styled.ImageWrapper>
					{selectedRoomType && (
						<Container
							margin={{ top: 'xxs' }}
							display="flex"
							flexDirection="column"
							space={'xxs'}
							spaceDirection={'vertical'}
						>
							<Container display="flex" justifyContent="space-between">
								<Typography.Text strong>{t('common.terms.type')}</Typography.Text>
								<Styled.RightAlignedText>
									{t(`materials.roomTypes.${camelCase(selectedRoomType)}`)}
								</Styled.RightAlignedText>
							</Container>
							{selectedInterior && (
								<Container display="flex" justifyContent="space-between">
									<Typography.Text strong>
										{t('modals.virtualStaging.summaryStep.selectedInteriorText')}
									</Typography.Text>
									<Styled.RightAlignedText>{selectedInterior}</Styled.RightAlignedText>
								</Container>
							)}
							{label && (
								<Container display="flex" justifyContent="space-between">
									<Typography.Text strong>{t('modals.virtualStaging.summaryStep.desiredLabelText')}</Typography.Text>
									<Styled.RightAlignedText>{label}</Styled.RightAlignedText>
								</Container>
							)}
						</Container>
					)}
				</Styled.Wrapper>
			</Styled.Col>
			{match.gte('lg') && (
				<Grid.Col>
					<Styled.Divider type="vertical" />
				</Grid.Col>
			)}
		</>
	)
}

export default VirtualStagingSummary
