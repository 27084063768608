import React, { FC, ReactNode } from 'react'
import { createLayoutManager, AppLayout, BasicLayout, SideLayout } from '@libs/components'
import Styled from './Styled'
import { PageLayoutType } from '../types'
import { Carousel, Typography } from 'antd'
import Header from '../Header'
import TaskUploader from 'modules/tasks/TaskUploader'
import { useBuildId } from '../hooks'
import { useLocalization } from '@libs/localization'

const ASIDE_SLIDES = ['/img/slide1.jpg', '/img/slide2.jpg', '/img/slide3.jpg', '/img/slide4.jpg']

const BuildId: FC = () => {
	const { buildId, onBuildIdClick } = useBuildId()
	const { t } = useLocalization()

	return (
		<Styled.ClickableContainer
			display={'flex'}
			justifyContent={'center'}
			alignItems={'center'}
			space={'xxs'}
			spaceDirection={'horizontal'}
			onClick={onBuildIdClick}
		>
			<Typography.Text type={'secondary'}>{t('common.terms.buildId')}:</Typography.Text>
			<Typography.Text type={'secondary'}>{buildId}</Typography.Text>
		</Styled.ClickableContainer>
	)
}

export default createLayoutManager(
	(contentKey: string | number, type: PageLayoutType, children: ReactNode, systemMessage?: string) =>
		({
			basic: <BasicLayout>{children}</BasicLayout>,
			app: (
				<AppLayout header={<Header />} systemMessage={systemMessage}>
					{children}
					<TaskUploader />
				</AppLayout>
			),
			side: (
				<SideLayout
					asideSpan={10}
					aside={
						<Styled.CarouselContainer>
							<Carousel autoplay={true} speed={800} autoplaySpeed={6000} infinite>
								{ASIDE_SLIDES.map(src => (
									<Styled.Slide key={src} $imageSrc={src} />
								))}
							</Carousel>
						</Styled.CarouselContainer>
					}
					footer={<BuildId />}
					contentKey={contentKey}
				>
					{children}
				</SideLayout>
			),
		}[type])
)
