import React, { FC } from 'react'
import { Button } from 'antd'
import Styled from './Styled'
import { Container } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { Settings32 } from '@carbon/icons-react'

interface CustomPackageCardProps {
	disabled?: boolean
	onSelect: () => void
}

const CustomPackageCard: FC<CustomPackageCardProps> = ({ onSelect, disabled }) => {
	const { t } = useLocalization()

	return (
		<Styled.Card size="small">
			<Container flex={'1 0 100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
				<Container>
					<Styled.ImageContainer>
						<div>
							<Settings32 />
						</div>
					</Styled.ImageContainer>
					<Styled.Title
						ellipsis={{
							rows: 2,
						}}
					>
						{t('orders.pages.new.stepOne.buildYourOwnPackageTitle')}
					</Styled.Title>
				</Container>
				<Container display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
					<Button type="primary" shape="round" size="small" disabled={disabled} onClick={onSelect}>
						{t('orders.pages.new.stepOne.buildYourOwnPackageAction')}
					</Button>
				</Container>
			</Container>
		</Styled.Card>
	)
}

export default CustomPackageCard
