import React, { FC } from 'react'
import { Button, Spin, Typography } from 'antd'
import { Autolocalize, Container, Stepper, useHideModal } from '@libs/components'
import { FeedbackDrawingType } from '@libs/api'
import { Formik } from 'formik'
import { useTaskRetouchingModal } from './hooks'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { Close24 } from '@carbon/icons-react'
import TaskModalTitle from 'modules/common/TaskModalTitle'

export interface TaskRetouchingModalProps extends TaskModalCommonProps {}

export interface ITaskRetouchingFormValues {
	drawings: FeedbackDrawingType[]
}

const initialFormValues: ITaskRetouchingFormValues = {
	drawings: [],
}

const TRANSITION_TIMEOUT = 200

const TaskRetouchingModal: FC<TaskRetouchingModalProps> = ({ task, onTaskComplete, onTaskCancel }) => {
	const hideModal = useHideModal()
	const [
		{
			currentStep = 0,
			isLoading,
			steps = [],
			selectedVisualMaterial,
			innerVisualMaterials = [],
			modalWrapperRef,
			title = '',
		},
		{ onBackClick, onNextClick, onFormSubmit },
	] = useTaskRetouchingModal({ task, onTaskComplete })

	const { t } = useLocalization()

	return (
		<Formik<ITaskRetouchingFormValues> initialValues={initialFormValues} onSubmit={values => onFormSubmit?.(values)}>
			{({ submitForm, isSubmitting, values }) => {
				const hasDrawingWithoutComment = values.drawings.some(({ comment }) => !comment?.trim())

				return (
					<Styled.Modal
						visible
						closeIcon={<Close24 />}
						onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
						width="auto"
						$transitionTimeout={TRANSITION_TIMEOUT}
						title={<TaskModalTitle title={title} caseId={task.caseReference} />}
						footer={[
							<Button disabled={currentStep === 0 || isLoading} key="back" onClick={onBackClick}>
								{t('navigation.back')}
							</Button>,
							<Button
								disabled={isLoading || !selectedVisualMaterial || hasDrawingWithoutComment}
								loading={isSubmitting}
								type="primary"
								key="next"
								onClick={() => {
									currentStep === 1 ? submitForm() : onNextClick?.()
								}}
							>
								{currentStep === 1 ? t('common.actions.confirm') : t('navigation.next')}
							</Button>,
						]}
					>
						<Autolocalize />
						{isLoading ? (
							<Container margin="xxl" display={'flex'} justifyContent={'center'}>
								<Spin />
							</Container>
						) : innerVisualMaterials.length > 0 ? (
							<Stepper current={currentStep} ref={modalWrapperRef}>
								{steps.map(({ title: stepTitle, content }, index) => (
									<Stepper.Step key={index} title={stepTitle}>
										{content}
									</Stepper.Step>
								))}
							</Stepper>
						) : (
							<Container margin="xxl" display={'flex'} justifyContent={'center'}>
								<Typography.Paragraph>{t('modals.virtualStaging.emptyList')}</Typography.Paragraph>
							</Container>
						)}
					</Styled.Modal>
				)
			}}
		</Formik>
	)
}

export default TaskRetouchingModal
