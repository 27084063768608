import React, { FC } from 'react'
import { Typography } from 'antd'
import { Container, Grid } from '@libs/components'
import { IVirtualStaging } from '@libs/api'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'

export interface SelectInteriorStepProps {
	selectedRoom: IVirtualStaging
	selectInterior: (interiorName: IVirtualStaging['interiorOptions'][number]['name']) => void
}

const SelectInteriorStep: FC<SelectInteriorStepProps> = ({ children, selectedRoom, selectInterior }) => {
	const { t } = useLocalization()
	return (
		<Container>
			<Grid.Row>
				{children}
				<Grid.Col flex="1 1 0%">
					<Container padding={{ bottom: 'xxs' }}>
						<Typography.Title level={5}>{t('modals.virtualStaging.selectInteriorStep.title')}</Typography.Title>
						<Grid.Row gutter={'sm'}>
							{selectedRoom.interiorOptions.map(({ name, url, isSelected }) => (
								<Grid.Col key={name} xs={12} lg={6}>
									<Styled.InteriorCard $selected={isSelected} onClick={() => selectInterior(name)}>
										<Styled.ImageWrapper>
											<img src={url} alt={`${name} example`} />
										</Styled.ImageWrapper>
										<Typography.Text ellipsis={{ tooltip: name }}>{name}</Typography.Text>
									</Styled.InteriorCard>
								</Grid.Col>
							))}
						</Grid.Row>
					</Container>
				</Grid.Col>
			</Grid.Row>
		</Container>
	)
}

export default SelectInteriorStep
