import React, { FC, useEffect, useState } from 'react'
import { IProduct } from '@libs/api'
import Styled from './Styled'
import { Container, QuantityCounter } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { useAuth } from 'modules/auth/hooks'
import { useScreenMatch } from '@libs/theme'
import { SpacingMixinParamType } from 'styled-components'
import { useNewOrderNavigation } from '../hooks'

interface ProductCardProps {
	product: IProduct
	quantity: number
	disabled?: boolean
	onQuantityChange: (quantity: number, product: IProduct) => void
	addWarning?: boolean
	shouldAskForCopyright?: boolean
}

const FALLBACK_THUMBNAIL = '/placeholders/logo-placeholder.jpeg'

const ProductCard: FC<ProductCardProps> = props => {
	const { product, quantity, disabled, onQuantityChange, addWarning, shouldAskForCopyright = false } = props
	const [isCopyrightAsked, setIsCopyrightAsked] = useState(!shouldAskForCopyright)
	const { thumbnail, name, price, currency, description } = product
	const [shouldUseFallbackThumbnail, setShouldUseFallbackThumbnail] = useState(!thumbnail)
	const thumbnailSrc = shouldUseFallbackThumbnail ? FALLBACK_THUMBNAIL : thumbnail
	const { f, t } = useLocalization()
	const { user } = useAuth()
	const match = useScreenMatch()
	const formattedPrice = f(price || 0, { currency, decimals: 2 })
	const isPriceTooLong = match.map({ xxs: false, xxl: formattedPrice.length > 6 })
	const { showAlert } = useNewOrderNavigation()

	useEffect(() => {
		if (thumbnail) setShouldUseFallbackThumbnail(false)
	}, [thumbnail])

	return (
		<Styled.Card size="small">
			<Container
				flex={'1 0 100%'}
				display={'flex'}
				flexDirection={'column'}
				justifyContent={'space-between'}
				spaceDirection={'vertical'}
				space={addWarning ? 'xs' : 'xxs'}
			>
				<Container>
					<Styled.ImageContainer>
						<img src={thumbnailSrc} alt={name} onError={() => setShouldUseFallbackThumbnail(true)} />
					</Styled.ImageContainer>
					<Styled.Title
						ellipsis={{
							rows: 2,
						}}
						title={name}
						strong
					>
						{name}
					</Styled.Title>
					{description && (
						<Styled.Title
							ellipsis={{
								rows: 2,
							}}
							title={description}
						>
							{description}
						</Styled.Title>
					)}
					{addWarning && (
						<Styled.Note type={'danger'} $visible={false}>
							** {t('orders.pages.new.stepTwo.productCanOnlyBeOrderedSeparately')}
						</Styled.Note>
					)}
				</Container>
				<Container
					display={'flex'}
					flexDirection={match.map({ xxs: 'column', lg: 'row' })}
					alignItems={'center'}
					justifyContent={match.map({ xxs: 'flex-start', lg: 'space-between' })}
					space={match.map<SpacingMixinParamType>({ xxs: 'sm', lg: 0 })}
					spaceDirection={match.map({ xxs: 'vertical', lg: 'horizontal' })}
				>
					<Styled.Price $hyphen={isPriceTooLong}>
						{user?.visiblePrices && formattedPrice.split(' ').map(value => <span key={value}>{value}</span>)}
					</Styled.Price>
					<QuantityCounter
						disabled={disabled}
						quantity={quantity}
						maxQuantity={product.maxOrderAmount}
						onChange={newQuantity => {
							const onNext = () => {
								onQuantityChange(newQuantity, product)
							}

							if (product.showAlert && !isCopyrightAsked) {
								showAlert(() => {
									setIsCopyrightAsked(true)
									onNext()
								})
								return
							}

							onNext()
						}}
					/>
				</Container>
			</Container>
		</Styled.Card>
	)
}

export default ProductCard
