import { Close24 } from '@carbon/icons-react'
import { Container, Grid, Stepper, useHideModal, useModalScroll } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { Button, Spin, Typography } from 'antd'
import TaskModalTitle from 'modules/common/TaskModalTitle'
import MaterialList from 'modules/material/MaterialList'
import React, { FC, SyntheticEvent, useCallback, useMemo, useState } from 'react'
import { TaskModalCommonProps } from '../types'
import { useTaskBeforeAfterModalData } from './hooks'
import Styled from './Styled'

export interface TaskBeforeAfterModalProps extends TaskModalCommonProps {}

const THUMBNAIL_FALLBACK_SOURCE = '/placeholders/image-placeholder.jpeg'

const TaskBeforeAfterModal: FC<TaskBeforeAfterModalProps> = props => {
	const { t } = useLocalization()
	const { scrollTo: scrollModalTo } = useModalScroll()
	const hideModal = useHideModal()

	const onThumbnailLoadError = useCallback(
		(event: SyntheticEvent<HTMLImageElement, Event>) =>
			((event.target as HTMLImageElement).src = THUMBNAIL_FALLBACK_SOURCE),
		[]
	)

	const {
		beforeSelection,
		setBeforeSelection,
		afterSelection,
		setAfterSelection,
		materials,
		materialBeforePool,
		materialAfterPool,
		areMaterialsLoading,
		completeTask,
		isCompletingTask,
	} = useTaskBeforeAfterModalData(props)

	const [currentStep, setCurrentStep] = useState(0)
	const isNextStepDisabled = (currentStep === 0 && !beforeSelection) || (currentStep === 1 && !afterSelection)

	const steps = useMemo(
		() => [
			{
				title: t('modals.beforeAfter.beforeStepTitle'),
				content: (
					<Container>
						<MaterialList
							disabledInactiveOverlay
							visualMaterials={materialBeforePool.map(material =>
								material === beforeSelection
									? {
											...material,
											isSelected: true,
									  }
									: material
							)}
							axis="xy"
							showAll
							onItemClick={materialId =>
								setBeforeSelection(materialBeforePool.find(candidate => candidate.id === materialId))
							}
						/>
					</Container>
				),
			},
			{
				title: t('modals.beforeAfter.afterStepTitle'),
				content: (
					<Container>
						<MaterialList
							disabledInactiveOverlay
							visualMaterials={materialAfterPool.map(material =>
								material === afterSelection
									? {
											...material,
											isSelected: true,
									  }
									: material
							)}
							showAll
							axis="xy"
							onItemClick={materialId =>
								setAfterSelection(materialAfterPool.find(candidate => candidate.id === materialId))
							}
						/>
					</Container>
				),
			},
			{
				title: t('modals.beforeAfter.overviewStepTitle'),
				content: (
					<Container padding={{ bottom: 'xxl' }}>
						<Grid.Row>
							<Grid.Col xs={24} md={{ span: 10, offset: 2 }} lg={{ span: 8, offset: 4 }}>
								{beforeSelection && (
									<Container
										display={'flex'}
										flexDirection={'column'}
										alignItems={'center'}
										space={'sm'}
										spaceDirection={'vertical'}
									>
										<Typography.Text strong>{t('modals.beforeAfter.beforeSelection')}</Typography.Text>
										<Styled.ImagePreview
											src={beforeSelection.source ?? THUMBNAIL_FALLBACK_SOURCE}
											alt={'before selection'}
											onError={onThumbnailLoadError}
										/>
									</Container>
								)}
							</Grid.Col>
							<Grid.Col xs={24} md={{ span: 10 }} lg={8}>
								{afterSelection && (
									<Container
										display={'flex'}
										flexDirection={'column'}
										alignItems={'center'}
										space={'sm'}
										spaceDirection={'vertical'}
									>
										<Typography.Text strong>{t('modals.beforeAfter.afterSelection')}</Typography.Text>
										<Styled.ImagePreview
											src={afterSelection.source ?? THUMBNAIL_FALLBACK_SOURCE}
											alt={'before selection'}
											onError={onThumbnailLoadError}
										/>
									</Container>
								)}
							</Grid.Col>
						</Grid.Row>
					</Container>
				),
			},
		],
		[
			materialBeforePool,
			materialAfterPool,
			beforeSelection,
			afterSelection,
			setBeforeSelection,
			setAfterSelection,
			t,
			onThumbnailLoadError,
		]
	)

	return (
		<Styled.Modal
			visible
			closeIcon={<Close24 />}
			onCancel={props.onTaskCancel ? () => props.onTaskCancel?.(props.task) : hideModal}
			width="auto"
			title={<TaskModalTitle title={t('modals.beforeAfter.title')} caseId={props.task.caseReference} />}
			footer={[
				<Button
					disabled={isCompletingTask || currentStep === 0}
					key="back"
					onClick={() => setCurrentStep(prevCurrentStep => prevCurrentStep - 1)}
				>
					{t('navigation.back')}
				</Button>,
				<Button
					disabled={isNextStepDisabled || areMaterialsLoading}
					loading={isCompletingTask}
					type="primary"
					key="next"
					onClick={() =>
						currentStep === steps.length - 1 ? completeTask() : setCurrentStep(prevCurrentStep => prevCurrentStep + 1)
					}
				>
					{currentStep === steps.length - 1 ? t('common.actions.confirm') : t('navigation.next')}
				</Button>,
			]}
		>
			{areMaterialsLoading ? (
				<Container margin="xxl" display={'flex'} justifyContent={'center'}>
					<Spin />
				</Container>
			) : materials.length >= 2 ? (
				<Stepper current={currentStep} onTransitionComplete={() => scrollModalTo(0)}>
					{steps.map(({ title, content }, index) => (
						<Stepper.Step key={index} title={title}>
							{content}
						</Stepper.Step>
					))}
				</Stepper>
			) : (
				<Container margin="xxl" display={'flex'} justifyContent={'center'}>
					<Typography.Paragraph>{t('modals.beforeAfter.notEnoughMaterials')}</Typography.Paragraph>
				</Container>
			)}
		</Styled.Modal>
	)
}

export default TaskBeforeAfterModal
