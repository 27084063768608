import { IPackage, IProduct } from '@libs/api'
import { Container, Sticky } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { Button, Card, Typography } from 'antd'
import { useAuth } from 'modules/auth/hooks'
import BackAnchor from 'modules/common/BackAnchor'
import React, { FC, useMemo } from 'react'
import { useTheme } from 'styled-components'
import { useNewOrderContext } from '../context'
import { useNewOrderNavigation } from '../hooks'
import Styled from './Styled'

export interface IOrderSummaryOption {
	name: string
	amount: number
	price: number
}

interface OrderSummaryProps {
	loading?: boolean
	disabled?: boolean
	sticky?: boolean
	onContinue: () => void
	submitBtnText?: string
}

const OrderSummary: FC<OrderSummaryProps> = ({ loading, disabled, sticky, onContinue, submitBtnText: buttonText }) => {
	const { f, t } = useLocalization()
	const theme = useTheme()
	const { user } = useAuth()
	const stickyOffsetTop = theme.heights.xxl + theme.paddings.xl // as defined in NewOrderPageContent -> padding vertical (+ header height)
	const stickyOffsetBottom = theme.paddings.xl
	const { onBack } = useNewOrderNavigation()
	const submitBtnText = buttonText || t('common.terms.continue')

	const [{ package: pkg, products, step }] = useNewOrderContext()

	const packageBasePrice = useMemo(() => {
		let total = 0
		pkg?.products.forEach(product => {
			total += product.price || 0
		})
		pkg?.packages?.forEach(item => {
			total += item.basePrice
		})
		return total
	}, [pkg])

	const totalPrice = useMemo<number>(() => {
		const mainPrice =
			packageBasePrice +
			(pkg?.choices?.reduce((acc, choice) => {
				const selectedProduct = choice.products.find(({ id }) => id === choice.selectedProductId)
				if (selectedProduct) {
					return acc + (selectedProduct?.price || 0)
				}

				const selectedPackage = choice.packages?.find(({ id }) => id === choice.selectedPackageId)
				return acc + (selectedPackage?.totalPrice || 0)
			}, 0) || 0)

		return mainPrice + (products?.reduce((acc, option) => acc + (option.price || 0) * option.quantity, 0) || 0)
	}, [pkg, products, packageBasePrice])

	const choices = useMemo(
		() =>
			pkg?.choices?.reduce(
				(acc, { selectedProductId, selectedPackageId, packages: packageOptions, products: productOptions }) => {
					const selectedPackage = packageOptions?.find(({ id }) => id === selectedPackageId)
					if (selectedPackage) {
						acc.push(selectedPackage as any)
						return acc
					}
					const selectedProduct = productOptions.find(({ id }) => id === selectedProductId)
					if (selectedProduct) {
						acc.push(selectedProduct as any)
					}

					return acc
				},
				[] as IProduct[] | IPackage[]
			),
		[pkg]
	)

	const isOrderEmpty = !pkg && !products?.length

	return (
		<Sticky
			enabled={sticky}
			offsetTop={stickyOffsetTop}
			offsetBottom={stickyOffsetBottom}
			targetSelector={'.ant-card-body'}
		>
			<Card size={'small'} title={sticky && <Styled.CardTitle>{t('orders.pages.new.summary.title')}</Styled.CardTitle>}>
				<Container
					display={'flex'}
					flexDirection={'column'}
					alignContent={'stretch'}
					space={'xl'}
					spaceDirection={'vertical'}
				>
					<Container space={'sm'} spaceDirection={'vertical'}>
						<Typography.Title level={3}>{t('orders.pages.new.summary.title')}</Typography.Title>
						{pkg && (
							<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
								<Typography.Text type={'secondary'}>{t('common.terms.package')}</Typography.Text>
								<Styled.OrderLine>
									<Container flex={1}>
										<Typography.Text strong>{pkg.name}</Typography.Text>
									</Container>
									<Container margin={{ left: 'xxs' }}>
										{user?.visiblePrices && (
											<Styled.Price strong>
												{f(packageBasePrice, {
													currency: pkg.currency || pkg.products?.[0].currency || 'DKK',
													decimals: 2,
												})}
											</Styled.Price>
										)}
									</Container>
								</Styled.OrderLine>
								{choices?.map((product: IPackage | IProduct) => (
									<Styled.OrderLine key={product.id}>
										<Container flex={1}>
											<Typography.Text strong>{product.name}</Typography.Text>
										</Container>
										<Container margin={{ left: 'xxs' }}>
											{user?.visiblePrices && (
												<Styled.Price strong>
													{f(('totalPrice' in product ? product.totalPrice : product.price) || 0, {
														currency: pkg?.currency || product.currency || 'DKK',
														decimals: 2,
													})}
												</Styled.Price>
											)}
										</Container>
									</Styled.OrderLine>
								))}
							</Container>
						)}
						{!!products?.length && (
							<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
								<Typography.Text type={'secondary'}>{t('common.terms.options')}</Typography.Text>
								{products?.map((option, index) => (
									<Styled.OrderLine key={index}>
										<Container display={'flex'} flex={1}>
											<Container display={'flex'} flex={'50px 0 0'}>
												<Typography.Text strong ellipsis>
													{option.quantity}
												</Typography.Text>
												<Typography.Text strong>&nbsp;x</Typography.Text>
											</Container>
											<Typography.Text strong>{option.name}</Typography.Text>
										</Container>
										<Container flex={0} margin={{ left: 'xxs' }}>
											{user?.visiblePrices && (
												<Styled.Price strong>
													{f((option.price || 0) * option.quantity, {
														currency: pkg?.currency || option.currency || 'DKK',
														decimals: 2,
													})}
												</Styled.Price>
											)}
										</Container>
									</Styled.OrderLine>
								))}
							</Container>
						)}
						{isOrderEmpty && <Typography.Text>{t('orders.pages.new.summary.empty')}</Typography.Text>}
					</Container>
					{!isOrderEmpty && user?.visiblePrices && (
						<Container display={'flex'} justifyContent={'flex-end'} space={'sm'} spaceDirection={'horizontal'}>
							<Typography.Text strong>{t('common.terms.totalPrice')}</Typography.Text>
							<Styled.Price strong>
								{f(totalPrice, {
									currency: pkg?.currency || pkg?.products?.[0].currency || products?.[0]?.currency || 'DKK',
									decimals: 2,
								})}
							</Styled.Price>
						</Container>
					)}
					<Button
						type={'primary'}
						shape={'round'}
						loading={loading}
						disabled={disabled || isOrderEmpty}
						onClick={onContinue}
					>
						{submitBtnText}
					</Button>
					{(step === 1 || step === 2) && (
						<Container display="flex" justifyContent="center">
							<BackAnchor onBack={onBack} title={t('orders.pages.new.previousStep')} />
						</Container>
					)}
				</Container>
			</Card>
		</Sticky>
	)
}

export default OrderSummary
