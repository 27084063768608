import { useEffect, useMemo, useState, useCallback } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useCaseById, useSimplifiedCaseOrders } from '../hooks'
import QUERIES from 'modules/common/queries'
import {
	OrdersApi,
	UsersApi,
	isEsoftUser,
	IUserBranchAccess,
	IDatahubCase,
	ISimplifiedOrder,
	OrderStatus,
	IDatahubOrderLine,
} from '@libs/api'
import { useLocalization } from '@libs/localization'
import { message } from 'antd'
import { useUsersBranchById } from 'modules/branches/hooks'
import { IDatahubWatcherDto } from '@libs/api/build/users/dtos'
import { useBookingInfo, useOrderById } from 'modules/orders/hooks'

export function useOrderLines(orderId?: ISimplifiedOrder['id']) {
	return useQuery(
		[QUERIES.orderLines, orderId],
		async () => (orderId ? await OrdersApi.getOrderLines(orderId) : undefined),
		{ enabled: !!orderId }
	)
}

export function useOrderLineById({
	orderId,
	orderlineId,
}: {
	orderId?: ISimplifiedOrder['id']
	orderlineId?: IDatahubOrderLine['id']
}) {
	const query = useOrderLines(orderId)

	return {
		...query,
		data: useMemo(() => query.data?.find(({ id }) => id === orderlineId), [query.data, orderlineId]),
	}
}

export function useCaseOrderDetailsData({
	caseId,
	orderId,
}: {
	caseId: IDatahubCase['id']
	orderId: ISimplifiedOrder['id']
}) {
	const { f, t } = useLocalization()
	const {
		data: orders = [],
		isLoading: areOrdersLoading,
		isFetched: areOrdersFetched,
	} = useSimplifiedCaseOrders(caseId)
	const { data: foundCase, isLoading: isCaseLoading } = useCaseById(caseId)
	const { data: bookingInfo, isLoading: isBookingInfoLoading } = useBookingInfo(orderId)
	const [selectedOrderId, setSelectedOrderId] = useState<string>(orderId)
	const { data: orderLines = [], isLoading: areOrderLinesLoading } = useOrderLines(selectedOrderId)
	const { data: initialOrder } = useOrderById(orderId)

	const queryClient = useQueryClient()

	useEffect(() => {
		initialOrder && setSelectedOrderId(initialOrder.id)
	}, [initialOrder])

	const selectedOrder = useMemo(() => orders.find(({ id }) => id === selectedOrderId), [orders, selectedOrderId])

	useEffect(() => {
		selectedOrder && setSelectedOrderId(prevSelecteOrderId => prevSelecteOrderId || selectedOrder.id)
	}, [selectedOrder])

	const ordersOptions = useMemo(
		() =>
			orders.map(({ salesId: orderSalesId, createdDate }) => ({
				label: `${orderSalesId} (${f(createdDate, { date: 'short' })})`,
				value: orderSalesId,
			})),
		[orders, f]
	)

	const { data: orderWatchers = [], isFetched: areWatchersFetched } = useQuery(
		[QUERIES.orderWatchers, orderId],
		async () => (orderId ? await OrdersApi.getOrderWatchers(orderId) : undefined),
		{
			enabled: !!orderId,
		}
	)

	const foundBranch = useUsersBranchById(foundCase?.departmentId)

	const { data: departmentMembers = [], isLoading: areDepartmentMembersLoading } = useQuery(
		[QUERIES.userListByBranchId, foundBranch?.id],
		async () => (foundBranch ? await UsersApi.getUsersForBranch(foundBranch.id) : undefined),
		{
			enabled: !!foundBranch?.id,
		}
	)

	const { mutateAsync: updateResponsibleAgent, isLoading: isResponsibleUpdating } = useMutation(
		OrdersApi.updateResponsibleAgent,
		{
			onError: (_, __, context) => {
				message.error(t('orders.pages.details.responsibleUpdateError'))
				queryClient.setQueryData(
					[QUERIES.orderListSimplified, caseId],
					(context as { previousOrderData: ISimplifiedOrder[] }).previousOrderData
				)
			},
			onSuccess: success => {
				if (success) message.success(t('orders.pages.details.responsibleUpdateSuccess'))
				else message.error(t('orders.pages.details.responsibleUpdateError'))
			},
			onMutate: ({ userId }) => {
				const queryKey = [QUERIES.orderListSimplified, caseId]
				const previousOrderData = queryClient.getQueryData<ISimplifiedOrder[]>(queryKey)
				queryClient.setQueryData<ISimplifiedOrder[]>(
					queryKey,
					cachedOrders =>
						cachedOrders?.map(oldOrder => ({
							...oldOrder,
							responsibleUserId: oldOrder.id === selectedOrder?.id ? userId : oldOrder.responsibleUserId,
						})) || []
				)
				return { previousOrderData }
			},
		}
	)

	const { mutateAsync: updateOrderWatchers, isLoading: isWatchersUpdating } = useMutation(
		OrdersApi.updateOrderWatchers,
		{
			onError: (_, __, context) => {
				message.error(t('orders.pages.details.watchersUpdateError'))
				queryClient.setQueryData(
					[QUERIES.orderWatchers, orderId],
					(context as { previousOrderData: IDatahubWatcherDto[] }).previousOrderData
				)
			},
			onSuccess: success => {
				if (success) {
					message.success(t('orders.pages.details.watchersUpdateSuccess'))
					queryClient.invalidateQueries([QUERIES.orderWatchers, orderId])
				} else message.error(t('orders.pages.details.watchersUpdateError'))
			},
			onMutate: ({ userIds }) => {
				const queryKey = [QUERIES.orderWatchers, orderId]
				const previousOrderData = queryClient.getQueryData<IDatahubWatcherDto[]>(queryKey)
				// TODO: include other fields for user selected
				queryClient.setQueryData<Partial<IDatahubWatcherDto>[]>(queryKey, () =>
					userIds.map(id => ({
						id,
					}))
				)
				return { previousOrderData }
			},
		}
	)

	const onResponsibleAgentChange = useCallback(
		(value: string) => {
			selectedOrder &&
				updateResponsibleAgent({
					userId: value,
					orderId: selectedOrder.id,
				})
		},
		[updateResponsibleAgent, selectedOrder]
	)

	const onWatchersChange = useCallback(
		(users: IUserBranchAccess[]) => {
			updateOrderWatchers({
				orderId,
				userIds: users.map(({ id }) => id),
			})
		},
		[updateOrderWatchers, orderId]
	)

	const departmentMemberOptions = useMemo(
		() =>
			departmentMembers.map(({ id, fullName }) => ({
				value: id,
				label: fullName,
			})),
		[departmentMembers]
	)

	const isEsoftUserValue = useMemo(() => isEsoftUser(), [])

	const totalPrice = useMemo(
		() =>
			orderLines
				.filter(({ status }) => status !== OrderStatus.Cancelled)
				.reduce((memo, next) => memo + next.price, 0) || 0,
		[orderLines]
	)

	return {
		departmentMembers,
		selectedOrderId,
		setSelectedOrderId,
		ordersOptions,
		orderLines,
		isFetched: areOrdersFetched && areWatchersFetched,
		isLoading:
			areOrdersLoading || areDepartmentMembersLoading || isCaseLoading || isBookingInfoLoading || areOrderLinesLoading,
		isResponsibleUpdating,
		isWatchersUpdating,
		onResponsibleAgentChange,
		onWatchersChange,
		departmentMemberOptions,
		isEsoftUserValue,
		totalPrice,
		orderWatchers,
		foundCase,
		bookingInfo,
		selectedOrder,
		orders,
	}
}
