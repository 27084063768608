import React, { FC, useMemo } from 'react'
import { useHideModal } from '@libs/components/build/Modals/context'
import { Modal, Button, message } from 'antd'
import { Form, FormItem } from 'formik-antd'
import { Formik } from 'formik'
import { IDatahubCase, ILinkMaterial, IBranch, MaterialApi } from '@libs/api'
import { useMutation, useQuery } from 'react-query'
import { useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useLocalization } from '@libs/localization'
import { Autolocalize, FormikInput, UserInteraction } from '@libs/components'
import { createValidationSchema } from '@libs/util'
import { Close24 } from '@carbon/icons-react'

export interface SaveLinkMaterialModalProps {
	title: string
	material?: ILinkMaterial
	caseId: IDatahubCase['id']
	clientId: IBranch['axCustomerId']
}

export interface ISaveLinkMaterialFormValues extends Pick<ILinkMaterial, 'name' | 'url'> {}

const formSchema = createValidationSchema(yup =>
	yup.object().shape({
		name: yup.string().required(),
		url: yup.string().url().required(),
	})
)

const SaveLinkMaterialModal: FC<SaveLinkMaterialModalProps> = ({ title, caseId, material }) => {
	const hideModal = useHideModal()
	const queryClient = useQueryClient()
	const { data: linkMaterials = [] } = useQuery(
		[QUERIES.linkMaterials, caseId],
		() => MaterialApi.getLinkMaterials(caseId),
		{
			enabled: !!caseId,
		}
	)
	const { mutateAsync: saveLinkAsset, isLoading } = useMutation(MaterialApi.saveLinkAsset, {
		onSuccess: response => {
			if (response.success) {
				queryClient.invalidateQueries([QUERIES.linkMaterials, caseId])

				message.success(t('modals.saveLink.success'))
				hideModal()
				return
			}

			message.error(t('modals.saveLink.error'))
		},
	})

	const { t } = useLocalization()

	const onSubmit = async (values: ISaveLinkMaterialFormValues) => {
		const isEdit = !!material?.id

		// TODO: improve types
		const newItem: any = {
			name: values.name,
			type: 'link',
			url: values.url,
		}

		await saveLinkAsset({
			caseId,
			assets: isEdit
				? linkMaterials.map(item =>
						item.id === material?.id
							? {
									...item,
									...newItem,
							  }
							: item
				  )
				: linkMaterials.concat([newItem]),
		})
	}

	const initialFormValues: ISaveLinkMaterialFormValues = useMemo(
		() => ({
			name: material?.name || '',
			url: material?.url || '',
		}),
		[material]
	)

	return (
		<Modal
			visible
			closeIcon={<Close24 />}
			onCancel={hideModal}
			title={title}
			footer={[
				<Button form="save-link-material-form-id" loading={isLoading} key="submit" htmlType="submit" type="primary">
					{t('modals.saveLink.submit')}
				</Button>,
			]}
		>
			<Formik<ISaveLinkMaterialFormValues>
				initialValues={initialFormValues}
				validationSchema={formSchema}
				onSubmit={onSubmit}
			>
				{({ isSubmitting }) => (
					<UserInteraction enabled={!isSubmitting}>
						<Form id="save-link-material-form-id" layout="vertical">
							<Autolocalize />
							<FormItem name="name" label={t('common.terms.title')} required>
								<FormikInput name="name" />
							</FormItem>
							<FormItem name="url" label={t('common.terms.url')} required>
								<FormikInput name="url" placeholder="http://example.com" />
							</FormItem>
						</Form>
					</UserInteraction>
				)}
			</Formik>
		</Modal>
	)
}

export default SaveLinkMaterialModal
