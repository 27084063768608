import { useLocalization } from '@libs/localization'
import React, { FC } from 'react'
import Styled from './Styled'

interface IModalTitleProps {
	title: string
	caseId: string
}

const TaskModalTitle: FC<IModalTitleProps> = ({ title, caseId }) => {
	const { t } = useLocalization()

	return (
		<Styled.TitleWrapper display={'flex'} flexDirection={'column'} space={'xs'} spaceDirection={'vertical'}>
			{title}
			<Styled.Subtitle>
				{t('common.terms.case')}: {caseId}
			</Styled.Subtitle>
		</Styled.TitleWrapper>
	)
}

export default TaskModalTitle
