import React, { FC, useMemo, useState } from 'react'
import { useHideModal, UserInteraction } from '@libs/components'
import { Close24 } from '@carbon/icons-react'
import { Button, message } from 'antd'
import { useLocalization } from '@libs/localization'
import { useMutation, useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import { IProduct, IBranch, OrdersApi, PackagesApi, ISimplifiedOrder } from '@libs/api'
import ProductList from 'modules/orders/ProductList'
import Styled from './Styled'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import { useAuth } from 'modules/auth/hooks'

export interface CaseAddExtraOrderLineModalProps {
	orderId: ISimplifiedOrder['id']
	clientId: IBranch['axCustomerId']
}

const CaseAddExtraOrderLineModal: FC<CaseAddExtraOrderLineModalProps> = ({ orderId, clientId }) => {
	const [selectedProducts, setSelectedProducts] = useState<IProduct[]>([])
	const hideModal = useHideModal()
	const { t } = useLocalization()
	const invalidateTaskQueries = useTasksQueriesInvalidation()
	const { user } = useAuth()

	const foundBranchId = useMemo(
		() => user?.branches.find(({ axCustomerId }) => axCustomerId === clientId)?.id,
		[user, clientId]
	)

	const { data: allProducts = [], isFetched: areProductsFetched } = useQuery(
		[QUERIES.additionalProducts, foundBranchId],
		() => PackagesApi.getAdditionalProducts(foundBranchId!),
		{ enabled: !!foundBranchId }
	)

	const { mutateAsync: addOrderLines, isLoading: isOrderUpdating } = useMutation(OrdersApi.addOrderLines, {
		onError: (err: Error) => {
			message.error(err.message)
		},
		onSuccess: () => {
			invalidateTaskQueries()

			message.success(t('modals.addExtraOrderLine.orderUpdateSuccess'))
			hideModal()

			// showModal('new.tasks', { tasks })
		},
	})

	const handleQuantityChange = (newQuantity: number, product: IProduct) => {
		const foundIndex = selectedProducts ? selectedProducts.findIndex(({ id }) => product.id === id) : -1

		if (!newQuantity) {
			setSelectedProducts(prevState => prevState.filter((_, currentIndex) => currentIndex !== foundIndex))
		} else if (foundIndex === -1) {
			setSelectedProducts(prevState =>
				prevState.concat([
					{
						...product,
						quantity: newQuantity,
					},
				])
			)
		} else {
			setSelectedProducts(prevState =>
				prevState.map(item => ({
					...item,
					quantity: item.id === product.id ? newQuantity : item.quantity,
				}))
			)
		}
	}

	return (
		<Styled.Modal
			visible
			onCancel={hideModal}
			title={t('common.terms.purchaseIndividualProductsTitle')}
			width={'auto'}
			closeIcon={<Close24 />}
			footer={[
				<Button
					disabled={!areProductsFetched || selectedProducts.length === 0}
					loading={isOrderUpdating}
					key="submit"
					htmlType="submit"
					type="primary"
					onClick={() => addOrderLines({ orderId, caseProducts: selectedProducts })}
				>
					{t('modals.addExtraOrderLine.addOrder')}
				</Button>,
			]}
		>
			<UserInteraction enabled={!isOrderUpdating}>
				<ProductList
					products={allProducts}
					areProductsFetched={areProductsFetched}
					selectedProducts={selectedProducts}
					onQuantityChange={handleQuantityChange}
					modalView
				/>
			</UserInteraction>
		</Styled.Modal>
	)
}

export default CaseAddExtraOrderLineModal
