import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ISimplifiedVisualMaterial, IVirtualStaging, TaskCategory, TasksApi, VirtualStagingType } from '@libs/api'
import { useMutation, useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import MaterialList, { IMaterialList } from 'modules/material/MaterialList'
import SelectRoomTypeStep from '../SelectRoomTypeStep'
import SelectInteriorStep from '../SelectInteriorStep'
import VirtualStagingSummary from '../VirtualStagingSummary'
import SelectLabelStep from '../SelectLabelStep'
import VisualStagingResultStep, { IVisualStagingResult } from '../VisualStagingResultStep'
import { useLocalization } from '@libs/localization'
import { message } from 'antd'
import { useHideModal } from '@libs/components'
import { TaskVirtualStagingModalProps } from '.'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import { useOrderLineById } from 'modules/cases/CaseOrderDetailsPageContent/hooks'
import { useVisualMaterials } from 'modules/material/hooks'

export const useTaskVirtualStagingModal = ({
	task,
	filteredMaterialTypes,
	onTaskComplete,
}: TaskVirtualStagingModalProps) => {
	const hideModal = useHideModal()
	const { f, t } = useLocalization()
	const invalidateTaskQueries = useTasksQueriesInvalidation()
	const [innerVisualMaterials, setInnerVisualMaterials] = useState<ISimplifiedVisualMaterial[]>([])
	const [innerVirtualStaging, setInnerVirtualStaging] = useState<IVirtualStaging[]>([])
	const [imageLabel, setImageLabel] = useState<string>()
	const [currentStep, setCurrentStep] = useState(0)
	const [lastStep, setLastStep] = useState(0)

	const {
		visualMaterials,
		isLoading: areMaterialsLoading,
		isFetched: areMaterialsFetched,
	} = useVisualMaterials(task.caseId)

	const virtualStagingType: VirtualStagingType =
		task.type === TaskCategory.VirtualStagingCommercial || task.type === TaskCategory.VirtaulStagingCommercial720
			? 'Commercial'
			: 'Residential' // these are expected strings paramters for BE, should NOT be translated
	const {
		data: virtualStaging,
		isLoading: isVirtualStagingLoading,
		isFetched: isVirtualStagingFetched,
	} = useQuery([QUERIES.virtualStaging, virtualStagingType], () => TasksApi.getVirtualStagingData(virtualStagingType), {
		onSuccess: results => {
			if (!results || results.length === 0) {
				message.error(t('modals.virtualStaging.emptyRooms'))
			}
		},
		enabled: currentStep > 0,
	})

	const { data: foundOrderline } = useOrderLineById({ orderId: task.orderId, orderlineId: task.orderlineId })

	const { mutateAsync: orderVirtualStaging, isLoading: isOrderLoading } = useMutation(TasksApi.orderVirtualStaging, {
		onSuccess: result => {
			if (result) invalidateTaskQueries()
		},
	})

	const selectedVisualMaterial = innerVisualMaterials.find(({ isSelected }) => isSelected)
	const selectedRoom = innerVirtualStaging.find(({ isSelected }) => isSelected)
	const selectedInterior = selectedRoom?.interiorOptions.find(({ isSelected }) => isSelected)
	const isLoading = areMaterialsLoading || isVirtualStagingLoading
	const isFetched = areMaterialsFetched || isVirtualStagingFetched

	useEffect(() => {
		if (Array.isArray(visualMaterials) && visualMaterials.length > 0) {
			setInnerVisualMaterials(visualMaterials.filter(({ type }) => filteredMaterialTypes.includes(type)))
		}
	}, [visualMaterials])

	useEffect(() => {
		if (Array.isArray(virtualStaging) && virtualStaging.length > 0) {
			setInnerVirtualStaging(virtualStaging)
		}
	}, [virtualStaging])

	const selectMaterialItem: IMaterialList['onItemClick'] = useCallback(
		(materialId: ISimplifiedVisualMaterial['id']) => {
			setInnerVisualMaterials(
				innerVisualMaterials.map(material => ({
					...material,
					isSelected: material.id === materialId ? !material.isSelected : false,
				}))
			)
		},
		[innerVisualMaterials]
	)

	const selectRoom = useCallback((roomTypeName: IVirtualStaging['roomTypeName']) => {
		setInnerVirtualStaging(prev =>
			prev.map(item => ({
				...item,
				isSelected: item.roomTypeName === roomTypeName ? !item.isSelected : false,
			}))
		)
	}, [])

	const selectInterior = useCallback((interiorName: IVirtualStaging['interiorOptions'][number]['name']) => {
		setInnerVirtualStaging(prev =>
			prev.map(item => ({
				...item,
				interiorOptions: item.interiorOptions.map(option => ({
					...option,
					isSelected: option.name === interiorName ? !option.isSelected : false,
				})),
			}))
		)
	}, [])

	const choices = useMemo(() => {
		let results: IVisualStagingResult[] = []
		if (selectedVisualMaterial && selectedRoom && selectedInterior) {
			results = [
				{
					title: t('modals.virtualStaging.selectedImageTitle'),
					source: selectedVisualMaterial.source,
					thumbnail: selectedVisualMaterial.thumbnailUrl,
					description:
						selectedVisualMaterial.description ||
						(selectedVisualMaterial.updatedDate && f(selectedVisualMaterial.updatedDate, { date: 'short' })) ||
						'',
				},
				{
					title: t('modals.virtualStaging.selectedRoomTitle'),
					source: selectedRoom.url,
					description: selectedRoom.roomTypeName,
				},
				{
					title: t('modals.virtualStaging.selectedFurnitureTitle'),
					source: selectedInterior.url,
					description: selectedInterior.name,
				},
			]

			if (imageLabel) {
				results.push({
					title: t('modals.virtualStaging.labelPreviewTitle'),
					source: selectedVisualMaterial.source,
					thumbnail: selectedVisualMaterial.thumbnailUrl,
					description: imageLabel,
					label: imageLabel,
				})
			}
		}

		return results
	}, [imageLabel, selectedVisualMaterial, selectedRoom, selectedInterior, f, t])

	const steps = useMemo(() => {
		const summary = (
			<VirtualStagingSummary
				visualMaterial={selectedVisualMaterial as ISimplifiedVisualMaterial}
				selectedRoomType={selectedRoom?.roomTypeName}
				selectedInterior={selectedInterior?.name}
				label={imageLabel}
			/>
		)

		return [
			{
				title: t('modals.virtualStaging.selectItemStep.stepTitle'),
				content: (
					<MaterialList
						disabledInactiveOverlay
						visualMaterials={innerVisualMaterials}
						axis="xy"
						onItemClick={selectMaterialItem}
						showAll
					/>
				),
			},
			{
				title: t('modals.virtualStaging.selectRoomStep.stepTitle'),
				content: (
					<SelectRoomTypeStep selectRoom={selectRoom} rooms={innerVirtualStaging}>
						{summary}
					</SelectRoomTypeStep>
				),
			},
			{
				title: t('modals.virtualStaging.selectInteriorStep.stepTitle'),
				content: (
					<SelectInteriorStep selectedRoom={selectedRoom as IVirtualStaging} selectInterior={selectInterior}>
						{summary}
					</SelectInteriorStep>
				),
			},
			{
				title: t('modals.virtualStaging.selectLabelStep.stepTitle'),
				content: (
					<SelectLabelStep selectedValue={imageLabel} selectLabel={setImageLabel}>
						{summary}
					</SelectLabelStep>
				),
			},
			{
				title: t('modals.virtualStaging.summaryStep.stepTitle'),
				content: <VisualStagingResultStep choices={choices} />,
			},
		]
	}, [
		innerVisualMaterials,
		selectMaterialItem,
		selectedVisualMaterial,
		innerVirtualStaging,
		selectRoom,
		selectedRoom,
		selectInterior,
		selectedInterior,
		imageLabel,
		choices,
		t,
	])

	const isNextEnabled = useMemo(() => {
		switch (currentStep) {
			case 0:
				return !!selectedVisualMaterial
			case 1:
				return !!selectedRoom
			case 2:
				return !!selectedInterior
			case 3:
			default:
				return true
		}
	}, [currentStep, selectedVisualMaterial, selectedRoom, selectedInterior])

	const onBackClick = useCallback(() => {
		setCurrentStep(prev => (prev === 0 ? 0 : prev - 1))
	}, [])

	const submitOrdering = useCallback(async () => {
		if (selectedVisualMaterial && selectedInterior && selectedRoom && foundOrderline) {
			const result = await orderVirtualStaging({
				taskId: task.id,
				materialIds: [selectedVisualMaterial.id.toString()],
				batchId: foundOrderline.batchId,
				roomType: selectedRoom.roomTypeName,
				code: selectedInterior.name,
				furniture: selectedInterior.url,
				label: imageLabel,
			})

			if (result) {
				message.success(t('modals.virtualStaging.success'))

				if (onTaskComplete) {
					onTaskComplete(task)
					return
				}

				hideModal()
			} else {
				message.error(t('modals.virtualStaging.failed'))
			}
		}
	}, [
		orderVirtualStaging,
		selectedVisualMaterial,
		selectedInterior,
		selectedRoom,
		task,
		foundOrderline,
		imageLabel,
		hideModal,
		t,
		onTaskComplete,
	])

	const onNextClick = useCallback(async () => {
		if (currentStep === steps.length - 1) {
			await submitOrdering()
		} else {
			setCurrentStep(prev => (prev === 4 ? 4 : prev + 1))
		}
	}, [currentStep, steps, submitOrdering])

	return [
		{
			isLoading,
			isFetched,
			isOrderLoading,
			steps,
			currentStep,
			isNextEnabled,
			lastStep,
			innerVisualMaterials,
		},
		{
			onBackClick,
			onNextClick,
			setLastStep,
		},
	]
}
