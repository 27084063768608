import { COLLAGE_OUTPUT_HEIGHT, COLLAGE_OUTPUT_WIDTH } from '../CollageEditor/consts'
import { ICollageTemplate } from '../CollageEditor/types'

export const generateOutputImage = async ({ layout, items, imageUrls }: ICollageTemplate & { imageUrls: string[] }) => {
	const canvas = document.createElement('canvas')
	const context = canvas.getContext('2d')

	canvas.width = layout === 'horizontal' ? COLLAGE_OUTPUT_WIDTH : COLLAGE_OUTPUT_HEIGHT
	canvas.height = layout === 'horizontal' ? COLLAGE_OUTPUT_HEIGHT : COLLAGE_OUTPUT_WIDTH

	if (context) {
		context.fillStyle = 'white'
		context.fillRect(0, 0, canvas.width, canvas.height)
	}

	await Promise.all(
		items.map(
			(templateItem, index) =>
				new Promise((resolve, reject) => {
					if (context) {
						const image = new Image()
						image.src = imageUrls[index]
						image.onload = function () {
							context.drawImage(
								image,
								templateItem.startX,
								templateItem.startY,
								templateItem.width,
								templateItem.height
							)
							resolve('')
						}
					} else {
						reject()
					}
				})
		)
	)

	return new Promise<Blob>(resolve => canvas.toBlob(blob => resolve(blob as Blob)))
}

export const downloadOutput = (dataurl: string) => {
	const link = document.createElement('a')
	link.href = dataurl
	link.download = 'test.png'
	link.click()
}
