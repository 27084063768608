import { TasksApi } from '@libs/api'
import { Autolocalize, FormikInput, FormikTextArea, useHideModal, UserInteraction } from '@libs/components'
import { Button, message, Modal } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem } from 'formik-antd'
import React, { FC } from 'react'
import { useMutation } from 'react-query'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { createValidationSchema } from '@libs/util'
import { Close24 } from '@carbon/icons-react'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import TaskModalTitle from 'modules/common/TaskModalTitle'
import { useAuth } from 'modules/auth/hooks'

export interface TaskArialModalProps extends TaskModalCommonProps {}

interface IFormValues {
	roofColor: string
	cadastralNumber: string
	comment?: string
}

const initialFormValues: IFormValues = {
	roofColor: '',
	cadastralNumber: '',
	comment: '',
}

const formSchema = createValidationSchema(yup =>
	yup.object().shape({
		roofColor: yup.string().required(),
		cadastralNumber: yup.string().required(),
		comment: yup.string(),
	})
)

const TaskArialModal: FC<TaskArialModalProps> = ({ task, onTaskComplete, onTaskCancel }) => {
	const hideModal = useHideModal()
	const invalidateTaskQueries = useTasksQueriesInvalidation()
	const { t } = useLocalization()
	const { user } = useAuth()
	const { mutateAsync: completeTask } = useMutation(
		(values: IFormValues) =>
			TasksApi.createComment({
				taskId: task.id,
				comment: [
					`${t('common.terms.comment')}:\n${values.comment ?? ''}`,
					`${t('common.terms.cadastralNumber')}:\n${values.cadastralNumber}`,
					`${t('common.terms.roofColor')}:\n${values.roofColor}`,
				].join('\n\n'),
				email: user?.email || '',
			}),
		{
			onSuccess: response => {
				if (!response?.success) {
					message.error(t('modals.basic.failed'))
					return
				}

				invalidateTaskQueries()

				if (onTaskComplete) {
					onTaskComplete(task)
					return
				}

				hideModal()
			},
		}
	)

	return (
		<Formik initialValues={initialFormValues} validationSchema={formSchema} onSubmit={values => completeTask(values)}>
			{({ dirty, isValid, isSubmitting }) => (
				<Modal
					visible
					closeIcon={<Close24 />}
					title={<TaskModalTitle title={task.productName} caseId={task.caseReference} />}
					onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
					footer={
						<Button
							loading={isSubmitting}
							type={'primary'}
							htmlType={'submit'}
							form={'task-arial-form-id'}
							disabled={!dirty || !isValid}
						>
							{t('common.actions.completeTask')}
						</Button>
					}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form layout={'vertical'} id={'task-arial-form-id'}>
							<Autolocalize />
							<FormItem name={'roofColor'} label={t('common.terms.roofColor')} required>
								<FormikInput name={'roofColor'} />
							</FormItem>
							<FormItem name={'cadastralNumber'} label={t('common.terms.cadastralNumber')} required>
								<FormikInput name={'cadastralNumber'} />
							</FormItem>
							<FormItem name={'comment'} label={t('common.terms.comment')}>
								<FormikTextArea name={'comment'} autoSize={{ minRows: 2, maxRows: 4 }} />
							</FormItem>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default TaskArialModal
