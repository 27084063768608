import React, { FC, useState } from 'react'
import { Container, useHideModal } from '@libs/components'
import { ANT_BUTTON_AUTO_WIDTH_CLASS_NAME, useScreenMatch } from '@libs/theme'
import { ChevronRight24 } from '@carbon/icons-react'
import { IDatahubTask } from '@libs/api'
import { useOpenTask } from 'modules/tasks/hooks'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'
import { useShowModal } from 'modules/common/GlobalModals'

interface NewTasksListProps {
	tasks: IDatahubTask[]
	modal?: boolean
}

const NewTasksList: FC<NewTasksListProps> = ({ tasks, modal: isModal }) => {
	const [completedTasks, setCompletedTasks] = useState<IDatahubTask['id'][]>([])
	const match = useScreenMatch()
	const { t } = useLocalization()
	const showModal = useShowModal()
	const hideModal = useHideModal()

	const handleTaskCancelOrComplete = (task: IDatahubTask) => {
		const undoneTasks = tasks.filter(({ id }) => task.id !== id)

		if (undoneTasks.length === 0) {
			hideModal()
			return
		}

		if (isModal) {
			undoneTasks.length && showModal('new.tasks', { tasks: undoneTasks })
			return
		}

		setCompletedTasks(prevCompletedTasks => [...prevCompletedTasks, task.id])
		hideModal()
	}

	const onComplete = (task: IDatahubTask) => {
		handleTaskCancelOrComplete(task)
	}

	const openTask = useOpenTask(onComplete, handleTaskCancelOrComplete)

	return (
		<Container space={'xs'} spaceDirection={'vertical'}>
			{tasks
				.filter(task => !completedTasks.includes(task.id))
				.map((task, index) => (
					<Container
						key={index}
						display={'flex'}
						space={match.map({ xxs: 'xxs', md: 'sm' })}
						spaceDirection={'horizontal'}
					>
						<Styled.Task>{t(`tasks.categories.${task.type}`)}</Styled.Task>
						<Styled.TaskButton
							className={ANT_BUTTON_AUTO_WIDTH_CLASS_NAME}
							type={'primary'}
							shape={match.map({ xxs: 'circle', md: 'round' })}
							size={match.map({ xxs: 'small', md: 'middle' })}
							onClick={() => openTask(task)}
						>
							{match.map({
								xxs: <ChevronRight24 />,
								md: t('common.actions.openTask'),
							})}
						</Styled.TaskButton>
					</Container>
				))}
		</Container>
	)
}

export default NewTasksList
