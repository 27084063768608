import React, { FC, useCallback, SyntheticEvent } from 'react'
import { Typography } from 'antd'
import { Container } from '@libs/components'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'
import { DEFAULT_IMG_SOURCE } from 'modules/material/MaterialItem'
import { useScreenMatch } from '@libs/theme'

export interface IVisualStagingResult {
	source: string
	thumbnail?: string
	title: string
	description: string
	label?: string
}

export interface VisualStagingResultStepProps {
	choices: IVisualStagingResult[]
}

const VisualStagingResultStep: FC<VisualStagingResultStepProps> = ({ choices }) => {
	const onImageLoadError = useCallback(
		(event: SyntheticEvent<HTMLImageElement, Event>, thumbnail?: IVisualStagingResult['thumbnail']) => {
			const target = event.target as HTMLImageElement
			if (target.src !== thumbnail) {
				target.src = thumbnail || DEFAULT_IMG_SOURCE
			} else {
				target.src = DEFAULT_IMG_SOURCE
			}
		},
		[]
	)
	const { t } = useLocalization()
	const match = useScreenMatch()
	return (
		<Container>
			<Typography.Title level={4}>{t('modals.virtualStaging.summaryStep.title')}</Typography.Title>
			<Typography.Paragraph>{t('modals.virtualStaging.summaryStep.instructions')}</Typography.Paragraph>
			<Container
				display="flex"
				space="xs"
				spaceDirection={match.map({ xxs: 'vertical', md: 'horizontal' })}
				flexWrap={'wrap'}
			>
				<Styled.ItemRow>
					{choices.map(({ label, source, title, description, thumbnail }) => {
						const dynamicProps =
							choices.length === 3
								? {
										lg: 8,
								  }
								: {
										lg: 12,
										xl: 6,
								  }

						return (
							<Styled.ItemCol key={title} xs={24} {...dynamicProps}>
								<Styled.Item display="flex" flexDirection="column">
									<Typography.Title level={4}>{title}</Typography.Title>
									<Styled.Wrapper>
										<Styled.ImageWrapper>
											<img src={source} onError={e => onImageLoadError(e, thumbnail)} alt="Choice image" />
											{label && <Styled.LabelContainer>{label}</Styled.LabelContainer>}
										</Styled.ImageWrapper>
										<Container padding="xs">
											<Typography.Text ellipsis={{ tooltip: description }}>{description}</Typography.Text>
										</Container>
									</Styled.Wrapper>
								</Styled.Item>
							</Styled.ItemCol>
						)
					})}
				</Styled.ItemRow>
			</Container>
		</Container>
	)
}

export default VisualStagingResultStep
