import styled from 'styled-components'
import { Typography } from 'antd'

const TextContent = styled(Typography.Paragraph)`
	margin-bottom: 0 !important;
	white-space: break-spaces;
`

export default {
	TextContent,
}
