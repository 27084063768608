import React, { useMemo } from 'react'
import { Grid, Skeleton } from '@libs/components'
import { useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import { IPackage, PackagesApi } from '@libs/api'
import PackageCard from '../PackageCard'
import Styled from './Styled'
import { useNewOrderContext } from '../context'
import { useLocalization } from '@libs/localization'
import CustomPackageCard from '../CustomPackageCard'
import { useNewOrderNavigation } from '../hooks'
import { useScreenMatch } from '@libs/theme'

const NewOrderStepOne = () => {
	const [orderState, setOrderState] = useNewOrderContext()

	const { data: packages = [], isLoading } = useQuery(
		[QUERIES.packages, orderState.departmentId],
		() => PackagesApi.getPackages(orderState.departmentId!),
		{
			enabled: !!orderState.departmentId,
		}
	)
	const { t } = useLocalization()
	const { onContinue, showAlert } = useNewOrderNavigation()

	const flowBasedPackages = useMemo(
		() => packages.filter(({ type }) => type === orderState.packageType),
		[packages, orderState.packageType]
	)

	const match = useScreenMatch()

	const hasProductWithCopyright = (currentPackage: IPackage) =>
		currentPackage.products.some(product => product.showAlert) ||
		!!currentPackage.choices?.some(
			choice => choice.products.some(product => product.showAlert) || choice.packages?.some(hasProductWithCopyright)
		)

	return (
		<Grid.Row>
			<Grid.Col xs={24}>
				<Styled.CardListTitle level={5}>{t('common.actions.choosePackage')}</Styled.CardListTitle>
				{isLoading ? (
					<Skeleton.Cards
						gutter={'sm'}
						size={'small'}
						span={match.map({ xxs: 24, md: 12, xl: 8 })}
						items={match.map({ xxs: 4, xl: 6 })}
					/>
				) : (
					<Grid.Row gutter={['sm', 'sm']}>
						{orderState.packageType === 'property' && (
							<Grid.Col xs={24} md={12} xl={8}>
								<CustomPackageCard onSelect={onContinue} />
							</Grid.Col>
						)}
						{flowBasedPackages.map((packageObj, index) => (
							<Grid.Col key={index} xs={24} md={12} xl={8}>
								<PackageCard
									package={packageObj}
									onSelect={() => {
										const onNext = () => {
											setOrderState(prevState => ({
												...prevState,
												package: packageObj,
												products: [],
												step: (prevState.step ?? 0) + 1,
											}))
										}

										if (hasProductWithCopyright(packageObj)) {
											showAlert(onNext)
											return
										}

										onNext()
									}}
								/>
							</Grid.Col>
						))}
					</Grid.Row>
				)}
			</Grid.Col>
		</Grid.Row>
	)
}

export default NewOrderStepOne
