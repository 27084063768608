import { IBranch, IDatahubCase, ISimplifiedOrder, OrdersApi, UsersApi } from '@libs/api'
import { useCases, useSimplifiedCaseOrders } from 'modules/cases/hooks'
import React, { UIEvent, useCallback, useMemo, useState } from 'react'
import { useDebounce } from 'use-debounce/lib'
import { useLocalization } from '@libs/localization'
import { useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import { Button } from 'antd'
import { useShowModal } from 'modules/common/GlobalModals'
import { useNewOrderContext } from './context'
import { useHideModal } from '@libs/components'
import { useAuth } from 'modules/auth/hooks'
import { mapPropertyTypeFromDto } from '@libs/api/build/orders/helpers'
import { convertLocaleToSupportedString } from '@libs/util'

export function useContactPersons() {
	const { locale } = useLocalization()
	const { data: contactPersonTypes = [], isLoading } = useQuery(
		QUERIES.contactPersonTypes,
		UsersApi.getContactPersonTypes
	)
	const fixedLocale = useMemo(() => convertLocaleToSupportedString(locale), [locale])
	const contactPersonTypeOptions = useMemo(
		() =>
			contactPersonTypes.map(option => ({
				value: option.key,
				label: option.i18n.find(({ languageCode }) => languageCode === fixedLocale)?.text,
			})),
		[contactPersonTypes, fixedLocale]
	)

	return { contactPersonTypeOptions, isLoading }
}

export function useCreateOrderCaseAutocomplete(departmentId?: IBranch['id']) {
	const { t } = useLocalization()
	const { user } = useAuth()
	const [query, setQuery] = useState('')
	const [selectedCaseId, setSelectedCaseId] = useState('')
	const isValidQuery = query.length >= 3
	const { data, isLoading, hasNextPage, fetchNextPage } = useCases(
		{
			query,
			departmentId: departmentId ? [departmentId] : undefined,
			includeNoOrders: true,
		},
		isValidQuery
	)
	const [isStillLoading] = useDebounce(isLoading, 500)

	const existingCaseOptions = useMemo(
		() =>
			data.map(caseObj => ({
				value: caseObj.reference,
				data: caseObj,
				label: `${caseObj.reference}${caseObj.address.trim() ? ` (${caseObj.address})` : ''}`,
			})),
		[data]
	)

	const newCaseOption = useMemo(() => {
		const foundDepartment = user?.branches.find(currentDepartment => currentDepartment.id === departmentId)
		const castedQuery = foundDepartment?.useUpperCase ? query.toUpperCase() : query

		return isValidQuery && !data.find(({ reference }) => reference === query)
			? {
					value: castedQuery,
					data: { reference: castedQuery, departmentName: undefined, departmentId: undefined, id: undefined },
					label: `${t('cases.actions.createNew')} "${castedQuery}"`,
			  }
			: undefined
	}, [isValidQuery, data, user, departmentId, query, t])

	const options = useMemo(
		() => (newCaseOption ? [newCaseOption, ...existingCaseOptions] : existingCaseOptions),
		[newCaseOption, existingCaseOptions]
	)

	const selectedCase = useMemo(() => {
		// existing value
		const foundExisting = options.find(candidate => candidate.value === selectedCaseId && candidate.data.id)
		if (foundExisting) return foundExisting.data
		// new value
		return options.find(candidate => candidate.value === selectedCaseId)?.data ?? { reference: selectedCaseId }
	}, [options, selectedCaseId])

	const onPopupScroll = useCallback(
		(e: UIEvent<HTMLDivElement>) => {
			const target = e.target as HTMLDivElement
			if (hasNextPage && target.offsetHeight + target.scrollTop + 200 >= target.scrollHeight) {
				fetchNextPage()
			}
		},
		[hasNextPage, fetchNextPage]
	)

	return {
		query,
		setQuery,
		selectedCaseId,
		setSelectedCaseId,
		selectedCase,
		options,
		isLoading,
		isStillLoading,
		onPopupScroll,
	}
}

export function useNewOrderNavigation() {
	const { t } = useLocalization()
	const showModal = useShowModal()
	const hideModal = useHideModal()
	const [{ step: currentStep }, setOrderState] = useNewOrderContext()

	const onBack = useCallback(() => {
		setOrderState(prevState => ({
			...prevState,
			...(prevState.step === 1 && { package: undefined, products: undefined }),
			step: prevState.step === 0 ? 0 : (prevState?.step || 0) - 1,
		}))
	}, [setOrderState])

	const onContinue = useCallback(() => {
		setOrderState(prevState => ({
			...prevState,
			step: (prevState?.step || 0) + 1,
		}))
	}, [setOrderState])

	const onStepSelect = useCallback(
		(selectedStep: number) => {
			setOrderState(prevState => ({
				...prevState,
				...(selectedStep === 0 && { package: undefined, products: undefined }),
				step: selectedStep,
			}))
		},
		[setOrderState]
	)

	const checkIfStepIsDisabled = useCallback(
		(step: number) => (currentStep ? step > currentStep : step > 0),
		[currentStep]
	)

	const showAlert = useCallback(
		(onConfirm: () => void) => {
			showModal('order.new.copyright.alert', {
				isDanger: false,
				confirmLabel: t('common.terms.yes'),
				rejectNode: (
					<Button
						key="reject"
						onClick={() => {
							hideModal()
						}}
					>
						{t('common.terms.no')}
					</Button>
				),
				title: t('modals.copyrightAlert.title'),
				message: t('modals.copyrightAlert.message'),
				onConfirm: () => {
					hideModal()
					onConfirm()
				},
			})
		},
		[showModal, hideModal, t]
	)

	return { onBack, onContinue, onStepSelect, checkIfStepIsDisabled, showAlert }
}

export function useBookingInfo(orderId?: ISimplifiedOrder['id']) {
	return useQuery(
		[QUERIES.bookingByOrderId, orderId],
		async () => (orderId ? await OrdersApi.getBookingInfo(orderId) : undefined),
		{
			enabled: !!orderId,
		}
	)
}

export function useOrderById(orderId?: ISimplifiedOrder['id']) {
	return useQuery(
		[QUERIES.orderById, orderId],
		async () => (orderId ? await OrdersApi.getDatahubOrderById(orderId) : undefined),
		{
			enabled: !!orderId,
		}
	)
}

export function useOrderByCaseId({
	caseId,
	orderId,
}: {
	caseId?: IDatahubCase['id']
	orderId?: ISimplifiedOrder['id']
}) {
	const query = useSimplifiedCaseOrders(caseId)
	const foundOrder = useMemo(() => query.data?.find(({ id }) => id === orderId), [query.data, orderId])

	return {
		...query,
		data: foundOrder,
	}
}

export function usePropertyTypes() {
	const { locale } = useLocalization()

	const query = useQuery([QUERIES.propertyTypes], () => OrdersApi.getPropertyTypes())

	return {
		...query,
		data: query.data?.map(type => mapPropertyTypeFromDto(type, locale)) || [],
	}
}
