import React, { PropsWithChildren } from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'antd'
import { Select as _Select } from 'formik-antd'
import { SelectProps } from 'formik-antd/es'
import { margin, padding, screen } from '@libs/theme/mixins'
import { Container } from '@libs/components'

interface ActionButtonProps {
	menu?: boolean
}

const ActionButton = styled(Button)`
	display: flex;
	align-items: center;
	margin-top: ${margin('xxs')};

	svg {
		margin-left: ${margin('xxs')};
		margin-right: ${margin('xxs')};
	}

	${({ menu }: ActionButtonProps) =>
		menu &&
		css`
			padding-right: ${padding('xl')};
		`}
`

const SelectDropdownMapped = ({ className, ...props }: PropsWithChildren<SelectProps>) => (
	<_Select dropdownClassName={className} {...props} />
)

const Select = styled(SelectDropdownMapped)`
	max-width: 700px;

	${screen.lte.lg`
			max-width: 500px;
	`}

	${screen.lte.xs`
			max-width: 280px;
	`}
`

const SelectContainer = styled.div`
	max-width: 700px;

	.ant-select {
		${screen.lte.xl`
		 		max-width: 500px;
		`}

		${screen.lte.lg`
		 		max-width: 350px;
		`}
		
    ${screen.lte.sm`
		 		max-width: inherit;
		`}
	}
`

const EditButtonContainer = styled(Container)`
	${screen.gte.md`
			margin-left: ${margin('sm')};
	`}
`

export default {
	ActionButton,
	Select,
	SelectContainer,
	EditButtonContainer,
}
