import React, { FC } from 'react'
import Link from 'next/link'
import { Card, Typography, message } from 'antd'
import { useShowModal } from 'modules/common/GlobalModals'
import { IDatahubCase, ILinkMaterial, MaterialApi } from '@libs/api'
import { CopyFile24, DocumentAttachment24, Link24, SubtractAlt24, Edit24 } from '@carbon/icons-react'
import { ConfirmModalProps, useHideModal, Container } from '@libs/components'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'

interface NonVisualMaterialsProps {
	caseId: IDatahubCase['id']
	wideView?: boolean
}

const NonVisualMaterials: FC<NonVisualMaterialsProps> = ({ caseId, wideView }) => {
	const { t } = useLocalization()
	const showModal = useShowModal()
	const hideModal = useHideModal()
	const queryClient = useQueryClient()
	const { data: linkMaterials = [] } = useQuery(
		[QUERIES.linkMaterials, caseId],
		() => MaterialApi.getLinkMaterials(caseId),
		{
			enabled: !!caseId,
		}
	)
	const { data: documentMaterials = [] } = useQuery(
		[QUERIES.documentMaterials, caseId],
		() => MaterialApi.getDocumentMaterials(caseId),
		{
			enabled: !!caseId,
		}
	)

	const { mutateAsync: removeLink } = useMutation(MaterialApi.deleteLinkAsset, {
		onSuccess: success => {
			success
				? message.success(t('materials.messages.linkRemoveSuccess'))
				: message.error(t('materials.messages.linkRemoveFailed'))
			queryClient.invalidateQueries([QUERIES.linkMaterials, caseId])
			queryClient.invalidateQueries([QUERIES.documentMaterials, caseId])
		},
	})

	const onConfirmLinkRemoval = async (linkMaterial: ILinkMaterial) => {
		await removeLink({
			caseId,
			id: linkMaterial.id as number,
			description: linkMaterial.name,
			url: linkMaterial.url,
			type: linkMaterial.type,
		})

		hideModal()
	}

	const Divider = () => <Styled.Divider type={wideView ? 'vertical' : 'horizontal'} />
	const containerProps = wideView ? { display: 'flex', justifyContent: 'space-between' } : {}

	return (
		<Card title={t('common.terms.otherMaterial')}>
			<Container {...containerProps}>
				<Styled.Section wideView={wideView}>
					<Typography.Title level={5}>{t('common.terms.locationMaterials')}</Typography.Title>
					<Styled.ListItem>{t('cases.pages.details.locationMaterialTitle')}</Styled.ListItem>
					<Styled.LinkButtonWithIcon
						icon={<CopyFile24 />}
						title={t('materials.actions.addLocationMaterial')}
						onClick={() => showModal('material.location.copy', { caseId })}
					/>
				</Styled.Section>
				<Divider />
				<Styled.Section wideView={wideView}>
					<Typography.Title level={5}>{t('common.terms.documents')}</Typography.Title>
					{documentMaterials.length === 0 ? (
						<Styled.ListItem>{t('materials.messages.noDocuments')}</Styled.ListItem>
					) : (
						documentMaterials.map(material => (
							<Styled.ListItem display="flex" alignItems="center" justifyContent="space-between" key={material.name}>
								<Link href={material.url}>
									<a target="_blank">{material.name}</a>
								</Link>
								<Styled.DocumentActions display="flex" alignItems="center">
									<Edit24
										onClick={e => {
											e.stopPropagation()
											showModal('material.document.save', {
												title: t('common.actions.editItem', { item: material.name }),
												caseId,
												material,
											})
										}}
									/>
									<SubtractAlt24
										onClick={e => {
											e.stopPropagation()
											showModal('material.document.remove', {
												title: t('common.actions.removeItem', { item: material.name }),
												message: t('modals.removeDocument.warningNote'),
												confirmLabel: t('modals.removeDocument.submit'),
												onConfirm: () => onConfirmLinkRemoval(material),
											} as ConfirmModalProps)
										}}
									/>
								</Styled.DocumentActions>
							</Styled.ListItem>
						))
					)}
					<Styled.LinkButtonWithIcon
						icon={<DocumentAttachment24 />}
						title={t('materials.actions.addDocumentMaterial')}
						onClick={() =>
							showModal('material.document.save', {
								title: t('modals.saveDocument.title'),
								caseId,
							})
						}
					/>
				</Styled.Section>
				<Divider />
				<Styled.Section wideView={wideView}>
					<Typography.Title level={5}>{t('common.terms.links')}</Typography.Title>
					{linkMaterials.length === 0 ? (
						<Styled.ListItem>{t('materials.messages.noLinks')}</Styled.ListItem>
					) : (
						linkMaterials.map(material => (
							<Styled.ListItem display="flex" alignItems="center" justifyContent="space-between" key={material.name}>
								<Link href={material.url}>
									<a target="_blank">{material.name}</a>
								</Link>
								<Styled.DocumentActions display="flex" alignItems="center">
									<Edit24
										onClick={e => {
											e.stopPropagation()
											showModal('material.link.save', {
												title: t('common.actions.editItem', { item: material.name }),
												caseId,
												material,
											})
										}}
									/>

									<SubtractAlt24
										onClick={e => {
											e.stopPropagation()
											showModal('material.link.remove', {
												title: t('common.actions.removeItem', { item: material.name }),
												message: t('modals.removeLink.warningNote'),
												confirmLabel: t('modals.removeLink.submit'),
												onConfirm: () => onConfirmLinkRemoval(material),
											} as ConfirmModalProps)
										}}
									/>
								</Styled.DocumentActions>
							</Styled.ListItem>
						))
					)}
					<Styled.LinkButtonWithIcon
						icon={<Link24 />}
						title={t('materials.actions.addLinkMaterial')}
						onClick={() =>
							showModal('material.link.save', {
								title: t('modals.saveLink.title'),
								caseId,
							})
						}
					/>
				</Styled.Section>
			</Container>
		</Card>
	)
}

export default NonVisualMaterials
