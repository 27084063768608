import { useHideModal } from '@libs/components'
import { Button, Typography, Modal } from 'antd'
import { useAppEvents } from 'modules/common/app.events'
import React from 'react'
import { ANT_MODAL_VERTICAL_FOOTER_CLASS_NAME } from '@libs/theme'
import { useLocalization } from '@libs/localization'
import { Close24 } from '@carbon/icons-react'

const ConfirmLeaveNewOrderModal = () => {
	const hideModal = useHideModal()
	const appEvents = useAppEvents()
	const { t } = useLocalization()

	return (
		<Modal
			className={ANT_MODAL_VERTICAL_FOOTER_CLASS_NAME}
			title={t('modals.confirmLeaveNewOrder.title')}
			visible
			closeIcon={<Close24 />}
			onCancel={hideModal}
			footer={[
				<Button
					key="start-over"
					danger
					onClick={() => {
						hideModal()
						appEvents.dispatch('orders.new.context.clear')
					}}
				>
					{t('modals.confirmLeaveNewOrder.leave')}
				</Button>,
				<Button key="cancel" onClick={hideModal} type={'text'}>
					{t('modals.confirmLeaveNewOrder.stay')}
				</Button>,
			]}
		>
			<Typography.Paragraph>{t('modals.confirmLeaveNewOrder.message')}</Typography.Paragraph>
		</Modal>
	)
}

export default ConfirmLeaveNewOrderModal
