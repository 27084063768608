import styled from 'styled-components'
import { color, margin, typography } from '@libs/theme/mixins'
import { Button } from 'antd'

const Title = styled.div`
	${typography('xl')}
	color: ${color('black')};
	font-weight: 500;
	cursor: row-resize;

	& .anticon {
		margin-right: ${margin('xxs')};
	}
`

const RemoveButton = styled(Button)`
	height: auto;
	padding: 0;
`

export default {
	Title,
	RemoveButton,
}
