import { DepartmentApi, IBranch } from '@libs/api'
import { useAuth } from 'modules/auth/hooks'
import queries from 'modules/common/queries'
import { useQuery } from 'react-query'

export function useUsersBranchById(branchId?: IBranch['id']): IBranch | undefined {
	const { user } = useAuth()
	return user?.branches.find(branch => branch.id === branchId)
}

export function useWhitelabelSettings(enabled = true) {
	return useQuery([queries.whitelabel], DepartmentApi.getWhitelabelSettings, {
		enabled,
	})
}

export function useUserBranches(enabled = true) {
	return useQuery([queries.branches], DepartmentApi.getUserBranches, {
		enabled,
	})
}
