import React, { FC, useMemo } from 'react'
import { Table, Radio, Select, Typography, message } from 'antd'
import { IBranch, IUser, UserAccess, UsersApi } from '@libs/api'
import { useFormikContext } from 'formik'
import { IEditUserFormValues } from '../EditUserModal'
import { useLocalization } from '@libs/localization'
import { Container, createTableColumns, IconButton } from '@libs/components'
import {
	ANT_TABLE_MOBILE_SHOW_HEAD_CLASS_NAME,
	ANT_TABLE_TRIM_OUTER_PADDING_CLASS_NAME,
	useScreenMatch,
} from '@libs/theme'
import { useAuth } from 'modules/auth/hooks'
import { Close24 } from '@carbon/icons-react'
import { useMutation, useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'

export interface DepartmentPermissionsTableProps {
	userId?: IUser['id']
	branches: IBranch[]
	disabled?: boolean
	loading?: boolean
}

const DepartmentPermissionsTable: FC<DepartmentPermissionsTableProps> = ({ userId, branches, disabled, loading }) => {
	const { values, setFieldValue } = useFormikContext<IEditUserFormValues>()
	const { t } = useLocalization()
	const match = useScreenMatch()
	const { user, userIsLoading } = useAuth()
	const queryClient = useQueryClient()

	const branchesWithAdminRight = useMemo(
		() => user?.branches.filter(branch => branch.access === UserAccess.Admin) || [],
		[user]
	)

	const { mutateAsync: setUserAccess } = useMutation(UsersApi.updateBranchAccessForUser, {
		onSuccess: response => {
			if (response?.success) {
				queryClient.invalidateQueries(QUERIES.userList)
				queryClient.invalidateQueries([QUERIES.userById, userId])
			}
		},
	})

	const { mutateAsync: revokeAccess } = useMutation(UsersApi.revokeBranchAccessForUser, {
		onSuccess: response => {
			if (response?.success) {
				message.success(t('modals.revokeAccess.success'))
				queryClient.invalidateQueries(QUERIES.userList)
				queryClient.invalidateQueries([QUERIES.userById, userId])
				return
			}
			message.error(t('modals.revokeAccess.error'))
		},
		onError: () => {
			message.error(t('modals.revokeAccess.error'))
		},
	})

	const desktopColumns = useMemo(
		() =>
			createTableColumns<IBranch>([
				{
					title: t('common.terms.departmentName'),
					dataIndex: 'name',
					ellipsis: true,
				},
				{
					title: t('common.terms.user'),
					dataIndex: 'access',
					width: 80,
					align: 'center',
					render: (_, { id }, index) => (
						<Radio
							disabled={disabled || userIsLoading || !branchesWithAdminRight.some(branch => branch.id === id)}
							checked={values.branches[index].access === UserAccess.Regular}
							name={`user-access[${index}]`}
							onClick={() => {
								setFieldValue(`branches[${index}].access`, UserAccess.Regular)
								if (userId) {
									setUserAccess({
										userId,
										branchId: id,
										isAdmin: false,
									})
								}
							}}
						/>
					),
				},
				{
					title: t('common.terms.admin'),
					dataIndex: 'access',
					width: 80,
					align: 'center',
					render: (_, { id }, index) => (
						<Radio
							disabled={disabled || userIsLoading || !branchesWithAdminRight.some(branch => branch.id === id)}
							checked={values.branches[index].access === UserAccess.Admin}
							name={`user-access[${index}]`}
							onClick={() => {
								setFieldValue(`branches[${index}].access`, UserAccess.Admin)
								if (userId) {
									setUserAccess({
										userId,
										branchId: id,
										isAdmin: true,
									})
								}
							}}
						/>
					),
				},
				userId
					? {
							dataIndex: 'actions',
							width: 50,
							align: 'center',
							render: (_, { id }) =>
								branchesWithAdminRight.some(branch => branch.id === id) ? (
									<IconButton
										title={t('common.actions.revokeAccess')}
										danger
										size="small"
										onClick={() =>
											revokeAccess({
												userId,
												branchId: id,
											})
										}
									>
										<Close24 />
									</IconButton>
								) : undefined,
					  }
					: false,
			]),
		[values, disabled, t, setFieldValue, userIsLoading, branchesWithAdminRight, revokeAccess, userId, setUserAccess]
	)

	const mobileOptions = useMemo(
		() =>
			[
				userId ? { value: UserAccess.None, label: t('common.actions.revokeAccess') } : undefined,
				{ value: UserAccess.Regular, label: t('common.terms.user') },
				{ value: UserAccess.Admin, label: t('common.terms.admin') },
			].filter(item => !!item) as {
				value: string
				label: string
			}[],
		[userId, t]
	)

	const mobileColumns = useMemo(
		() =>
			createTableColumns<IBranch>([
				{
					title: t('common.terms.permissions'),
					render: (_, { id, name }, index) => (
						<Container space={'xxs'} spaceDirection={'vertical'}>
							<Container>
								<Typography.Text>{name}</Typography.Text>
							</Container>
							<Select
								disabled={disabled || userIsLoading || !branchesWithAdminRight.some(branch => branch.id === id)}
								value={values.branches[index].access}
								options={mobileOptions}
								onChange={async value => {
									if (userId) {
										if (value === UserAccess.None) {
											await revokeAccess({
												userId,
												branchId: id,
											})
											return
										}
									}
									setFieldValue(`branches[${index}].access`, value)
								}}
							/>
						</Container>
					),
				},
			]),
		[values, disabled, t, setFieldValue, userIsLoading, branchesWithAdminRight, mobileOptions, revokeAccess, userId]
	)

	const columns = useMemo(
		() =>
			match.map({
				xxs: mobileColumns,
				md: desktopColumns,
			}),
		[mobileColumns, desktopColumns, match]
	)

	return (
		<Table
			className={`${ANT_TABLE_TRIM_OUTER_PADDING_CLASS_NAME} ${ANT_TABLE_MOBILE_SHOW_HEAD_CLASS_NAME}`}
			size="small"
			columns={columns}
			dataSource={branches}
			pagination={false}
			loading={loading}
			rowKey={record => record.name}
		/>
	)
}

export default DepartmentPermissionsTable
