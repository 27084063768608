import React, { Fragment, useCallback, useMemo } from 'react'
import { useInfiniteQuery, useQuery, useMutation, useQueryClient } from 'react-query'
import {
	IDatahubCase,
	IDatahubTask,
	IGetTaskListParams,
	ISubmitNoteOptions,
	ISimplifiedOrder,
	TaskCategory,
	TasksApi,
	TaskStatus,
} from '@libs/api'
import { useLocalization } from '@libs/localization'
import { Container, createTableColumns, Grid } from '@libs/components'
import { useShowModal } from 'modules/common/GlobalModals'
import QUERIES from 'modules/common/queries'
import { Button, message, Typography } from 'antd'
import { useParsedFilters } from 'modules/common/hooks'
import { TaskModalCommonProps } from './types'
import { useScreenMatch } from '@libs/theme'
import { SortOrder } from 'antd/es/table/interface'
import { ArrowRight24 } from '@carbon/icons-react'
import { useAuth } from 'modules/auth/hooks'

export function useTaskFilterOptions() {
	const { t } = useLocalization()
	const parsedQueryString = useParsedFilters<{
		branchIds: string[]
		category?: string[]
		status?: string[]
		query?: string
	}>('tasks')
	const { data } = useQuery(QUERIES.taskFilters, TasksApi.getFilters)

	// empty qs should be reinitialized with ALL filters selected
	// query is ignored in order to be able to search if this is the only parameter
	const isQSEmpty = useMemo(
		() => !parsedQueryString.category && !parsedQueryString.branchIds && !parsedQueryString.status,
		[parsedQueryString]
	)

	const categoryOptions = useMemo(
		() =>
			data?.categories.map(item => ({
				value: item,
				label: t(`tasks.categories.${item}`),
			})) ?? [],
		[data, t]
	)
	const categoryValues = useMemo(() => {
		const qsArray = parsedQueryString.category as TaskCategory[]

		if (qsArray && categoryOptions.length > 0) {
			return categoryOptions.filter(({ value }) => qsArray.includes(value)).map(option => option.value)
		} else if (!qsArray) {
			return isQSEmpty ? categoryOptions.map(option => option.value) : []
		}
		return qsArray
	}, [categoryOptions, parsedQueryString, isQSEmpty])

	const statusOptions = useMemo(
		() =>
			data?.statuses.map(item => ({
				value: item,
				label: t(`tasks.statuses.${item}`),
			})) ?? [],
		[data, t]
	)
	const statusValues = useMemo(() => {
		const qsArray = parsedQueryString.status as TaskStatus[]

		if (qsArray && statusOptions.length > 0) {
			return statusOptions.filter(({ value }) => qsArray.includes(value)).map(option => option.value)
		} else if (!qsArray) {
			return isQSEmpty ? statusOptions.map(option => option.value) : []
		}
		return qsArray
	}, [statusOptions, parsedQueryString, isQSEmpty])

	const departmentOptions = useMemo(
		() =>
			data?.branches.map(item => ({
				value: item.id,
				label: item.name,
			})) ?? [],
		[data]
	)
	const departmentValues = useMemo(() => {
		const qsArray = parsedQueryString.branchIds

		if (qsArray && departmentOptions.length > 0) {
			return departmentOptions.filter(({ value }) => qsArray.includes(value)).map(option => option.value)
		} else if (!qsArray) {
			return isQSEmpty ? departmentOptions.map(option => option.value) : []
		}
		return qsArray
	}, [departmentOptions, parsedQueryString, isQSEmpty])

	return {
		categoryOptions,
		statusOptions,
		departmentOptions,
		categoryValues,
		statusValues,
		departmentValues,
		parsedQueryString,
	}
}

// if onTaskComplete or onTaskCancel is passed, hideModal() should be called from this functions
export function useOpenTask(
	onTaskComplete?: TaskModalCommonProps['onTaskComplete'],
	onTaskCancel?: TaskModalCommonProps['onTaskCancel']
) {
	const showModal = useShowModal()
	return useCallback(
		(task: IDatahubTask) => {
			switch (task.type) {
				case TaskCategory.Basic:
					showModal('tasks.basic', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.BasicOpt:
					showModal('tasks.basic.opt', { task, optional: true, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.BookPhotographer:
					showModal('tasks.book.photographer', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.ComText:
					showModal('tasks.text.input', { task, comtext: true, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.CopywriteTexts:
					showModal('tasks.copywrite.text', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.IntroOutro:
					showModal('tasks.intro.outro', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.IntroOutroSpeak:
					showModal('tasks.intro.outro', { task, speak: true, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.NoStyleText:
					showModal('tasks.text.input', { task, noStyleText: true, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.SpeakInput:
					showModal('tasks.speak.input', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.Text:
					showModal('tasks.text.input', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.VidibiliComment:
					showModal('tasks.vidibili.comment', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.VirtualStaging:
				case TaskCategory.VirtualStagingCommercial:
					showModal('tasks.virtual.staging', {
						task,
						filteredMaterialTypes: ['Picture'],
						onTaskComplete,
						onTaskCancel,
					})
					break

				case TaskCategory.VirtaulStagingCommercial720:
				case TaskCategory.VirtualStaging720:
					showModal('tasks.virtual.staging', {
						task,
						filteredMaterialTypes: ['PanoramasHtml5'],
						onTaskComplete,
						onTaskCancel,
					})
					break

				case TaskCategory.UploadImages:
					showModal('tasks.uploader', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.UploadVideos:
					showModal('tasks.uploader', { task, supportVideo: true, onTaskComplete })
					break

				case TaskCategory.VirtualStagingIndoorRenovation:
				case TaskCategory.VirtualStagingOutdoorRenovation:
				case TaskCategory.VirtualStagingRemoveObjects:
					showModal('tasks.retouching', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.Arial:
					showModal('tasks.arial', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.Person:
				case TaskCategory.PersonInfo:
					showModal('tasks.person.info', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.BeforeAfter:
					showModal('tasks.beforeAfter', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.SomeGif:
					showModal('tasks.social.media.gif', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.ImageSelector:
					showModal('tasks.image.selector', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.SoMe:
					showModal('tasks.social.media', { task, onTaskComplete, onTaskCancel })
					break

				case TaskCategory.SoMeSlideshow:
					showModal('tasks.social.media.slideshow', { task, onTaskComplete, onTaskCancel })
					break
			}
		},
		[showModal, onTaskComplete, onTaskCancel]
	)
}

export function useTasksTable(params: IGetTaskListParams, variant: 'basic' | 'extended' = 'extended') {
	const openTask = useOpenTask()
	const { f, t } = useLocalization()
	const sortable = variant === 'extended'
	const enabled =
		(!params.query || params.query.length >= 3) &&
		(!params.branchIds ||
			params.branchIds.length > 0 ||
			!params.category ||
			params.category.length > 0 ||
			!params.status ||
			params.status.length > 0)
	const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useTasks(params, enabled)

	const getDefaultSortOrder = useCallback(
		(columnDataIndex: string) =>
			(params.orderBy === columnDataIndex ? params.orderByDirection : undefined) as SortOrder | undefined,
		[params]
	)
	const match = useScreenMatch()

	const desktopColumns = useMemo(
		() =>
			createTableColumns<IDatahubTask>([
				match.gte('xxl') && {
					title: t('common.terms.creationDate'),
					dataIndex: 'createdAt',
					sorter: sortable,
					sortOrder: getDefaultSortOrder('createdAt'),
					render: (value: Date) => f(value, { date: 'short' }),
				},
				{
					title: t('common.terms.department'),
					dataIndex: 'branchName',
					ellipsis: true,
					sorter: sortable,
					sortOrder: getDefaultSortOrder('branchName'),
				},
				{
					title: t('common.terms.case'),
					dataIndex: 'caseReference',
					ellipsis: true,
					sorter: sortable,
					sortOrder: getDefaultSortOrder('caseReference'),
				},
				{
					title: t('common.terms.address'),
					dataIndex: 'address',
					ellipsis: true,
					render: (value: string) => (value?.trim() ? value : '-'),
				},
				variant === 'extended' && {
					title: t('common.terms.product'),
					dataIndex: 'productName',
					ellipsis: true,
					sorter: sortable,
					sortOrder: getDefaultSortOrder('productName'),
				},
				match.gte('xxl') && {
					title: t('common.terms.type'),
					dataIndex: 'type',
					ellipsis: true,
					sorter: sortable,
					sortOrder: getDefaultSortOrder('type'),
					render: item => t(`tasks.categories.${item}`),
				},
				variant === 'extended' && {
					title: t('common.terms.status'),
					dataIndex: 'status',
					ellipsis: true,
					sorter: sortable,
					sortOrder: getDefaultSortOrder('status'),
					render: item => t(`tasks.statuses.${item}`),
				},
				{
					title: '',
					dataIndex: 'actions',
					align: 'right',
					width: 200,
					render: (_, taskObj) => (
						<Button type={'primary'} shape={'round'} size={'small'} onClick={() => openTask(taskObj)}>
							{taskObj.status === TaskStatus.Complete ? t('common.actions.viewTask') : t('common.actions.takeAction')}
						</Button>
					),
				},
			]),
		[f, t, openTask, sortable, variant, match, getDefaultSortOrder]
	)

	const mobileColumns = useMemo(
		() =>
			createTableColumns<IDatahubTask>([
				{
					title: t('common.terms.task'),
					render: (_, taskObj) => (
						<Container space={'lg'} spaceDirection={'vertical'}>
							<Grid.Row gutter={'sm'}>
								<Grid.Col span={24}>
									<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
										<Typography.Text type={'secondary'} strong>
											{t('common.terms.case')}
										</Typography.Text>
										<Typography.Text ellipsis title={taskObj.caseReference}>
											{taskObj.caseReference}
										</Typography.Text>
									</Container>
								</Grid.Col>
								<Grid.Col span={12}>
									<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
										<Typography.Text type={'secondary'} strong>
											{t('common.terms.department')}
										</Typography.Text>
										<Typography.Text ellipsis title={taskObj.branchName}>
											{taskObj.branchName}
										</Typography.Text>
									</Container>
								</Grid.Col>
								<Grid.Col span={12}>
									<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
										<Typography.Text type={'secondary'} strong>
											{t('common.terms.address')}
										</Typography.Text>
										<Typography.Text ellipsis title={taskObj.address}>
											{taskObj.address}
										</Typography.Text>
									</Container>
								</Grid.Col>
								{variant === 'extended' && (
									<Fragment>
										<Grid.Col span={12}>
											<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
												<Typography.Text type={'secondary'} strong>
													{t('common.terms.type')}
												</Typography.Text>
												<Typography.Text ellipsis title={t(`tasks.categories.${taskObj.type}`)}>
													{t(`tasks.categories.${taskObj.type}`)}
												</Typography.Text>
											</Container>
										</Grid.Col>
										<Grid.Col span={12}>
											<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
												<Typography.Text type={'secondary'} strong>
													{t('common.terms.product')}
												</Typography.Text>
												<Typography.Text ellipsis title={taskObj.productName}>
													{taskObj.productName}
												</Typography.Text>
											</Container>
										</Grid.Col>
									</Fragment>
								)}
							</Grid.Row>
							{match.lte('xs') && (
								<Button type={'primary'} shape={'round'} size={'small'} onClick={() => openTask(taskObj)}>
									{taskObj.status === TaskStatus.Complete
										? t('common.actions.viewTask')
										: t('common.actions.takeAction')}
								</Button>
							)}
						</Container>
					),
				},
				match.gte('sm') && {
					title: t('common.terms.actions'),
					align: 'right',
					width: 100,
					render: (_, taskObj) => (
						<Container display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
							<Button type={'primary'} shape={'circle'} size={'small'} onClick={() => openTask(taskObj)}>
								<ArrowRight24 />
							</Button>
						</Container>
					),
				},
			]),
		[openTask, t, variant, match]
	)

	const columns = useMemo(
		() =>
			match.map({
				xxs: mobileColumns,
				lg: desktopColumns,
			}),
		[match, mobileColumns, desktopColumns]
	)

	const dataSource = useMemo(
		() => data?.pages.reduce((acc, page) => [...acc, ...page.items], [] as IDatahubTask[]) ?? [],
		[data]
	)

	return {
		isLoading,
		columns,
		dataSource,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
		openTask,
	}
}

export function useTasksQueriesInvalidation() {
	const queryClient = useQueryClient()

	return useCallback(() => {
		queryClient.invalidateQueries(QUERIES.tasks)
		queryClient.invalidateQueries(QUERIES.taskFilters)
		queryClient.invalidateQueries(QUERIES.totalTasksByReference)
		queryClient.invalidateQueries(QUERIES.totalTasksForUser)
		queryClient.invalidateQueries(QUERIES.orderTasks) // tasks source for reciept page
		queryClient.invalidateQueries(QUERIES.getOrder) // task list presented in order details also, i.e. receipt page
		queryClient.invalidateQueries(QUERIES.orderLines) // task list presented in order details also, i.e. receipt page
	}, [queryClient])
}

export function useNoteSubmitter(task: IDatahubTask) {
	const invalidateTaskQueries = useTasksQueriesInvalidation()
	const { t } = useLocalization()
	const { user } = useAuth()
	let notePrefix = ''

	switch (task.type) {
		case TaskCategory.Basic:
		case TaskCategory.BasicOpt:
			notePrefix = `${t('common.terms.comment')}: `
			break
		case TaskCategory.IntroOutroSpeak:
		case TaskCategory.SpeakInput:
			notePrefix = `${t('modals.speakInput.inputToSpeakLabel')}: `
			break
	}

	const { mutateAsync: createComment, isLoading: isCreateCommentLoading } = useMutation(TasksApi.createComment)
	const { mutateAsync: completeTask, isLoading: isCompleteTaskLoading } = useMutation(TasksApi.completeTask, {
		onSuccess: result => {
			if (result.success) invalidateTaskQueries()
		},
	})

	return {
		isLoading: isCreateCommentLoading || isCompleteTaskLoading,
		submit: async (noteText: string, options?: ISubmitNoteOptions) => {
			options && options.person && (notePrefix = `${t('common.terms.person')}: ${options.person}, ${notePrefix}`)

			const response = await createComment({
				comment: notePrefix + noteText,
				taskId: task.id,
				email: user?.email || '',
			})

			if (!response?.success) {
				message.error(t('modals.copywriteText.commentFailed'))
				return false
			}
			const taskCompleteResponse = await completeTask(task.id)

			if (!taskCompleteResponse?.success) {
				message.error(t('modals.copywriteText.completeFailed'))
				return false
			}

			message.success(t('modals.copywriteText.success'))

			return true
		},
	}
}

export const useInputForSpeak = (caseId: IDatahubCase['id'], enabled = true) =>
	useQuery(
		[QUERIES.inputForSpeakSlideShowText, caseId],
		async () => {
			const groupedTexts = await TasksApi.getInputForSpeakSlideShowText(caseId)

			return groupedTexts
				.reduce(
					(memo, next) =>
						memo +
						' ' +
						next.texts
							.reduce((textMemo, textNext) => (textNext.content ? `${textMemo} ${textNext.content}` : textMemo), '')
							.trim(),
					''
				)
				.trim()
		},
		{
			enabled,
		}
	)

export function useTasks(params: IGetTaskListParams, enabled = true) {
	return useInfiniteQuery(
		[QUERIES.tasks, params],
		({ pageParam = 0 }) =>
			TasksApi.getList({
				...params,
				page: pageParam,
			}),
		{
			enabled,
			getNextPageParam: ({ page, pageSize, totalItems }) => {
				return (page + 1) * pageSize < totalItems ? page + 1 : undefined
			},
		}
	)
}

export function useOrderTasks(orderId?: ISimplifiedOrder['id']) {
	return useQuery(
		[QUERIES.orderTasks, orderId],
		async () => (orderId ? await TasksApi.getOrderTasks(orderId) : undefined),
		{
			enabled: !!orderId,
		}
	)
}

export function useUploadsByTask(taskId: IDatahubTask['id']) {
	return useQuery(
		[QUERIES.uploadsByTaskId, taskId],
		async () => (taskId ? await TasksApi.getUploads(taskId) : undefined),
		{
			enabled: !!taskId,
		}
	)
}
