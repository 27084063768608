/* eslint-disable @typescript-eslint/no-explicit-any */
import { IDatahubCase, ISimplifiedVisualMaterial } from '@libs/api'
import React, { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import { FixedSizeGrid } from 'react-window'
import { ReactWindowScroller } from 'react-window-scroller'
import memoize from 'memoize-one'
import MaterialItem, { IMaterialItem } from '../MaterialItem'
import { Grid } from '@libs/components'
import { useShowModal } from 'modules/common/GlobalModals'
import Styled from './Styled'
import { useScreenMatch } from '@libs/theme'

const GUTTER_SIZE = 20

// There is some true spagetti code right here and
// we should definetely refactor the MaterialList component
// so that it provides less props but more imperative

export interface IMaterialList extends Pick<IMaterialItem, 'onDescriptionEdit' | 'disabledInactiveOverlay'> {
	/** List of materials */
	visualMaterials: ISimplifiedVisualMaterial[]
	/** Interaction state */
	disabled?: boolean
	actionDisabled?: boolean
	/** Sorting state */
	sortable?: boolean
	scrollableAtHeight?: string
	/** Material Item click handler */
	onItemClick?: (materialId: ISimplifiedVisualMaterial['id']) => void
	className?: string
	caseId?: IDatahubCase['id']
	size?: 'small' | 'large'
	showAll?: boolean
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Cell = React.memo(({ data, columnIndex, rowIndex, style }: any) => {
	const {
		visualMaterials,
		columnsCount,
		showModal,
		actionDisabled,
		caseId,
		disabled,
		disabledInactiveOverlay,
		modalMaterials,
		onDescriptionEdit,
		onItemClick,
		sortable,
	} = data

	const index = Math.round(rowIndex * columnsCount + columnIndex)
	const material = visualMaterials[index]

	if (!material)
		return (
			<div
				style={{
					...style,
					top: style.top + GUTTER_SIZE,
					height: style.height - GUTTER_SIZE,
					left: style.left + GUTTER_SIZE,
					width: style.width - GUTTER_SIZE,
				}}
			/>
		)

	return (
		<div
			style={{
				...style,
				top: style.top + GUTTER_SIZE,
				height: style.height - GUTTER_SIZE,
				left: style.left + GUTTER_SIZE,
				width: style.width - GUTTER_SIZE,
			}}
		>
			<div onClick={() => !disabled && !material.isIFPMaterial && onItemClick?.(material.id)}>
				<MaterialItem
					index={index}
					dragDisabled={disabled || material.isIFPMaterial}
					disabled={disabled || !sortable || material.isIFPMaterial}
					selectable={!sortable && !disabled && !material.isIFPMaterial}
					disabledInactiveOverlay={disabledInactiveOverlay}
					showDisabledInteraction={!!onDescriptionEdit && material.isIFPMaterial}
					onDescriptionEdit={!material.isIFPMaterial ? onDescriptionEdit : undefined}
					showHighQualityImage={material.sourceOrientation !== 'Landscape' && material.sourceType === 'Image'}
					onImageClick={
						disabled && !actionDisabled
							? () => {
									if (material.sourceType === 'Link') {
										window.open(material.source)
										return
									}
									showModal('material.carousel', {
										visualMaterials: modalMaterials,
										initialMaterialId: material.id,
									})
							  }
							: undefined
					}
					{...material}
					fileName={material.fileName}
					thumbnailUrl={material.thumbnailUrl}
					tags={material.label ? [material.label.translateKey] : []}
					caseId={caseId}
				/>
			</div>
		</div>
	)
})

const createItemData = memoize(
	(
		visualMaterials,
		columnsCount,
		showModal,
		actionDisabled,
		caseId,
		disabled,
		disabledInactiveOverlay,
		modalMaterials,
		onDescriptionEdit,
		onItemClick,
		sortable
	) => ({
		visualMaterials,
		columnsCount,
		showModal,
		actionDisabled,
		caseId,
		disabled,
		disabledInactiveOverlay,
		modalMaterials,
		onDescriptionEdit,
		onItemClick,
		sortable,
	})
)

const MaterialList = SortableContainer(
	({
		caseId,
		visualMaterials,
		disabled,
		onDescriptionEdit,
		onItemClick,
		className,
		sortable,
		disabledInactiveOverlay,
		actionDisabled,
		scrollableAtHeight,
		size = 'large',
		showAll,
	}: IMaterialList) => {
		const showModal = useShowModal()
		const match = useScreenMatch()
		const [, updateState] = useState<unknown>()
		const listContainerRef = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>

		const modalMaterials = useMemo(
			() => visualMaterials.filter(({ sourceType }) => sourceType.toLowerCase() !== 'link'),
			[visualMaterials]
		)

		useEffect(() => {
			// Set initial scale
			const updateScale = () => updateState({})

			// Set scale on resize
			window.addEventListener('resize', updateScale)
			return () => {
				window.removeEventListener('resize', updateScale)
			}
		}, [])

		const columnsCount = useMemo(() => {
			if (size === 'small') return 3

			if (match.lte('xs')) return 1
			if (match.lte('lg')) return 3
			return 6
		}, [size, match])

		const itemData = createItemData(
			visualMaterials,
			columnsCount,
			showModal,
			actionDisabled,
			caseId,
			disabled,
			disabledInactiveOverlay,
			modalMaterials,
			onDescriptionEdit,
			onItemClick,
			sortable
		)

		const rowCount = Math.ceil(visualMaterials.length / columnsCount)
		const columnWidth = listContainerRef.current ? listContainerRef.current.clientWidth / columnsCount : 0
		const rowHeight = useMemo(
			() => columnWidth * 0.565 + (onDescriptionEdit ? 81 : 71),
			[columnWidth, onDescriptionEdit]
		) // hardcoded sizes
		const height = useMemo(() => rowHeight * rowCount + GUTTER_SIZE, [rowHeight, rowCount])

		return (
			<Styled.Wrapper className={className} $scrollableAtHeight={scrollableAtHeight}>
				<Grid.Row
					ref={listContainerRef}
					gutter="sm"
					style={{ height, marginLeft: -GUTTER_SIZE, marginTop: -GUTTER_SIZE }}
				>
					<ReactWindowScroller isGrid>
						{({ ref, outerRef, onScroll, style }) => (
							<FixedSizeGrid
								ref={ref as any}
								outerRef={outerRef}
								onScroll={onScroll as any}
								style={style}
								columnCount={columnsCount}
								columnWidth={columnWidth}
								height={window.innerHeight}
								rowCount={rowCount}
								rowHeight={rowHeight}
								width={window.innerWidth}
								itemData={itemData}
								{...(visualMaterials.length < 100 && {
									overscanRowCount: showAll ? 999 : 3,
								})}
							>
								{Cell}
							</FixedSizeGrid>
						)}
					</ReactWindowScroller>
				</Grid.Row>
			</Styled.Wrapper>
		)
	}
)

export default MaterialList
