import styled, { css } from 'styled-components'
import { Card as _Card, Typography } from 'antd'
import { margin, fontSize, screen } from '@libs/theme/mixins'

const Card = styled(_Card)`
	height: 100%;
	min-width: 300px;

	.ant-card-body {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
`

const ImageContainer = styled.div`
	min-height: 170px;
	max-height: 200px;
	margin-bottom: ${margin('sm')};
	display: flex;
	flex: 1;

	img {
		width: 100%;
		object-fit: cover;
	}
`

const Title = styled(Typography.Paragraph)`
	margin-bottom: ${margin('xxs')};
	${screen.lte.md`
		text-align: center;
	`}
`

const Price = styled(Typography.Text)<{ $hyphen: boolean }>`
	display: flex;

	${({ $hyphen }) =>
		$hyphen &&
		css`
			flex-direction: column;
			align-items: center;
		`}

	span {
		margin-right: 5px;
	}

	font-size: ${fontSize('lg')};
	${screen.lte.md`
		text-align: center;
	`}
`

const Note = styled(Typography.Text)<{ $visible: boolean }>`
	visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
	user-select: ${({ $visible }) => ($visible ? 'auto' : 'none')};
	font-size: ${fontSize('sm')};
	margin-bottom: ${margin('md')};
`

export default {
	Card,
	Title,
	ImageContainer,
	Price,
	Note,
}
