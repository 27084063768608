import { IDatahubCase, ISimplifiedVisualMaterial, MaterialApi } from '@libs/api'
import { Container } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { AutoComplete, Typography } from 'antd'
import queries from 'modules/common/queries'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { View16, ViewOff16 } from '@carbon/icons-react'
import format from '@libs/localization/build/format'

import Styled from './Styled'

export interface IMaterialItemMeta
	extends Pick<ISimplifiedVisualMaterial, 'updatedDate' | 'description' | 'isActive' | 'id'> {
	/** Name editing callback */
	onDescriptionEdit?: (
		name: ISimplifiedVisualMaterial['description'],
		materialId: ISimplifiedVisualMaterial['id']
	) => void
	caseId?: IDatahubCase['id']
}

const MaterialItemMeta: FC<IMaterialItemMeta> = React.memo(
	({ onDescriptionEdit, updatedDate, description, id, isActive, caseId }) => {
		const { t } = useLocalization()
		const [searchKey, setSearchKey] = useState(description || '')

		const { data: suggestedNames = [] } = useQuery(
			[queries.suggestedMaterialNames],
			async () => (caseId ? await MaterialApi.getSuggestedMaterialNames(caseId) : undefined),
			{
				enabled: !!onDescriptionEdit && !!caseId,
			}
		)

		const filteredNames = useMemo(
			() => suggestedNames.filter(suggestion => suggestion.includes(searchKey)),
			[searchKey, suggestedNames]
		)

		useEffect(() => {
			setSearchKey(description || '')
		}, [description])

		return (
			<Container padding="xxs">
				<Container display="flex" alignItems="center" justifyContent="space-between">
					{onDescriptionEdit ? (
						<AutoComplete
							searchValue={searchKey}
							onClick={e => e.stopPropagation()}
							placeholder={`${t('common.terms.name')}...`}
							onSearch={value => setSearchKey(value)}
							onSelect={value => {
								onDescriptionEdit(value, id)
							}}
							onBlur={e => {
								onDescriptionEdit((e.target as any).value, id)
							}}
							showSearch
							bordered={false}
							filterOption={false}
						>
							{filteredNames.map((suggestion, index) => (
								<AutoComplete.Option key={suggestion + index} value={suggestion}>
									{suggestion}
								</AutoComplete.Option>
							))}
						</AutoComplete>
					) : (
						<Styled.Name ellipsis>{description || <>&nbsp;</>}</Styled.Name>
					)}
					{isActive ? <View16 /> : <ViewOff16 />}
				</Container>
				<Container display="flex" alignItems="center" justifyContent="space-between">
					<Typography.Text type="secondary">
						{updatedDate ? format(updatedDate, { date: 'short' }) : <>&nbsp;</>}
					</Typography.Text>
				</Container>
			</Container>
		)
	}
)

export default MaterialItemMeta
