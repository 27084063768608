import styled from 'styled-components'
import { Grid } from '@libs/components'
import { color, padding, margin, screen } from '@libs/theme/mixins'
import { Typography } from 'antd'
import { InputNumber } from 'formik-antd'

const DividedRow = styled(Grid.Row)`
	> *:first-child {
		border-right: 1px solid ${color(['gray', '600'])};
		padding-right: ${padding('xl')} !important;
	}

	> *:last-child {
		padding-left: ${padding('xl')} !important;
	}

	h5 {
		margin-bottom: ${margin('sm')};
	}
`

const CardListTitle = styled(Typography.Title)`
	margin-bottom: ${margin('sm')} !important;
	${screen.lte.xs`
		text-align: center;
	`}
`

const AreaInput = styled(InputNumber)`
	width: 100%;
`

export default {
	DividedRow,
	CardListTitle,
	AreaInput,
}
