import { Container } from '@libs/components'
import { borderRadius, color } from '@libs/theme/mixins'
import styled, { css } from 'styled-components'

const GridTemplate = styled.div<{ $grid: string; $height?: number; $disabled?: boolean }>`
	display: grid;
	gap: 0;
	grid-template-columns: 50%;
	${({ $grid }) =>
		$grid &&
		css`
			grid-template-areas: ${$grid};
		`}

	${({ $disabled }) =>
		$disabled &&
		css`
			pointer-events: none;
		`}

	${({ $height }) =>
		$height &&
		css`
			height: ${$height}px;
		`}
`

const CollageItemWrapper = styled(Container)`
	display: flex;
	position: relative;

	.advanced-cropper {
		width: 100%;
		background: transparent;
	}
`

const DropArea = styled(Container)`
	border: 4px dashed ${color(['gray', '400'])};
	border-radius: ${borderRadius('md')};
	background: ${color(['gray', '200'])};
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 2px;
	left: 2px;
	right: 2px;
	bottom: 2px;
`

const LoaderWrapper = styled(Container)`
	position: absolute;
	top: 2px;
	left: 2px;
	right: 2px;
	bottom: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
`

export default { GridTemplate, CollageItemWrapper, DropArea, LoaderWrapper }
