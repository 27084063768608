import React, { FC, RefObject, useRef, useEffect, useState, useMemo, SyntheticEvent, useCallback } from 'react'
import { Radio as AntRadio, Form as AntForm, Tooltip, Affix, Select } from 'antd'
import { Cursor_224, DotMark24, ArrowRight24, Lasso24 } from '@carbon/icons-react'
import { Container, FormikTextArea } from '@libs/components'
import { Form, FormItem, Radio } from 'formik-antd'
import Styled from './Styled'
import FeedbackCanvas, { IFeedbackCanvasProps } from 'modules/cases/FeedbackCanvas'
import { CSSTransition } from 'react-transition-group'
import { FeedbackDrawingShape, IDatahubTask, ISimplifiedVisualMaterial, TaskCategory } from '@libs/api'
import { IFeedbackShape } from 'modules/cases/FeedbackPageContent/types'
import { useLocalization } from '@libs/localization'
import { useScreenMatch } from '@libs/theme'
import { IDropdownOption } from 'modules/common/types'
import { useFormikContext, getIn } from 'formik'

const IMAGE_TRANSITION_TIMEOUT = 500
const DEFAULT_IMG_SOURCE = '/placeholders/image-placeholder.jpeg'

export interface MaterialFeedbackProps extends Omit<IFeedbackCanvasProps, 'mode'> {
	feedbackContainerRef?: RefObject<HTMLDivElement>
	source: ISimplifiedVisualMaterial['source']
	scrollableRef?: HTMLElement
	task?: IDatahubTask
}

export interface IPopoverContentProps {
	name: string
	visible: boolean
	type?: 'text' | 'radio' | 'dropdown'
}

const OUTDOOR_OPTIONS_KEYS: Record<string, string[]> = {
	ROOF: ['BLACK', 'DARK_GREY', 'LIGHT_GREY', 'RED'],
	FACADE: ['WHITE', 'BLACK', 'DARK_GREY', 'LIGHT_GREY', 'BEIGE'],
	GRASS: ['REPAIR_GRASS', 'REPLACE_GRASS', 'GREEN_GRASS'],
	WINDOWS_AND_DOORS: ['WHITE', 'BLACK', 'BEIGE'],
	SOCKET_COLOR: ['BLACK', 'DARK_GREY'],
	REMOVE_BLUR: ['REMOVE', 'BLUR'],
}

// Replacemenet of old translation
const outdoorOptionsMap = (key: string): string => {
	switch (key) {
		case 'ROOF':
			return 'Roof'
		case 'FACADE':
			return 'Facade'
		case 'WINDOWS_AND_DOORS':
			return 'Windows & doors'
		case 'SOCKET_COLOR':
			return 'Socket color'
		case 'BLACK':
			return 'Black'
		case 'BLACK_NO_PATTERN':
			return 'Black (no pattern)'
		case 'BLACK_WITH_PATTERN':
			return 'Black (with pattern)'
		case 'DARK_GREY':
			return 'Dark grey'
		case 'DARK_GREY_NO_PATTERN':
			return 'Dark grey (no pattern)'
		case 'DARK_GREY_WITH_PATTERN':
			return 'Dark grey (with pattern)'
		case 'LIGHT_GREY':
			return 'Light grey'
		case 'LIGHT_GREY_NO_PATTERN':
			return 'Light grey (no pattern)'
		case 'LIGHT_GREY_WITH_PATTERN':
			return 'Light grey (with pattern)'
		case 'BEIGE':
			return 'Beige'
		case 'BEIGE_NO_PATTERN':
			return 'Beige (no pattern)'
		case 'BEIGE_WITH_PATTERN':
			return 'Beige (with pattern)'
		case 'WHITE':
			return 'White'
		case 'WHITE_NO_PATTERN':
			return 'White (no pattern)'
		case 'WHITE_WITH_PATTERN':
			return 'White (with pattern)'
		case 'RED':
			return 'Red #D97359'
		case 'REMOVE_BLUR':
			return 'Remove/Blur'
		case 'REMOVE':
			return 'Remove'
		case 'BLUR':
			return 'Blur'
		case 'GRASS':
			return 'Grass'
		case 'REPAIR_GRASS':
			return 'Repair grass'
		case 'REPLACE_GRASS':
			return 'Replace grass'
		case 'GREEN_GRASS':
			return 'Green grass'
		default:
			return key
	}
}

export const PopoverContent: FC<IPopoverContentProps> = ({ name, visible, type = 'text' }) => {
	const wrapperRef = useRef<HTMLDivElement>(null)
	const { t } = useLocalization()
	const [selectedType, selectType] = useState<string>()
	const [selectedSubType, selectSubType] = useState<string>()
	const { values, setFieldValue } = useFormikContext()
	const formikValue = getIn(values, name)

	useEffect(() => {
		if (selectedType && selectedSubType) {
			const foundSubType = outdoorOptionsMap(selectedSubType || '')
			setFieldValue(name, `${outdoorOptionsMap(selectedType)}${foundSubType ? `: ${foundSubType}` : ''}`)
			return
		}
		// or reset since both are required
		setFieldValue(name, null)
	}, [selectedSubType])

	useEffect(() => {
		if (!formikValue) {
			if (type === 'dropdown' && !selectedType) {
				// select 1st option by the default
				selectType('ROOF')
			}
		}
	}, [formikValue])

	const isRadio = type === 'radio'
	const isDropdown = type === 'dropdown'

	const outdoorOptions = useMemo<IDropdownOption[]>(
		() =>
			Object.keys(OUTDOOR_OPTIONS_KEYS).map(key => ({
				value: key,
				label: t(`modals.retouchTask.outdoorTypes.${key}`),
			})),
		[t]
	)

	const outdoorSubOptions = useMemo<IDropdownOption[]>(
		() =>
			selectedType
				? OUTDOOR_OPTIONS_KEYS[selectedType].map(key => ({
						value: key,
						label: t(`modals.retouchTask.outdoorTypes.${key}`),
				  }))
				: [],
		[selectedType, t]
	)

	useEffect(() => {
		if (visible) {
			const timeout = setTimeout(() => {
				wrapperRef.current?.querySelector('textarea')?.focus()
			}, 250)

			return () => {
				clearTimeout(timeout)
			}
		}
	}, [visible])

	return (
		<Styled.PopoverContent ref={wrapperRef} $hasRadio={isRadio}>
			<Form layout={'vertical'}>
				{isDropdown && (
					<>
						<FormItem name={name} label={t('common.placeholders.selectType')} required>
							<Select
								value={selectedType}
								onSelect={(value: string) => {
									selectType(value)
									selectSubType(undefined)
								}}
							>
								{outdoorOptions.map(({ value, label }, index) => (
									<Select.Option key={index} value={value}>
										{label}
									</Select.Option>
								))}
							</Select>
						</FormItem>
						{selectedType && (
							<FormItem name={name} label={t(`modals.retouchTask.outdoorTypes.${selectedType}`)} required>
								<Select value={selectedSubType} onSelect={(value: string) => selectSubType(value)}>
									{outdoorSubOptions.map(({ value, label }, index) => (
										<Select.Option key={index} value={value}>
											{label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
						)}
					</>
				)}
				{!isDropdown && (
					<FormItem name={name} label={isRadio ? '' : t('common.terms.comment')}>
						{isRadio && (
							<Radio.Group name={name}>
								<Radio name={name} value={t('modals.retouchTask.removal.mustBeRemoved')}>
									{t('modals.retouchTask.removal.mustBeRemoved')}
								</Radio>
								<Radio name={name} value={t('modals.retouchTask.removal.mustBeBlurred')}>
									{t('modals.retouchTask.removal.mustBeBlurred')}
								</Radio>
							</Radio.Group>
						)}
						{!isRadio && !isDropdown && <FormikTextArea name={name} autoSize={{ minRows: 2, maxRows: 4 }} />}
					</FormItem>
				)}
			</Form>
		</Styled.PopoverContent>
	)
}

const MaterialFeedback: FC<MaterialFeedbackProps> = ({
	feedbackContainerRef,
	source,
	scrollableRef,
	task,
	...feedbackCanvasProps
}) => {
	const containerRef = useRef<HTMLDivElement>(null)
	const { t } = useLocalization()
	const [selectedShapeIndex, setSelectedShapeIndex] = useState(0)
	const [erroredImageSource, setErroredImageSource] = useState('')

	const onImageLoadError = useCallback((event: SyntheticEvent<HTMLImageElement, Event>) => {
		const img = event.target as HTMLImageElement
		setErroredImageSource(img.src)
		img.src = DEFAULT_IMG_SOURCE
	}, [])

	const shapes: IFeedbackShape[] = useMemo(() => {
		switch (task?.type) {
			case TaskCategory.VirtualStagingRemoveObjects:
				return [{ type: FeedbackDrawingShape.Lasso, label: t('common.terms.lasso'), icon: <Lasso24 /> }]
			case TaskCategory.VirtualStagingOutdoorRenovation:
				return [
					{ type: FeedbackDrawingShape.Point, label: t('common.terms.point'), icon: <DotMark24 /> },
					{ type: FeedbackDrawingShape.Lasso, label: t('common.terms.lasso'), icon: <Lasso24 /> },
				]
			default:
				return [
					{ type: FeedbackDrawingShape.Point, label: t('common.terms.point'), icon: <DotMark24 /> },
					{ type: FeedbackDrawingShape.Arrow, label: t('common.terms.arrow'), icon: <ArrowRight24 /> },
					{ type: FeedbackDrawingShape.Lasso, label: t('common.terms.lasso'), icon: <Lasso24 /> },
				]
		}
	}, [t, task])

	const match = useScreenMatch()

	const Tools = useMemo(
		() => (
			<Styled.ToolsContainer
				display={'flex'}
				flexDirection={match.map({ xxs: 'column', md: 'row' })}
				alignItems={match.map({ xxs: 'flex-start', md: 'flex-end' })}
				space={match.gte('md') ? 'md' : 0}
				spaceDirection={match.map({ xxs: 'vertical', md: 'horizontal' })}
			>
				<Container>
					<AntForm.Item>
						<AntRadio.Group
							size={'small'}
							value={shapes[selectedShapeIndex].type}
							onChange={e => setSelectedShapeIndex(shapes.findIndex(candidate => candidate.type === e.target.value)!)}
						>
							{shapes.map(({ type, icon, label }) => (
								<Tooltip key={type} title={label} placement={'top'}>
									<AntRadio.Button value={type} key={type}>
										{icon}
									</AntRadio.Button>
								</Tooltip>
							))}
						</AntRadio.Group>
					</AntForm.Item>
				</Container>
				<Styled.Instructions>
					<Cursor_224 />
					<span>{t('materials.messages.feedbackInstructions')}</span>
				</Styled.Instructions>
			</Styled.ToolsContainer>
		),
		[match, shapes, selectedShapeIndex, t]
	)

	return (
		<Container ref={feedbackContainerRef} space={'xxs'} spaceDirection={'vertical'}>
			{scrollableRef ? (
				<Affix offsetTop={0} target={() => scrollableRef}>
					{Tools}
				</Affix>
			) : (
				Tools
			)}

			<Styled.ImageContainer $transitionTimeout={IMAGE_TRANSITION_TIMEOUT} ref={containerRef}>
				<Styled.ImageContainerTransitionGroup>
					<CSSTransition timeout={IMAGE_TRANSITION_TIMEOUT} classNames={'transition'}>
						<img src={source} alt="Image with feedback" onError={onImageLoadError} />
					</CSSTransition>
				</Styled.ImageContainerTransitionGroup>
				<FeedbackCanvas
					selectedImageSource={source}
					mode={shapes[selectedShapeIndex].type}
					{...feedbackCanvasProps}
					containerRef={containerRef}
				/>
				{erroredImageSource === source && (
					<Styled.ImageErrorMessage type={'danger'}>
						{t('cases.pages.feedback.imageLoadErrorMessage')}
					</Styled.ImageErrorMessage>
				)}
			</Styled.ImageContainer>
		</Container>
	)
}

export default MaterialFeedback
