import { IDatahubTask, MaterialApi, TaskCategory, TasksApi } from '@libs/api'
import { useHideModal } from '@libs/components'
import { Modal, Typography, Divider, List, Table } from 'antd'
import React, { FC, useMemo } from 'react'
import { useLocalization } from '@libs/localization'
import { Container } from '@libs/components'
import { Close24 } from '@carbon/icons-react'
import { useQuery } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useUploadsByTask } from '../hooks'

export interface CompletedTaskModalProps extends IDatahubTask {}

const CompletedTaskModal: FC<CompletedTaskModalProps> = ({ type, orderlineId, productName, id, caseId }) => {
	const hideModal = useHideModal()
	const { t } = useLocalization()
	const { data: materialData } = useQuery(
		[QUERIES.visualMaterialsSimplified, caseId],
		() => MaterialApi.getSimplifiedVisualMaterials(caseId),
		{
			enabled: !!caseId && type === TaskCategory.BeforeAfter,
		}
	)

	const { materials = [] } = materialData || {}

	const { data: uploadedFiles = [] } = useUploadsByTask(id)

	const { data: copyWriteTexts = [] } = useQuery([QUERIES.copyWriteTexts, orderlineId], () =>
		TasksApi.getCopyWriteText(orderlineId)
	)

	const beforeAfter = useMemo(() => {
		const foundBeforeAfter = materials.find(({ before, after }) => before && after)

		if (!foundBeforeAfter) return

		return {
			beforeSource: foundBeforeAfter.before,
			afterSource: foundBeforeAfter.after,
		}
	}, [materials])

	const uploadedFilesColumns = useMemo(
		() => [
			{
				title: t('modals.completedTask.fileNameColumn'),
				dataIndex: 'fileName',
				key: 'fileName',
			},
			{
				title: t('modals.completedTask.guidColumn'),
				dataIndex: 'guid',
				key: 'guid',
			},
		],
		[t]
	)

	return (
		<Modal
			closeIcon={<Close24 />}
			visible
			width={960}
			title={t(`tasks.categories.${type}`)}
			onCancel={hideModal}
			footer={null}
		>
			<Typography.Title level={5}>{productName}</Typography.Title>
			<Divider />
			<Container space="lg" spaceDirection="vertical" margin={{ top: 'xl' }}>
				{!!copyWriteTexts.length && (
					<List
						dataSource={copyWriteTexts}
						renderItem={({ content, sectionName }) => (
							<List.Item>
								<List.Item.Meta
									title={<Typography.Title level={5}>{sectionName}</Typography.Title>}
									description={
										<>
											<Typography.Text strong>{t('modals.completedTask.originalText')}:</Typography.Text>
											<br />
											<Typography.Text>{content}</Typography.Text>
											<br />
										</>
									}
								/>
							</List.Item>
						)}
					/>
				)}
				{beforeAfter && (
					<Container display="flex" flexDirection="column" spaceDirection="vertical" space="md">
						<Container display="flex" flexDirection="column" spaceDirection="vertical" space="xs">
							<Typography.Text>{t('common.terms.before')}</Typography.Text>
							<img src={beforeAfter.beforeSource} alt={t('common.terms.before')} />
						</Container>
						<Container display="flex" flexDirection="column" spaceDirection="vertical" space="xs">
							<Typography.Text>{t('common.terms.after')}</Typography.Text>
							<img src={beforeAfter.afterSource} alt={t('common.terms.after')} />
						</Container>
					</Container>
				)}
				{!!uploadedFiles.length && (
					<>
						<Table pagination={false} size="small" dataSource={uploadedFiles} columns={uploadedFilesColumns} />
						<Typography.Text strong>
							{t('modals.completedTask.successfullyUploaded')}: {uploadedFiles.length || 0}
						</Typography.Text>
					</>
				)}
			</Container>
		</Modal>
	)
}

export default CompletedTaskModal
