import React, { FC } from 'react'
import { Button, Spin, Typography } from 'antd'
import { Autolocalize, Container, Stepper, useHideModal } from '@libs/components'
import { IDatahubCase } from '@libs/api'
import { Formik } from 'formik'
import { useCollageModal } from './hooks'
import { Close24 } from '@carbon/icons-react'

import Styled from './Styled'

export interface CollageModalProps {
	caseId: IDatahubCase['id']
}

export interface ICollageFormValues {
	template: number
	images: string[]
	outputImages: string[]
}

const initialFormValues: ICollageFormValues = {
	template: 0,
	images: [],
	outputImages: [],
}

const TRANSITION_TIMEOUT = 200

const CollageModal: FC<CollageModalProps> = ({ caseId }) => {
	const hideModal = useHideModal()
	const {
		theme,
		match,
		currentStep = 0,
		isLoading,
		steps = [],
		innerVisualMaterials = [],
		nextButtonText,
		t,
		onBackClick,
		onNextClick,
		onFormSubmit,
	} = useCollageModal({ caseId })

	return (
		<Formik<ICollageFormValues> initialValues={initialFormValues} onSubmit={values => onFormSubmit?.(values)}>
			{({ values, submitForm, isSubmitting }) => (
				<Styled.Modal
					visible
					closeIcon={<Close24 />}
					onCancel={hideModal}
					width={1200}
					$transitionTimeout={TRANSITION_TIMEOUT}
					title={t('modals.collage.title')}
					footer={
						<Container
							display="flex"
							alignItems="center"
							gap={theme.paddings.xs as any}
							flex={match.lte('xs') ? 1 : undefined}
							flexDirection={match.lte('xs') ? 'column' : 'row'}
						>
							<Button disabled={currentStep === 0 || isLoading} key="back" onClick={onBackClick}>
								{t('navigation.back')}
							</Button>
							<Button
								disabled={isLoading || (currentStep === 1 && values.outputImages.some(url => !url))}
								loading={isSubmitting}
								type="primary"
								key="next"
								onClick={() => {
									if (currentStep === 1) {
										submitForm()
										return
									}

									onNextClick()
								}}
							>
								{nextButtonText}
							</Button>
						</Container>
					}
				>
					<Autolocalize />
					{isLoading ? (
						<Container margin="xxl" display={'flex'} justifyContent={'center'}>
							<Spin />
						</Container>
					) : innerVisualMaterials.length > 0 ? (
						<Stepper
							current={currentStep}
							onStepChange={newIndex => {
								if (currentStep !== 0 && newIndex === 0) onBackClick()
							}}
						>
							{steps.map(({ title: stepTitle, content }, index) => (
								<Stepper.Step key={index} title={stepTitle}>
									{content}
								</Stepper.Step>
							))}
						</Stepper>
					) : (
						<Container margin="xxl" display={'flex'} justifyContent={'center'}>
							<Typography.Paragraph>{t('modals.virtualStaging.emptyList')}</Typography.Paragraph>
						</Container>
					)}
				</Styled.Modal>
			)}
		</Formik>
	)
}

export default CollageModal
