import React, { FC, useCallback, useEffect } from 'react'
import Head from 'next/head'
import { useMutation, useQueryClient } from 'react-query'
import { AuthApi, IS_ESOFT_USER_KEY } from '@libs/api'
import QUERIES from 'modules/common/queries'
import { useLocalization } from '@libs/localization'
import appRoutes from 'app.routes'
import { Autolocalize, Container, FormikInput, UserInteraction } from '@libs/components'
import { Formik } from 'formik'
import { Form, FormItem } from 'formik-antd'
import { createValidationSchema } from '@libs/util'
import { Col, message, Row, Typography } from 'antd'
import Styled from './Styled'

interface LoginPageContentProps {
	isEsoft?: boolean
}

interface IFormValues {
	email: string
}

const initialFormValues: IFormValues = {
	email: '',
}

const formSchema = createValidationSchema(yup =>
	yup.object().shape({
		email: yup.string().required().required(),
	})
)

const LoginPageContent: FC<LoginPageContentProps> = ({ isEsoft = false }) => {
	const queryClient = useQueryClient()
	const { t } = useLocalization()

	const callback = useCallback((response: Awaited<ReturnType<typeof AuthApi.esoftAuth>>) => {
		const isEsoftUser = localStorage.getItem(IS_ESOFT_USER_KEY) === 'true'
		if (response?.loginUrl) {
			window.location.href = response.loginUrl
			!isEsoftUser && queryClient.invalidateQueries(QUERIES.user)
		}
	}, [])

	const { mutateAsync, isLoading } = useMutation(
		({ email }: IFormValues) => AuthApi.b2cAuth(email, appRoutes.authRedirect()),
		{
			onError: (err: Error) => {
				message.error(err.message)
			},
			onSuccess: callback,
		}
	)

	useEffect(() => {
		if (isEsoft) {
			AuthApi.esoftAuth(appRoutes.authRedirect()).then(callback)
			return
		}
	}, [])

	return (
		<>
			<Head>
				<meta property="og:url" content="https://booqly.dk" />
				<meta property="og:type" content="Website" />
				<meta property="og:title" content={t('auth.og.title')} />
				<meta property="og:description" content={t('auth.og.subTitle')} />
				<meta property="og:image" content="https://dev.booqly.dk/logo.png" />
				<meta property="og:locale" content="en_US" />
				<meta property="og:locale:alternate" content="da_DK" />
				<meta property="og:locale:alternate" content="sv_SE" />
			</Head>
			{!isEsoft && (
				<Row>
					<Col xs={24} lg={{ span: 16, push: 4 }} xl={{ span: 12, push: 6 }}>
						<Container margin={{ bottom: 'xxl' }}>
							<Styled.Logo src={'/logo.png'} />
							<Typography.Title level={2}>{t('auth.pages.login.greeting')}</Typography.Title>
						</Container>
						<Formik<IFormValues>
							initialValues={initialFormValues}
							validationSchema={formSchema}
							onSubmit={values => mutateAsync(values)}
							validateOnBlur={false}
						>
							{({ dirty, isValid, isSubmitting }) => (
								<Form layout="vertical" noValidate>
									<Autolocalize />
									<UserInteraction enabled={!isSubmitting}>
										<FormItem label={t('common.terms.email')} name="email" required>
											<FormikInput autoFocus type="email" name="email" />
										</FormItem>
									</UserInteraction>
									<Styled.LoginButton
										disabled={!dirty || !isValid}
										loading={isLoading}
										title={t('auth.pages.login.submit')}
										type="primary"
										shape="round"
									>
										{t('navigation.next')}
									</Styled.LoginButton>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			)}
		</>
	)
}

export default LoginPageContent
