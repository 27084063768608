import { Close24 } from '@carbon/icons-react'
import { IDatahubOrderLine, ISimplifiedOrder, MaterialApi } from '@libs/api'
import { useHideModal } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { ANT_MODAL_VERTICAL_FOOTER_CLASS_NAME } from '@libs/theme'
import { Button, Typography, Modal, message } from 'antd'
import React, { FC } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useOrderById } from 'modules/orders/hooks'

export interface MaterialBatchRemovalModalProps {
	batchId: IDatahubOrderLine['batchId']
	orderId: ISimplifiedOrder['id']
	orderLineIds: IDatahubOrderLine['id'][]
}

const MaterialBatchRemovalModal: FC<MaterialBatchRemovalModalProps> = ({ batchId, orderId, orderLineIds }) => {
	const hideModal = useHideModal()
	const queryClient = useQueryClient()
	const { t } = useLocalization()
	const { data: foundOrder } = useOrderById(orderId)
	const { mutateAsync: removeEWARPMaterials, isLoading } = useMutation(MaterialApi.deleteMaterialsAndAssets, {
		onSuccess: result => {
			if (!result?.success) {
				message.error(t('modals.materialBatchRemoval.error'))
				return
			}
			message.success(t('modals.materialBatchRemoval.success'))
			queryClient.invalidateQueries(QUERIES.visualMaterialsSimplified)
			hideModal()
		},
		onError: () => {
			message.error(t('materials.removeBatch.error'))
		},
	})

	const onConfirm = async () => {
		if (!orderLineIds.length || !orderId) {
			message.error(t('modals.materialBatchRemoval.noBatchId'))
			return
		}
		await removeEWARPMaterials(orderLineIds)
	}

	return (
		<Modal
			title={t('modals.materialBatchRemoval.title')}
			visible
			closeIcon={<Close24 />}
			className={ANT_MODAL_VERTICAL_FOOTER_CLASS_NAME}
			onCancel={hideModal}
			footer={[
				<Button key="confirm" type="primary" loading={isLoading} onClick={onConfirm}>
					{t('common.actions.confirm')}
				</Button>,
			]}
		>
			<Typography.Paragraph>
				{t('modals.materialBatchRemoval.message', { batchId, orderId: foundOrder?.salesId })}
			</Typography.Paragraph>
		</Modal>
	)
}

export default MaterialBatchRemovalModal
