import { useHideModal } from '@libs/components'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'
import { DocumentTypesKeys, MaterialApi, S3Api } from '@libs/api'
import { ISaveDocumentMaterialFormValues, SaveDocumentMaterialModalProps } from '.'
import { useMemo, useState } from 'react'
import { UploadFile } from 'antd/es/upload/interface'
import { message } from 'antd'
import { useLocalization } from '@libs/localization'
import { useCaseById } from 'modules/cases/hooks'

export const useSaveDocumentMaterialModal = ({ material, caseId }: SaveDocumentMaterialModalProps) => {
	const hideModal = useHideModal()
	const queryClient = useQueryClient()
	const { data: s3Bucket } = useQuery(QUERIES.s3Credentials, S3Api.getS3Bucket)
	const { mutateAsync: signS3Upload } = useMutation(S3Api.signS3Upload)
	const { mutateAsync: s3Upload } = useMutation(S3Api.s3Upload)
	const [fileList, setFileList] = useState<UploadFile[]>(
		material ? [{ name: material.url.substr(material.url.lastIndexOf('/') + 1), url: material.url } as UploadFile] : []
	)
	const { data: documentMaterials = [] } = useQuery([QUERIES.documentMaterials, caseId], () =>
		MaterialApi.getDocumentMaterials(caseId)
	)
	const { data: foundCase } = useCaseById(caseId)
	const { mutateAsync: saveDocumentAsset, isLoading } = useMutation(MaterialApi.saveDocumentAsset, {
		onSuccess: response => {
			if (response.success) {
				queryClient.invalidateQueries([QUERIES.documentMaterials, caseId])

				message.success(t('modals.saveDocument.success'))
				hideModal()
				return
			}

			message.error(t('modals.saveDocument.error'))
		},
	})
	const { t } = useLocalization()

	const documentTypes = useMemo(
		() => [
			{
				value: DocumentTypesKeys.ConditionReport,
				label: t('materials.documentTypes.conditionReport'),
			},
			{
				value: DocumentTypesKeys.ElectricalInstallationReport,
				label: t('materials.documentTypes.electricalInstallationReport'),
			},
			{
				value: DocumentTypesKeys.EnergyLabeling,
				label: t('materials.documentTypes.energyLabeling'),
			},
			{
				value: DocumentTypesKeys.Miscellaneous,
				label: t('materials.documentTypes.miscellaneous'),
			},
			{
				value: DocumentTypesKeys.ProjectSalesProspectus,
				label: t('materials.documentTypes.projectSalesProspectus'),
			},
			{
				value: DocumentTypesKeys.BusinessProspectus,
				label: t('materials.documentTypes.businessProspectus'),
			},
		],
		[t]
	)

	const onSubmit = async (values: ISaveDocumentMaterialFormValues) => {
		const isEdit = !!material?.id

		// TODO: improve types
		const newItem: any = {
			name: values.name,
			type: values.type,
			url: values.url,
		}

		await saveDocumentAsset({
			caseId,
			assets: isEdit
				? documentMaterials.map(item =>
						item.id === material?.id
							? {
									...item,
									...newItem,
							  }
							: item
				  )
				: documentMaterials.concat([newItem]),
		})
	}

	const initialFormValues: ISaveDocumentMaterialFormValues = useMemo(
		() => ({
			name: material?.name || '',
			type: material?.type || '',
			url: material?.url || '',
		}),
		[material]
	)

	return {
		s3Bucket,
		documentTypes,
		signS3Upload,
		s3Upload,
		fileList,
		setFileList,
		isLoading,
		onSubmit,
		initialFormValues,
		foundCase,
	}
}
