import styled from 'styled-components'
import { margin } from '@libs/theme/mixins'

const SectionHeader = styled.div`
	margin-bottom: ${margin('sm')};

	h5 {
		margin-bottom: 0;

		svg {
			vertical-align: text-bottom;
		}
	}
`

const FooterWrapper = styled.div`
	button {
		min-width: 185px;
	}

	.typeSelector {
		margin-bottom: ${margin('xxs')};
	}
`

export default {
	SectionHeader,
	FooterWrapper,
}
