import { TasksApi } from '@libs/api'
import { Autolocalize, Container, useHideModal, UserInteraction } from '@libs/components'
import { Button, message, Modal, Space, Typography } from 'antd'
import { Formik } from 'formik'
import { Form, FormItem, Radio } from 'formik-antd'
import React, { FC, useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { createValidationSchema } from '@libs/util'
import { Close24 } from '@carbon/icons-react'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import TaskModalTitle from 'modules/common/TaskModalTitle'
import QUERIES from 'modules/common/queries'

export interface TaskSocialMediaModalProps extends TaskModalCommonProps {}

interface IFormValues {
	styleOption: string
}

const formSchema = createValidationSchema(yup =>
	yup.object().shape({
		styleOption: yup.string().required(),
	})
)

const TaskSocialMediaModal: FC<TaskSocialMediaModalProps> = ({ task, onTaskComplete, onTaskCancel }) => {
	const hideModal = useHideModal()
	const invalidateTaskQueries = useTasksQueriesInvalidation()
	const { t } = useLocalization()
	const { data: socialMediaTypes = [] } = useQuery(QUERIES.socialMediaTypes, TasksApi.getSocialMediaTypes)
	const { mutateAsync: completeTask } = useMutation(
		(values: IFormValues) =>
			TasksApi.completeSocialMediaTask({
				taskId: task.id,
				type: values.styleOption,
			}),
		{
			onSuccess: response => {
				if (!response.success) {
					message.error(t('modals.socialMediaSlideshow.error'))
					return
				}

				invalidateTaskQueries()
				message.success(t('modals.socialMediaSlideshow.success'))

				if (onTaskComplete) {
					onTaskComplete(task)
					return
				}

				hideModal()
			},
		}
	)

	const initialFormValues: IFormValues = useMemo(
		() => ({
			styleOption: socialMediaTypes?.[0] || '',
		}),
		[socialMediaTypes]
	)

	const soMeStyleOptions = useMemo(
		() =>
			socialMediaTypes.map(option => ({
				value: option,
				label: t(`modals.socialMedia.options.${option}`),
			})),
		[t, socialMediaTypes]
	)

	return (
		<Formik
			initialValues={initialFormValues}
			validationSchema={formSchema}
			enableReinitialize
			onSubmit={values => completeTask(values)}
		>
			{({ isValid, isSubmitting }) => (
				<Modal
					visible
					closeIcon={<Close24 />}
					title={
						<TaskModalTitle
							title={t('modals.socialMedia.title', { productName: task.productName })}
							caseId={task.caseReference}
						/>
					}
					onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
					footer={
						<Button
							loading={isSubmitting}
							type={'primary'}
							htmlType={'submit'}
							form={'task-arial-form-id'}
							disabled={!isValid}
						>
							{t('common.actions.completeTask')}
						</Button>
					}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form layout={'vertical'} id={'task-arial-form-id'}>
							<Autolocalize />
							<Space direction="vertical" size="large">
								<Typography.Text>{t('modals.socialMedia.message')}</Typography.Text>
								<FormItem name="styleOption" label={t('modals.socialMedia.label')} required>
									<Container margin={{ top: 'xxs' }}>
										<Radio.Group name="styleOption">
											<Space size="large" direction="vertical">
												{soMeStyleOptions.map(({ value, label }) => (
													<Radio key={value} name={value} value={value} size="small">
														{label}
													</Radio>
												))}
											</Space>
										</Radio.Group>
									</Container>
								</FormItem>
							</Space>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default TaskSocialMediaModal
