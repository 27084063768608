import React, { FC } from 'react'
import { useHideModal, UploadZone, Autolocalize, UserInteraction, FormikInput } from '@libs/components'
import { Modal, Button, message } from 'antd'
import { Form, FormItem, Select } from 'formik-antd'
import { Formik } from 'formik'
import { IDatahubCase, getUniqueS3DocumentPath, IDocumentMaterial } from '@libs/api'
import { RcFile } from 'antd/es/upload'
import { useSaveDocumentMaterialModal } from './hooks'
import { useLocalization } from '@libs/localization'
import { createValidationSchema } from '@libs/util'
import { Close24 } from '@carbon/icons-react'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'

const notAllowedFileNamesRegex = /[æøåÆØÅÖö,;:´¨'!"]/

export interface SaveDocumentMaterialModalProps {
	title: string
	material?: IDocumentMaterial
	caseId: IDatahubCase['id']
}

export interface ISaveDocumentMaterialFormValues extends Pick<IDocumentMaterial, 'name' | 'url' | 'type'> {}

const formSchema = createValidationSchema(yup =>
	yup.object().shape({
		name: yup.string().required(),
		type: yup.string().required(),
		url: yup.string().url().required(),
	})
)

const SaveDocumentMaterialModal: FC<SaveDocumentMaterialModalProps> = ({ title, caseId, material }) => {
	const hideModal = useHideModal()
	const {
		s3Bucket,
		documentTypes,
		signS3Upload,
		s3Upload,
		fileList,
		setFileList,
		isLoading,
		onSubmit,
		initialFormValues,
		foundCase,
	} = useSaveDocumentMaterialModal({ title, caseId, material })

	const { t } = useLocalization()

	return (
		<Modal
			visible
			closeIcon={<Close24 />}
			onCancel={hideModal}
			title={title}
			width={600}
			footer={[
				<Button form="save-document-material-form-id" loading={isLoading} key="submit" htmlType="submit" type="primary">
					{t('modals.saveDocument.submit')}
				</Button>,
			]}
		>
			<Formik<ISaveDocumentMaterialFormValues>
				initialValues={initialFormValues}
				validationSchema={formSchema}
				onSubmit={onSubmit}
			>
				{({ setFieldValue, isSubmitting }) => (
					<UserInteraction enabled={!isSubmitting}>
						<Form id="save-document-material-form-id" layout="vertical">
							<Autolocalize />
							<FormItem name="name" label={t('common.terms.title')} required>
								<FormikInput name="name" />
							</FormItem>
							<FormItem name="type" label={t('common.terms.type')} required>
								<Select name="type" placeholder={t('common.placeholders.selectType')}>
									{documentTypes?.map(({ value, label }) => (
										<Select.Option key={value} value={value}>
											{label}
										</Select.Option>
									))}
								</Select>
							</FormItem>
							<FormItem name="url" label={t('common.terms.document')} required>
								<UploadZone
									name="url"
									text={t('modals.saveDocument.uploadInstructions')}
									hint={t('modals.saveDocument.uploadHint')}
									maxCount={1}
									fileList={fileList}
									onChange={({ file }) => {
										if (file.status === 'uploading') {
											file.status = 'done'
											setFileList([file])
										}
									}}
									onRemove={() => {
										setFileList([])
									}}
									beforeUpload={file => {
										if (notAllowedFileNamesRegex.test(file.name)) {
											message.error(t('modals.uploader.invalidFileName'))
											return false
										}
										if (file.name.indexOf('.') !== file.name.lastIndexOf('.')) {
											message.error(t('modals.uploader.dotNotSupported'))
											return false
										}
										return true
									}}
									customRequest={async ({ file }) => {
										const { name } = file as RcFile
										const fileExtension = name.substring(name.lastIndexOf('.') + 1)
										let fileName = name.replace(/[^\w.-_]/gi, '')
										const hasSameName = fileList.some(existingFile => existingFile.fileName === fileName)
										fileName = `${hasSameName ? `${uuidv4()}-` : ''}${fileName}`

										if (s3Bucket) {
											const fullPath = getUniqueS3DocumentPath({
												caseId,
												branchId: foundCase?.departmentId || '',
												extension: fileExtension,
											})

											const { preSignedUrl } = await signS3Upload({
												expiration: dayjs().add(2, 'minute').toISOString(),
												filePath: fullPath,
											})

											await s3Upload({
												preSignedUrl,
												file: file as Blob,
											})

											const fileUrl = preSignedUrl.split('?')[0]

											setFieldValue('url', fileUrl)

											return true
										}

										return false
									}}
								/>
							</FormItem>
						</Form>
					</UserInteraction>
				)}
			</Formik>
		</Modal>
	)
}

export default SaveDocumentMaterialModal
