import styled from 'styled-components'
import { Modal as _Modal } from 'antd'
import { margin } from '@libs/theme/mixins'

const Description = styled.span`
	display: inline-block;
	padding-bottom: ${margin('sm')};
`

const Modal = styled(_Modal)`
	.ant-modal-content {
		margin-top: ${margin('md')};
		margin-bottom: ${margin('md')};
	}
`

export default {
	Description,
	Modal,
}
