import { AuthApi, IS_ESOFT_USER_KEY } from '@libs/api'
import appRoutes from 'app.routes'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'
import { forgetNewOrderState } from 'modules/orders/context'
import { useAppEvents } from 'modules/common/app.events'
import { AUTH_REDIRECT_URL, MINDWORKING_REDIRECT_REFERENCE } from '@libs/api/build/http'
import { useResetPersistedFilter } from 'modules/common/hooks'
import { LAST_DEPARTMENT_ID } from 'modules/dashboard/DashboardPageContent/consts'
import qs from 'qs'

const BLACK_LISTED_REDIRECT_URLS = [
	appRoutes.activeDirectory(),
	appRoutes.login(),
	appRoutes.esoftLogin(),
	appRoutes.authRedirect(),
]

export function useAuth() {
	const client = useQueryClient()
	const router = useRouter()
	const appEvents = useAppEvents()
	const resetPersistedFilters = useResetPersistedFilter()
	const { provider } =
		typeof window !== 'undefined'
			? (qs.parse(window.location.search.substring(1)) as Partial<Record<string, string>>)
			: ({} as Partial<Record<string, string>>)

	const persistRedirectUrl = useCallback(() => {
		if (!BLACK_LISTED_REDIRECT_URLS.some(url => window.location.href.includes(url)) && !provider) {
			localStorage.setItem(AUTH_REDIRECT_URL, `${window.location.pathname}${window.location.search}`)
		}
	}, [provider])

	const logout = useCallback(
		(userInitiatedLogout = false) => {
			// ignore logouts when forced by mindworking urls
			if (provider?.toLowerCase() === 'mindworking') return

			const isEsoftUser = localStorage.getItem(IS_ESOFT_USER_KEY) === 'true'
			userInitiatedLogout ? localStorage.removeItem(AUTH_REDIRECT_URL) : persistRedirectUrl()
			AuthApi.deauthenticate()
			resetPersistedFilters()
			localStorage.removeItem(LAST_DEPARTMENT_ID)
			appEvents.dispatch('orders.new.context.clear')
			isEsoftUser ? router.push(appRoutes.esoftLogin()) : router.push(appRoutes.login())
		},
		[router, persistRedirectUrl, appEvents, resetPersistedFilters, provider]
	)

	const { data: user, isLoading: userIsLoading } = useQuery(QUERIES.user, AuthApi.getAuthenticatedUser, {
		enabled:
			AuthApi.isAuthenticated() &&
			router.pathname !== appRoutes.activeDirectory() &&
			router.pathname !== appRoutes.authRedirect(), // prevent getting initial user on AD page which leads to 404 response for internal users
		onError: () => {
			logout()
		},
	})

	const softLogout = useCallback(async () => {
		forgetNewOrderState()
		if (localStorage.getItem(IS_ESOFT_USER_KEY) === 'true') {
			await router.push(appRoutes.activeDirectory())
			client.removeQueries()
			AuthApi.clearAuthHeaders()
			resetPersistedFilters()
			localStorage.removeItem(LAST_DEPARTMENT_ID)
			appEvents.dispatch('orders.new.context.clear')
		} else {
			logout(true)
		}
	}, [client, router, logout, appEvents, resetPersistedFilters])

	return {
		user,
		userIsLoading,
		logout,
		softLogout,
		persistRedirectUrl,
	}
}

export function useMindworkingUrl() {
	const queryClient = useQueryClient()
	const { maeglersMail, provider, sagsnr } =
		typeof window !== 'undefined'
			? (qs.parse(window.location.search.substring(1)) as Partial<Record<string, string>>)
			: ({} as Record<string, string>)

	const isMindworking = provider?.toLowerCase() === 'mindworking'

	const query = useQuery(
		[QUERIES.mindworking, maeglersMail],
		async () => (maeglersMail ? await AuthApi.b2cAuth(maeglersMail, appRoutes.authRedirect()) : undefined),
		{
			onSuccess: response => {
				const isEsoftUser = localStorage.getItem(IS_ESOFT_USER_KEY) === 'true'
				if (sagsnr) {
					localStorage.setItem(MINDWORKING_REDIRECT_REFERENCE, sagsnr)
				}
				if (response?.loginUrl) {
					window.location.href = response.loginUrl
					!isEsoftUser && queryClient.invalidateQueries(QUERIES.user)
				}
			},
			enabled: !!maeglersMail && !!sagsnr && isMindworking,
		}
	)

	return {
		...query,
		isMindworking,
	}
}
