import React, { ComponentType, Fragment, useCallback, useEffect, useMemo } from 'react'
import {
	CasesApi,
	IDatahubCase,
	OrderStatus,
	TasksApi,
	OrdersApi,
	CaseCategory,
	IGetCasesListParams,
	ICaseTextGroup,
	TextsApi,
	IGetCasesListResponse,
	CaseMaterialStatus,
	ICommonResponse,
	IUpdateCaseAddressParams,
	IAddress,
	FeedbackDrawingType,
	ISimplifiedVisualMaterial,
	FeedbackDrawingShape,
	transformCanvasDocumentToValid,
	getS3TaskUploadTempPath,
	S3Api,
	IDatahubOrderLine,
	IFeedbackLasso,
} from '@libs/api'
import { useLocalization } from '@libs/localization'
import { useShowModal } from 'modules/common/GlobalModals'
import { useRouter } from 'next/router'
import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query'
import { Container, IconButton, createTableColumns, Grid, Tooltip } from '@libs/components'
import appRoutes from 'app.routes'
import {
	ArrowsHorizontal24,
	Compare24,
	Document24,
	DocumentDownload24,
	DocumentView24,
	Events24,
	Help24,
	Image24,
	Map24,
	Person24,
	Store24,
	Chart_3D24,
	OverflowMenuVertical24,
} from '@carbon/icons-react'
import { Dropdown, Menu, message, Typography, Button } from 'antd'
import QUERIES from 'modules/common/queries'
import { useVisualMaterials } from 'modules/material/hooks'
import { useParsedFilters } from 'modules/common/hooks'
import { useScreenMatch, ANT_DROPDOWN_BUTTON_ROUND_CLASS_NAME } from '@libs/theme'
import { SortOrder } from 'antd/es/table/interface'
import Link from 'next/link'
import { ISelectedProduct } from 'modules/common/types'
import { mapFullAddressFromUpdateCaseAddress } from '@libs/api/build/cases/helpers'
import { ARROW_SIZE, LASSO_BORDER_WIDTH, LASSO_FILL_STYLE, POINT_SIZE } from './FeedbackCanvas/constants'
import { rgbaColor } from '@libs/theme/mixins'
import { useTheme } from 'styled-components'
import dayjs from 'dayjs'

//const GUID_PATTERN = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i

export function useCaseFilterOptions() {
	const { t } = useLocalization()
	const parsedQueryString = useParsedFilters<{
		departmentId: string[]
		category?: string[]
		status?: string[]
		query?: string
		fromDate?: string
		toDate?: string
	}>('cases')
	const { data } = useQuery(QUERIES.caseFilters, CasesApi.getFilters)

	// empty qs should be reinitialized with ALL filters selected
	// query is ignored in order to be able to search if this is the only parameter
	const isQSEmpty = useMemo(
		() =>
			!parsedQueryString.category &&
			!parsedQueryString.departmentId &&
			!parsedQueryString.status &&
			!parsedQueryString.fromDate &&
			!parsedQueryString.toDate,
		[parsedQueryString]
	)

	const categoryOptions = useMemo(
		() =>
			data?.categories.map(item => ({
				value: item,
				label: t(`cases.categories.${item}`),
			})) ?? [],
		[data, t]
	)
	const categoryValues = useMemo(() => {
		const qsArray = parsedQueryString.category as CaseCategory[]

		if (qsArray && categoryOptions.length > 0) {
			return categoryOptions.filter(({ value }) => qsArray.includes(value)).map(option => option.value)
		} else if (!qsArray) {
			return isQSEmpty ? categoryOptions.map(option => option.value) : []
		}
		return qsArray
	}, [categoryOptions, parsedQueryString, isQSEmpty])

	const statusOptions = useMemo(
		() =>
			data?.statuses.map(item => ({
				value: item,
				label: t(`cases.statuses.${item}`),
			})) ?? [],
		[data, t]
	)
	const statusValues = useMemo(() => {
		const qsArray = parsedQueryString.status as OrderStatus[]

		if (qsArray && statusOptions.length > 0) {
			return statusOptions.filter(({ value }) => qsArray.includes(value)).map(option => option.value)
		} else if (!qsArray) {
			return isQSEmpty ? statusOptions.map(option => option.value) : []
		}
		return qsArray
	}, [statusOptions, parsedQueryString, isQSEmpty])

	const departmentOptions = useMemo(
		() =>
			data?.departments.map(item => ({
				value: item.id,
				label: item.name,
			})) ?? [],
		[data]
	)
	const departmentValues = useMemo(() => {
		const qsArray = parsedQueryString.departmentId

		if (qsArray && departmentOptions.length > 0) {
			return departmentOptions.filter(({ value }) => qsArray.includes(value)).map(option => option.value)
		} else if (!qsArray) {
			return isQSEmpty ? departmentOptions.map(option => option.value) : []
		}
		return qsArray
	}, [departmentOptions, parsedQueryString, isQSEmpty])

	return {
		categoryOptions,
		categoryValues,
		statusOptions,
		statusValues,
		departmentOptions,
		departmentValues,
		parsedQueryString,
	}
}

export function useCasePresentation() {
	const { t } = useLocalization()
	const options = useMemo(
		() => [
			{
				filter: 'all',
				label: t('materials.pages.presentation.links.showAll'),
				mobileLabel: t('materials.pages.presentation.links.presentAll'),
			},
			{
				filter: 'active',
				label: t('materials.pages.presentation.links.showActive'),
				mobileLabel: t('materials.pages.presentation.links.presentActive'),
			},
		],
		[t]
	)

	return { options }
}

const CATEGORY_ICON_MAP: Record<CaseCategory, ComponentType> = {
	[CaseCategory.Case]: Document24,
	[CaseCategory.Group]: Events24,
	[CaseCategory.IntroOutro]: ArrowsHorizontal24,
	[CaseCategory.Location]: Map24,
	[CaseCategory.Portrait]: Person24,
	[CaseCategory.Shop]: Store24,
	[CaseCategory.Matterport]: Chart_3D24,
	[CaseCategory.BeforeAfter]: Compare24,
}

function useCaseTableColumns(params: IGetCasesListParams, variant: 'basic' | 'extended' = 'extended') {
	const { f, t } = useLocalization()
	const showModal = useShowModal()
	const presentation = useCasePresentation()
	const sortable = variant === 'extended'
	const match = useScreenMatch()

	const getDefaultSortOrder = useCallback(
		(columnDataIndex: string) =>
			(params.orderBy === columnDataIndex ? params.orderByDirection : undefined) as SortOrder | undefined,
		[params]
	)

	const desktopColumns = useMemo(
		() =>
			createTableColumns<IDatahubCase>([
				match.gte('xxl') && {
					title: t('common.terms.lastUpdated'),
					dataIndex: 'deliveryDate',
					sorter: sortable,
					sortOrder: getDefaultSortOrder('deliveryDate'),
					render: (value: Date) => (value ? f(value, { date: 'short' }) : '-'),
				},
				{
					title: t('common.terms.department'),
					dataIndex: 'departmentName',
					ellipsis: true,
				},
				{
					title: t('common.terms.case'),
					dataIndex: 'reference',
					ellipsis: true,
					sorter: sortable,
					sortOrder: getDefaultSortOrder('reference'),
				},
				{
					title: t('common.terms.address'),
					dataIndex: 'address',
					ellipsis: { showTitle: false },
					sorter: sortable,
					sortOrder: getDefaultSortOrder('address'),
					render: (_, caseObj) => {
						const address = caseObj.address.trim()

						if (address) {
							return (
								<Tooltip title={address} display={'inline'} placement={'topLeft'}>
									{address}
								</Tooltip>
							)
						} else {
							return '-'
						}
					},
				},
				(match.gte('xxl') || variant === 'basic') && {
					title: t('common.terms.category'),
					dataIndex: 'categories',
					render: (_, caseObj) => (
						<Container fill display={'flex'} alignItems={'center'} space={'xs'}>
							{caseObj.categories?.map(category => {
								const Icon = CATEGORY_ICON_MAP[category] ?? Help24
								return (
									<Tooltip key={category} title={t(`cases.categories.${category}`)}>
										<Icon />
									</Tooltip>
								)
							})}
						</Container>
					),
				},
				variant === 'extended' && {
					title: t('common.terms.status'),
					dataIndex: 'orderStatus',
					ellipsis: true,
					render: item => t(`cases.statuses.${item}`),
				},
				{
					title: '',
					dataIndex: 'actions',
					width: 200,
					render: (_, caseObj) => {
						const areMaterialButtonsDisabled =
							!caseObj.hasMaterials || caseObj.materialStatus !== CaseMaterialStatus.ACTIVE

						return (
							<Container fill display={'flex'} alignContent={'center'} justifyContent={'flex-end'} space={'xs'}>
								{caseObj.id && (
									<Link href={appRoutes.cases.details(caseObj.id)} passHref>
										<IconButton title={t('common.actions.viewCase')}>
											<DocumentView24 />
										</IconButton>
									</Link>
								)}
								<IconButton
									disabled={areMaterialButtonsDisabled}
									danger={caseObj.materialStatus === CaseMaterialStatus.ARCHIVED}
									title={t('common.actions.getMaterials')}
									onClick={e => {
										e.stopPropagation()
										showModal('material.download', {
											caseId: caseObj.id,
										})
									}}
								>
									<DocumentDownload24 />
								</IconButton>
								<Dropdown
									disabled={areMaterialButtonsDisabled}
									placement={'bottomRight'}
									overlay={
										<Menu>
											{presentation.options.map(({ filter, label }) => (
												<Menu.Item key={filter}>
													<Link href={appRoutes.presentation(caseObj.id, filter)} passHref>
														<a target="_blank" onClick={e => e.stopPropagation()}>
															{label}
														</a>
													</Link>
												</Menu.Item>
											))}
										</Menu>
									}
								>
									<IconButton title={t('common.actions.present')} onClick={e => e.stopPropagation()}>
										<Image24 />
									</IconButton>
								</Dropdown>
							</Container>
						)
					},
				},
			]),
		[f, showModal, presentation, t, sortable, variant, match, getDefaultSortOrder]
	)

	const mobileColumns = useMemo(
		() =>
			createTableColumns<IDatahubCase>([
				{
					title: t('common.terms.case'),
					render: (_, caseObj) => {
						const areMaterialButtonsDisabled =
							!caseObj.hasMaterials || caseObj.materialStatus !== CaseMaterialStatus.ACTIVE

						return (
							<Container space={'lg'} spaceDirection={'vertical'}>
								<Grid.Row gutter={'sm'}>
									<Grid.Col span={24}>
										<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
											<Typography.Text type={'secondary'} strong>
												{t('common.terms.case')}
											</Typography.Text>
											<Typography.Text ellipsis title={caseObj.reference}>
												{caseObj.reference}
											</Typography.Text>
										</Container>
									</Grid.Col>
									<Grid.Col span={12}>
										<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
											<Typography.Text type={'secondary'} strong>
												{t('common.terms.address')}
											</Typography.Text>
											<Typography.Text ellipsis title={caseObj.address.trim()}>
												{caseObj.address.trim() || '-'}
											</Typography.Text>
										</Container>
									</Grid.Col>
									<Grid.Col span={12}>
										<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
											<Typography.Text type={'secondary'} strong>
												{t('common.terms.department')}
											</Typography.Text>
											<Typography.Text ellipsis title={caseObj.departmentName}>
												{caseObj.departmentName}
											</Typography.Text>
										</Container>
									</Grid.Col>
									{variant === 'extended' && (
										<Fragment>
											<Grid.Col span={12}>
												<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
													<Typography.Text type={'secondary'} strong>
														{t('common.terms.category')}
													</Typography.Text>
													<Container fill display={'flex'} alignItems={'center'} space={'xs'}>
														{caseObj.categories?.map(category => {
															const Icon = CATEGORY_ICON_MAP[category] ?? Help24
															return (
																<Tooltip key={category} title={t(`cases.categories.${category}`)}>
																	<Icon />
																</Tooltip>
															)
														})}
													</Container>
												</Container>
											</Grid.Col>
											<Grid.Col span={12}>
												<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
													<Typography.Text type={'secondary'} strong>
														{t('common.terms.status')}
													</Typography.Text>
													<Typography.Text ellipsis title={t(`cases.statuses.${caseObj.orderStatus}`)}>
														{t(`cases.statuses.${caseObj.orderStatus}`)}
													</Typography.Text>
												</Container>
											</Grid.Col>
										</Fragment>
									)}
								</Grid.Row>
								{match.lte('xs') && caseObj.id && (
									<Dropdown.Button
										className={ANT_DROPDOWN_BUTTON_ROUND_CLASS_NAME}
										placement={'topRight'}
										type={'primary'}
										size={'small'}
										overlay={
											<Menu>
												<Menu.Item
													disabled={areMaterialButtonsDisabled}
													onClick={() =>
														showModal('material.download', {
															caseId: caseObj.id,
														})
													}
												>
													{t('common.actions.getMaterials')}
												</Menu.Item>
												{presentation.options.map(({ filter, mobileLabel }) => (
													<Menu.Item key={filter} disabled={areMaterialButtonsDisabled}>
														<Link href={appRoutes.presentation(caseObj.id, filter)} passHref>
															<a rel={'noreferrer'} target="_blank">
																{mobileLabel}
															</a>
														</Link>
													</Menu.Item>
												))}
											</Menu>
										}
									>
										<Link href={appRoutes.cases.details(caseObj.id)} passHref>
											<a>{t('common.actions.viewCase')}</a>
										</Link>
									</Dropdown.Button>
								)}
							</Container>
						)
					},
				},
				match.gte('sm') && {
					title: t('common.terms.actions'),
					width: 100,
					render: (_, caseObj) => {
						const areMaterialButtonsDisabled =
							!caseObj.hasMaterials || caseObj.materialStatus !== CaseMaterialStatus.ACTIVE

						return (
							<Container display={'flex'} justifyContent={'flex-end'}>
								<Dropdown
									placement={'bottomRight'}
									overlay={
										<Menu>
											{caseObj.id && (
												<Menu.Item>
													<Link href={appRoutes.cases.details(caseObj.id)} passHref>
														<a>{t('common.actions.viewCase')}</a>
													</Link>
												</Menu.Item>
											)}
											<Menu.Item
												disabled={areMaterialButtonsDisabled}
												onClick={() =>
													showModal('material.download', {
														caseId: caseObj.id,
													})
												}
											>
												{t('common.actions.getMaterials')}
											</Menu.Item>
											{presentation.options.map(({ filter, mobileLabel }) => (
												<Menu.Item key={filter} disabled={areMaterialButtonsDisabled}>
													<Link href={appRoutes.presentation(caseObj.id, filter)} passHref>
														<a rel={'noreferrer'} target="_blank">
															{mobileLabel}
														</a>
													</Link>
												</Menu.Item>
											))}
										</Menu>
									}
								>
									<Button shape={'circle'} size={'small'} type={'default'}>
										<OverflowMenuVertical24 />
									</Button>
								</Dropdown>
							</Container>
						)
					},
				},
			]),
		[t, match, presentation.options, showModal, variant]
	)

	return useMemo(() => match.map({ xxs: mobileColumns, lg: desktopColumns }), [match, mobileColumns, desktopColumns])
}

export function useCases(params: IGetCasesListParams, queryEnabled = true) {
	const enabled = queryEnabled && (!params.query || params.query.length >= 3)

	const {
		data: rawData = {
			pages: [] as IGetCasesListResponse[],
			pageParams: undefined,
		},
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
		isFetched,
	} = useInfiniteQuery(
		[QUERIES.cases, params],
		({ pageParam = 0 }) =>
			CasesApi.getDatahubCases({
				...params,
				page: pageParam,
			}),
		{
			enabled,
			getNextPageParam: ({ page, pageSize, totalItems }) => {
				return (page + 1) * pageSize < totalItems ? page + 1 : undefined
			},
		}
	)

	const data = useMemo(
		() => rawData?.pages.reduce((acc, page) => [...acc, ...page.items], [] as IDatahubCase[]) ?? [],
		[rawData]
	)

	return {
		data,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage,
		isFetched,
	}
}

export function useCasesTable(
	params: IGetCasesListParams,
	variant?: Parameters<typeof useCaseTableColumns>[1],
	enabled = true
) {
	const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } = useCases(params, enabled)

	const columns = useCaseTableColumns(params, variant)

	return {
		isLoading,
		columns,
		dataSource: data,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	}
}

export function useSimplifiedCaseOrders(caseId?: IDatahubCase['id'], enabled = true) {
	return useQuery(
		[QUERIES.orderListSimplified, caseId],
		async () => (caseId ? await OrdersApi.getCaseOrders(caseId) : undefined),
		{
			enabled: enabled && !!caseId,
		}
	)
}

export function useCaseDetails(caseId: IDatahubCase['id'], enabled: boolean) {
	const { data: address } = useQuery([QUERIES.caseAddress, caseId], () => CasesApi.getAddressByCaseId(caseId), {
		enabled: enabled && !!caseId,
		staleTime: 0, // disable cache for instant updates
	})

	const { data: caseOpenTasks = [] } = useQuery(
		[QUERIES.totalTasksByReference, caseId],
		() => TasksApi.getOpenTasksByCaseId(caseId),
		{
			enabled: enabled && !!caseId,
		}
	)

	return {
		address,
		totalTasks: caseOpenTasks.length || 0,
	}
}

export const useCaseDetailsPage = () => {
	const router = useRouter()
	const unparsedId = decodeURIComponent(window.location.pathname.split('/')[2]) || router.query.id
	const isIdGUID = router.pathname === '/cases/[id]/details'

	const { data: foundCase } = useCaseById(unparsedId as string, !!unparsedId && isIdGUID)

	const id = isIdGUID ? foundCase?.reference : unparsedId

	const {
		data: doesCaseExist,
		isFetched: isCaseFetched,
		isLoading: isCaseExistLoading,
	} = useQuery(
		[QUERIES.checkIfCaseExists, unparsedId, foundCase?.departmentId],
		() =>
			unparsedId && unparsedId !== 'undefined' && foundCase?.departmentId
				? CasesApi.checkIfCaseExists(unparsedId as string, foundCase.departmentId)
				: undefined,
		{
			enabled: !!unparsedId && unparsedId !== 'undefined' && !!foundCase?.departmentId,
			cacheTime: 6000,
		}
	)
	const caseExists = isCaseFetched && !!doesCaseExist && !!foundCase?.id
	const { address, totalTasks } = useCaseDetails(foundCase?.id as string, caseExists)
	const queryClient = useQueryClient()
	const { t } = useLocalization()
	const { mutateAsync: updateAddress } = useMutation<ICommonResponse, Error, IUpdateCaseAddressParams>(
		CasesApi.updateCaseAddress,
		{
			onError: (err, _, context) => {
				message.error(err || t('cases.messages.addressUpdateFailed'))
				queryClient.setQueryData(
					[QUERIES.caseAddress, foundCase?.id],
					(context as { previousCaseData: IAddress }).previousCaseData
				)
			},
			onSuccess: success => {
				success
					? message.success(t('cases.messages.addressUpdateSuccess'))
					: message.error(t('cases.pages.details.addressUpdateFailed'))
				queryClient.invalidateQueries(QUERIES.caseAddress)
			},
			onMutate: newAddress => {
				const previousCaseData = queryClient.getQueryData<IAddress>([QUERIES.caseAddress, foundCase?.id])
				queryClient.setQueryData([QUERIES.caseAddress, foundCase?.id], {
					full: mapFullAddressFromUpdateCaseAddress(newAddress),
					street: newAddress.street,
					houseNumber: newAddress.buildingNumber,
					city: newAddress.city,
					postNumber: newAddress.zip,
				})
				return { previousCaseData }
			},
		}
	)
	const { data: cases = [], isFetched: isCaseListFetched } = useCases(
		{
			page: 0,
			pageSize: 1,
			query: id as string,
		},
		!isIdGUID
	)

	useEffect(() => {
		if (!isIdGUID && isCaseListFetched && cases?.[0]?.id) {
			router.replace(appRoutes.cases.details(cases[0].id))
		}
	}, [isIdGUID, isCaseListFetched, cases])

	const useVisualMaterialsHookData = useVisualMaterials(foundCase?.id, caseExists)

	return {
		caseId: foundCase?.id,
		reference: id as string,
		caseAddress: address,
		doesCaseExist,
		isCaseFetched,
		isCaseExistLoading,
		router,
		totalTasks,
		updateAddress,
		useVisualMaterialsHookData,
		showError: isIdGUID ? false : isCaseListFetched && !cases?.[0],
		foundCase,
	}
}

export const useTranslatedTexts = (caseId?: IDatahubCase['id']) => {
	const { t } = useLocalization()

	const {
		data: groups = [],
		isLoading: areGroupsLoading,
		isFetched: areGroupsFetched,
	} = useQuery([QUERIES.textGroups], TextsApi.getTextGroups)

	const {
		data: caseTexts,
		isLoading: areTextsLoading,
		isFetched: areTextsFetched,
	} = useQuery([QUERIES.caseTexts, caseId], async () => (caseId ? TextsApi.getCaseTexts(caseId) : undefined), {
		enabled: !!caseId,
	})

	const translatedTexts = useMemo(
		() =>
			groups.map<ICaseTextGroup>(group => ({
				groupName: t(`cases.textGroups.${group.groupName}`),
				texts: group.texts.map(text => {
					const foundGroup = caseTexts?.find(item => item.groupName === group.groupName)
					if (!foundGroup) return text
					const foundTextContent = foundGroup.texts.find(item => item.name === text.name)

					return {
						...text,
						content: foundTextContent?.content || '',
					}
				}),
			})),
		[caseTexts, groups, t]
	)

	return {
		translatedTexts,
		isLoading: areGroupsLoading || areTextsLoading,
		isFetched: areGroupsFetched && areTextsFetched,
	}
}

export const useProductStorage = () => {
	const STORAGE_KEY = 'case.selected.product'

	const setSelectedProduct = (product: ISelectedProduct) => {
		localStorage.setItem(STORAGE_KEY, JSON.stringify(product))
	}

	const getSelectedProduct = (): ISelectedProduct | null => {
		try {
			return JSON.parse(localStorage.getItem(STORAGE_KEY) as string)
		} catch (err) {
			return null
		}
	}

	const clearSelectedProduct = () => {
		localStorage.removeItem(STORAGE_KEY)
	}

	return {
		setSelectedProduct,
		getSelectedProduct,
		clearSelectedProduct,
	}
}

export const useCaseById = (caseId?: IDatahubCase['id'], enabled = true) =>
	useQuery([QUERIES.caseDetails, caseId], async () => (caseId ? await CasesApi.getDatahubCase(caseId) : undefined), {
		enabled: !!caseId && enabled,
	})

export const useContactPersonsByCaseId = (caseId?: IDatahubCase['id'], enabled = true) =>
	useQuery(
		[QUERIES.contactPersonsByCaseId, caseId],
		async () => (caseId ? await CasesApi.getContactPersonsByCaseId(caseId) : undefined),
		{
			enabled: !!caseId && enabled,
		}
	)

export const useTryUploadResultImageIntoS3 = ({
	drawingComments = [],
	branchId,
	orderlineId,
}: {
	drawingComments: string[]
	branchId?: string
	orderlineId?: IDatahubOrderLine['id']
}) => {
	const theme = useTheme()

	const CANVAS_SETTINGS = useMemo(
		() => ({
			pointDrawingSize: POINT_SIZE,
			arrowDrawingSize: ARROW_SIZE,
			drawingColor: rgbaColor('primary', 0.8)({ theme }),
			drawingFiller: rgbaColor('primary', 0.2)({ theme }),
			labelColor: theme.colors.error['500'],
		}),
		[theme]
	)

	const drawArrow = (context: CanvasRenderingContext2D, p1: { x: number; y: number }, p2: { x: number; y: number }) => {
		const headlen = 10 // length of head in pixels
		const dx = p2.x - p1.x
		const dy = p2.y - p1.y
		const angle = Math.atan2(dy, dx)
		context.moveTo(p1.x, p1.y)
		context.lineTo(p2.x, p2.y)
		context.lineTo(p2.x - headlen * Math.cos(angle - Math.PI / 6), p2.y - headlen * Math.sin(angle - Math.PI / 6))
		context.moveTo(p2.x, p2.y)
		context.lineTo(p2.x - headlen * Math.cos(angle + Math.PI / 6), p2.y - headlen * Math.sin(angle + Math.PI / 6))
	}

	const getImageFromCanvas = (
		drawings: FeedbackDrawingType[],
		material: ISimplifiedVisualMaterial
	): Promise<string | undefined | null> =>
		new Promise((resolve, reject) => {
			const lassoDrawings = drawings.filter(drawing => drawing.shape === FeedbackDrawingShape.Lasso)
			const pointDrawings = drawings.filter(drawing => drawing.shape === FeedbackDrawingShape.Point)
			const arrowDrawings = drawings.filter(drawing => drawing.shape === FeedbackDrawingShape.Arrow)

			if (drawings.length === 0) return resolve(null)

			const img = new Image()
			img.crossOrigin = 'anonymous'
			img.onload = () => {
				const canvas = document.createElement('canvas')
				canvas.width = img.width
				canvas.height = img.height

				const context = canvas.getContext('2d') as CanvasRenderingContext2D

				context.drawImage(img, 0, 0, img.width, img.height, 0, 0, img.width, img.height)

				for (const drawing of lassoDrawings) {
					const { points } = drawing as IFeedbackLasso
					const { labelPoint, color, comment } = transformCanvasDocumentToValid(
						{ ...CANVAS_SETTINGS, size: [img.width, img.height] },
						drawing,
						drawingComments.length
					)

					context.fillStyle = LASSO_FILL_STYLE
					context.strokeStyle = color
					context.lineWidth = LASSO_BORDER_WIDTH
					context.beginPath()

					context.moveTo(points[0][0], points[0][1])

					for (let i = 1; i < points.length; i++) {
						context.lineTo(points[i][0], points[i][1])
					}

					context.closePath()
					context.stroke()
					context.fill()

					context.font = 'bold 24px sans-serif'
					context.fillStyle = CANVAS_SETTINGS.labelColor
					drawingComments.push(comment)
					context.fillText(drawingComments.length.toString(), labelPoint.x, labelPoint.y)
				}

				for (const drawing of pointDrawings) {
					const { labelPoint, comment, color, p1, p2 } = transformCanvasDocumentToValid(
						{ ...CANVAS_SETTINGS, size: [img.width, img.height] },
						drawing,
						drawingComments.length
					)

					context.fillStyle = CANVAS_SETTINGS.drawingFiller
					context.strokeStyle = color
					const oldAlpha = context.globalAlpha
					context.globalAlpha = 0
					context.rect(p1.x, p1.y, p2.x - p1.x, p2.y - p1.y)
					context.fill()
					context.globalAlpha = oldAlpha
					context.stroke()

					context.font = 'bold 24px sans-serif'
					context.fillStyle = CANVAS_SETTINGS.labelColor
					drawingComments.push(comment)
					context.fillText(drawingComments.length.toString(), labelPoint.x - 8, labelPoint.y + 8)
				}

				for (const drawing of arrowDrawings) {
					const { labelPoint, comment, p1, p2 } = transformCanvasDocumentToValid(
						{ ...CANVAS_SETTINGS, size: [img.width, img.height] },
						drawing,
						drawingComments.length
					)

					context.strokeStyle = CANVAS_SETTINGS.drawingColor
					context.lineWidth = LASSO_BORDER_WIDTH

					drawArrow(context, p1, p2)

					context.stroke()

					context.font = 'bold 24px sans-serif'
					context.fillStyle = CANVAS_SETTINGS.labelColor
					drawingComments.push(comment)
					context.fillText(drawingComments.length.toString(), labelPoint.x, labelPoint.y)
				}

				resolve(canvas.toDataURL())
			}
			img.onerror = () => reject()
			img.src = `${material.source}?t=${Date.now()}`
		})

	const uploadImage = async (file: Blob | null, material: ISimplifiedVisualMaterial) => {
		if (!file) return ''

		const s3Key = getS3TaskUploadTempPath({
			task: { branchId: branchId || 'feedback', orderlineId },
			fileUuid: material.id,
			fileExtension: 'png',
		})

		const s3re = await S3Api.signS3Upload({
			expiration: dayjs().add(2, 'minute').toISOString(),
			filePath: s3Key,
		})

		const { preSignedUrl } = s3re

		await S3Api.s3Upload({
			preSignedUrl,
			file: file,
		})

		return preSignedUrl.split('?')[0]
	}

	const tryUploadResultImageIntoS3 = async (material: ISimplifiedVisualMaterial, drawings: FeedbackDrawingType[]) => {
		const imageFromCanvas = await getImageFromCanvas(drawings, material)
		let uploadedDrawingsUrl: string | null = null

		if (imageFromCanvas) {
			const resp = await fetch(imageFromCanvas as string)
			const imgBlob = await resp.blob()
			uploadedDrawingsUrl = await uploadImage(imgBlob, material)
		}

		return uploadedDrawingsUrl || material.source
	}

	return tryUploadResultImageIntoS3
}
