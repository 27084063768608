import { IAddress } from '@libs/api'
import React, { FC } from 'react'
import { INewOrderState } from '../context'
import { useOrderFormAddress } from './hooks'
import Styled from './Styled'

export interface IOrderFormAddress {
	name: string
	onChange: (option: any) => void
	idCheck?: boolean
	mergeUpdate: (newAddress: IAddress) => RecursivePartialType<INewOrderState>
}

const OrderFormAddress: FC<IOrderFormAddress> = ({ name, onChange, idCheck = false, mergeUpdate }) => {
	const { setAddressPrediction, setAddressQuery, addressAutocomplete, providerSupportsSubpremise, t } =
		useOrderFormAddress({ name, idCheck, mergeUpdate })

	return (
		<Styled.AddressAutoComplete
			name={name}
			loading={addressAutocomplete.isStillLoading}
			options={addressAutocomplete.options}
			placeholder={
				providerSupportsSubpremise
					? t('common.placeholders.minChars', { min: 3 })
					: t('common.placeholders.specificAddressAutocomplete')
			}
			onSearch={setAddressQuery}
			onChange={(_, option: any) => {
				setAddressPrediction(option.data)
				onChange(option)
			}}
		/>
	)
}

export default OrderFormAddress
