import React, { FC, useMemo } from 'react'
import { Modal, Button } from 'antd'
import { Form, FormItem } from 'formik-antd'
import { Formik } from 'formik'
import { Autolocalize, FormikInput, Grid, SelectAutocomplete, useHideModal, UserInteraction } from '@libs/components'
import { AddressApi, IAddress } from '@libs/api'
import { useLocalization } from '@libs/localization'
import { ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME } from '@libs/theme'
import { useAddressSelector } from 'modules/common/hooks'
import { useAuth } from 'modules/auth/hooks'
import { Close24 } from '@carbon/icons-react'

interface IFormValues {
	address: string
	addressFloor?: string
	addressAppartmentNumber?: string
}

export interface CaseAddressEditModalProps {
	initialAddress: IAddress
	onSubmit: (address: IAddress) => Promise<boolean> | void
}

const CaseAddressEditModal: FC<CaseAddressEditModalProps> = ({ initialAddress, onSubmit }) => {
	const { user } = useAuth()
	const hideModal = useHideModal()
	const { t } = useLocalization()
	const providerSupportsSubpremise =
		!!user && AddressApi.providerSupportsSubpremise(user?.branches?.[0]?.countryCode?.toLowerCase())
	const {
		address,
		setPrediction: setAddressPrediction,
		setQuery: setAddressQuery,
		autocomplete: addressAutocomplete,
	} = useAddressSelector(initialAddress)

	const initialFormValues: IFormValues = useMemo(
		() => ({
			address: address?.full || '',
			addressFloor: address?.floor,
			addressAppartmentNumber: address?.appartmentNumber,
		}),
		[address]
	)

	return (
		<Formik<IFormValues>
			initialValues={initialFormValues}
			enableReinitialize
			onSubmit={async ({ addressFloor, addressAppartmentNumber }) => {
				await onSubmit({
					...(address as IAddress),
					floor: addressFloor as string,
					appartmentNumber: addressAppartmentNumber as string,
				})
				hideModal()
			}}
		>
			{({ dirty, isSubmitting, setFieldValue }) => (
				<Modal
					visible
					onCancel={hideModal}
					closeIcon={<Close24 />}
					title={t('modals.caseAddressEdit.title')}
					footer={[
						<Button
							loading={isSubmitting}
							disabled={!dirty}
							form="edit-address-form-id"
							key="submit"
							htmlType="submit"
							type="primary"
						>
							{t('common.actions.save')}
						</Button>,
					]}
				>
					<UserInteraction enabled={!isSubmitting}>
						<Form id="edit-address-form-id" layout="vertical">
							<Autolocalize />
							<FormItem name="address" label={t('common.terms.address')} required>
								<SelectAutocomplete
									name="address"
									loading={addressAutocomplete.isStillLoading}
									options={addressAutocomplete.options}
									placeholder={
										providerSupportsSubpremise
											? t('common.placeholders.minChars', { min: 3 })
											: t('common.placeholders.specificAddressAutocomplete')
									}
									onSearch={setAddressQuery}
									onChange={(_, option: any) => {
										setAddressPrediction(option.data)
										setFieldValue('addressFloor', '')
										setFieldValue('addressAppartmentNumber', '')
									}}
								/>
							</FormItem>
							{!providerSupportsSubpremise && (
								<Grid.Row gutter={['sm', 0]}>
									<Grid.Col xs={24} md={12}>
										<FormItem
											name="addressFloor"
											label={t('common.terms.floor')}
											className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
										>
											<FormikInput name="addressFloor" />
										</FormItem>
									</Grid.Col>
									<Grid.Col xs={24} md={12}>
										<FormItem
											name="addressAppartmentNumber"
											label={t('common.terms.appartmentNumber')}
											className={ANT_FORM_ITEM_PRESERVE_MARGIN_CLASS_NAME}
										>
											<FormikInput name="addressAppartmentNumber" />
										</FormItem>
									</Grid.Col>
								</Grid.Row>
							)}
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default CaseAddressEditModal
