import styled from 'styled-components'
import { fontSize } from '@libs/theme/mixins'
import { Container } from '@libs/components'

const CarouselContainer = styled.div`
	// Blend effect overlay
	position: relative;
	&:after {
		display: block;
		content: ' ';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
`

const Slide = styled.div<{ $imageSrc: string }>`
	height: 100vh;
	background-image: url('${({ $imageSrc }) => $imageSrc}');
	background-size: cover;
`

const ClickableContainer = styled(Container)`
	cursor: pointer;
	font-size: ${fontSize('sm')};
`

export default {
	CarouselContainer,
	Slide,
	ClickableContainer,
}
