import React, { FC, useEffect, useMemo } from 'react'
import { useHideModal } from '@libs/components/build/Modals/context'
import { Modal, Button, message, Table, Typography, Spin } from 'antd'
import { Form } from 'formik-antd'
import { Formik, useFormikContext } from 'formik'
import {
	CaseCategory,
	CasesApi,
	IBranch,
	IDatahubCase,
	IGetCasesListParams,
	ISimplifiedVisualMaterial,
	MaterialApi,
	ProductCategory,
} from '@libs/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useState } from 'react'
import { useDebounce } from 'use-debounce/lib'
import { Grid, Container, Autolocalize, UserInteraction, createTableColumns, FormikInput } from '@libs/components'
import { ChevronDown24, ChevronUp24, Close24 } from '@carbon/icons-react'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'
import { useScreenMatch } from '@libs/theme'
import { useVisualMaterials } from '../hooks'
import { useCaseById } from 'modules/cases/hooks'

export interface CopyMaterialByCaseLocationModalProps {
	caseId: IDatahubCase['id']
}

export interface ICopyMaterialByCaseLocationFormValues {
	isVisualMode: boolean
	selectedCaseMaterials: {
		caseId: IDatahubCase['id']
		reference: IDatahubCase['reference']
		visualMaterials: ISimplifiedVisualMaterial['id'][]
		branchId: IBranch['id']
	}[]
}

const initialFormValues: ICopyMaterialByCaseLocationFormValues = {
	isVisualMode: false,
	selectedCaseMaterials: [],
}

interface IPersistedMaterials {
	caseId: IDatahubCase['id']
	visualMaterials: ISimplifiedVisualMaterial[]
}

const VisualModeMaterials = () => {
	const [casesWithMaterials, selectCasesWithMaterials] = useState<IPersistedMaterials[]>([])
	const { values, setFieldValue } = useFormikContext<ICopyMaterialByCaseLocationFormValues>()
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		const casesToPull = values.selectedCaseMaterials.filter(
			({ caseId }) => !casesWithMaterials.some(item => item.caseId === caseId)
		)

		if (casesToPull.length > 0) {
			setIsLoading(true)
			Promise.all(casesToPull.map(({ caseId }) => MaterialApi.getSimplifiedVisualMaterials(caseId)))
				.then(responses => {
					selectCasesWithMaterials(prev =>
						prev.concat(
							responses.reduce(
								(memo, next, index) =>
									memo.concat([
										{
											caseId: casesToPull[index].caseId,
											visualMaterials: next.materials.filter(
												({ productCategory }) => productCategory === ProductCategory.Location
											),
										},
									]),
								[] as IPersistedMaterials[]
							)
						)
					)
				})
				.finally(() => setIsLoading(false))
		}
	}, [values.selectedCaseMaterials])

	const selectedCasesVisualMaterials = useMemo(
		() =>
			casesWithMaterials.reduce(
				(memo, next) =>
					memo.concat(
						next.visualMaterials.map(material => ({
							...material,
							isSelected: values.selectedCaseMaterials.some(item => item.visualMaterials.includes(material.id))
								? !material.isSelected
								: material.isSelected,
						}))
					),
				[] as ISimplifiedVisualMaterial[]
			),
		[casesWithMaterials, values.selectedCaseMaterials]
	)

	if (isLoading) return <Spin />

	return (
		<Styled.MaterialList
			size="small"
			visualMaterials={selectedCasesVisualMaterials}
			onItemClick={materialId => {
				setFieldValue(
					'selectedCaseMaterials',
					values.selectedCaseMaterials.map(item => {
						const isCurrentItemCase = casesWithMaterials.find(
							({ caseId, visualMaterials }) =>
								caseId === item.caseId && visualMaterials.some(material => material.id === materialId)
						)
						if (!isCurrentItemCase) return item

						return {
							...item,
							visualMaterials: item.visualMaterials.includes(materialId)
								? item.visualMaterials.filter(id => id !== materialId)
								: item.visualMaterials.concat([materialId]),
						}
					})
				)
			}}
		/>
	)
}

const MaterialListWrapper: FC<IDatahubCase> = ({ id }) => {
	const { visualMaterials = [], isLoading } = useVisualMaterials(id)

	const locationMaterials = useMemo(
		() => visualMaterials.filter(({ productCategory }) => productCategory === ProductCategory.Location),
		[visualMaterials]
	)

	if (isLoading) return <Spin />

	return <Styled.MaterialList disabled size="small" visualMaterials={locationMaterials} />
}

const CopyMaterialByCaseLocationModal: FC<CopyMaterialByCaseLocationModalProps> = ({ caseId }) => {
	const { t } = useLocalization()
	const hideModal = useHideModal()
	const [searchKey, setSearchKey] = useState('')
	const params: IGetCasesListParams = useMemo(
		() => ({
			page: 0,
			pageSize: 1000,
			query: searchKey,
			category: [CaseCategory.Location],
		}),
		[searchKey]
	)
	const queryClient = useQueryClient()
	const [debouncedParams] = useDebounce(params, 1250)
	const { data: foundCase } = useCaseById(caseId)
	const { data: cases = [], isLoading } = useQuery(
		[QUERIES.caseImages, debouncedParams],
		async () => {
			const response = await CasesApi.getDatahubCases(debouncedParams)
			return response.items
		},
		{
			enabled: !!debouncedParams.query && debouncedParams.query.length >= 3,
			keepPreviousData: !!debouncedParams.query && debouncedParams.query.length < 3,
		}
	)
	const { mutateAsync: copyMaterials, isLoading: isCopying } = useMutation(MaterialApi.copyMaterials)

	const onSubmit = async (values: ICopyMaterialByCaseLocationFormValues) => {
		if (foundCase) {
			const responses = await Promise.all(
				values.selectedCaseMaterials.map(
					({ reference: fromReference, visualMaterials: assetIds, branchId: fromBranchId }) =>
						copyMaterials({
							caseId,
							copyAll: true,
							assetIds: assetIds.map(id => id.toString()),
							reference: fromReference,
							branchId: fromBranchId,
						})
				)
			)

			if (responses.every(response => response.success)) {
				message.success(t('modals.copyMaterialByCaseLocation.success'))
				queryClient.invalidateQueries([QUERIES.visualMaterialsSimplified, caseId])
				hideModal()
				return
			}

			message.error(t('modals.copyMaterialByCaseLocation.failed'))
		}
	}

	const [areCasesStillLoading] = useDebounce(isLoading, 500)

	const match = useScreenMatch()

	const desktopColumns = useMemo(
		() =>
			createTableColumns<IDatahubCase>([
				{
					title: t('common.terms.case'),
					dataIndex: 'reference',
				},
				{
					title: t('common.terms.address'),
					dataIndex: 'address',
				},
			]),
		[t]
	)

	const mobileColumns = useMemo(
		() =>
			createTableColumns<IDatahubCase>([
				{
					title: t('common.terms.case'),
					render: (_, data) => (
						<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
							<Typography.Text ellipsis>{data.reference}</Typography.Text>
							{data.address.trim() && <Typography.Text ellipsis>{data.address}</Typography.Text>}
						</Container>
					),
				},
			]),
		[t]
	)

	const columns = useMemo(
		() => match.map({ xxs: mobileColumns, lg: desktopColumns }),
		[match, mobileColumns, desktopColumns]
	)

	const dataSource = useMemo(
		() =>
			cases.map(caseObject => ({
				...caseObject,
				key: caseObject.id,
			})),
		[cases]
	)

	return (
		<Formik<ICopyMaterialByCaseLocationFormValues> initialValues={initialFormValues} onSubmit={onSubmit}>
			{({ values, setFieldValue, resetForm, isSubmitting }) => {
				const selectedCaseIds = values.selectedCaseMaterials.map(({ caseId: id }) => id)

				const hasSelectedMaterials = values.selectedCaseMaterials.some(item => item.visualMaterials.length > 0)

				return (
					<Modal
						visible
						width={800}
						closeIcon={<Close24 />}
						onCancel={hideModal}
						title={t('modals.copyMaterialByCaseLocation.title')}
						footer={[
							values.isVisualMode ? (
								<Button key="back" onClick={() => resetForm()}>
									{t('navigation.back')}
								</Button>
							) : (
								<Button
									key="select"
									disabled={values.selectedCaseMaterials.length === 0}
									type="primary"
									onClick={() => setFieldValue('isVisualMode', true)}
								>
									{t('common.actions.selectImages')}
								</Button>
							),
							values.isVisualMode && (
								<Button
									form="save-location-material-form"
									loading={isCopying}
									disabled={!hasSelectedMaterials}
									key="submit"
									htmlType="submit"
									type="primary"
								>
									{t('modals.copyMaterialByCaseLocation.submit')}
								</Button>
							),
						]}
					>
						<UserInteraction enabled={!isSubmitting}>
							<Form id="save-location-material-form" layout="vertical">
								<Autolocalize />
								{values.isVisualMode ? (
									<VisualModeMaterials />
								) : (
									<Grid.Row>
										<Grid.Col xs={24}>
											<FormikInput
												type="search"
												name="name"
												// improve typing later
												onChange={(e: any) => setSearchKey(e.target.value)}
												placeholder={t('modals.copyMaterialByCaseLocation.searchPlaceholder', { min: 3 })}
											/>
										</Grid.Col>
										<Grid.Col xs={24}>
											<Table
												rowSelection={{
													type: 'checkbox',
													selectedRowKeys: selectedCaseIds,
													onChange: selectedKeys => {
														const selectedCases = dataSource.filter(({ id }) => selectedKeys.includes(id))
														const selectedCaseMaterials = selectedCases.map(selectedCase => ({
															caseId: String(selectedCase.id),
															reference: selectedCase.reference,
															branchId: selectedCase.departmentId,
															visualMaterials: [],
														}))
														setFieldValue('selectedCaseMaterials', selectedCaseMaterials)
													},
												}}
												size="small"
												expandable={{
													expandIconColumnIndex: 3,
													expandIcon: ({ expanded, onExpand, record }) => (
														<Container display="flex" onClick={e => onExpand(record, e)}>
															{expanded ? <ChevronUp24 /> : <ChevronDown24 />}
														</Container>
													),
													expandedRowRender: record => <MaterialListWrapper {...record} />,
												}}
												loading={areCasesStillLoading}
												columns={columns}
												dataSource={dataSource}
												pagination={false}
											/>
										</Grid.Col>
									</Grid.Row>
								)}
							</Form>
						</UserInteraction>
					</Modal>
				)
			}}
		</Formik>
	)
}

export default CopyMaterialByCaseLocationModal
