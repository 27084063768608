import { useAuth } from 'modules/auth/hooks'
import { INewOrderState, useNewOrderContext } from '../context'
import QUERIES from 'modules/common/queries'
import { CasesApi, IAddress, IBranch, ICreateOrderParams, IProduct, OrdersApi, TaskCategory } from '@libs/api'
import { useQuery, useQueryClient } from 'react-query'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useAddressSelector } from 'modules/common/hooks'
import { message } from 'antd'
import { SelectProps } from 'formik-antd'
import { useLocalization } from '@libs/localization'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import { useShowModal } from 'modules/common/GlobalModals'
import merge from 'lodash/merge'
import { getIn, useFormikContext } from 'formik'
import { IPropertyStepThreeFormValues } from '.'
import { useUserBranches, useUsersBranchById } from 'modules/branches/hooks'
import { usePropertyTypes } from '../hooks'
import { useBranchUsersList } from 'modules/auth/ActiveDirectoryPageContent/hooks'

interface IUseOrderFormAddress {
	name: string | keyof INewOrderState
	idCheck: boolean
	mergeUpdate: (newAddress: IAddress) => RecursivePartialType<INewOrderState>
}

export function useIsAnyTaskIncludesBooking(): boolean {
	const [orderState] = useNewOrderContext()

	return useMemo(() => {
		const findWithinProducts = (products: IProduct[]) =>
			products.some(({ tasks }) => tasks.some(({ type }) => type === TaskCategory.BookPhotographer))

		return (
			findWithinProducts(orderState.package?.products || []) ||
			findWithinProducts(orderState.products || []) ||
			findWithinProducts(
				orderState.package?.choices?.reduce((memo, next) => {
					const foundProduct = next.products.find(({ id }) => next.selectedProductId === id)
					if (foundProduct) return memo.concat([foundProduct])
					return memo
				}, [] as IProduct[]) || []
			)
		)
	}, [orderState])
}

export function useNewOrderStepThreeData() {
	const showModal = useShowModal()
	const queryClient = useQueryClient()
	const [orderState, setOrderState] = useNewOrderContext()
	const { user } = useAuth()
	const { t } = useLocalization()
	const invalidateTaskQueries = useTasksQueriesInvalidation()
	// Mock it for now, since Manual Address edit is available
	const providerSupportsSubpremise = true // AddressApi.providerSupportsSubpremise(user?.branches?.[0]?.countryCode?.toLowerCase() || 'dk')

	const { data: propertyTypeKeys = [], isLoading: arePropertyTypesLoading } = usePropertyTypes()

	const { data: caseFilters } = useQuery(QUERIES.caseFilters, CasesApi.getFilters)

	const propertyTypeOptions = useMemo(
		() =>
			propertyTypeKeys
				.map(({ key, name }) => {
					// const formattedKey = t(`orders.propertyTypes.${camelCase(key)}`)
					return {
						value: key,
						label: name,
					}
				})
				.sort((prev, next) => (prev.label < next.label ? -1 : 1)),
		[propertyTypeKeys]
	)

	const foundBranch = useUsersBranchById(orderState.departmentId)

	const { data: departmentMembers = [], isLoading: areDepartmentMembersLoading } = useBranchUsersList(foundBranch?.id)

	const selectedDepartmentId = orderState.departmentId
	const setSelectedDepartmentId = useCallback(
		(value: IBranch['id']) =>
			setOrderState(prevState => ({
				...prevState,
				departmentId: value,
			})),
		[setOrderState]
	)

	const departmentOptions = useMemo<SelectProps['options']>(
		() =>
			(user?.branches || []).map(userDepartment => ({
				value: userDepartment.id,
				label: userDepartment.name,
			})),
		[user]
	)

	// Calculate max living area based on tasks from the package selection (ugly af)
	const maxPropertyLivingAreaValue = useMemo(
		() =>
			orderState.package
				? [
						...(orderState.package.products ?? []),
						...(orderState.package.choices?.reduce((acc, choice) => acc.concat(choice.products), [] as IProduct[]) ??
							[]),
				  ].reduce(
						(maxValue, { maxKvmSize }) =>
							typeof maxKvmSize === 'number' && maxKvmSize > maxValue ? maxKvmSize : maxValue,
						0
				  )
				: undefined,
		[orderState.package]
	)

	// Submit order logic when orderState.status === 'processing'
	const isSubmittingRef = useRef(false)
	useEffect(() => {
		if (orderState.status === 'processing' && !isSubmittingRef.current) {
			isSubmittingRef.current = true
			OrdersApi.createOrder({
				...orderState,
				user,
			} as ICreateOrderParams)
				.then(orderResponse => {
					isSubmittingRef.current = false
					setOrderState(prevState => ({
						...prevState,
						status: 'done',
						step: (prevState.step ?? 0) + 1,
						orderResponse,
						case: {
							...prevState.case,
							id: prevState.case?.id || orderResponse.caseId,
							reference: prevState.case!.reference,
						},
						orderId: orderResponse.orderId,
					}))

					queryClient.invalidateQueries([QUERIES.userList, orderState.departmentId])
					invalidateTaskQueries()
				})
				.catch(err => {
					isSubmittingRef.current = false
					setOrderState(prevState => ({
						...prevState,
						status: 'draft',
					}))

					message.error(err.message ?? t('orders.pages.new.createOrderFailedDueToUnknownError'))
				})
		}
	}, [orderState, setOrderState, queryClient, t, invalidateTaskQueries, user])

	const departmentMemberOptions = useMemo(
		() =>
			departmentMembers.map(({ id, fullName }) => ({
				value: id,
				label: fullName,
			})),
		[departmentMembers]
	)

	const anyTaskIncludesBooking = useIsAnyTaskIncludesBooking()

	return {
		propertyTypeOptions,
		arePropertyTypesLoading,
		departmentMembers,
		departmentMemberOptions,
		areDepartmentMembersLoading,
		maxPropertyLivingAreaValue: maxPropertyLivingAreaValue || 999999, // requested fallback is 999999
		department: foundBranch,
		orderState,
		setOrderState,
		user,
		selectedDepartmentId,
		setSelectedDepartmentId,
		departmentOptions,
		providerSupportsSubpremise,
		anyTaskIncludesBooking,
		showModal,
		filterCategories: caseFilters?.categories || [],
	}
}

export function useOrderFormAddress({ name, idCheck, mergeUpdate }: IUseOrderFormAddress) {
	const { t } = useLocalization()
	const [orderState, setOrderState] = useNewOrderContext()
	const { values } = useFormikContext<IPropertyStepThreeFormValues>()
	const anyTaskIncludesBooking = useIsAnyTaskIncludesBooking()
	// Mock it for now, since Manual Address edit is available
	const providerSupportsSubpremise = true // AddressApi.providerSupportsSubpremise(user?.branches?.[0]?.countryCode?.toLowerCase() || 'dk')

	const { data: branches = [] } = useUserBranches()

	const addressValue = getIn(orderState, name) as IAddress

	const mergeOrderState = (updates: RecursivePartialType<INewOrderState>) =>
		setOrderState(prevState =>
			merge(
				{},
				prevState,
				{
					departmentId: values.departmentId,
					hasExternalStylist: values.hasExternalStylist,
					photographerComment: values.photographerComment,
					responsibleUser: {
						id: values.responsibleUserId,
					},
					contactPersons: values.contactPersons,
					watchers: values.watchers,
					property: values.property,
					anyTaskIncludesBooking,
					...(values.shouldSendEndUserInvoice && { endUserInvoice: values.endUserInvoice }),
					...(values.shouldSendElectronicInvoice && { electronicInvoice: values.electronicInvoice }),
				},
				updates
			)
		)

	const foundBranch = useMemo(
		() => branches.find(({ id }) => id === orderState.branchId),
		[branches, orderState.branchId]
	)

	const {
		address,
		setPrediction: setAddressPrediction,
		setQuery: setAddressQuery,
		autocomplete: addressAutocomplete,
	} = useAddressSelector(
		idCheck ? (addressValue?.id ? addressValue : undefined) : addressValue,
		foundBranch?.countryCode
	)

	useEffect(() => {
		if (address) {
			mergeOrderState(mergeUpdate(address))
		}
	}, [address])

	useEffect(() => {
		if (typeof addressValue === 'string' && addressAutocomplete.options?.[0]) {
			setAddressPrediction(addressAutocomplete.options[0].data)
		}
	}, [addressAutocomplete.options])

	return {
		address,
		setAddressPrediction,
		setAddressQuery,
		addressAutocomplete,
		providerSupportsSubpremise,
		t,
	}
}
