import { borderRadius, color, easing } from '@libs/theme/mixins'
import styled, { ColorType } from 'styled-components'
import { ARROW_BODY_SIZE } from './constants'

export const ARROW_BODY_COLOR: ColorType = ['primary', '500']

const Wrapper = styled.div<{ $transitionTimeout: number }>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	cursor: copy;
	border-radius: ${borderRadius('md')};
	user-select: none;
	touch-action: none;

	// Item transition
	.transition-enter {
		opacity: 1;
	}

	.transition-enter-active {
		// item should directly enter without transitions
	}

	.transition-exit {
		opacity: 1;
	}

	.transition-exit-active {
		opacity: 0;
		transition: opacity ${({ $transitionTimeout }) => `${$transitionTimeout}ms`} ${easing('in')};
	}
`

const Arrow = styled.div`
	position: absolute;
	z-index: 2;
`

const ArrowOrigin = styled.div<{
	$selected: boolean
}>`
	position: absolute;
	z-index: 0;
	border-radius: 50%;
	top: 0;
	left: 0;
	transform: translate(-50%, -50%);
	background-color: ${color('white')};
	border: 1px solid ${({ $selected }) => ($selected ? color('primary') : color(['gray', '300']))};
	color: ${({ $selected }) => ($selected ? color('primary') : color('black'))};
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`

const ArrowBody = styled.div`
	position: absolute;
	z-index: 1;
	top: 0;
	right: 0;
	left: 0;
	transform-origin: 0 50%;
	pointer-events: none;
`

const ArrowBodyTail = styled.div`
	position: absolute;
	top: 0;
	right: ${ARROW_BODY_SIZE * 0.5}px;
	height: ${ARROW_BODY_SIZE}px;
	transform: translateY(-50%);
	background-color: ${color(ARROW_BODY_COLOR)};
`

const ArrowBodyPointer = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	transform-origin: right top;
	transform: rotate(45deg);
	z-index: 2;
	border-top: ${ARROW_BODY_SIZE}px solid ${color(ARROW_BODY_COLOR)};
	border-right: ${ARROW_BODY_SIZE}px solid ${color(ARROW_BODY_COLOR)};
`

const Point = styled.div<{
	$selected: boolean
}>`
	position: absolute;
	z-index: 2;
	cursor: pointer;
	border: 1px solid ${({ $selected }) => ($selected ? color('primary') : color(['gray', '300']))};
	color: ${({ $selected }) => ($selected ? color('primary') : color('black'))};
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${color('white')};
	border-radius: ${borderRadius('sm')};
	transform: translate(-50%, -50%);
`

const Lasso = styled.div<{
	$top?: number
	$left?: number
	$hovered?: boolean
}>`
	position: absolute;
	${({ $top }) => $top && `top: ${$top}px`};
	${({ $left }) => $left && `left: ${$left}px`};
	z-index: 1;

	canvas {
		${({ $hovered }) => $hovered && `cursor: pointer;`};
	}
`

export default {
	Wrapper,
	Arrow,
	ArrowOrigin,
	ArrowBody,
	ArrowBodyTail,
	ArrowBodyPointer,
	Point,
	Lasso,
}
