import styled from 'styled-components'
import { Card as _Card, Typography } from 'antd'
import { margin, fontSize, screen } from '@libs/theme/mixins'

const Card = styled(_Card)`
	height: 100%;

	.ant-card-body {
		height: 100%;
		display: flex;
		flex-direction: column;
	}
`

const ImageContainer = styled.div`
	min-height: 170px;
	max-height: 200px;
	margin-bottom: ${margin('sm')};
	display: flex;
	flex: 1;

	img {
		width: 100%;
		object-fit: cover;
	}
`

const Title = styled(Typography.Paragraph)`
	min-height: 48px;
	${screen.lte.md`
		text-align: center;
	`}
`

const Price = styled(Typography.Text)`
	font-size: ${fontSize('lg')};
	${screen.lte.md`
		text-align: center;
	`}
`

export default {
	Card,
	Title,
	ImageContainer,
	Price,
}
