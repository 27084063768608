import React, { FC } from 'react'
import { Typography } from 'antd'
import { Container, Grid } from '@libs/components'
import { IVirtualStaging } from '@libs/api'
import Styled from './Styled'
import { useLocalization } from '@libs/localization'
import camelCase from 'lodash/camelCase'

export interface SelectRoomTypeStepProps {
	rooms: IVirtualStaging[]
	selectRoom: (roomTypeName: IVirtualStaging['roomTypeName']) => void
}

const SelectRoomTypeStep: FC<SelectRoomTypeStepProps> = ({ children, rooms, selectRoom }) => {
	const { t } = useLocalization()
	return (
		<Container>
			<Grid.Row gutter={'sm'}>
				{children}
				<Grid.Col flex="1 1 0%">
					<Container padding={{ bottom: 'xxs' }}>
						<Typography.Title level={5}>{t('modals.virtualStaging.selectRoomStep.title')}</Typography.Title>
						{rooms.length ? (
							<Grid.Row gutter={'sm'}>
								{rooms.map(({ roomTypeName, url, isSelected }) => {
									const translatedRoomTypeName = t(`materials.roomTypes.${camelCase(roomTypeName)}`)

									return (
										<Grid.Col key={roomTypeName} xs={12} lg={6}>
											<Styled.RoomTypeCard $selected={isSelected} onClick={() => selectRoom(roomTypeName)}>
												<Styled.ImageWrapper>
													<img src={url} alt={`${roomTypeName} example`} />
												</Styled.ImageWrapper>
												<Typography.Text ellipsis={{ tooltip: translatedRoomTypeName }}>
													{translatedRoomTypeName}
												</Typography.Text>
											</Styled.RoomTypeCard>
										</Grid.Col>
									)
								})}
							</Grid.Row>
						) : (
							<Typography.Text>{t('modals.virtualStaging.emptyRooms')}</Typography.Text>
						)}
					</Container>
				</Grid.Col>
			</Grid.Row>
		</Container>
	)
}

export default SelectRoomTypeStep
