export const FILTER_STORAGE_KEY = 'filters'

/**
 * Checks if the item is a valid property to be url encoded
 *
 * @param item - Any source item
 * @returns - Whether it is a valid url parameter or not
 */
export const isValidUrlParam = (item: any): boolean => {
	if (typeof item === 'object' && !(item instanceof Date) && !Array.isArray(item)) return false
	if (Array.isArray(item)) {
		return item.every(isValidUrlParam)
	}
	return true
}
