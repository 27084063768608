import React, { FC, useMemo } from 'react'
import { useHideModal } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { Button, Modal } from 'antd'
import { BookingTypes, IDatahubTask } from '@libs/api'
import { usePhotographerBookingModal } from './hooks'
import { Close24 } from '@carbon/icons-react'
import { TaskModalCommonProps } from 'modules/tasks/types'
import TaskModalTitle from 'modules/common/TaskModalTitle'
import TaskBookPhotographerContent from './TaskBookPhotographerContent'

export interface TaskBookPhotographerModalProps extends TaskModalCommonProps {
	task: IDatahubTask
}

const TaskBookPhotographerModal: FC<TaskBookPhotographerModalProps> = ({ task, onTaskComplete, onTaskCancel }) => {
	const hideModal = useHideModal()
	const { t } = useLocalization()
	const usePhotographerBookingResult = usePhotographerBookingModal({ task, onTaskComplete })
	const { bookingType, isSubmitting, selectedSecondaryItem, onConfirmClick, onBackClick } = usePhotographerBookingResult

	const isConfirmEnabled = useMemo(
		() => (bookingType === BookingTypes.BookingSecondaryConfirmed ? !!selectedSecondaryItem : true),
		[bookingType, selectedSecondaryItem]
	)

	return (
		<Modal
			visible
			closeIcon={<Close24 />}
			title={<TaskModalTitle title={t('tasks.categories.BOOK_PHOTOGRAPHER')} caseId={task.caseReference} />}
			onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
			width={800}
			footer={
				bookingType !== BookingTypes.BookingPrimaryConfirmed && [
					<Button key="back" onClick={onBackClick}>
						{t('navigation.back')}
					</Button>,
					<Button
						key="confirm"
						loading={isSubmitting}
						disabled={!isConfirmEnabled}
						type="primary"
						onClick={onConfirmClick}
					>
						{t('common.actions.confirm')}
					</Button>,
				]
			}
		>
			<TaskBookPhotographerContent {...usePhotographerBookingResult} task={task} />
		</Modal>
	)
}

export default TaskBookPhotographerModal
