import { useMutation, useQuery } from 'react-query'
import dayjs from 'dayjs'
import QUERIES from 'modules/common/queries'
import { IFeedbackParams, ITaskCopywriteText, ITaskCopywriteTextWithReview, OrdersApi, TasksApi } from '@libs/api'
import { TaskCopywriteTextModalProps } from './index'
import { useAuth } from 'modules/auth/hooks'
import { useState } from 'react'
import { message } from 'antd'
import { useHideModal } from '@libs/components'
import { useLocalization } from '@libs/localization'
import { useTasksQueriesInvalidation } from 'modules/tasks/hooks'
import { useOrderLineById } from 'modules/cases/CaseOrderDetailsPageContent/hooks'

export const useTaskCopywriteTextModal = ({ task, onTaskComplete }: TaskCopywriteTextModalProps) => {
	const [isRejectingText, setIsRejectingText] = useState(false)
	const { user } = useAuth()
	const hideModal = useHideModal()
	const { t } = useLocalization()
	const invalidateTaskQueries = useTasksQueriesInvalidation()

	// disable text rejecting for tasks created more than 30 days ago
	const isTooLateToReject = dayjs(task.createdAt) < dayjs().subtract(30, 'days')

	const { data: copyWriteTexts = [], isLoading: isTextLoading } = useQuery(
		[QUERIES.copyWriteTexts, task.orderlineId],
		() => TasksApi.getCopyWriteText(task.orderlineId)
	)

	const { data: caseOrderList, isLoading: isCaseOrderListLoading } = useQuery(
		[QUERIES.orderListSimplified, task.caseId],
		() => OrdersApi.getCaseOrders(task.caseId),
		{
			enabled: isRejectingText,
		}
	)

	const { data: foundOrderLine } = useOrderLineById({ orderId: task.orderId, orderlineId: task.orderlineId })

	const foundOrder = caseOrderList?.find(orderListItem => orderListItem.id === task.orderId)

	const { mutateAsync: submitTexts, isLoading: isSubmitLoading } = useMutation(TasksApi.saveCopyWriteText)
	const { mutateAsync: submitReview, isLoading: isReviewLoading } = useMutation(OrdersApi.submitFeedback)
	const { mutateAsync: completeTask, isLoading: isCompleteTaskLoading } = useMutation(TasksApi.completeTask, {
		onSuccess: result => {
			if (result.success) invalidateTaskQueries()
		},
	})

	const isLoading =
		isTextLoading || isSubmitLoading || isCompleteTaskLoading || isReviewLoading || isCaseOrderListLoading

	const handleCompleteTask = async () => {
		const isTaskComplete = await completeTask(task.id)

		if (isTaskComplete) {
			message.success(t('modals.copywriteText.success'))

			if (onTaskComplete) {
				onTaskComplete(task)
				return
			}

			hideModal()
		} else {
			message.error(t('modals.copywriteText.completeFailed'))
		}
	}

	const onApprove = async (approvedTexts: ITaskCopywriteText) => {
		const mappedTexts = copyWriteTexts.map(section => {
			const { sectionName, content } = section

			return {
				approved: true,
				copywriteTextType: sectionName,
				description: sectionName,
				editedText: approvedTexts[sectionName] || null,
				originalText: content,
				editIsOpen: true,
			}
		})

		const isTextApproved = await submitTexts({
			orderTaskId: task.id,
			copyWriteTexts: mappedTexts,
		})

		if (isTextApproved) {
			await handleCompleteTask()
		} else {
			message.error(t('modals.copywriteText.submitFailed'))
		}
	}

	const onReviewAndReject = async (values: ITaskCopywriteTextWithReview) => {
		if (!user || !foundOrder || !foundOrderLine) {
			message.error(t('modals.copywriteText.errorRejecting'))
			return
		}

		const params: IFeedbackParams = {
			orderlineId: foundOrderLine.id,
			type: values.feedbackType,
			comment: values.comment,
			// useless params used for other task
			uploadedDocuments: [],
			drawingComments: [],
		}

		if (await submitReview(params)) {
			await handleCompleteTask()
		} else {
			message.error(t('modals.copywriteText.errorRejecting'))
		}
	}

	return [
		{
			isRejectingText,
			isLoading,
			copyWriteTexts,
			isTooLateToReject,
			isReviewLoading,
			isTextLoading,
		},
		{
			setIsRejectingText,
			onApprove,
			onReviewAndReject,
		},
	]
}
