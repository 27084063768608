import styled from 'styled-components'
import { SubmitButton } from 'formik-antd'
import { margin, screen } from '@libs/theme/mixins'

const Logo = styled.img`
	width: 140px;
	margin-bottom: ${margin('xl')};
	margin-right: auto;
`

const LoginButton = styled(SubmitButton)`
	margin-top: ${margin('xl')};
	align-self: center;
	width: 300px;

	${screen.lte.xxs`
		width: 100%;
	`}
`

export default {
	Logo,
	LoginButton,
}