import { RefSelectProps } from 'antd/es/select'
import { RefObject, useEffect, useMemo, useRef, useState } from 'react'
import { AddressApi, IDatahubCase } from '@libs/api'
import { useAddressSearch } from '../hooks'
import { useLocalization } from '@libs/localization'
import { useAuth } from 'modules/auth/hooks'
import { useShowModal } from '../GlobalModals'

export const useEditableAddress = ({
	address,
	otherBranchCountryCode,
}: {
	address: IDatahubCase['address']
	otherBranchCountryCode?: string
}) => {
	const ref = useRef() as RefObject<HTMLDivElement>
	const { user } = useAuth()
	const showModal = useShowModal()
	const [searchKey, setSearchKey] = useState('')
	const { options: addressOptions } = useAddressSearch(searchKey, undefined, otherBranchCountryCode)
	const providerSupportsSubpremise =
		!!user &&
		AddressApi.providerSupportsSubpremise((otherBranchCountryCode || user?.branches?.[0]?.countryCode)?.toLowerCase())

	const addressInputRef = useRef<RefSelectProps>() as RefObject<RefSelectProps>
	const [title, setTitle] = useState(address)
	const [editActive, setEditActive] = useState(false)
	const { t } = useLocalization()

	useEffect(() => {
		if (address !== title) {
			setTitle(address)
		}
	}, [address, title])

	useEffect(() => {
		if (editActive) {
			addressInputRef.current?.focus()
		}
	}, [editActive])

	const addressPlaceholder = useMemo(() => t('common.placeholders.address'), [t])

	return {
		t,
		ref,
		title,
		editActive,
		addressInputRef,
		addressOptions,
		addressPlaceholder,
		providerSupportsSubpremise,
		setSearchKey,
		setEditActive,
		setTitle,
		showModal,
	}
}
