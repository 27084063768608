import { borderRadius, color, easing, fontSize, height, margin, screen, typography } from '@libs/theme/mixins'
import { Typography } from 'antd'
import { TransitionGroup } from 'react-transition-group'
import styled from 'styled-components'
import { Container } from '@libs/components'

const ToolsContainer = styled(Container)`
	background: ${color('white')};
`

const Instructions = styled.div`
	${typography('sm')};
	display: flex;
	align-items: center;
	color: ${color(['gray', '700'])};
	font-weight: 500;
	height: ${height('md')};
	> * {
		margin-right: ${margin('xs')};
		&:last-child {
			margin-right: 0;
		}
	}
`

const ImageContainer = styled.div<{ $transitionTimeout: number }>`
	border-radius: ${borderRadius('md')};
	position: relative;
	overflow: hidden;

	img {
		width: 100%;

		// Transition animations
		&.transition-enter {
			opacity: 0;
		}

		&.transition-enter-active {
			opacity: 1;
			transition: all ${({ $transitionTimeout }) => `${$transitionTimeout}ms`} ${easing('in')};
		}

		&.transition-exit {
			opacity: 1;
		}

		&.transition-exit-active {
			opacity: 0;
			transition: all ${({ $transitionTimeout }) => `${$transitionTimeout}ms`} ${easing('in')};
		}
	}
`

const ImageContainerTransitionGroup = styled(TransitionGroup)`
	position: relative;
	z-index: 0;
`

const PopoverContent = styled.div<{ $hasRadio: boolean }>`
	width: ${({ $hasRadio }) => ($hasRadio ? '280px' : '350px')};

	.ant-radio-group {
		flex-direction: column;

		> *:not(:last-child) {
			margin-bottom: ${margin('xs')};
		}
	}

	${screen.lte.xs`
		width: 100%;
	`}
`

const ImageErrorMessage = styled(Typography.Text)`
	position: absolute;
	left: 50%;
	bottom: ${margin('xxl')};
	transform: translate(-50%);
	text-align: center;
	font-size: ${fontSize('lg')};
`

export default {
	ToolsContainer,
	Instructions,
	ImageContainer,
	ImageContainerTransitionGroup,
	PopoverContent,
	ImageErrorMessage,
}
