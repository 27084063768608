import { IDatahubCase, ISimplifiedVisualMaterial, MaterialApi, S3Api } from '@libs/api'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { message } from 'antd'
import { useHideModal } from '@libs/components'
import { ICollageFormValues } from '.'
import { useLocalization } from '@libs/localization'
import { useCollagePricing, useVisualMaterials } from 'modules/material/hooks'
import { COLLAGE_TEMPLATES } from '../CollageEditor/consts'
import { generateOutputImage } from './helpers'
import TemplateSelectionStep from './TemplateSelectionStep'
import CollageEditingStep from './CollageEditingStep'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import { getS3Path } from '@libs/api/build/s3/helpers'
import { useMutation, useQueryClient } from 'react-query'
import { useTheme } from 'styled-components'
import { useScreenMatch } from '@libs/theme'
import QUERIES from 'modules/common/queries'

export const useCollageModal = ({ caseId }: { caseId: IDatahubCase['id'] }) => {
	const { t, f } = useLocalization()
	const [currentStep, setCurrentStep] = useState(0)
	const hideModal = useHideModal()
	const [innerVisualMaterials, setInnerVisualMaterials] = useState<ISimplifiedVisualMaterial[]>([])
	const theme = useTheme()
	const match = useScreenMatch()
	const queryClient = useQueryClient()

	const { data: pricing } = useCollagePricing(caseId)
	const { visualMaterials, isLoading } = useVisualMaterials(caseId)

	const { mutateAsync: submitCollage, isLoading: isCollageSubmitting } = useMutation(MaterialApi.submitCollage, {
		onSuccess: response => {
			if (response?.success) {
				queryClient.invalidateQueries([QUERIES.orderListSimplified, caseId])
				queryClient.invalidateQueries([QUERIES.visualMaterialsSimplified, caseId])
				message.success(t('modals.collage.success'))
				hideModal()
				return
			}

			message.error(t('common.messages.collageGeneratingError') || t('modals.collage.failed'))
		},
	})

	const collagePrice = useMemo(
		() =>
			t('common.actions.buyFor', {
				value: f(pricing?.price ?? 0.0, { currency: pricing?.currency || 'DKK', decimals: 2 }),
			}),
		[pricing, t, f]
	)

	const uploadImage = async (file: Blob | null) => {
		if (!file) return ''

		const s3Key = getS3Path({
			innerDir: 'collage',
			outerDir: caseId,
			fileUuid: uuidv4(),
			fileExtension: 'png',
		})

		const s3re = await S3Api.signS3Upload({
			expiration: dayjs().add(2, 'minute').toISOString(),
			filePath: s3Key,
		})

		const { preSignedUrl } = s3re

		await S3Api.s3Upload({
			preSignedUrl,
			file: file,
		})

		return preSignedUrl.split('?')[0]
	}

	const onFormSubmit = async (values: ICollageFormValues) => {
		try {
			const canvasBlob = await generateOutputImage({
				...COLLAGE_TEMPLATES[values.template],
				imageUrls: values.outputImages,
			})
			const imageUrl = await uploadImage(canvasBlob)
			await submitCollage({
				caseId,
				collageUrl: imageUrl,
			})
		} catch {
			message.error(t('common.messages.collageGeneratingError') || t('modals.collage.failed'))
		}
	}

	useEffect(() => {
		if (Array.isArray(visualMaterials) && visualMaterials.length > 0) {
			setInnerVisualMaterials(visualMaterials.filter(({ type }) => type === 'Picture'))
		}
	}, [visualMaterials])

	const steps = useMemo(() => {
		return [
			{
				title: t('modals.collage.templateStep.title'),
				content: <TemplateSelectionStep />,
			},
			{
				title: t('modals.collage.editStep.title'),
				content: <CollageEditingStep materials={innerVisualMaterials} />,
			},
		]
	}, [innerVisualMaterials, t])

	const onBackClick = useCallback(() => setCurrentStep(prev => (prev === 0 ? 0 : prev - 1)), [])

	const onNextClick = useCallback(() => setCurrentStep(prev => prev + 1), [])

	const nextButtonText = useMemo(() => {
		if (isCollageSubmitting) return t('common.actions.generating')
		return currentStep === 1 ? collagePrice : t('navigation.next')
	}, [t, currentStep, collagePrice, isCollageSubmitting])

	return {
		theme,
		match,
		currentStep,
		isLoading,
		steps,
		innerVisualMaterials,
		nextButtonText,
		t,
		onBackClick,
		onNextClick,
		onFormSubmit,
	}
}
