import React, { FC, useEffect } from 'react'
import { useInputForSpeak, useNoteSubmitter } from 'modules/tasks/hooks'
import { Autolocalize, FormikTextArea, useHideModal, UserInteraction } from '@libs/components'
import { Button, Modal, Typography, Space, Spin } from 'antd'
import { Form, FormItem } from 'formik-antd'
import { Formik } from 'formik'
import { useQueryClient } from 'react-query'
import QUERIES from 'modules/common/queries'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { Close24 } from '@carbon/icons-react'
import TaskModalTitle from 'modules/common/TaskModalTitle'

export interface TaskSpeakInputModalProps extends TaskModalCommonProps {}

interface ISpeakInputForm {
	noteText: string
}

const TaskSpeakInputModal: FC<TaskSpeakInputModalProps> = ({ task, onTaskComplete, onTaskCancel }) => {
	const { Text } = Typography
	const hideModal = useHideModal()
	const { submit, isLoading: isSubmitting } = useNoteSubmitter(task)
	const queryClient = useQueryClient()
	const { t } = useLocalization()

	useEffect(() => {
		return () => {
			queryClient.removeQueries([QUERIES.inputForSpeakSlideShowText, task.caseId])
		}
	}, [])

	const { data: inputForSpeak, isLoading: isInputForSpeakLoading } = useInputForSpeak(task.caseId)

	const isLoading = isInputForSpeakLoading || isSubmitting

	const onSubmit = async (data: ISpeakInputForm) => {
		if (await submit(data.noteText)) {
			if (onTaskComplete) {
				onTaskComplete(task)
				return
			}

			hideModal()
		}
	}

	const initialValues: ISpeakInputForm = {
		noteText: inputForSpeak || '',
	}

	return (
		<Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
			{({ values, isSubmitting: isSubmittingForm }) => (
				<Modal
					visible
					closeIcon={<Close24 />}
					title={<TaskModalTitle title={t('modals.speakInput.title')} caseId={task.caseReference} />}
					onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
					footer={
						<Button
							form="task-speak-input-form"
							htmlType="submit"
							type="primary"
							loading={isLoading}
							disabled={values.noteText.length === 0}
						>
							{t('common.actions.completeTask')}
						</Button>
					}
				>
					<UserInteraction enabled={!isSubmittingForm}>
						<Form id="task-speak-input-form" layout="vertical">
							<Autolocalize />
							<Space direction="vertical" size="middle">
								<Text>{t('modals.speakInput.instructions')}</Text>

								<FormItem name="noteText" label={t('common.terms.speech')}>
									{isInputForSpeakLoading ? (
										<Spin>
											<FormikTextArea name="noteText" autoSize={{ minRows: 2, maxRows: 4 }} size="small" />
										</Spin>
									) : (
										<FormikTextArea name="noteText" autoSize={{ minRows: 2, maxRows: 4 }} size="small" />
									)}
								</FormItem>
							</Space>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default TaskSpeakInputModal
