import { Container } from '@libs/components'
import { typography } from '@libs/theme/mixins'
import styled from 'styled-components'

const Wrapper = styled(Container)`
	${typography('md')}
	cursor: pointer;
	font-weight: 500;
	transition: opacity 0.3s;

	&:hover {
		opacity: 0.6;
	}
`

export default {
	Wrapper,
}
