import { Container } from '@libs/components'
import Styled from './Styled'
import { IDatahubTask } from '@libs/api'
import React, { FC } from 'react'
import { useLocalization } from '@libs/localization'
import { useOpenTask } from '../hooks'
import { ITaskUploderMaterial } from './types'
import { useOrderLineById } from 'modules/cases/CaseOrderDetailsPageContent/hooks'

interface ITaskUploaderEntry {
	task: IDatahubTask
	materials: ITaskUploderMaterial[]
}

const TaskUploaderEntry: FC<ITaskUploaderEntry> = ({ task, materials }) => {
	const { t } = useLocalization()
	const openTask = useOpenTask()
	const { data: foundOrderLine } = useOrderLineById({ orderId: task.orderId, orderlineId: task.orderlineId })

	return (
		<Styled.PopoverMenuItem onClick={() => openTask(task)}>
			<Container display={'flex'} flexDirection={'column'} space={'xxs'} spaceDirection={'vertical'}>
				<Container
					display={'flex'}
					justifyContent={'space-between'}
					alignItems={'center'}
					space={'sm'}
					spaceDirection={'horizontal'}
				>
					<Styled.PopoverMenuTitle ellipsis>{task.productName}</Styled.PopoverMenuTitle>
					<Container>
						<Styled.PopoverMenuText>
							{materials.length} / {foundOrderLine?.orderedQuantity || 0}
						</Styled.PopoverMenuText>
					</Container>
				</Container>
				<Container display={'flex'} flexDirection={'column'}>
					<Styled.PopoverMenuText ellipsis>
						<b>{t('common.terms.case')}: </b>
						{task.caseId}
					</Styled.PopoverMenuText>
					<Styled.PopoverMenuText ellipsis>
						<b>{t('common.terms.order')}: </b>
						{task.orderId}
					</Styled.PopoverMenuText>
				</Container>
			</Container>
		</Styled.PopoverMenuItem>
	)
}

export default TaskUploaderEntry
