import styled from 'styled-components'
import { margin, padding } from '@libs/theme/mixins'
import { Divider as _Divider, Radio, Typography } from 'antd'
import { Grid } from '@libs/components'

const PackageContentList = styled(Grid.Row)`
	padding-top: ${padding('xxs')};

	> *:nth-child(3n-2) {
		text-align: right;
	}
`

const Divider = styled(_Divider)`
	margin: ${margin('xs')} 0;
`

const RadioContainer = styled(Radio.Group)`
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	row-gap: ${margin('md')};
`

const Prices = styled(Typography.Text)`
	white-space: nowrap;
`

export default {
	PackageContentList,
	Divider,
	RadioContainer,
	Prices,
}
