import { useQuery } from 'react-query'
import { useDebounce } from 'use-debounce'
import { DepartmentApi, IBranch, UsersApi } from '@libs/api'
import QUERIES from 'modules/common/queries'

export function useBranchSearch(query: string) {
	const [debouncedQuery] = useDebounce(query, 1000)
	const { data, isLoading } = useQuery(
		[QUERIES.departmentSearch, debouncedQuery],
		async () => {
			return await DepartmentApi.searchBranchByKey({
				searchString: debouncedQuery,
				skip: 0,
				take: 10, // no pagination for now
			})
		},
		{
			enabled: debouncedQuery.length >= 3,
		}
	)

	return {
		data: data?.results ?? [],
		isLoading,
	}
}

export function useBranchUsersList(branchId: IBranch['id'] | undefined) {
	return useQuery([QUERIES.membersByCompanyId, branchId], () => UsersApi.getUsersForBranch(branchId!), {
		enabled: !!branchId,
	})
}

export function useBranchById(branchId?: IBranch['id']) {
	return useQuery(
		[QUERIES.branchById, branchId],
		async () => (branchId ? await DepartmentApi.getBranchById(branchId) : undefined),
		{
			enabled: !!branchId,
		}
	)
}
