import React, { FC, useMemo } from 'react'
import {
	useHideModal,
	Grid,
	Autolocalize,
	UserInteraction,
	FormikInput,
	FormikPhoneInput,
	FormikTextArea,
} from '@libs/components'
import { Close24, Help24 } from '@carbon/icons-react'
import { Button, Modal, message } from 'antd'
import { Form, FormItem, Radio, Select, Checkbox } from 'formik-antd'
import { Formik } from 'formik'
import Styled from './Styled'
import Skeleton from './Skeleton'
import { TasksApi, ITaskInputTextForm, ITaskInputText } from '@libs/api'
import { useMutation } from 'react-query'
import { useNoteSubmitter } from 'modules/tasks/hooks'
import { useLocalization } from '@libs/localization'
import { TaskModalCommonProps } from '../types'
import { createValidationSchema } from '@libs/util'
import { useContactPersons, usePropertyTypes } from 'modules/orders/hooks'
import { useScreenMatch } from '@libs/theme'
import { useAuth } from 'modules/auth/hooks'
import TaskModalTitle from 'modules/common/TaskModalTitle'
import { useBranchUsersList } from 'modules/auth/ActiveDirectoryPageContent/hooks'
import { useCaseById, useContactPersonsByCaseId } from 'modules/cases/hooks'

export interface TaskTextInputModalProps extends TaskModalCommonProps {
	comtext?: boolean
	noStyleText?: boolean
}

interface IFormText {
	inputArea: {
		heading: string
		name: string
		description: string
	}
	radioGroup: {
		heading: string
		name: string
		description: string
		options: string[]
	}
	propertyTypeDesc: string
	interviewDesc: string
	responsibleAgentDesc: string
}

const TaskTextInputModal: FC<TaskTextInputModalProps> = ({
	task,
	comtext,
	noStyleText,
	onTaskComplete,
	onTaskCancel,
}) => {
	const isComtext = !!comtext
	const isNoStyleText = !!noStyleText
	const { Row, Col } = Grid
	const hideModal = useHideModal()
	const { submit, isLoading: isSubmitting } = useNoteSubmitter(task)
	const { user } = useAuth()
	const { t } = useLocalization()
	const { data: contactPersons = [] } = useContactPersonsByCaseId(task.caseId)

	let isInterviewChecked = false

	const formSchema = useMemo(
		() =>
			createValidationSchema(yup =>
				yup.object().shape({
					inputArea: yup.string().required(),
					propertyType: yup.string().required(),
					isInterviewRequired: yup.boolean().required(),
					responsibleAgent: yup.string().required(),
					contactType: yup.string().when('isInterviewRequired', {
						is: true,
						then: yup.string().required(),
					}),
					phone: yup.string().when('isInterviewRequired', {
						is: true,
						then: yup.string().min(8).phone().required(),
					}),
					secondaryPhone: yup.string().when('isInterviewRequired', {
						is: true,
						then: yup.string().min(8).phone().nullable(),
					}),
					email: yup.string().when('isInterviewRequired', {
						is: true,
						then: yup.string().email().required(),
					}),
					contactName: yup.string().when('isInterviewRequired', {
						is: true,
						then: yup.string().required(),
					}),
					...(!isNoStyleText && { radioGroup: yup.string().required() }),
				})
			),
		[isNoStyleText]
	)

	const conditionalText = isComtext
		? {
				inputArea: {
					heading: t('modals.textInput.inputArea.comtextTitle'),
					name: 'textForCopyWriter',
					description: t('modals.textInput.inputArea.description'),
				},
				radioGroup: {
					heading: t('modals.textInput.options.comtextTitle'),
					name: 'offerForm',
					description: t('modals.textInput.options.comtextDescription'),
					options: [t('common.terms.ownership'), t('common.terms.rent'), t('common.terms.both')],
				},
		  }
		: {
				inputArea: {
					heading: t('modals.textInput.inputArea.title'),
					name: 'importantElements',
					description: t('modals.textInput.inputArea.description'),
				},
				radioGroup: {
					heading: t('modals.textInput.options.title'),
					name: 'writingStyle',
					description: t('modals.textInput.options.description'),
					options: [t('common.terms.descriptive'), t('common.terms.lightlyColorful'), t('common.terms.colorful')],
				},
		  }

	const text: IFormText = {
		...conditionalText,
		propertyTypeDesc: t('modals.textInput.propertyTypeLabel'),
		interviewDesc: t('modals.textInput.interviewInstructions'),
		responsibleAgentDesc: t('modals.textInput.responsibleAgentInstructions'),
	}

	function mapInputTextToNote(data: ITaskInputTextForm) {
		const interview = data.isInterviewRequired
			? [
					t('common.terms.yes'),
					`${t('common.terms.name')}: ${data.contactName}`,
					`${t('common.terms.email')}: ${data.email}`,
					`${t('common.terms.phone')}: ${data.phone}`,
					data.secondaryPhone && `${t('common.terms.phoneAlt')}: ${data.secondaryPhone}`,
			  ]
					.filter(Boolean)
					.join(', ')
			: t('common.terms.no')

		const radioGroup = `${isComtext ? t('modals.textInput.options.comtextTitle') : t('common.terms.writingStyle')}: ${
			data.radioGroup
		}`

		return [
			`${text.inputArea.heading}: ${data.inputArea}`,
			`${t('common.terms.propertyType')}: ${data.propertyType}`,
			`${t('modals.textInput.shouldAnInterviewBeConductedText')}: ${interview}`,
			`${t('common.terms.responsibleAgent')}: ${data.responsibleAgent}`,
			...(!isNoStyleText ? [radioGroup] : []),
		].join(', ')
	}

	const {
		data: propertyTypeKeys = [],
		isLoading: isPropertyTypeListLoading,
		isFetched: isPropertyTypeListFetched,
	} = usePropertyTypes()

	const {
		data: userList = [],
		isLoading: isUserListLoading,
		isFetched: isUserListFetched,
	} = useBranchUsersList(task.branchId)

	const { mutateAsync: sendEmail, isLoading: isSendEmailLoading } = useMutation(TasksApi.sendEmailForInterview)

	const propertyTypes = useMemo(
		() =>
			propertyTypeKeys
				.map(({ name }) => {
					// const formattedKey = t(`orders.propertyTypes.${camelCase(key)}`)
					return {
						value: name,
						label: name,
					}
				})
				.sort((prev, next) => (prev.label < next.label ? -1 : 1)),
		[propertyTypeKeys]
	)

	const { data: caseDetails } = useCaseById(task.caseId)

	const propertyTypeLabel = propertyTypes.find(candidate => candidate?.value === caseDetails?.property?.type)?.label

	const onSubmit = async (data: ITaskInputTextForm) => {
		const inputText: ITaskInputText = {
			email: data.email || '',
			name: data.contactName || '',
			taskId: task.id,
		}

		let isSendEmailPosted = false

		if (data.isInterviewRequired) {
			isInterviewChecked = true
			const emailResponse = await sendEmail(inputText)

			isSendEmailPosted = emailResponse.success

			if (!isSendEmailPosted) {
				message.error(t('modals.textInput.failed'))
				return
			}
		}

		const isSubmitted = await submit(mapInputTextToNote(data))
		const isSuccess = isInterviewChecked ? isSubmitted && isSendEmailPosted : isSubmitted

		if (isSuccess) {
			if (onTaskComplete) {
				onTaskComplete(task)
				return
			}

			hideModal()
		}
	}

	const isLoading = isPropertyTypeListLoading || isUserListLoading || isSubmitting || isSendEmailLoading
	const isFetched = isPropertyTypeListFetched && isUserListFetched

	const initialValues: ITaskInputTextForm = useMemo(
		() => ({
			inputArea: '',
			propertyType: propertyTypeLabel || '',
			isInterviewRequired: false,
			responsibleAgent: userList?.find(({ id }) => id === task.contactPersonId)?.fullName || '',
			contactType: contactPersons?.[0]?.type || '',
			phone: contactPersons?.[0]?.phone || '',
			secondaryPhone: contactPersons?.[0]?.phoneAlt || '',
			email: contactPersons?.[0]?.email || '',
			contactName: contactPersons?.[0]?.name || '',
			...(!isNoStyleText && { radioGroup: text.radioGroup.options[0] }),
		}),
		[userList, task, isNoStyleText, text.radioGroup.options, propertyTypeLabel, contactPersons]
	)

	const match = useScreenMatch()

	const generateTooltip = (desc: string) => {
		const defaultSize = {
			title: desc,
			icon: <Help24 />,
			placement: 'rightTop',
		}

		if (desc.length > 200) {
			return {
				...defaultSize,
				overlayStyle: { maxWidth: 400 },
			}
		} else {
			return defaultSize
		}
	}

	const InterviewSection: FC = () => {
		const { contactPersonTypeOptions, isLoading: isContactTypeListLoading } = useContactPersons()

		return (
			<>
				<FormItem name="contactType" label={t('common.terms.contactPerson')} required>
					<Select name="contactType" disabled={isContactTypeListLoading}>
						{!isContactTypeListLoading &&
							contactPersonTypeOptions &&
							contactPersonTypeOptions.map(({ value, label }) => (
								<Select.Option key={value} value={value}>
									{label}
								</Select.Option>
							))}
					</Select>
				</FormItem>
				<FormItem name="contactName" label={t('common.terms.name')} required>
					<FormikInput name="contactName" />
				</FormItem>
				<FormItem name="phone" label={t('common.terms.phone')} required>
					<FormikPhoneInput country={user?.branches?.[0]?.countryCode?.toLowerCase()} name="phone" />
				</FormItem>
				<FormItem name="secondaryPhone" label={t('common.terms.phoneAlt')}>
					<FormikPhoneInput country={user?.branches?.[0]?.countryCode?.toLowerCase()} name="secondaryPhone" />
				</FormItem>
				<FormItem name="email" label={t('common.terms.email')} required>
					<FormikInput name="email" />
				</FormItem>
			</>
		)
	}

	return (
		<Formik<ITaskInputTextForm>
			initialValues={initialValues}
			validationSchema={formSchema}
			enableReinitialize
			onSubmit={onSubmit}
		>
			{({ values, dirty, isValid, isSubmitting: isSubmittingForm }) => (
				<Modal
					visible
					closeIcon={<Close24 />}
					title={<TaskModalTitle title={text.inputArea.heading} caseId={task.caseReference} />}
					onCancel={onTaskCancel ? () => onTaskCancel(task) : hideModal}
					footer={
						<Button
							form="task-text-input-form"
							htmlType="submit"
							type="primary"
							disabled={!dirty || !isValid}
							loading={isLoading}
						>
							{t('common.actions.completeTask')}
						</Button>
					}
				>
					<UserInteraction enabled={!isSubmittingForm}>
						<Form id="task-text-input-form" layout="vertical">
							<Autolocalize />
							<Row>
								<Col span={24}>
									{!isFetched ? (
										<Skeleton.TextInputForm />
									) : (
										<Styled.FormWrapper>
											<FormItem
												name="inputArea"
												label={<>{text.inputArea.heading}</>}
												required
												tooltip={generateTooltip(text.inputArea.description)}
											>
												<FormikTextArea
													name="inputArea"
													autoSize={{ minRows: 2, maxRows: 4 }}
													size="small"
													placeholder={t('modals.textInput.inputArea.placeholder')}
												/>
											</FormItem>

											<FormItem
												name="propertyType"
												label={<>{t('common.terms.propertyType')}</>}
												required
												tooltip={generateTooltip(text.propertyTypeDesc)}
											>
												<Select
													showSearch
													name="propertyType"
													placeholder={t('modals.textInput.propertyTypeLabel')}
													disabled={isPropertyTypeListLoading}
												>
													{propertyTypes.map(({ value, label }, index) => (
														<Select.Option key={index} value={value}>
															{label}
														</Select.Option>
													))}
												</Select>
											</FormItem>

											{!isNoStyleText && (
												<FormItem
													name="radioGroup"
													label={<>{text.radioGroup.heading}</>}
													required
													tooltip={generateTooltip(text.radioGroup.description)}
												>
													{match.map({
														xxs: (
															<Select name={'radioGroup'}>
																{text.radioGroup.options.map(option => (
																	<Select.Option key={option} name={option} value={option}>
																		{option}
																	</Select.Option>
																))}
															</Select>
														),
														md: (
															<Radio.Group name="radioGroup">
																{text.radioGroup.options.map(option => (
																	<Radio key={option} name={option} value={option} size="small">
																		{option}
																	</Radio>
																))}
															</Radio.Group>
														),
													})}
												</FormItem>
											)}

											<FormItem
												name="isInterviewRequired"
												label={<>{t('common.terms.interview')}</>}
												tooltip={generateTooltip(text.interviewDesc)}
											>
												<Checkbox name="isInterviewRequired">{t('modals.textInput.conductInterviewAction')}</Checkbox>
											</FormItem>

											{values.isInterviewRequired && <InterviewSection />}

											<FormItem
												name="responsibleAgent"
												label={<>{t('common.terms.responsibleAgent')}</>}
												required
												tooltip={generateTooltip(text.responsibleAgentDesc)}
											>
												<Select
													name="responsibleAgent"
													placeholder={t('common.placeholders.selectAgent')}
													disabled={isUserListLoading}
												>
													{!isUserListLoading &&
														userList &&
														userList.map(({ fullName, email }) => (
															<Select.Option key={fullName} value={fullName}>
																{fullName} ({email})
															</Select.Option>
														))}
												</Select>
											</FormItem>
										</Styled.FormWrapper>
									)}
								</Col>
							</Row>
						</Form>
					</UserInteraction>
				</Modal>
			)}
		</Formik>
	)
}

export default TaskTextInputModal
