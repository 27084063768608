import { color, padding } from '@libs/theme/mixins'
import styled from 'styled-components'
import { Typography } from 'antd'

const CardTitle = styled.div`
	// Just to make sure title is rendered but not visible (in order to align the cards)
	visibility: hidden;
`

const OrderLine = styled.div`
	display: flex;
	justify-content: space-between;

	&:not(:last-child) {
		padding-bottom: ${padding('xxs')};
		border-bottom: 1px solid ${color(['primary', '200'])};
	}
`

const Price = styled(Typography.Text)`
	white-space: nowrap;
`

export default {
	CardTitle,
	OrderLine,
	Price,
}
