import { margin, padding } from '@libs/theme/mixins'
import styled from 'styled-components'
import { Alert } from 'antd'

const AnchorContainer = styled.div`
	margin-bottom: ${margin('xxs')};
`

const NoMaterialsAlert = styled(Alert)`
	padding: ${padding('md')} !important;
`

export default {
	AnchorContainer,
	NoMaterialsAlert,
}
