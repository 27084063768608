import { IBranch, IDatahubCase, IDatahubOrderLine, IDatahubTask, ISimplifiedOrder, TaskStatus } from '@libs/api'

const isProdLikeEnv =
	process.env.NEXT_PUBLIC_DEPLOYMENT_ENV === 'production' || process.env.NEXT_PUBLIC_DEPLOYMENT_ENV === 'qa'

export default {
	dashboard: () => '/',
	newOrder: () => '/new-order',
	receipt: ({ orderId, caseId }: { orderId: ISimplifiedOrder['id']; caseId: IDatahubCase['id'] }) =>
		`/receipt?orderId=${orderId}&caseId=${caseId}`,
	activeDirectory: () => '/active-directory',
	login: () => '/login',
	esoftLogin: () => '/esoft',
	presentation: (caseId: IDatahubCase['id'], filter: string) => `/presentation?caseId=${caseId}&filter=${filter}`,
	users: () => '/users',
	authRedirect: () => '/auth-redirect',
	feedback: ({
		caseId,
		orderId,
		orderlineId,
		vendorOrderLineId,
	}: {
		caseId: IDatahubCase['id']
		orderId: ISimplifiedOrder['id']
		orderlineId: IDatahubOrderLine['id']
		vendorOrderLineId: IDatahubOrderLine['vendorOrderLineId']
	}) =>
		`/feedback?caseId=${caseId}&orderId=${orderId}&vendorOrderLineId=${vendorOrderLineId}&orderlineId=${orderlineId}`,
	cases: {
		list: () => '/cases',
		view: (id: IDatahubCase['id'], clientId: IBranch['axCustomerId']) =>
			`/cases/${encodeURIComponent(id)}?clientId=${clientId}`,
		details: (id: IDatahubCase['id']) => `/cases/${encodeURIComponent(id)}/details`,
		viewOrder: (caseId: IDatahubCase['id'], orderId: ISimplifiedOrder['id']) =>
			`/cases/${encodeURIComponent(caseId)}/orders?orderId=${orderId}`,
	},
	tasks: {
		list: (query?: string, statuses?: TaskStatus[]) => {
			let url = '/tasks'

			if (query) {
				url += `?query=${query}`
			}

			if (statuses && statuses.length > 0) {
				if (query) url += '&'
				else url += '?'

				url += statuses.map(status => `status[]=${status}`).join('&')
			}
			return url
		},
		view: (id: IDatahubTask['id']) => `/tasks/${id}`,
	},
	support: () => '/support',
	externalLinks: {
		shine: () => (isProdLikeEnv ? 'https://shine.esoft.com' : 'https://connect-testing.esoft.com'),
		shineLogin: ({ token }: { token: string }) =>
			isProdLikeEnv
				? `https://shine.esoft.com/login/redirect?code=''&id_token=${token}`
				: `https://connect-testing.esoft.com/login/redirect?code=''&id_token=${token}`,
		esoft: () => 'http://esoft.dk',
	},
}
