import styled from 'styled-components'
import { Card } from 'antd'
import { Container, Grid } from '@libs/components'
import { padding, color, fontSize, screen } from '@libs/theme/mixins'

const Wrapper = styled(Card)`
	.ant-card-body {
		padding: 0;
		display: flex;
		flex-direction: column;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
`

const ItemRow = styled(Grid.Row)`
	width: 100%;
`

const ItemCol = styled(Grid.Col)`
	display: flex;
`

const Item = styled(Container)`
	flex: 1;

	${screen.lte.sm`
		flex: 1 1 100%;
	`}
`

const ImageWrapper = styled.div`
	position: relative;
	flex: 1;
	display: flex;
	align-items: center;
	overflow: hidden;
	height: 0;
	padding-top: 56.25%;

	img {
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		object-fit: cover;
		z-index: 0;
		width: 100%;
	}
`

const LabelContainer = styled.div`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	background: rgba(0, 0, 0, 0.6);
	padding: ${padding('xxs')};
	color: ${color(['gray', '000'])};
	writing-mode: vertical-rl;
	font-size: ${fontSize('xxs')};
`

export default { Wrapper, ImageWrapper, LabelContainer, Item, ItemRow, ItemCol }
