import styled, { css } from 'styled-components'
import { Card, Modal as _Modal } from 'antd'
import { borderRadius, color, easing, fontSize, margin, padding, rgbaColor, screen } from '@libs/theme/mixins'
import { Container, ContainerStyled } from '@libs/components'
import { CollageLayoutType } from '../CollageEditor/types'

const Modal = styled(_Modal)<{ $transitionTimeout: number }>`
	max-width: 90vw;

	.ant-steps {
		width: 100%;
		max-width: 500px;
		margin-left: auto;
		margin-right: auto;
	}

	// Transition animations
	.transition-enter {
		opacity: 0;
	}

	.transition-enter-active {
		opacity: 1;
		transition: all ${({ $transitionTimeout }) => `${$transitionTimeout}ms`} ${easing('in')};
	}

	.transition-exit {
		opacity: 0;
	}

	.transition-exit-active {
		opacity: 1;
		transition: all ${({ $transitionTimeout }) => `${$transitionTimeout}ms`} ${easing('in')};
	}

	.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
		margin-left: 0;
	}

	${screen.lte.sm`
		top: 8px;
	`}
`

const TemplateItem = styled(Card)<{ selected: boolean; $smallSize?: boolean }>`
	.ant-card-body {
		transition: all 0.3s;
		cursor: pointer;
		position: relative;
		padding: 0;
		overflow: hidden;
		border-width: 4px;
		border-radius: ${borderRadius('md')};

		.ant-card-head-title {
			overflow: visible;
		}

		${ContainerStyled.Wrapper} {
			height: 100%;
		}

		img {
			margin: 0 !important;
			height: 100%;
			min-width: 100%;
			object-fit: cover;
		}

		${({ selected }) =>
			selected &&
			css`
				border-color: ${color('primary')};
				box-shadow: 0 6px 12px 0 ${rgbaColor('primary', 0.25)};
			`}
	}

	${({ $smallSize }) =>
		$smallSize &&
		css`
			.ant-card-body {
				border-width: 2px;
			}
		`}
`

const TemplateItemFooter = styled.div<{ $smallSize?: boolean }>`
	display: flex;
	justify-content: center;
	width: 100%;
	background-color: ${color(['primary', '800'])};
	padding: ${padding('xxs')};

	.ant-typography {
		margin: 0 5px;
	}

	${({ $smallSize }) =>
		$smallSize &&
		css`
			font-size: ${fontSize('sm')};
			padding: 4px;

			.ant-typography {
				margin: 0;
			}

			.ant-radio-inner {
				transform: scale(0.75);
			}
		`}
`

const GridTemplateItem = styled(Container)<{ $gridArea: string; $layout: CollageLayoutType }>`
	gap: ${margin('xxs')};
	margin-right: 0 !important;
	display: grid;
	padding: ${padding('xxs')};

	${({ $gridArea, $layout }) => css`
		grid-template-areas: ${$gridArea};
		width: ${$layout === 'vertical' ? '50%' : '100%'};
	`}

	> * {
		background: ${color(['gray', '200'])};
	}
`

const ImageBlocker = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	user-select: none;
`

export default {
	Modal,
	TemplateItem,
	TemplateItemFooter,
	GridTemplateItem,
	ImageBlocker,
}
